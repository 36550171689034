import firebase from 'firebase/app';
import 'firebase/analytics';

import {
  EventParams,
  AnalyticsService as AnalyticsService,
  UserProperties,
} from './AnalyticsService';

const analytics = firebase.analytics();

export class FirebaseAnalytics extends AnalyticsService {
  async setCurrentScreen(screenName: string): Promise<void> {
    try {
      await analytics.setCurrentScreen(screenName);
    } catch (err) {
      console.error(err);
    }
  }
  async setUserId(uid: string): Promise<void> {
    try {
      await analytics.setUserId(uid);
    } catch (err) {
      console.error(err);
    }
  }
  async logEvent(name: string, params?: EventParams): Promise<void> {
    try {
      await analytics.logEvent(name, params);
    } catch (err) {
      console.error(err);
    }
  }
  async setUserProperties(props: UserProperties): Promise<void> {
    try {
      await analytics.setUserProperties(props);
    } catch (err) {
      console.error(err);
    }
  }
}
