import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  errorMessageState,
  selectedFirstDeliveryDateState,
  selectedMembershipProductState,
  subscriptionState,
} 
from '../../states';
import { Subscription } from '../../../domain/entities/Subscription';
import { Routes } from '../../../config';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { SelectFirstDeliveryDateCalendar } from './SelectFirstDeliveryDateCalendar';
import { addBusinessDays, isWeekend } from 'date-fns';
import AlertDialog from '../../components/AlertDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { DeliveryDateInfo } from './DeliveryDateInfo';
import { DeliveryDateHeader } from '../DeliveryDateHeader';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',

    [theme.breakpoints.up('sm')]: {
      width: '534px',
      borderRadius: '5rem',
      boxShadow: '0px 5px 23px rgba(0, 0, 0, 0.05)',
      marginBottom: '104px',
    },
  },

  container: {
    padding: '0 20px',

    [theme.breakpoints.up('sm')]: {
      padding: '0 37px',
    },
  },

  footer: {
    marginTop: '87px',
    width: '100%',
    textAlign: 'center',
    fontSize: '1rem',
    color: '#578FFC',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
      marginTop: '119px',
    },
  },

  button: {
    display: 'block',
    width: '100%',
    height: '4rem',
    borderRadius: '5.5rem',
    border: '2px solid black',
    color: 'black',
    fontSize: '1.7rem',
    fontWeight: 500,
    margin: '1.6rem auto 100px',
    background: 'none',
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
      width: '36.9rem',
      height: '4.9rem',
      fontWeight: 'bold',
      borderRadius: '4.2rem',
      border: '1px solid black',
      margin: '1.6rem auto 105px',
    },
  },
  notice: {
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: '1.3rem',
    color: 'grey',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
    },
  },
}));

export default function SelectFirstDeliveryDatePage() {
  const classes = useStyles();
  const history = useHistory();
  const subscription = useRecoilValue(subscriptionState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const [alertOpen, setAlertOpen] = useState(false);
  const [redirectionPage, setRedirectionPage] = useState('');
  const setSelectedFirstDeliveryDate = useSetRecoilState(
    selectedFirstDeliveryDateState,
  );
  const today = new Date();
  const [selectedDate, setSelectedDate] = useState<Date>(
    today.getHours() < 10 ? today : addBusinessDays(today, 1),
  );
  const selectedMembershipProduct = useRecoilValue(
    selectedMembershipProductState,
  );

  const isSubscriber = Subscription.isActive(subscription);

  useEffect(() => {
    // 첫 발송일 선택시 시간은 항상 오전 10시로
    // rel: https://artlabai.atlassian.net/jira/software/projects/MN/boards/5?selectedIssue=MN-369
    selectedDate.setHours(10, 0, 0, 0);
    setSelectedFirstDeliveryDate(selectedDate);
  }, [selectedDate, setSelectedFirstDeliveryDate]);

  useEffect(() => {
    if (isSubscriber) {
      setAlertOpen(true);
    } else if (!selectedMembershipProduct) {
      setErrorMessage('결제 진행 정보가 없습니다. 다시 시도해 주세요.');
      setRedirectionPage(Routes.skinQuizSolution);
    }
  }, [isSubscriber, selectedMembershipProduct, setErrorMessage]);

  function onConfirmClicked() {
    history.push(Routes.skinQuizAddress);
  }

  function onAlertDialogClosed() {
    history.push(Routes.mypageMembership);
  }

  function onErrorDialogClosed() {
    setErrorMessage('');
    if (redirectionPage) {
      history.replace(redirectionPage);
    }
  }

  function updateSelectedDate(value: Date) {
    if (isWeekend(value)) {
      // no-op
    } else {
      setSelectedDate(value);
    }
  }

  return (
    <main className={classes.root}>
      <DeliveryDateHeader />
      <section className={classes.container}>
        <SelectFirstDeliveryDateCalendar
          selectedDate={selectedDate}
          updateSelectedDate={updateSelectedDate}
        />
        <DeliveryDateInfo selectedDate={selectedDate} />
        <p className={classes.footer}>
          발송 주기는 MY페이지에서 언제든 변경할 수 있어요.
        </p>
        <button className={classes.button} onClick={onConfirmClicked}>
          확인
        </button>
      </section>
      <AlertDialog
        open={alertOpen}
        message={
          <p>
            이미 멤버십 회원입니다.
            <br />
            MY 멤버십 페이지에서,
            <br />
            이용중인 혜택을 확인해 보세요.
          </p>
        }
        onClose={onAlertDialogClosed}
      />
      <ErrorDialog onClose={onErrorDialogClosed} />
    </main>
  );
}
