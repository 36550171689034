import React from 'react';
import SkinTypeRecommend from '../../../data/skinTypeRecommend';
import { Box, Typography } from '@material-ui/core';
import { BoxDivider, getJpgImageSrc } from '../styles';

import { IngredientLabel, SubtitleTypo, useStyles } from './IngredientBox';
import {
  ReportImage,
  Prescription,
} from '../../../domain/entities/SkinDiagnosis';

type PropsFor0 = {
  userName: string;
  skinType: string;
  reportImage: ReportImage;
  prescription: Prescription;
};

export function IngredientRecommendFor0({
  userName,
  skinType,
  reportImage,
  prescription,
}: PropsFor0): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={`${classes.box} ${classes.ingredientBox}`}>
      <Typography className={classes.title}>추천 성분</Typography>
      <Typography className={classes.boxSummary}>
        <Typography component={'p'}>
          {skinType}타입인 {userName}님의
        </Typography>
        <Typography component={'p'}>피부고민 개선을 돕는 성분입니다</Typography>
      </Typography>

      <Box className={classes.list}>
        {reportImage?.ingredient?.map((image, index) => {
          const item = prescription?.ingredients[index];
          let RecommendCategory: string;
          switch (item.tag) {
            case '피부건조개선':
            case '유수분밸런스조절':
              RecommendCategory = '수분보유력';
              break;
            case '피지분비조절':
              RecommendCategory = '유분보유력';
              break;
            case '피부진정':
            case '트러블케어':
            case '피부장벽강화':
              RecommendCategory = '피부 보호력';
              break;
            case '색소침착개선':
              RecommendCategory = '피부 투명도';
              break;
            case '탄력강화':
              RecommendCategory = '피부 탄력';
              break;
          }

          return (
            <Box
              key={item.name}
              className={classes.listItem}
              borderTop="1px solid #F0F0F0"
            >
              <img
                className={classes.ingredientImage}
                src={getJpgImageSrc(image)}
                alt={''}
              />
              <Box display="flex" flexDirection="column" paddingBottom="1.1rem">
                <SubtitleTypo titleLen={item.name.length}>
                  {item.name}
                  {item.tag && (
                    <IngredientLabel
                      className={classes.ingredientLabel}
                      skintype={RecommendCategory}
                    >
                      {item.tag}
                    </IngredientLabel>
                  )}
                </SubtitleTypo>
                <Typography className={classes.body}>{item.perform}</Typography>
              </Box>
              <BoxDivider />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

function RandArray(array: string[]) {
  const rand = (Math.random() * array.length) | 0;
  const rValue = array[rand];
  return rValue;
}

type PropsForN = {
  userName: string;
  skinType: string;
  goodIngredients: string[];
};

export function IngredientRecommendForN({
  userName,
  skinType,
  goodIngredients,
}: PropsForN): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={`${classes.box} ${classes.ingredientBox}`}>
      <Typography className={classes.title}>추천 성분</Typography>
      <Typography className={classes.boxSummary}>
        <Typography component={'p'}>
          {skinType}타입인 {userName}님의
        </Typography>
        <Typography component={'p'}>피부고민 개선을 돕는 성분입니다</Typography>
      </Typography>

      <Box className={classes.list}>
        {goodIngredients?.map((ingredient, index) => {
          const item = SkinTypeRecommend[ingredient];
          const itemTag = RandArray(item.tag);
          const image =
            'ingredient' +
            ingredient.slice(0, 1).toUpperCase() +
            ingredient.slice(1);
          let RecommendCategory: string;
          switch (itemTag) {
            case '피부건조개선':
            case '유수분밸런스조절':
              RecommendCategory = '수분보유력';
              break;
            case '피지분비조절':
              RecommendCategory = '유분보유력';
              break;
            case '피부진정':
            case '트러블케어':
            case '피부장벽강화':
              RecommendCategory = '피부 보호력';
              break;
            case '색소침착개선':
              RecommendCategory = '피부 투명도';
              break;
            case '탄력강화':
              RecommendCategory = '피부 탄력';
              break;
          }

          return (
            <Box
              key={item.korean}
              className={classes.listItem}
              borderTop="1px solid #F0F0F0"
            >
              <img
                className={classes.ingredientImage}
                src={getJpgImageSrc(image)}
                alt={''}
              />
              <Box display="flex" flexDirection="column" paddingBottom="1.1rem">
                <SubtitleTypo titleLen={item.korean.length}>
                  {item.korean}
                  {item.tag && (
                    <IngredientLabel
                      className={classes.ingredientLabel}
                      skintype={RecommendCategory}
                    >
                      {itemTag}
                    </IngredientLabel>
                  )}
                </SubtitleTypo>
                <Typography className={classes.body}>
                  {item.description}
                </Typography>
              </Box>
              <BoxDivider />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
