import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ArrowBack as BackIcon, Menu as MenuIcon } from '@material-ui/icons';
import { Routes } from '../../config';
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  appBarTitleState,
  authCredentialState,
  authService,
  useWhiteTextInToolbarState,
} from '../states';
import logoImage from '../../assets/image/manifold_logo_black.svg';

const useStyles = makeStyles((theme: Theme) => ({
  appbar: {
    position: 'absolute',
    background: 'transparent',
  },
  toolbar: {
    height: '7rem',
  },
  drawer: {
    width: '20rem',
  },
  toolbarIcon: {
    position: 'absolute',
    left: '1.6rem',
  },
  toolbarMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 4,

    [theme.breakpoints.up('sm')]: {
      display: 'block',
      width: '50%',
      paddingLeft: 'calc(25% - 19rem)',
    },
  },
  toolbarMainButton: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toolbarMenu: {
    display: 'flex',
    flex: 1,
  },
  menuItem: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  menuItemText: {
    '&:hover': {
      color: '#000;',
    },
    '*:focus': {
      outline: 'none',
    },
  },
  activeMenuItemText: {
    color: '#000',
  },
  divider: {
    marginBottom: '1rem',
  },
}));

const menuItems = [
  {
    name: '피부테스트',
    path: Routes.skinQuiz,
  },
  // {
  //   name: '브랜드 스토리',
  //   path: Routes.story,
  // },
  // {
  //   name: '고객후기',
  //   path: { pathname: 'https://bit.ly/3kXGy6H' },
  //   newTab: true,
  // },
  // {
  //   name: '고객센터',
  //   path: Routes.support,
  // },
  // {
  //   name: '로그인',
  //   path: Routes.login,
  //   auth: false,
  // },
  // {
  //   name: 'MY 페이지',
  //   path: Routes.mypage,
  //   auth: true,
  // },
];

export default function MenuBar(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const auth = useRecoilValue(authService);
  const authCredential = useRecoilValue(authCredentialState);
  const appBarTitle = useRecoilValue(appBarTitleState);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isHome, setIsHome] = useState(false);
  const useToolbarMenu = Routes.useToolbarMenu(location.pathname);

  const useWhiteTextInToolbar = useRecoilValue(useWhiteTextInToolbarState);

  function ToolbarMenu(): JSX.Element {
    return (
      <>
        <MainLink />
        <Hidden xsDown>{listToolbarItems()}</Hidden>
        <Hidden xsDown>
          <Box flexGrow="1" />
        </Hidden>
      </>
    );
  }

  function ToolbarIcon(): JSX.Element {
    return (
      <>
        <Hidden smUp>
          {useToolbarMenu ? (
            <IconButton
              className={classes.toolbarIcon}
              edge="start"
              aria-label="menu"
              onClick={() => setOpenDrawer(true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
          ) : (
            <IconButton
              className={classes.toolbarIcon}
              edge="start"
              aria-label="back"
              onClick={() => history.goBack()}
            >
              <BackIcon fontSize="large" />
            </IconButton>
          )}
        </Hidden>
      </>
    );
  }

  function MainLink(props) {
    return (
      <div className={classes.toolbarMain} {...props}>
        {smUp || !appBarTitle ? (
          <NavLink className={classes.toolbarMainButton} to={Routes.root}>
            <img src={logoImage} alt={'Logo'} />
          </NavLink>
        ) : (
          <Box fontSize="1.7rem" fontWeight="normal">
            {appBarTitle}
          </Box>
        )}
      </div>
    );
  }

  function LogoutMenu(props) {
    return (
      <ListItem
        button
        style={{ display: !authCredential && 'none' }}
        className={classes.menuItem}
        onClick={() => auth.signOut()}
        {...props}
      >
        <ListItemText
          primary={
            <Typography variant="h4" noWrap>
              로그아웃
            </Typography>
          }
        ></ListItemText>
      </ListItem>
    );
  }

  function ListItemLink(props) {
    return (
      <ListItem
        button
        className={classes.menuItem}
        component={Link}
        {...props}
      />
    );
  }

  function checkAuthState(item) {
    if (item.auth === true && !authCredential) {
      return false;
    } else if (item.auth === false && authCredential) {
      return false;
    } else {
      return true;
    }
  }

  function checkShareState() {
    if (location.pathname.split('/').includes('share')) {
      return true;
    } else {
      return false;
    }
  }
  function listDrawerItems() {
    return (
      <List
        className={classes.drawer}
        component="nav"
        onClick={toggleDrawer}
        onKeyDown={toggleDrawer}
      >
        <MainLink height="5rem" />
        <Divider className={classes.divider} />
        {menuItems.map((item) => {
          if (checkShareState()) {
            return;
          }
          if (!checkAuthState(item)) {
            return;
          }
          return (
            <ListItemLink key={item.name} to={item.path} target={undefined}>
              <ListItemText
                primary={
                  <Typography variant="h4" noWrap>
                    {item.name}
                  </Typography>
                }
              />
            </ListItemLink>
          );
        })}
        <Divider className={classes.divider} />
        <LogoutMenu />
      </List>
    );
  }

  function listToolbarItems() {
    return (
      <List className={classes.toolbarMenu}>
        {menuItems.map((item) => {
          if (checkShareState()) {
            return;
          }
          if (!checkAuthState(item)) {
            return;
          }
          const isActive = location.pathname === item.path;
          return (
            <ListItemLink key={item.name} to={item.path} target={undefined}>
              <ListItemText
                style={
                  isActive
                    ? {}
                    : {
                        color: useWhiteTextInToolbar ? '#F3F3F3' : '#9C9C9C',
                      }
                }
                primary={
                  <Typography
                    className={
                      isActive
                        ? classes.activeMenuItemText
                        : classes.menuItemText
                    }
                    variant="h4"
                    noWrap
                  >
                    {item.name}
                  </Typography>
                }
              />
            </ListItemLink>
          );
        })}
      </List>
    );
  }

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    setIsHome(location.pathname.slice(0, 9) === Routes.root);
  }, [location.pathname]);

  return (
    <AppBar
      position="static"
      className={classes.appbar}
      style={isHome ? { boxShadow: 'none' } : {}}
      color="transparent"
      elevation={1}
    >
      <Toolbar className={classes.toolbar}>
        <ToolbarIcon />
        <ToolbarMenu />
      </Toolbar>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        {listDrawerItems()}
      </Drawer>
    </AppBar>
  );
}
