import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authCredentialState,
  authUserState,
  errorMessageState,
  paymentCouponState,
  paymentPriceState,
  quizBackgroundState,
  selectedMembershipProductState,
  skinQuizAnswerSheetState,
  skinQuizSimpleAnswerSheetState,
  subscriptionState,
} from '../../states';
import { PaymentFlowHeader } from '../PaymentFlowHeader';
import { useHistory } from 'react-router';
import { Routes } from '../../../config';
import { AddressForm2 } from '../../components/addressform/AddressForm2';
import { RemoteUser } from '../../../data/repositories/RemoteUser';
import { Subscription } from '../../../domain/entities/Subscription';
import AlertDialog from '../../components/AlertDialog';
import ErrorDialog from '../../components/ErrorDialog';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { PaymentPrice } from '../../../domain/entities/Payment';
import { QuizBackground } from '../../../domain/entities/QuizBackground';
import { ProductInfo } from './ProductInfo';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',

    [theme.breakpoints.up('sm')]: {
      width: '534px',
      borderRadius: '5rem',
      boxShadow: '0px 5px 23px rgba(0, 0, 0, 0.05)',
      marginBottom: '180px',
    },
  },
}));

export const AddressPage2 = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const setQuizBackground = useSetRecoilState(quizBackgroundState);
  const selectedMembershipProduct = useRecoilValue(
    selectedMembershipProductState,
  );
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const setAnswerSheet = useSetRecoilState(skinQuizAnswerSheetState);
  const setSimpleAnswerSheet = useSetRecoilState(
    skinQuizSimpleAnswerSheetState,
  );
  const [alertOpen, setAlertOpen] = useState(false);
  const [redirectionPage, setRedirectionPage] = useState('');
  const authUser = useRecoilValue(authUserState);
  const authCredential = useRecoilValue(authCredentialState);
  const subscription = useRecoilValue(subscriptionState);
  const setPaymentPrice = useSetRecoilState(paymentPriceState);
  const setPaymentCoupon = useSetRecoilState(paymentCouponState);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const isSubscriber = Subscription.isActive(subscription);

  useEffect(() => {
    setQuizBackground(QuizBackground.GREY);
  }, [setQuizBackground]);

  useEffect(() => {
    if (!selectedMembershipProduct) {
      setErrorMessage('결제 진행 정보가 없습니다. 다시 시도해 주세요.');
      setRedirectionPage(Routes.skinQuizSolution);
    } else {
      setPaymentPrice(
        new PaymentPrice({
          price:
            selectedMembershipProduct.discountedPrice ??
            selectedMembershipProduct.price,
        }),
      );
      setPaymentCoupon('');
    }
  }, [
    selectedMembershipProduct,
    setErrorMessage,
    setPaymentCoupon,
    setPaymentPrice,
  ]);

  useEffect(() => {
    if (isSubscriber) {
      setAlertOpen(true);
    }
  }, [isSubscriber]);

  useEffect(() => {
    async function checkMembershipExists(
      remoteUserRepo: RemoteUser,
      userId: string,
    ) {
      const memberships = await remoteUserRepo.getUserMemberships(userId);
      return memberships && memberships?.length > 0;
    }

    async function updateUserQuizByUserId(
      remoteUserRepo: RemoteUser,
      userId: string,
      quizId: string,
    ) {
      remoteUserRepo
        .updateUserQuiz(userId, quizId)
        .then(() => {
          setAnswerSheet({});
          setSimpleAnswerSheet({});
        })
        .catch((err) => console.error(err));
    }

    const userId = authUser?.userId;
    const quizId = authUser?.quizId;
    const userIdAndQuizIdExists = userId && quizId;
    if (!userIdAndQuizIdExists) return;

    const repo = new RemoteUser(authCredential);

    checkMembershipExists(repo, userId).then((membershipsExists) => {
      if (!membershipsExists) {
        updateUserQuizByUserId(repo, userId, quizId);
      } else {
        return;
      }
    });
  }, [
    authCredential,
    authUser?.quizId,
    authUser?.userId,
    history,
    setAnswerSheet,
    setSimpleAnswerSheet,
  ]);

  function onAddressSaved() {
    history.push(Routes.skinQuizPayment);
  }

  function onAlertDialogClosed() {
    history.push(Routes.mypageSubscription);
  }

  const onErrorDialogClosed = () => {
    setErrorMessage('');
    if (redirectionPage) {
      history.replace(redirectionPage);
    }
  };

  return (
    <main className={classes.root}>
      <PaymentFlowHeader highlightCount={1} />
      <ProductInfo />
      <AddressForm2
        onSaved={onAddressSaved}
        margin={smUp ? '0px 68px 119px' : '0px 20px 100px'}
        showPlaceholder={true}
      />
      <AlertDialog
        open={alertOpen}
        message={
          <p>
            이미 멤버십 회원입니다.
            <br />
            MY 멤버십 페이지에서,
            <br />
            이용중인 혜택을 확인해 보세요.
          </p>
        }
        onClose={onAlertDialogClosed}
      />
      <ErrorDialog onClose={onErrorDialogClosed} />
    </main>
  );
};
