import React from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import { GridLabelProps } from '@nivo/radar';
import { format } from 'date-fns';

function formatDate(date: Date): string {
  return format(date, 'yyyy.MM.dd');
}

export default function RadarChart({ dataObj, isOnMobile }) {
  const { data, keys, indexBy, color } = dataObj;
  const scoreObj = {};

  let currentReportDate = new Date('2000-01-01');
  const temp = Object.keys(data[0]).filter((elem) => elem !== 'name');
  temp.map((date) => {
    const dateArray = date.split('.');
    const dateObj = new Date(
      Number(dateArray[0]),
      Number(dateArray[1]) - 1,
      Number(dateArray[2]),
    );
    if (currentReportDate < dateObj) currentReportDate = dateObj;
  });
  const currentReportDateString = formatDate(currentReportDate);

  data.map((elem) => {
    const name = elem.name;
    const value = elem[currentReportDateString];
    scoreObj[name] = value;
  });

  const LabelComponent = ({ id, anchor }: GridLabelProps) => {
    const ctop =
      id == '비색소성' || id == '저항성' ? (isOnMobile ? '-2%' : '2%') : '-10%';
    const isMobileStatus = isOnMobile ? '-3.5%' : '0';
    const statusText = scoreObj[id] > 50 ? '충분' : '부족';
    const labelColor = scoreObj[id] > 50 ? '#76D6FF' : '#FF6D6D';
    const statusTextSize = isOnMobile ? 7 : 10;
    const statusBoxW = isOnMobile ? 32 : 39;
    const statusBoxH = isOnMobile ? 11 : 18;
    const labelTextSize = isOnMobile ? 12 : 15;
    const rx = isOnMobile ? '20%' : '25%';
    const textX = isOnMobile ? '30%' : '25%';

    return (
      <g>
        <g
          transform={`translate(${
            anchor === 'end' ? -40 : anchor === 'middle' ? -30 : 8
          }, 7)`}
        >
          <text
            textAnchor="middle"
            style={{
              fontSize: labelTextSize,
              width: '100px',
            }}
            x="5%"
            y={isMobileStatus}
          >
            <tspan>{id}</tspan>
          </text>
          <svg width={statusBoxW} height={statusBoxH} y={ctop}>
            <rect
              width={statusBoxW}
              height={statusBoxH}
              rx={rx}
              fill={labelColor}
            ></rect>
            <text
              x={textX}
              y="55%"
              dominantBaseline="middle"
              style={{
                fill: 'white',
                fontSize: statusTextSize,
                fontWeight: 500,
              }}
            >
              {statusText}
            </text>
          </svg>
        </g>
      </g>
    );
  };
  return (
    <ResponsiveRadar
      gridLabel={LabelComponent}
      gridLabelOffset={15}
      data={data}
      keys={keys}
      dotSize={8}
      indexBy={indexBy}
      maxValue={100}
      gridLevels={4}
      gridShape="linear"
      margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
      colors={color}
      fillOpacity={0.25}
      legends={[
        {
          anchor: 'top-right',
          direction: 'column',
          translateX: -90,
          translateY: -30,
          itemOpacity: 0.7,
          itemDirection: 'right-to-left',
          itemWidth: 20,
          itemHeight: 12,
          itemsSpacing: 12,
          itemTextColor: '#333',
          symbolSize: 11,
          symbolShape: 'circle',
        },
      ]}
      theme={
        isOnMobile
          ? {
              fontSize: 12,
              fontFamily: 'Noto Sans KR',
            }
          : {
              fontSize: 13,
              fontFamily: 'Noto Sans KR',
            }
      }
    />
  );
}
