import React from 'react';
import { Hidden } from '@material-ui/core';

export interface LandingText {
  name: string;
  firstSection: Section;
  secondSection: Section;
  thirdection: Section;
  fourthSection: Section;
  buttonClassName1: string;
  buttonClassName2: string;
}

interface Section {
  title: JSX.Element;
  contents: JSX.Element;
  subContents?: JSX.Element;
}

export const defaultLandingText: LandingText = {
  name: 'default',
  firstSection: {
    title: <></>,

    contents: <>피부에 따라 필요한 성분도 다르니까</>,
  },
  secondSection: {
    title: <>나의 진짜 피부타입</>,
    contents: (
      <>
        전문가가 개발한 테스트로 &nbsp;
        <Hidden smDown>
          <br />
        </Hidden>
        피부 분석을 받아보세요
      </>
    ),
  },
  thirdection: {
    title: <>나에게 맞춘 화장품</>,
    contents: (
      <>
        사용 주기와 피부 상태에 맞춰 &nbsp;
        <Hidden smDown>
          <br />
        </Hidden>
        맞춤형 화장품과 피부 리포트 발송
      </>
    ),
    subContents: <>* 피부 리포트는 온라인에서 확인하실 수 있습니다</>,
  },
  fourthSection: {
    title: (
      <>
        피부,&nbsp;
        <Hidden only={'md'}>
          <br />
        </Hidden>
        전문가에게 물어보세요
      </>
    ),
    contents: (
      <>
        혼자 고민하지 마세요 &nbsp;
        <Hidden smUp>
          <br />
        </Hidden>
        전문가가 함께 해결해드려요
      </>
    ),
  },
  buttonClassName1: 'newnormalButton1',
  buttonClassName2: 'newnormalButton2',
};
export const newNormalLandingText: LandingText = {
  name: 'newNormal',
  firstSection: {
    title: (
      <>
        완전히&nbsp;
        <Hidden smUp>
          <br />
        </Hidden>
        새로운 스킨케어
      </>
    ),

    contents: <>피부에 따라 필요한 성분도 다르니까</>,
  },
  secondSection: {
    title: <>나의 진짜 피부타입</>,
    contents: (
      <>
        전문가가 개발한 테스트로 &nbsp;
        <Hidden smDown>
          <br />
        </Hidden>
        피부 분석을 받아보세요
      </>
    ),
  },
  thirdection: {
    title: <>나에게 맞춘 화장품</>,
    contents: (
      <>
        사용 주기와 피부 상태에 맞춰 &nbsp;
        <Hidden smDown>
          <br />
        </Hidden>
        맞춤형 화장품과 피부 리포트 발송
      </>
    ),
    subContents: <>* 피부 리포트는 온라인에서 확인하실 수 있습니다</>,
  },
  fourthSection: {
    title: (
      <>
        피부,&nbsp;
        <Hidden only={'md'}>
          <br />
        </Hidden>
        전문가에게 물어보세요
      </>
    ),
    contents: (
      <>
        혼자 고민하지 마세요 &nbsp;
        <Hidden smUp>
          <br />
        </Hidden>
        전문가가 함께 해결해드려요
      </>
    ),
  },
  buttonClassName1: 'newnormalButton1',
  buttonClassName2: 'newnormalButton2',
};
export const convenienceLandingText: LandingText = {
  name: 'convenience',
  firstSection: {
    title: (
      <>
        쉽고&nbsp;
        <Hidden smUp>
          <br />
        </Hidden>
        간편한 스킨케어
      </>
    ),

    contents: <>나에게 맞는 성분 조합으로 만들다</>,
  },
  secondSection: {
    title: (
      <>
        3분 만에 확인하는
        <br />내 진짜 피부타입
      </>
    ),
    contents: (
      <>
        핸드폰 하나면 끝! &nbsp;
        <Hidden smDown>
          <br />
        </Hidden>
        피부 분석을 받아보세요
      </>
    ),
  },
  thirdection: {
    title: (
      <>
        원하는 주기마다,
        <br />
        피부에 맞는 성분으로 배송
      </>
    ),
    contents: (
      <>
        계절이나 환경에 따라 달라지는 피부 상태, &nbsp;
        <Hidden smDown>
          <br />
        </Hidden>
        딱 맞는 성분으로 새롭게 보내드려요
      </>
    ),
    subContents: <>* 피부 리포트는 온라인에서 확인하실 수 있습니다</>,
  },
  fourthSection: {
    title: <>피부 전문가와 1:1 상담</>,
    contents: (
      <>
        혼자 고민하지 마세요 &nbsp;
        <Hidden xsDown>
          <br />
        </Hidden>
        언제든 편하게 전문가에게 물어보세요
      </>
    ),
  },
  buttonClassName1: 'convenienceButton1',
  buttonClassName2: 'convenienceButton2',
};
