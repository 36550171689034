import { device } from '../../utils/device';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    color: '#6A6A6A',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.1)',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },

  ingredientBox: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '5.9rem',
      paddingRight: '5.9rem',
    },
  },

  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '5px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginBottom: '9px',
    },
  },

  body: {
    marginBottom: '19px',
    color: '#000000',
    fontSize: '1.2rem',
    lineHeight: '1.738rem',
    fontWeight: 'normal',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
  },
  boxSummary: {
    color: '#000000',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    lineHeight: '1.73rem',
    textAlign: 'center',
    marginBottom: '19px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
    },
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '2.1rem',
    padding: '0 1.7rem',
    // height:'126px',
    // marginBottom: '1.1rem',/
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    },
  },
  ingredientImage: {
    width: '6rem',
    height: '6rem',
    marginRight: '1.2rem',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      width: '8.8rem',
      height: '8.8rem',
      marginRight: '4.3rem',
    },
  },

  divider: {
    margin: '1rem 3.3rem',
    [theme.breakpoints.up('sm')]: {
      margin: '1rem 5.7rem',
    },
  },

  ingredientLabel: {
    verticalAlign: 'inherit',
    marginLeft: '5px',
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px',
      verticalAlign: 'middle',
    },
  },
  cautiontext: {
    textAlign: 'end',
    color: '#C4C4C4',
    fontWeight: 400,
    fontSize: '11px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
  routineList: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    fontSize: '1.3rem',
    lineHeight: '1.9rem',
    margin: '1rem 0',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2.179rem',
    },
  },
  routineListBullet: {
    height: '2.4rem',
    width: '2.4rem',
    minWidth: '2.4rem',
    border: '0.1rem solid',
    borderRadius: '50%',
    margin: '0.7rem 2rem 0.7rem 0',
    // padding: '0.1rem 0 0 0',
    textAlign: 'center',
  },
  routineListItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.2rem',
    wordBreak: 'keep-all',
  },
}));

const colorCategory = {
  '수분보유력': '#FFCAD7',
  '유분보유력': '#B4BDFC',
  '피부 보호력': '#89F3D9',
  '피부 투명도': '#FFC38B',
  '피부 탄력도': '#E3BFFF',
};

export const IngredientLabel = styled.span<{ skintype: string }>`
  font-size: 1rem;
  font-weight: normal;
  color: #fff;
  background-color: #ffc38b;
  border-radius: 13px;
  text-align: center;

  padding: 1px 9px;
  ${(props) =>
    props.skintype &&
    css`
      background-color: ${colorCategory[props.skintype]};
    `}
`;

export const SubtitleTypo = styled(Typography)<{ titleLen: number }>`
  && {
    font-size: 1.4rem;
    margin-top: 19px;
    margin-bottom: 19px;
    font-weight: bold;
    color: #000;

    @media ${device.desktop} {
      font-size: 1.6rem;
    }
    @media ${device.mobile} {
      ${(props) =>
        props.titleLen >= 10 &&
        css`
          font-size: 1rem;
        `}
    }
  }
`;
