import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { InAppBrowserExceptionPage } from './app/home/InAppBrowserExceptionPage';

function browserExceptionHandler() {
  if (
    navigator.userAgent.match(
      /inapp|NAVER|KAKAOTALK|Snapchat|Line|WirtschaftsWoche|Thunderbird|Instagram|everytimeApp|WhatsApp|Electron|wadiz|AliApp|zumapp|iPhone(.*)Whale|Android(.*)Whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|SamsungBrowser\/[^1]/i,
    )
  ) {
    if (navigator.userAgent.match(/iPhone|iPad/i)) {
      return <InAppBrowserExceptionPage />;
    } else {
      location.href =
        'intent://' +
        location.href.replace(/https?:\/\//i, '') +
        '#Intent;scheme=http;package=com.android.chrome;end';
      if (navigator.userAgent.match(/KAKAOTALK/i)) {
        location.href = 'kakaotalk://inappbrowser/close';
      } else {
        window.close();
      }
    }
  } else {
    return <App />;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <RecoilRoot>{browserExceptionHandler()}</RecoilRoot>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(undefined);
