import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { device } from '../utils/device';
import { findQuestionPage, questionPages } from './QuestionPage';

const ProgressBarBlock = styled.div`
  width: 38rem;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  text-align: right;
  @media ${device.mobile} {
    width: 23.7rem;
    font-size: 1.2rem;
    margin-top: 41px;
  }
`;

const ProgressBarBox = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 6px;
  background-color: #c4c4c4;
  border-radius: 38px;
`;

const Filler = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  background: linear-gradient(
    90.21deg,
    #b1beff 0.18%,
    #acf0ff 99.81%,
    rgba(91, 91, 91, 0) 99.82%
  );
  border-radius: 38px;
  transition: width 450ms;
`;

function ProgressBar({ page }): JSX.Element {
  const [currentProgress, setCurrentProgress] = useState('0%');

  useEffect(() => {
    const { order } = findQuestionPage(page) || {
      order: questionPages.length,
    };
    const currentProgress = `${Math.ceil(
      ((order - 3) / (questionPages.length - 3)) * 100,
    )}%`;
    setCurrentProgress(currentProgress);
  }, [page]);

  const isHide = page === 'name' || page === 'basic';

  return isHide ? (
    <></>
  ) : (
    <ProgressBarBlock>
      {`${currentProgress}완료`}
      <ProgressBarBox>
        <Filler width={currentProgress} />
      </ProgressBarBox>
    </ProgressBarBlock>
  );
}

export default ProgressBar;
