export interface IPaymentPrice {
  price: number;
  discount?: number;
  promotion?: number;
}

export class PaymentPrice implements IPaymentPrice {
  constructor(props: IPaymentPrice) {
    Object.assign(this, props);
  }
  static readonly defaultDeliveryFee = 2500;
  price: number;
  discount?: number;
  promotion?: number;

  get total(): number {
    return this.price - (this.discount || 0) - (this.promotion || 0);
  }
  get delivery(): number {
    return this.total === PaymentPrice.defaultDeliveryFee
      ? PaymentPrice.defaultDeliveryFee
      : 0;
  }
  get deliveryPromotion(): number {
    return this.total === PaymentPrice.defaultDeliveryFee
      ? this.price
      : this.promotion;
  }
  get deliveryDiscount(): number {
    return this.total === PaymentPrice.defaultDeliveryFee
      ? this.price
      : this.discount;
  }
}
