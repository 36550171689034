import Axios, { Method } from 'axios';
import { Consts } from '../../config';
import { AuthCredential } from '../services/AuthService';
import { HttpError } from '../../app/utils/errors';

interface RequestInput {
  method: Method;
  path: string;
  params?: any;
  data?: any;
}

export class RemoteApiClient {
  constructor(props: { baseUrl: string; authCredential?: AuthCredential }) {
    this._baseUrl = props.baseUrl;
    this._authCredential = props.authCredential;
  }

  private _baseUrl: string;
  private _authCredential: AuthCredential;

  public async request(input: RequestInput): Promise<any> {
    try {
      const { method, path, params, data } = input;
      const url = `${this._baseUrl}/${path}`;
      const res = await Axios.request({
        method,
        url,
        params,
        data,
        headers: this._authCredential
          ? { Authorization: 'Bearer ' + this._authCredential?.idToken }
          : undefined,
      });

      return res?.data;
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        throw new HttpError(status, data.error?.code, data.error?.message);
      } else {
        throw new HttpError(500, Consts.ERROR_SERVER_ERROR);
      }
    }
  }
}
