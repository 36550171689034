import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
import { Routes } from '../../config';
import FaceBookImg from '../../assets/image/facebook.png';
import KakaoImg from '../../assets/image/kakao.png';
import ShareImg from '../../assets/image/share.png';
import Header from './resultpage/ResultHeader';

import {
  authCredentialState,
  authLoadingState,
  authUserState,
  quizBackgroundState,
  skinQuizAnswerSheetState,
  skinQuizSimpleAnswerSheetState,
  skinQuizSolutionState,
} from '../states';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Dialog,
  DialogContent,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { BodyDivider } from './styles';

import { Button } from './StyledComponents';
import styled from 'styled-components';
import { RemoteQuiz } from '../../data/repositories/RemoteQuiz';
import loadingImage from '../../assets/image/manifold_loading.gif';
import {
  get0thRadarDataObj,
  getBarDataObj,
  SkinChartBox,
} from './resultpage/SkinChartBox';
import { SkinChartSummaryBox } from './resultpage/SkinChartSummaryBox';
import { RemoteUser } from '../../data/repositories/RemoteUser';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import { IngredientRecommendFor0 } from './resultpage/IngredientRecommendBox';
import {
  getCautionIngredient,
  IngredientCautionBox,
} from './resultpage/IngredientCautionBox';
import { RoutineFor0 } from './resultpage/RoutineBox';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: '36rem',
    padding: '3.7rem 1.6rem 2rem 1.6rem',
    color: '#000',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#fff',
      maxWidth: '120rem',
      width: '120rem',
      padding: '0 30.7rem 20.9rem',
      marginBottom: '102px',
    },
  },

  shareBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },

  icon: {
    width: '4rem',
    height: '4rem',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },

  headerTitle: {
    'fontSize': '2rem',
    'fontWeight': 'bold',
    'color': '#000',
    'marginTop': '1.2rem',
    '& p': {
      fontWeight: 'normal',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '1.3rem',
    },
  },
  headerBody: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '1.8rem',
    marginBottom: '1.2rem',
    color: '#6A6A6A',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      marginBottom: '6rem',
      lineHeight: '2.7rem',
    },
  },
  shareFont: {
    fontSize: '1.3rem',
    fontWeight: 400,
    lineHeight: '2rem',
    marginBottom: '1.2rem',
    color: '#6A6A6A',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      marginBottom: '6rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },
  shareTitle: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    marginTop: '4rem',
    marginBottom: '2rem',
    color: '#050505',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      color: '#050505',
      fontWeight: 'bold',
      fontSize: '2.6rem',
      marginTop: '6rem',
      marginBottom: '4rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },

  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '5px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginBottom: '9px',
    },
  },

  ctaButton: {
    height: '4.8rem',
    width: '100%',
    background: 'linear-gradient(90deg, #8EE4FF 7.93%, #8BB3FF 94.05%)',
    borderRadius: '2px',
    borderWidth: '0px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#fff',
    margin: '1.2rem 0',
    whiteSpace: 'nowrap',
  },

  modalRoot: {
    transform: 'translateZ(0)',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 2 2.6rem 0',
    width: '20.3rem',
    [theme.breakpoints.up('sm')]: {
      width: '30rem',
    },
  },
  modalBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: '32.8rem',
    maxWidth: '60rem',
    [theme.breakpoints.up('sm')]: {},
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5rem 0',
    [theme.breakpoints.up('sm')]: {},
  },
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'stretch',
    textAlign: 'center',
    padding: '1rem 3rem',
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 12rem',
    },
  },
  termsBox: {
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'bottom',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },
  emailInput: {
    backgroundColor: '#F0F0F0',
    margin: '1rem 0',
    textAlign: 'center',
    borderRadius:'5px',
    fontSize: '1.2rem',
    padding:'4px 0',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.7rem',
    },
  },
  input:{
    textAlign: "center"
  },
  descriptionBlock: {
    textAlign: 'left',
    color:'#6A6A6A',
    "& p": {
      fontSize:'1rem',
      lineHeight: '1.4rem',
      "& span": {
        fontWeight:'bold',
      }
    },
  marginBottom:'57px',
  },
  modalButton: {
    width:'250px',
    margin:'2rem auto 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '4rem',
    fontSize: '1.7rem',
    fontWeight: 500,
    border: '0.1rem solid #000',
    borderRadius: '0.5rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  shareButton: {
    width: '20.4rem',
    height: '4rem',
    border: '0.22rem solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5.5rem',
    marginTop: '2rem',
    marginBottom: '5rem',
    fontSize: '1.5rem',
    fontWeight: 500,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '25.6rem',
      height: '4.5rem',
      fontSize: '2rem',
      fontWeight: 500,
      justifyContent: 'center',
    },
  },
  boxRoot: {
    width: '28.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '50.6rem',

      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  boxShareUrl: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  divider: {
    margin: '1rem 3.3rem',
    [theme.breakpoints.up('sm')]: {
      margin: '1rem 5.7rem',
    },
  },
}));


const KakaoIcon = styled.img`
  width: 40px;
  margin-bottom: 6px;
  cursor: pointer;
`;

const ExternalLinkIcon = styled.img`
  width: 40px;
  margin-bottom: 6px;
  cursor: pointer;
`;

const FaceBookIcon = styled.img`
  width: 40px;
  margin-bottom: 6px;
  cursor: pointer;
`;

FaceBookIcon.defaultProps = {
  src: FaceBookImg,
};

KakaoIcon.defaultProps = {
  src: KakaoImg,
};
ExternalLinkIcon.defaultProps = {
  src: ShareImg,
};


function ResultPage(): JSX.Element {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('xs'));
  function CTAButton({ text }): JSX.Element {
    return (
      <Button
        className={classes.ctaButton}
        onClick={() =>
          setOpen(true)
        }
        isValid
      >
        {text}
      </Button>
    );
  }
  const setQuizBackground = useSetRecoilState(quizBackgroundState);
  const authUser = useRecoilValue(authUserState);
  const [skinQuizSolution, setSkinQuizSolution] = useRecoilState(
    skinQuizSolutionState,
  );
  const isAuthLoading = useRecoilValue(authLoadingState);
  const authCredential = useRecoilValue(authCredentialState);
  const setAnswerSheet = useSetRecoilState(skinQuizAnswerSheetState);
  const setSimpleAnswerSheet = useSetRecoilState(
    skinQuizSimpleAnswerSheetState,
  );

  const isSimple = false;
  const quizId: string = authUser?.quizId;
  const createdAt = skinQuizSolution?.createdAt;
  const {
    barScore,
    radarScore,
    skinType,
    reportContent,
    reportImage,
    prescription,
  } = skinQuizSolution?.diagnosis || {};

  const [open, setOpen] = useState(false);
  const [externalopen, externalsetOpen] = useState(false);
  const [sending, setSending] = useState(false);

  const emailRef = useRef();

  const expertop1 = [];
  const expertop2 = [];
  const expertoplenth = reportContent?.explanation.length;

  reportContent?.explanation.forEach(function (item, index) {
    if (index <= expertoplenth / 2) {
      expertop1.push(item);
    } else {
      expertop2.push(item);
    }
  });

  useEffect(() => {
    if (smDown) {
      setQuizBackground(QuizBackground.WHITE);
    } else {
      setQuizBackground(QuizBackground.GRADIENT);
    }
  }, [setQuizBackground, smDown]);

  useEffect(() => {
    async function checkMembershipExists(
      remoteUserRepo: RemoteUser,
      userId: string,
    ) {
      const memberships = await remoteUserRepo.getUserMemberships(userId);
      return memberships && memberships?.length > 0;
    }

    async function updateUserQuizByUserId(
      remoteUserRepo: RemoteUser,
      userId: string,
      quizId: string,
    ) {
      remoteUserRepo
        .updateUserQuiz(userId, quizId)
        .then(() => {
          setAnswerSheet({});
          setSimpleAnswerSheet({});
        })
        .catch((err) => console.error(err));
    }

    const userId = authUser?.userId;
    const quizId = authUser?.quizId;
    const userIdAndQuizIdExists = userId && quizId;
    if (!userIdAndQuizIdExists) return;

    const repo = new RemoteUser(authCredential);

    checkMembershipExists(repo, userId).then((membershipsExists) => {
      if (!membershipsExists) {
        updateUserQuizByUserId(repo, userId, quizId);
      } else {
        return;
      }
    });
  }, [
    authCredential,
    authUser?.quizId,
    authUser?.userId,
    history,
    setAnswerSheet,
    setSimpleAnswerSheet,
  ]);

  useEffect(() => {
    if (!skinQuizSolution?.diagnosis && !isAuthLoading) {
      if (authUser?.quizId) {
        const repo = new RemoteQuiz(authCredential);
        repo
          .getSkinQuizSolution(authUser?.quizId)
          .then((value) => {
            if (value) {
              setSkinQuizSolution(value);
            } else {
              history.replace(Routes.skinQuiz);
            }
          })
          .catch((err) => {
            console.error(err);
            history.replace(Routes.skinQuiz);
          });
      } else {
        history.replace(Routes.skinQuiz);
      }
    }
  }, [
    authCredential,
    authUser?.quizId,
    history,
    isAuthLoading,
    setSkinQuizSolution,
    skinQuizSolution?.diagnosis,
  ]);
  const shareUrl = Routes.skinQuizResultShare + '/' + quizId;
  const shareUrlThumnail = 'https://dev.manifold.fit/thumnail.png';

  const shareKakaoChat = () => {
    window['Kakao'].Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '매니폴드',
        description: '피부진단리포트 결과',
        imageUrl: shareUrlThumnail,
        link: {
          mobileWebUrl: 'https://manifold.fit' + shareUrl,
          webUrl: 'https://manifold.fit' + shareUrl,
        },
      },
      buttons: [
        {
          title: '피부 리포트 확인하기',
          link: {
            mobileWebUrl: 'https://manifold.fit' + shareUrl,
            webUrl: 'https://manifold.fit' + shareUrl,
          },
        },
      ],
    });
  };

  function validateEmail(email) {
    const reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return reg.test(email) === true;
  }

  async function sendEmail() {

    const email = (emailRef as any).current.value;
    if (!validateEmail(email)) {
      alert('잘못된 이메일 주소입니다. 이메일 주소를 확인해주세요');
      return;
    }

    try {
      setOpen(false);
      setSending(true);
      const repo = new RemoteQuiz();
      await repo.sendSkinQuizResultEmail(quizId, email);
      setSending(false);
      history.push(Routes.skinQuizSentEmail);
    } catch (err) {
      alert('이메일 전송에 실패했습니다. 잠시 후 다시 시도해 주세요');
      setSending(false);
    }
  }


  const doSomething = () => {
    externalsetOpen(true);
  };
  const doCopyUrl = () => {
    {
      navigator.clipboard.writeText('https://manifold.fit' + shareUrl);
    }
  };

  function ExternalLinkButton(): JSX.Element {
    return (
      <ExternalLinkIcon
        className={classes.icon}
        onClick={() => doSomething()}
      />
    );
  }
  const faceBookShare = () => {
    window.open('http://www.facebook.com/sharer.php?u=manifold.fit' + shareUrl);
  };

  function ShareButtonBox(): JSX.Element {
    return (
      <Box className={classes.shareBox}>
        <Box className={classes.iconBox}>
          <FaceBookIcon className={classes.icon} onClick={faceBookShare} />
          <KakaoIcon className={classes.icon} onClick={shareKakaoChat} />
          <ExternalLinkButton />
        </Box>
      </Box>
    );
  }

  const handleClose = () => {
    setOpen(false);
  };
  const externalhandleClose = () => {
    externalsetOpen(false);
  };

  function ExternalPopup(): JSX.Element {
    return (
      <Dialog
        style={{ width: '100%', maxWidth: 'sm', padding: '2px 2px 2px 2px' }}
        open={externalopen}
        onClose={externalhandleClose}
        aria-labelledby="form-dialog-title"
      >
        <Box className={classes.boxRoot}>
          <Typography className={classes.shareTitle}>공유하기</Typography>
          <br />
          <hr
            style={{
              color: '#F0F0F0',
              backgroundColor: '#F0F0F0',
              height: '1px',
              width: '70%',
            }}
          />
          <br />
          <br />
          <br />
          <Typography className={classes.shareFont}>
            매니폴드 피부타입 테스트를 공유해보세요
          </Typography>
          <br />
          <Box className={classes.boxShareUrl}>
            <TextField
              required
              id="filled-required"
              style={{ width: 250, height: 50 }}
              defaultValue={'https://manifold.fit' + shareUrl}
              variant="filled"
            />
          </Box>
          <Box textAlign="center">
            <br />
            <Button
              className={classes.shareButton}
              onClick={() => doCopyUrl()}
              isValid
            >
              공유링크 복사하기
            </Button>
          </Box>
        </Box>
      </Dialog>
    );
  }

  function EmailDialog(): JSX.Element {
    return (
      <Box className={classes.modalRoot}>
        <Dialog open={open} onClose={handleClose}>
          <Box className={classes.modalBox}>
            <DialogContent className={classes.modalContent}>
              <Typography className={classes.headerTitle}>
                멤버십 서비스 종료
              </Typography>
              <BodyDivider className={classes.divider} />
              <Box className={classes.modalForm}>
                <Typography
                  className={classes.headerBody}
                  style={{
                    color: '#6A6A6A',
                    fontWeight: 400,
                    marginBottom: '3rem',
                  }}
                >
                  더 나은 솔루션 제공을 위해
                  <br />
                  2022년 7월 29일자로 종료되었습니다.
                  <br />
                  <br />
                  앞으로의 소식이 궁금하시면
                  <br />
                  이메일을 입력하고 매니폴드를 응원해주세요!
                </Typography>
                <TextField
                  className={classes.emailInput}
                  autoFocus
                  type="email"
                  placeholder="example@email.com"
                  InputProps={{
                    disableUnderline: true,
                    classes:{input: classes.input}
                  }}
                  inputRef={emailRef}
                />
                <div className={classes.descriptionBlock}>
                  <p>
                  소식 받기 신청 시, <span>개인정보 수집 및 이용</span>에 동의하는 것으로 간주합니다
                  </p>
                  <br/>
                  <br/>
                  <p><span style={{'color':'#000'}}>
                  개인 정보 수집 및 이용 동의
                  </span></p>
                  <p>
                  이메일 발송을 위한 최소한의 개인정보를 수집하고 이용함.
                  </p>
                </div>
                
                <Box className={classes.modalButton} onClick={sendEmail}>
                  매니폴드 소식 받아보기
                </Box>
              </Box>
            </DialogContent>
          </Box>
        </Dialog>
      </Box>
    );
  }

  const userName = skinQuizSolution?.userName;
  const dateNumber = skinQuizSolution?.createdAt;
  const reportDate = dateNumber ? new Date(dateNumber) : new Date();
  const cautionIngredient = getCautionIngredient(
    skinType?.sscore?.sarray?.split(''),
    skinType?.total,
  );
  const radarData = get0thRadarDataObj(radarScore, createdAt);
  const barData = getBarDataObj(barScore);
  const isLoaded = cautionIngredient && radarData && barData;

  return (
    <LoadingScreen
      loading={!isLoaded}
      spinnerColor="#c4c4c4"
      logoSrc={loadingImage}
    >
      <Container maxWidth="lg" className={classes.root}>
        <Header reportDate={reportDate} userName={userName} />

        <SkinChartSummaryBox
          typeComment={reportContent?.typeComment}
          skinType={skinType?.total}
          userName={userName}
        />
        <SkinChartBox
          radarData={radarData}
          barData={barData}
          userName={userName}
        />
        <IngredientRecommendFor0
          userName={userName}
          skinType={skinType?.total}
          reportImage={reportImage}
          prescription={prescription}
        />
        <IngredientCautionBox
          userName={userName}
          skinType={skinType?.total}
          cautionIngredient={cautionIngredient}
        />
        <RoutineFor0 userName={userName} reportContent={reportContent} />
        <CTAButton text="피부에 맞는 화장품 확인하기" />
        {!isSimple && <ShareButtonBox />}
        <EmailDialog />
        <Backdrop
          className={classes.backdrop}
          open={sending}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <ExternalPopup />
        <Backdrop
          className={classes.backdrop}
          open={sending}
          onClick={externalhandleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </LoadingScreen>
  );
}

export default ResultPage;
