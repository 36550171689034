import React from 'react';
import { Box } from '@material-ui/core';
import playStoreImage from '../../assets/image/play_store.png';
import appStoreImage from '../../assets/image/app_store.png';

interface Properties {
  width: string;
  height: string;
  spacing: string;
}
export function StoreBanner(props: Properties): JSX.Element {
  const { width, height, spacing } = props;
  const style = {
    'width': width,
    'height': height,
    '& + &': {
      marginLeft: spacing,
    },
    'cursor': 'pointer',
  };

  return (
    <Box display="flex">
      <img
        style={style}
        src={appStoreImage}
        onClick={() =>
          window.open(
            'https://apps.apple.com/app/apple-store/id1527961587?pt=121964880&ct=manifold_1_QR&mt=8',
          )
        }
      />
      <Box ml={spacing} />
      <img
        style={style}
        src={playStoreImage}
        onClick={() =>
          window.open(
            'https://play.google.com/store/apps/details?id=ai.artlab.candy&referrer=utm_source%3D[…]%20%26utm_content%3Dandroid%26utm_campaign%3Dmanifold_1_QR%20',
          )
        }
      />
    </Box>
  );
}
