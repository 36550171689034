import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme, useMediaQuery, useTheme } from '@material-ui/core';

type Props = {
  date: Date;
  isToday: boolean;
  isSelectedDate: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderTop: '0.5px #C4C4C4 solid',
    paddingTop: '0.4rem',
    height: '5.1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      height: '9rem',
      paddingTop: '0.8rem',
    },
  },

  date: {
    height: '2.2rem',
    width: '100%',
    fontSize: '1.2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      height: '3.8rem',
      fontSize: '1.8rem',
    },
  },

  todayDot: {
    position: 'absolute',
    marginTop: '2.4rem',
    width: '0.6rem',
    height: '0.6rem',
    borderRadius: '50%',
    backgroundColor: '#FF444472',

    [theme.breakpoints.up('sm')]: {
      marginTop: '4.4rem',
      width: '1rem',
      height: '1rem',
    },
  },
}));

export const CalendarTile = ({
  date,
  isToday,
  isSelectedDate,
}: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div className={classes.root}>
      <div
        className={classes.date}
        style={{
          color: isSelectedDate ? 'white' : 'inherit',
        }}
      >
        <p
          style={{
            zIndex: 1, // date text should always appear on top
          }}
        >
          {date.getDate()}
        </p>
      </div>
      {isToday && <div className={classes.todayDot} />}
      {isSelectedDate && (
        <div
          style={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: smUp ? '3.8rem' : '2.2rem',
              height: smUp ? '3.8rem' : '2.2rem',
              backgroundColor: '#3B5998',
              borderRadius: '50%',
            }}
          />
        </div>
      )}
    </div>
  );
};
