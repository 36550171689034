export enum ConfigKey {
  landingMedia = 'landing_media',
  landingText = 'landing_text',
}

export abstract class ConfigService {
  abstract fetch(): Promise<boolean>;
  abstract getString(key: ConfigKey): string;
  abstract getNumber(key: ConfigKey): number;
  abstract getBoolean(key: ConfigKey): boolean;
}
