import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Dialog, Fade } from '@material-ui/core';
import { SurveyModal } from './SurveyModal';

export const SurveyModalLeavingGuard = ({
  navigate,
  when,
  shouldBlockNavigation,
}) => {
  const [modalVisible, updateModalVisible] = useState(false);
  const [lastLocation, updateLastLocation] = useState({
    pathname: '/',
    search: '',
    hash: '',
    state: null,
    key: 'wribt2',
  });
  const [confirmedNavigation, updateConfirmedNavigation] = useState(false);

  const showModal = (
    location: React.SetStateAction<{
      pathname: string;
      search: string;
      hash: string;
      state: any;
      key: string;
    }>,
  ) => {
    updateModalVisible(true);
    updateLastLocation(location);
  };

  const closeModal = (cb: () => void) => {
    updateModalVisible(false);
    if (cb) {
      cb();
    }
  };

  const handleConfirmNavigationClick = () => {
    closeModal(() => {
      if (lastLocation) {
        updateConfirmedNavigation(true);
      }
    });
  };

  useEffect(() => {
    if (confirmedNavigation) {
      navigate(lastLocation.pathname);
      updateConfirmedNavigation(false);
    }
  }, [confirmedNavigation, lastLocation.pathname, navigate]);

  const handleBlockedNavigation = (nextLocation) => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation);
      return false;
    }
    return true;
  };

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <Dialog open={modalVisible}>
        <Fade in={modalVisible}>
          <SurveyModal onLeave={handleConfirmNavigationClick} />
        </Fade>
      </Dialog>
    </>
  );
};
