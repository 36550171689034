import { User, UserItem } from '../../domain/entities/User';
import { UserAddress } from '../../domain/entities/UserAddress';
import {
  RegisterUserParams,
  RegisterUserResult,
  SatisfactionSurveyResult,
  UpdateUserParams,
  UserRepository,
} from '../../domain/repositories/UserRepository';
import { RemoteApiClient } from '../helpers/RemoteApiClient';
import { AuthCredential } from '../services/AuthService';
import { settings } from '../../config';
import { RemoteUserMapper } from '../mappers/RemoteUserMapper';
import { OrderItem } from '../../domain/entities/Order';
import { Subscription } from '../../domain/entities/Subscription';
import { Invoice } from '../../domain/entities/Invoice';
import Coupon from '../../domain/entities/Coupon';
import { SkinQuizSolution } from '../../domain/entities/SkinQuizSolution';
import { RemoteQuizMapper } from '../mappers/RemoteQuizMapper';
import { Membership } from '../../domain/entities/Membership';
import { PaymentPrice } from '../../domain/entities/Payment';
import { MembershipOrder } from '../../domain/entities/MembershipOrder';
import { MembershipOrderStatus } from '../../domain/entities/MembershipOrderStatus';
import { Product } from '../../domain/entities/Product';
import { LastCompletedOrder } from '../../domain/entities/LastCompletedOrder';
import { SkinReportList } from '../../domain/entities/SkinReportList';

export class RemoteUser extends UserRepository {
  constructor(authCredential?: AuthCredential) {
    super();

    this._api = new RemoteApiClient({
      baseUrl: settings.apiEndpoint,
      authCredential,
    });
  }

  private _api: RemoteApiClient;

  public async registerUser(
    params: RegisterUserParams,
  ): Promise<RegisterUserResult> {
    const { userId, ...data } = params;
    const result = await this._api.request({
      method: 'post',
      path: `users/${userId}/register`,
      data,
    });

    return RemoteUserMapper.toRegisterUserResult(result);
  }

  public async UpdateSatisfactionSurveyResult(
    params: SatisfactionSurveyResult,
  ): Promise<void> {
    const { userId, ...data } = params;
    await this._api.request({
      method: 'post',
      path: `users/${userId}/satisfaction-survey`,
      data: { answerSheet: data },
    });
  }

  public async updateUser(params: UpdateUserParams): Promise<void> {
    const { userId, ...data } = params;
    await this._api.request({
      method: 'post',
      path: `users/${userId}`,
      data,
    });
  }

  public async getUser(userId: string): Promise<User> {
    const data = await this._api.request({
      method: 'get',
      path: `users/${userId}`,
    });
    return RemoteUserMapper.toUser(data);
  }

  public async getUserByEmail(email: string): Promise<UserItem> {
    const data = await this._api.request({
      method: 'get',
      path: 'users',
      params: { email },
    });

    return RemoteUserMapper.toUserItem(data);
  }

  public async getUserByPhoneNumber(phoneNumber: string): Promise<UserItem> {
    const data = await this._api.request({
      method: 'get',
      path: 'users',
      params: { phoneNumber },
    });

    return RemoteUserMapper.toUserItem(data);
  }

  public async getUserSolution(userId: string): Promise<SkinQuizSolution> {
    const data = await this._api.request({
      method: 'post',
      path: `users/${userId}/solution`,
    });

    return RemoteQuizMapper.toSkinQuizSolution(data);
  }

  public async updateUserQuiz(userId: string, quizId: string): Promise<void> {
    await this._api.request({
      method: 'post',
      path: `users/${userId}/quizzes/${quizId}`,
    });
  }

  public async updateUserAddress(
    userId: string,
    address: UserAddress,
  ): Promise<void> {
    await this._api.request({
      method: 'post',
      path: `users/${userId}/address`,
      data: address,
    });
  }

  public async getSubscription(subscriptionId: string): Promise<Subscription> {
    const data = await this._api.request({
      method: 'get',
      path: `subscriptions/${subscriptionId}`,
    });
    return RemoteUserMapper.toSubscription(data);
  }

  public async createSubscription(
    membershipProductId: string,
    items: OrderItem[] | undefined,
    firstDeliveryDate: Date,
    coupon?: string,
  ): Promise<Subscription> {
    const data = await this._api.request({
      method: 'post',
      path: `subscriptions`,
      data: {
        productId: membershipProductId,
        items,
        coupon,
        periodStart: firstDeliveryDate.getTime(),
      },
    });
    const subscription = RemoteUserMapper.toSubscription(data);
    subscription.items = items;
    return subscription;
  }

  public async createSubscriptionBilling(
    subscriptionId: string,
    receiptId?: string,
  ): Promise<void> {
    await this._api.request({
      method: 'post',
      path: `subscriptions/${subscriptionId}/billing`,
      data: {
        receiptId,
      },
    });
  }

  public async getUpcomingInvoice(userId: string): Promise<Invoice> {
    const data = await this._api.request({
      method: 'get',
      path: `users/${userId}/invoices/upcoming`,
    });
    return RemoteUserMapper.toInvoice(data);
  }

  public async updateSubscriptionPeriod(
    subscriptionId: string,
    interval: number,
  ): Promise<void> {
    await this._api.request({
      method: 'post',
      path: `subscriptions/${subscriptionId}/period`,
      data: {
        interval,
      },
    });
  }

  public async checkCoupon(code: string, productId: string): Promise<Coupon> {
    const data = await this._api.request({
      method: 'post',
      path: `coupons/check`,
      data: {
        code,
        productId,
      },
    });

    return RemoteUserMapper.toCoupon(data);
  }

  public async getUserMemberships(userId: string): Promise<Membership[]> {
    const data = await this._api.request({
      method: 'get',
      path: `users/${userId}/invoices`,
    });

    return data?.invoices
      ?.filter((data) => data.status !== 'pending')
      ?.map((data) => {
        return {
          id: data.subscriptionId,
          title: data.productName,
          maxCount: data.usageTotal,
          periodStart: new Date(data.periodStart),
          payment: new PaymentPrice({
            price: data.price,
            discount: data.discount,
          }),
          isFirstSubscription: data.isFirst,
        };
      });
  }

  public async getMembershipOrders(
    subscriptionId: string,
  ): Promise<MembershipOrder[]> {
    const data = await this._api.request({
      method: 'get',
      path: `subscriptions/${subscriptionId}/orders`,
    });

    const getDeliveryCompanyName = (dataCompanyName: string): string => {
      return dataCompanyName === 'epost' ? '우체국택배' : dataCompanyName;
    };

    return data?.orders?.map((data) => {
      return {
        id: data.orderId,
        status:
          data.status === 'processing'
            ? MembershipOrderStatus.PROCESSING
            : data.status === 'completed'
            ? MembershipOrderStatus.DELIVERED
            : MembershipOrderStatus.DELIVERING,
        orderDate: new Date(data.createdAt),
        deliverCompletedDate: new Date(data.completedAt),
        trackingNumber: data.trackingNumber,
        deliveryCompany: getDeliveryCompanyName(data.deliveryCompany),
        customerNote: data.customerNote,
        shipping: RemoteUserMapper.toShipping(data.shipping),
      };
    });
  }

  public async setSubscriptionInterval(
    subscriptionId: string,
    interval: number,
  ): Promise<Subscription> {
    const data = await this._api.request({
      method: 'post',
      path: `subscriptions/${subscriptionId}/period`,
      data: {
        subscriptionId,
        interval,
        period: 'day',
      },
    });
    return RemoteUserMapper.toSubscription(data);
  }

  public async getMembershipOrderProducts(
    userId: string,
    orderId: number,
  ): Promise<Product[]> {
    const data = await this._api.request({
      method: 'get',
      path: `users/${userId}/orders/${orderId}`,
    });

    return data?.products;
  }

  public async getLastCompletedMembershipOrderProducts(
    userId: string,
  ): Promise<LastCompletedOrder> {
    const data = await this._api.request({
      method: 'get',
      path: `users/${userId}/orders/last`,
    });

    return {
      products: data?.products,
      createdAt: data?.createdAt,
    };
  }
  public async getUserSkinReportList(userId: string): Promise<SkinReportList> {
    const data = await this._api.request({
      method: 'get',
      path: `users/${userId}/skin-reports`,
    });

    return {
      reports: data?.reports,
    };
  }
}
