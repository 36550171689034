import React from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import useScreenSize from '../../home/UseScreenSize';
import { ScreenSize } from '../../home/ScreenSize';
import imgMobile from '../../../assets/image/mobile_circular_frame_package.png';
import imgDesktop from '../../../assets/image/desktop_circular_frame_package.png';

export const useStyles = makeStyles((theme: Theme) => ({
  box: {
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {},
  },
  image: {
    width: '206px',
    height: '206px',
    marginTop: '45px',
    marginBottom: '25px',
    [theme.breakpoints.up('sm')]: {
      width: '310px',
      height: '310px',
    },
  },
  text: {
    fontWeight: 500,
    fontSize: '1.8rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },
}));

function FramedImgBox(): JSX.Element {
  const classes = useStyles();
  const screenSize = useScreenSize();

  return (
    <Box className={classes.box} style={{ border: 'none' }}>
      <img
        src={screenSize == ScreenSize.MOBILE ? imgMobile : imgDesktop}
        className={classes.image}
      />
      <Typography className={classes.text}>
        피부기록 업데이트를 통해 추천된
        {<br />}
        스킨케어 제품을 확인해보세요
      </Typography>
    </Box>
  );
}
export default FramedImgBox;
