import {
  Box,
  Hidden,
  Link,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';
import blogicon from '../../assets/image/footer/blog.png';
import youtubeicon from '../../assets/image/footer/youtube.png';
import instaicon from '../../assets/image/footer/instagram.png';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '17.5rem',
    justifyContent: 'center',
    alignItems: 'stretch',
    backgroundColor: '#fff',
    opacity: '0.7',
    padding: '2.9rem 2.5rem',
    [theme.breakpoints.up('sm')]: {
      height: '19.4rem',
      flexDirection: 'row',
      justifyContent: 'space-evenly',
      padding: '2.6rem 0',
    },
  },
  section1: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  section2: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },

  text: {
    fontSize: '1rem',
    fontWeight: 'normal',
    lineHeight: '1.3rem',
    color: '#c4c4c4',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
      fontWeight: 500,
      lineHeight: '2.6rem',
      color: '#000',
    },
  },
  snsIconSection: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      cursor: 'pointer',
    },
  },
  snsIcon: {
    width: '30.45px',
    gap: '9px',
    [theme.breakpoints.up('sm')]: {
      width: '36px',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const openKakaoChat = () => {
  window['Kakao'].Channel.chat({
    channelPublicId: '_cSxaxoxb',
  });
};
const instaShare = () => {
  window.open('https://www.instagram.com/manifold.fit/');
};
const youtubeShare = () => {
  window.open(
    'https://www.youtube.com/channel/UCNdDLY7V4-M8EfNgmJfy59A/featured',
  );
};
const blogShare = () => {
  window.open('https://blog.naver.com/artlabai');
};

function Links(): JSX.Element {
  return (
    <p style={{ fontWeight: 500 }}>
      <Link
        color="inherit"
        href="https://www.notion.so/cceecd9207da49d7a2f5cc4c74170c7e"
        target="_blank"
        rel="noopener noreferrer"
      >
        개인정보처리방침
      </Link>
      {' | '}
      <Link
        color="inherit"
        href="https://www.notion.so/302042248a314ac780aa197f21c47a20"
        target="_blank"
        rel="noopener noreferrer"
      >
        이용약관
      </Link>
      {' | '}
      <Link color="inherit" onClick={openKakaoChat}>
        카톡채널을 통해 문의하기
      </Link>
    </p>
  );
}

function SnsIcons(): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.snsIconSection}>
      <img className={classes.snsIcon} src={instaicon} onClick={instaShare} />
      <img
        className={classes.snsIcon}
        src={youtubeicon}
        onClick={youtubeShare}
      />
      <img className={classes.snsIcon} src={blogicon} onClick={blogShare} />
    </Box>
  );
}

function Footer(): JSX.Element {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.section1}>
        <Typography className={classes.text}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <p style={{ fontWeight: 500, margin: 'auto 0' }}>(주)아트랩 </p>
            <Hidden smUp>
              <SnsIcons />
            </Hidden>
          </Box>
          <Hidden xsDown>
            <br />
          </Hidden>
          <Hidden smUp>
            <p style={{ fontWeight: 500 }}>주식회사 아트랩</p>
          </Hidden>
          <p>서울 강남구 테헤란로 217, 오렌지플래닛 3층 306호</p>
          <p>
            {
              '대표: 엄태웅 | 사업자등록번호: 686-87-01309 | 통신판매업번호: 제 2020-서울강남-00843호 '
            }
          </p>
          <p>고객센터: support_manifold@artlab.ai / 02-6959-9946</p>
        </Typography>
      </Box>

      <Box className={classes.section2}>
        <Hidden xsDown>
          <SnsIcons />
          <br />
        </Hidden>

        <Typography className={classes.text}>
          <Hidden smUp>
            <br />
          </Hidden>
          <Links />
          <Hidden smUp>
            <br />
          </Hidden>
          Copyright © 2020 ARTLAB. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
}

export default Footer;
