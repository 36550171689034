import React from 'react';
import {
  Backdrop,
  Box,
  Dialog,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { Button } from '../skinquiz/StyledComponents';
import { errorDialogButtonTextState, errorMessageState } from '../states';
import { useRecoilValue } from 'recoil';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'center',
    'minHeight': '30.2rem',
    'padding': '4.1rem 3.6rem',

    '& strong': {
      fontWeight: 'bold',
    },

    [theme.breakpoints.up('sm')]: {
      minWidth: '53.4rem',
      minHeight: '38.9rem',
      padding: '4.4rem 3.6rem',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '4.1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '4.4rem',
    },
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: '#F0F0F0',
  },
  body: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
    color: '#6A6A6A',
    lineHeight: '150%',
    marginTop: '4.1rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      lineHeight: '2.7rem',
      marginTop: '7.4rem',
      marginBottom: '7.4rem',
    },
  },
  button: {
    fontSize: '1.7rem',
    fontWeight: 500,
    margin: '4.1rem 0 0',
    minWidth: '25rem',
    minHeight: '4rem',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
}));

interface ErrorDialogProps {
  onClose: (e?: any) => void;
}

export default function ErrorDialog(props: ErrorDialogProps): JSX.Element {
  const classes = useStyles();
  const errorMessage = useRecoilValue(errorMessageState);
  const errorDialogButtonText = useRecoilValue(errorDialogButtonTextState);

  return (
    <Dialog open={!!errorMessage} onClose={props.onClose}>
      <Box className={classes.root}>
        <Typography className={classes.title}>오류</Typography>
        <div className={classes.divider} />
        <Typography className={classes.body}>{errorMessage}</Typography>
        <Button className={classes.button} onClick={props.onClose} isValid>
          {errorDialogButtonText || '확인'}
        </Button>
      </Box>
      <Backdrop open={!!errorMessage} onClick={props.onClose}></Backdrop>
    </Dialog>
  );
}
