import React from 'react';
import { Content, QuestionBlock } from '../StyledComponents';

import skinQuizData from '../../../data/skinQuizData';
import skinQuizDataSimple from '../../../data/skinQuizDataSimple';

import RadioQuestion from './RadioQuestion';
import CheckQuestion from './CheckQuestion';
import InputQuestion from './InputQuestion';
import IconQuestion from './IconQuestion';
import SelectableQuestion from './SelectableQuestion';
import TextQuestion from './TextQuestion';
import { useRecoilState } from 'recoil';
import {
  skinQuizAnswerSheetState,
  skinQuizSimpleAnswerSheetState,
} from '../../states';
import QuestionTip from './QuestionTip';

function QuestionContent({ page, quizType }) {
  const [defaultAnswerSheet, setDefaultAnswerSheet] = useRecoilState(
    skinQuizAnswerSheetState,
  );
  const [simpleAnswerSheet, setSimpleAnswerSheet] = useRecoilState(
    skinQuizSimpleAnswerSheetState,
  );

  const answerSheet =
    quizType === 'skin_simple' ? simpleAnswerSheet : defaultAnswerSheet;
  const setAnswerSheet =
    quizType === 'skin_simple' ? setSimpleAnswerSheet : setDefaultAnswerSheet;
  const quizData =
    quizType === 'skin_simple' ? skinQuizDataSimple : skinQuizData;

  const questions = quizData.filter(
    (elem) => elem.id === page || elem.key === page || elem.category === page,
  );

  const question = questions[0];

  return (
    <Content>
      <QuestionBlock>
        {questions.map((question) => {
          if (question.type === 'check') {
            return (
              <CheckQuestion
                key={question.id}
                question={question}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
              ></CheckQuestion>
            );
          } else if (question.type === 'radio') {
            return (
              <RadioQuestion
                key={question.id}
                question={question}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
              ></RadioQuestion>
            );
          } else if (question.type === 'input') {
            return (
              <InputQuestion
                key={question.id}
                question={question}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
              ></InputQuestion>
            );
          }else if (question.key === 'gender') {
            return (
              <IconQuestion
                key={question.id}
                question={question}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
              ></IconQuestion>
            );
          }  
          else if (question.type === 'selectable') {
            return (
              <SelectableQuestion
                key={question.id}
                question={question}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
              ></SelectableQuestion>
            );
          } else if (question.type === 'text') {
            return (
              <TextQuestion
                key={question.id}
                question={question}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
              ></TextQuestion>
            );
          }
        })}
        {question?.tip && <QuestionTip question={question} />}
      </QuestionBlock>
    </Content>
  );
}

export default QuestionContent;
