import React, { useEffect, useState } from 'react';
import { Content } from './StyledComponents';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  errorMessageState,
  kakaoFabState,
  quizBackgroundState,
  skinQuizSimpleAnswerSheetState,
} from '../states';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../config';
import QuestionContent from './questions/QuestionContent';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import footerMessage from '../../assets/image/skinquiz_footer_message.svg';
import BottomButtons from './questions/BottomButtons';
import skinQuizData from '../../data/skinQuizDataSimple';
import SimpleProgressBar from './SimpleProgressBar';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import ErrorDialog from '../components/ErrorDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tip: {
      marginTop: '3.7rem',
    },
    footer: {
      display: 'block',
      width: '13.5rem',
      height: '3.4rem',
      backgroundImage: `url(${footerMessage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginBottom: '5rem',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

export default function SimpleQuestionPage({ match }) {
  const history = useHistory();
  const classes = useStyles();
  const page = Number.parseInt(match?.params?.page);

  const skinQuizSimpleAnswerSheet = useRecoilValue(
    skinQuizSimpleAnswerSheetState,
  );
  const setQuizBackground = useSetRecoilState(quizBackgroundState);
  const setKakaoFab = useSetRecoilState(kakaoFabState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const [redirectionPage, setRedirectionPage] = useState('');

  const question = skinQuizData[page - 1];
  const isDone = (): boolean => {
    if (!page) {
      return false;
    }

    const input = skinQuizSimpleAnswerSheet[`question${question.id}`];
    if (Array.isArray(input)) {
      return input?.length > 0;
    } else {
      return !!input;
    }
  };

  const onSubmit = async () => {
    const isLast = page >= skinQuizData.length;
    if (isLast) {
      if (!skinQuizSimpleAnswerSheet.question1) {
        setErrorMessage('진행 정보가 초기화되었습니다. 다시 진행해주세요.');
        setRedirectionPage(Routes.skinQuiz);
      } else {
        history.push({ pathname: Routes.skinQuizProcessing, search: 'simple' });
      }
    } else {
      history.push(`${Routes.skinQuizSimple}/${page + 1}`);
    }
  };

  useEffect(() => {
    if (!page) {
      history.push(`${Routes.skinQuizSimple}/1`);
    }
    setQuizBackground(QuizBackground.WHITE);
  }, [history, page, setQuizBackground]);

  setKakaoFab(false);

  const onErrorDialogClosed = () => {
    setErrorMessage('');
    if (redirectionPage) {
      history.push(redirectionPage);
    }
  };

  const isStart = page === 1;
  const isLast = page >= skinQuizData.length;
  return (
    <Content>
      <SimpleProgressBar page={page} total={skinQuizData.length} />
      <QuestionContent page={page} quizType="skin_simple" />
      <BottomButtons
        page={page}
        isDone={isDone()}
        isStart={isStart}
        isLast={isLast}
        onSubmit={onSubmit}
      />
      <Box className={classes.footer} />
      <ErrorDialog onClose={onErrorDialogClosed} />
    </Content>
  );
}
