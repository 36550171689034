import React from 'react';
import skinTypeCaution from '../../../data/skinTypeCaution';

import { Box, Typography } from '@material-ui/core';
import { BoxDivider, getIconImageSrc } from '../styles';
import { Caution } from '../../../data/models/SkinTypeRecommend';
import { IngredientLabel, SubtitleTypo, useStyles } from './IngredientBox';

const getCautionIngredientNum = (sscore: string[] | undefined) => {
  for (let i = 0; i < sscore.length; i++) {
    if (sscore[i] === '1') return 's' + (i + 1);
  }
  return 's0';
};

export const getCautionIngredient = (
  careIngredients: string[] | undefined,
  careSkinType: string | undefined,
) => {
  if (!careIngredients || !careSkinType) return undefined;
  const skinTypeCautionObj = skinTypeCaution[careSkinType];
  const cautionIngredientNum = getCautionIngredientNum(careIngredients);
  if (
    Object.prototype.hasOwnProperty.call(
      skinTypeCautionObj,
      cautionIngredientNum,
    )
  ) {
    return skinTypeCautionObj[cautionIngredientNum].cautions;
  } else {
    return Object.values(skinTypeCautionObj)[0].cautions;
  }
};

type Props = {
  userName: string;
  skinType?: string;
  cautionIngredient?: Array<Caution>;
};

const NoCaution = ({
  userName,
  skinType,
  cautionIngredient,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={`${classes.box} ${classes.ingredientBox}`}>
      <Typography className={classes.title}>주의 성분</Typography>
      <Typography className={classes.boxSummary}>
        <Typography component={'p'}>
          {skinType}타입인 {userName}님은
        </Typography>
        <Typography component={'p'}>
          {cautionIngredient[0].explanation}
        </Typography>
      </Typography>
    </Box>
  );
};

const YesCaution = ({
  userName,
  skinType,
  cautionIngredient,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <Box className={`${classes.box} ${classes.ingredientBox}`}>
      <Typography className={classes.title}>주의 성분</Typography>
      <Typography className={classes.boxSummary}>
        <Typography component={'p'}>
          {skinType}타입인 {userName}님께서
        </Typography>
        <Typography component={'p'}>주의해야하는 성분입니다</Typography>
      </Typography>
      <Box className={classes.list}>
        {cautionIngredient?.map((obj, index) => {
          let problemCategory: string;
          switch (obj.problem) {
            case '피부건조':
              problemCategory = '수분보유력';
              break;
            case '트러블':
              problemCategory = '유분보유력';
              break;
            case '피부자극':
              problemCategory = '피부 보호력';
              break;
          }
          return (
            <Box
              key={obj.name}
              className={classes.listItem}
              borderTop="1px solid #F0F0F0"
            >
              <img
                className={classes.ingredientImage}
                src={getIconImageSrc(obj.category)}
                alt={''}
              />
              <Box display="flex" flexDirection="column" paddingBottom="1.1rem">
                <SubtitleTypo titleLen={obj.name.length}>
                  {obj.name}
                  <IngredientLabel
                    className={classes.ingredientLabel}
                    skintype={problemCategory}
                  >
                    {obj.problem}
                  </IngredientLabel>
                </SubtitleTypo>
                <Typography className={classes.body}>
                  {obj.explanation}
                </Typography>
              </Box>
              <BoxDivider />
            </Box>
          );
        })}
        <Typography className={classes.cautiontext}>
          상기 내용은 원료적 특성에 한합니다
        </Typography>
      </Box>
    </Box>
  );
};

type BoxProps = {
  userName: string;
  skinType: string;
  cautionIngredient: Caution[] | undefined;
};
export function IngredientCautionBox({
  userName,
  skinType,
  cautionIngredient,
}: BoxProps): JSX.Element {
  if (!userName || !skinType || !cautionIngredient) return <></>;
  else if (cautionIngredient.length < 2) {
    return (
      <NoCaution
        userName={userName}
        skinType={skinType}
        cautionIngredient={cautionIngredient}
      />
    );
  } else {
    return (
      <YesCaution
        userName={userName}
        skinType={skinType}
        cautionIngredient={cautionIngredient}
      />
    );
  }
}
