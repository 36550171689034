import React, { useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import {
  authCredentialState,
  authUserState,
  paymentPriceState,
} from '../../states';
import { RemoteUser } from '../../../data/repositories/RemoteUser';

type Props = {
  title: string;
  hasCouponUsed: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'margin': '0px 46px',
    'fontSize': '1.4rem',

    [theme.breakpoints.up('sm')]: {
      margin: '0px 85px 0',
      fontSize: '1.6rem',
    },

    '& strong': {
      fontWeight: 'bold',
    },
  },

  title: {
    'padding-top': '20px',
    'padding-bottom': '20px',
    'fontWeight': 700,
    'fontSize': '1.8rem',
  },

  row: {
    'display': 'flex',
    'flexDirection': 'row',
    'alignItems': 'center',
    'padding': '2.5px 3px',

    '& > span:first-child': {
      flex: 1,
    },
  },

  firstDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#C4C4C4',
    margin: '5.5px 0 13.5px',
  },

  secondDivider: {
    width: '100%',
    height: '1px',
    backgroundColor: '#C4C4C4',
    margin: '13.5px 0',
  },

  red: {
    color: 'red',
  },
  promotionRow: {
    float: 'right',
    padding: '2.5px 3px',
  },
  promotionBox: {
    fontSize: '10px',
    lineHeight: '14px',
    display: 'block',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 15px',

    background: '#FFFFFF',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: '7px',
  },
}));

// function getPromotionBoxNeeded(title: string, hasCouponUsed: boolean) {
//   return !hasCouponUsed && title.includes('매니폴드 스킨케어_Start');
// }

export const PricingBox = ({ title, hasCouponUsed }: Props): JSX.Element => {
  const classes = useStyles();
  const [isFirstOrder, setIsFirstOrder] = useState(false);
  const authUser = useRecoilValue(authUserState);
  const authCredential = useRecoilValue(authCredentialState);
  const payment = useRecoilValue(paymentPriceState);
  // const [isPromotionBoxNeeded, setIsPromotionBoxNeeded] = useState(false);

  // useEffect(() => {
  //   setIsPromotionBoxNeeded(getPromotionBoxNeeded(title, hasCouponUsed));
  // }, [hasCouponUsed, title]);

  useEffect(() => {
    const repo = new RemoteUser(authCredential);
    repo
      .getLastCompletedMembershipOrderProducts(authUser?.userId)
      .then(({ products }) => {
        setIsFirstOrder(products === undefined || products.length == 0);
      })
      .catch((err) => {
        console.error(err);
        setIsFirstOrder(err.status === 404);
      });
  }, [authCredential, authUser?.userId]);

  const { price, total, delivery, deliveryPromotion } = payment;

  return (
    <section className={classes.root}>
      <article className={classes.title}>
        <span>
          <strong>결제 금액</strong>
        </span>
      </article>
      <span></span>
      <article className={classes.row}>
        <span>상품금액</span>
        <span>{price.toLocaleString()}원</span>
      </article>
      <article
        className={classes.row}
        style={{
          display: payment?.promotion ? 'flex' : 'none',
        }}
      >
        <span>프로모션 적용</span>
        <span className={classes.red}>
          -{deliveryPromotion?.toLocaleString()}원
        </span>
      </article>
      <article
        className={classes.row}
        style={{
          display: isFirstOrder ? 'flex' : 'none',
        }}
      >
        <span>스타터 키트</span>
        <span>0 원</span>
      </article>
      <article className={classes.row}>
        <span>배송비</span>
        <span>{delivery ? `${delivery?.toLocaleString()}원` : '무료배송'}</span>
      </article>
      <div className={classes.secondDivider} />
      <article className={classes.row}>
        <span>최종결제 금액</span>
        <span>
          <strong>{total.toLocaleString()}원</strong>
        </span>
      </article>
      {/* {isPromotionBoxNeeded && (
        <article className={classes.promotionRow}>
          <span className={classes.promotionBox}>
            <strong>‘1주년선물’</strong> 코드 입력하고 <br />
            <strong>36,500원</strong> 할인받으세요
          </span>
        </article>
      )} */}
    </section>
  );
};
