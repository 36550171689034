import React from 'react';
import { ProductDetailInfo } from '../../../domain/entities/ProductDetailInfo';
import styled from 'styled-components';

type Props = {
  productDetailInfo: ProductDetailInfo | undefined;
};

export const ProductDetailModal = ({
  productDetailInfo,
}: Props): JSX.Element => {
  const ProductDetailModal = styled.div`
    max-width: 495px;
    width: calc(100vw-108);
    padding: 24px 22px;
    line-height: 17px;

    color: #000000;
  `;

  const IngredintTitle = styled.div`
    font-weight: bold;
    font-size: 1.2rem;
  `;

  const IngredintContents = styled.div`
    font-weight: noraml;
    font-size: 1.2rem;
    margin-bottom: 15px;
    // word-break:keep-all;
  `;

  const DescTitle = styled.div`
    margin-top: 13px;
    font-weight: bold;
    font-size: 1.3rem;
  `;

  const DescContents = styled.div`
    font-weight: normal;
    font-size: 1.3rem;
    margin-bottom: 13px;
    word-break: keep-all;
  `;

  const FormulaImg = styled.img`
    width: 120px;
    height: 120px;
  `;
  return (
    <ProductDetailModal>
      <IngredintTitle>전성분</IngredintTitle>
      <IngredintContents>
        {productDetailInfo?.subProductIngredint}
      </IngredintContents>
      <hr />
      <DescTitle>사용법</DescTitle>
      <DescContents>
        아침과 저녁, 앰플에 부스터를 넣어 섞은 후 앰플을 손에 펌핑해 깨끗한
        얼굴과 목에 두드리세요.
      </DescContents>
      {productDetailInfo?.subProductFeeling && <DescTitle>사용감</DescTitle>}

      <DescContents>{productDetailInfo?.subProductFeeling}</DescContents>
      <DescTitle>제형</DescTitle>
      <DescContents>{productDetailInfo?.subProductFormulation}</DescContents>
      <FormulaImg src={productDetailInfo?.formulationImage} />
    </ProductDetailModal>
  );
};
