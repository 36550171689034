import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';

type Props = {
  highlightCount: number;
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: '42px',

    [theme.breakpoints.up('sm')]: {
      paddingTop: '90px',
    },
  },

  title: {
    display: 'block',
    textAlign: 'center',
    fontSize: '2rem',
    lineHeight: '131%',
    fontWeight: 'bold',

    [theme.breakpoints.up('sm')]: {
      fontSize: '3.2rem',
      lineHeight: '150%',
    },
  },

  navContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '43px',

    [theme.breakpoints.up('sm')]: {
      marginTop: '36px',
    },
  },

  itemContainer: {
    'display': 'flex',
    'flexDirection': 'row',
    'justifyContent': 'center',
    'alignItems': 'center',
    'fontSize': '1rem',
    'color': '#C4C4C4',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.2rem',
    },

    '& > span:first-child': {
      fontSize: '0.9rem',
      fontWeight: 'lighter',
      color: 'white',
      borderRadius: '50%',
      backgroundColor: '#C4C4C4',
      margin: '0 4px',
      display: 'grid',
      justifyContent: 'center',
      alignContent: 'center',
      width: '1.5rem',
      height: '1.5rem',

      [theme.breakpoints.up('sm')]: {
        fontSize: '1.2rem',
        width: '1.9rem',
        height: '1.9rem',
        margin: '0 5px',
      },
    },

    '& > span:nth-child(2)': {
      marginRight: '4px',

      [theme.breakpoints.up('sm')]: {
        marginRight: '10px',
      },
    },
  },

  highlight: {
    'color': 'black',

    '& > span:first-child': {
      backgroundColor: 'black',
    },
  },
}));

export const PaymentFlowHeader = ({ highlightCount }: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <span className={classes.title}>주문 / 결제</span>
      <article className={classes.navContainer}>
        <div
          className={`${classes.itemContainer} ${
            highlightCount > 0 && classes.highlight
          }`}
        >
          <span>1</span>
          <span>배송지 입력</span>
          <ArrowForwardIos fontSize="inherit" color="inherit" />
        </div>
        <div className={`${classes.itemContainer} ${highlightCount > 1 && classes.highlight}`}>
          <span>2</span>
          <span>결제 정보 입력</span>
          <ArrowForwardIos fontSize="inherit" color="inherit" />
        </div>
        <div
          className={`${classes.itemContainer} ${
            highlightCount > 2 && classes.highlight
          }`}
        >
          <span>3</span>
          <span>결제 완료</span>
        </div>
        <div
          className={`${classes.itemContainer} ${
            highlightCount > 3 && classes.highlight
          }`}
        >
        </div>
      </article>
    </section>
  );
};
