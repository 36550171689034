import React from 'react';
import {
  CheckIcon,
  OptionItemCheckBox,
  OptionItemContainer,
  OptionItemLabel,
  OptionItemRadioInput,
  QuestionContainer,
  QuestionOptionsBlock,
  QuestionTitleBlock,
} from '../StyledComponents';
import { QuestionProps } from './QuestionProps';

function CheckQuestion({
  question,
  answerSheet,
  setAnswerSheet,
}: QuestionProps) {
  const questionId = `question${question.id}`;

  const onClick = (target) => {
    const newInputs = { ...answerSheet };
    const checked = parseInt(target.htmlFor);

    if (checked) {
      const checklist = new Set(newInputs[questionId] || []);
      if (checklist.has(checked)) {
        checklist.delete(checked);
      } else {
        checklist.add(checked);
      }
      newInputs[questionId] = Array.from(checklist).sort();
    }

    setAnswerSheet(newInputs);
  };

  const showCheck = (option) => {
    return (
      answerSheet[questionId] && answerSheet[questionId].includes(option.id)
    );
  };

  return (
    <QuestionContainer key={question.id}>
      <QuestionTitleBlock>{`${question.idSub}.${question.title}`}</QuestionTitleBlock>
      <QuestionOptionsBlock>
        {question.options.map((option) => (
          <OptionItemContainer key={option.id}>
            <OptionItemRadioInput />
            <OptionItemLabel
              htmlFor={option.id}
              onClick={({ currentTarget }) => onClick(currentTarget)}
            >
              <OptionItemCheckBox>
                <CheckIcon showCheck={showCheck(option)} />
              </OptionItemCheckBox>
              {option.text}
            </OptionItemLabel>
          </OptionItemContainer>
        ))}
      </QuestionOptionsBlock>
    </QuestionContainer>
  );
}

export default CheckQuestion;
