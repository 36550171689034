import React, { useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Spinner } from '@styled-icons/icomoon/Spinner';
import bubbleDropPNG from '../../assets/image/bubbleDrop.png';
import { Check, Eye, EyeOff } from '@styled-icons/heroicons-outline';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { showCardOnlyState, useWhiteTextInToolbarState } from '../states';
import { device } from '../utils/device';

export function Main(props) {
  const showCardOnly = useRecoilValue(showCardOnlyState);
  const setUseWhiteTextInToolbar = useSetRecoilState(
    useWhiteTextInToolbarState,
  );

  useEffect(() => {
    setUseWhiteTextInToolbar(props.useWhiteTextInToolbar ?? true);
    return () => setUseWhiteTextInToolbar(false);
  }, [props.useWhiteTextInToolbar, setUseWhiteTextInToolbar]);

  const propsStyle = props.style || {};

  const style: React.CSSProperties = {
    ...propsStyle,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  };

  if (!showCardOnly) {
    style.backgroundColor = '#fff';
    style.backgroundImage = `url(${bubbleDropPNG})`;
    style.backgroundRepeat = 'no-repeat';
    style.backgroundPosition = 'center';
    style.backgroundSize = 'cover';
  }

  return <Box style={style}>{props.children}</Box>;
}

export function Card(props) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const style: React.CSSProperties = {
    display: 'flex',
    position: 'relative',
    minHeight: '650px',
    // height: '100%',
    padding: '9.2rem 1.3rem 0',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
  };

  if (isDesktop) {
    style.boxShadow = '0px 4px 29px rgba(0, 0, 0, 0.1)';
    style.borderRadius = '5rem';
    style.minWidth = '44rem';
    style.maxWidth = '53.4rem';
    style.margin = '5rem 0 8rem 0';
    style.padding = '9.2rem 5rem 0';
  } else {
    style.width = '100%';
    style.borderRadius = '1rem';
    style.margin = '0 0 0.5rem 0';
  }

  return <Box style={style}>{props.children}</Box>;
}

export const AuthTitle = styled.div`
  width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 3.2rem;
  strong {
    font-weight: 700;
  }
  margin-top: 3.6rem;
  /* margin-bottom: 15rem; */
  & p {
    font-size: 1.4rem;
    line-height: 150%;
  }

  @media ${device.desktop} {
    margin-top: 0;
  }
`;

export const AuthMessage = styled.div`
  margin-top: 2rem;
  margin-bottom: 8rem;
  width: 100%;
  display: flex;
  text-align: left;
  align-items: center;
  justify-content: flex-start;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  strong {
    font-weight: 700;
  }
  &.date {
    flex-direction: column;
    align-items: flex-start;
    line-height: 200%;
  }
  span {
    font-size: 12px;
  }
`;

export const FormContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 3.2rem;
  padding-top: 7.2rem;

  @media ${device.desktop} {
    padding-top: 12.365rem;
  }
`;

export const InputBlock = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  & + & {
    margin-top: 20px;
  }
`;

export const InputLabel = styled.div`
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 1.9rem;
`;

export const Input = styled.input<{ showWarning?: boolean }>`
  padding: 0 20% 6px 0;
  width: 100%;
  caret-color: #c4c4c4;
  border: 0.5px solid #c4c4c4;
  border-style: none none solid none;
  margin-bottom: 1rem;
  font-size: 15px;
  &:focus {
    outline: none;
  }
  &:disabled {
    background: none;
  }
  ${(props) =>
    props.showWarning &&
    css`
      border: 1px solid #ff0000;
      border-style: none none solid none;
      caret-color: #ff0000;
    `}
`;

export const Button = styled.button<{ isValid: boolean }>`
  /* position: absolute; */
  margin-bottom: 140px;
  background-color: #fff;
  width: 20.4rem;
  height: 4rem;
  border: 2.23px solid #c4c4c4;
  border-radius: 55px;
  color: #c4c4c4;
  font-weight: normal;
  font-size: 1.7rem;

  ${(props) =>
    props.isValid &&
    css`
      border-color: #000;
      color: #000;
      cursor: pointer;
    `}

  @media ${device.desktop} {
    width: 36.9rem;
    height: 4.9rem;
    border: 0.87px solid #c4c4c4;
    border-radius: 42.51px;
    font-size: 1.6rem;
  }
`;

export const ForgotBlock = styled.div`
  position: absolute;
  bottom: 86px;
  display: flex;
  justify-content: center;
  text-align: center;
  /* margin-top: 1rem; */
  cursor: pointer;
  p {
    font-weight: 300;
    font-size: 15px;
    color: #8e8e8e;
    &::after {
      content: '';
      height: 0.25px;
      display: block;
      background-color: #8e8e8e;
    }
  }
`;
export const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const LoadingIcon = styled(Spinner)`
  width: 20px;
  animation: 2s linear ${spin} infinite;
`;

export const WarningMessage = styled.div<{ showWarning: boolean }>`
  font-weight: 300;
  font-size: 1.1rem;
  color: #ff0000;
  display: none;

  ${(props) =>
    props.showWarning &&
    css`
      display: block;
    `}
`;

export const CheckIcon = styled(Check)<{ showCheck: boolean }>`
  height: 0px;
  color: #578ffc;
  position: absolute;
  right: 0;
  ${(props) =>
    props.showCheck &&
    css`
      height: 20px;
    `}
`;

export const EyeIcon = styled(Eye)<{ showEye: boolean; shiftToLeft: boolean }>`
  height: 0px;
  color: #578ffc;
  position: absolute;
  right: 0px;
  ${(props) =>
    props.showEye &&
    css`
      height: 20px;
    `}
  ${(props) =>
    props.shiftToLeft &&
    css`
      right: 30px;
    `}
  cursor: pointer;
`;

export const EyeOffIcon = styled(EyeOff)<{
  showEyeOff: boolean;
  shiftToLeft: boolean;
}>`
  height: 0px;
  color: #578ffc;
  position: absolute;
  right: 0px;
  ${(props) =>
    props.showEyeOff &&
    css`
      height: 20px;
    `};
  ${(props) =>
    props.shiftToLeft &&
    css`
      right: 30px;
    `};
  cursor: pointer;
`;
