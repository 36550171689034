import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@material-ui/core';
import Fade from 'react-reveal/Fade';
import { SurveyInputBox } from './SurveyInputBox';
import { SurveyScoreBox } from './SurveyScoreBox';
import { UserSatisfactionSurveyAnswer } from '../../../domain/entities/UserSatisfactionSurvey';
import {
  authCredentialState,
  authUserState,
  skinReportListState,
} from '../../states';
import { useRecoilValue } from 'recoil';
import { useStyles } from './StyledComponents';
import { Routes } from '../../../config';
import { useHistory } from 'react-router-dom';
import { RemoteUser } from '../../../data/repositories/RemoteUser';

const SATISFIED_SCORE = 8;

const UserSatisfactionSurvey = (): JSX.Element => {
  const classes = useStyles();
  const authUser = useRecoilValue(authUserState);
  const history = useHistory();
  const skinReportList = useRecoilValue(skinReportListState);
  const [answerSheet, setAnswerSheet] = useState<UserSatisfactionSurveyAnswer>({
    firstAnswer: undefined,
    secondAnswer: undefined,
    thirdAnswer: undefined,
    fourthAnswer: undefined,
  });
  const QuestionLen = Object.values(answerSheet).length;
  const [unAnsweredCount, updateUnAnsweredCount] = useState(QuestionLen);
  const refs = useRef([]);

  useEffect(() => {
    updateUnAnsweredCount(
      QuestionLen -
        Object.values(answerSheet).filter(
          (answer) => typeof answer !== 'undefined' && answer !== '',
        ).length -
        1,
    );
  }, [QuestionLen, answerSheet]);

  const buttonClickHandler = () => {
    if (unAnsweredCount > 0) {
      // 몇 번째 컴포넌트부터 대답이 안됐는지 체크
      if (!answerSheet.secondAnswer && !answerSheet.thirdAnswer) {
        refs.current[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        refs.current[0].focus({ preventScroll: true });
      } else {
        refs.current[1].scrollIntoView({ behavior: 'smooth', block: 'center' });
        refs.current[1].focus({ preventScroll: true });
      }
    } else {
      const gsheetRow = {
        name: authUser?.address?.recipientName,
        userId: authUser?.userId,
        latestMembershipName: skinReportList?.reports[0]?.reportName,
        ...answerSheet,
      };
      history.push(Routes.skinQuizUserSatisfactionSurveyFinish);
      try {
        const repo = new RemoteUser(authCredential);
        repo.UpdateSatisfactionSurveyResult(gsheetRow);
      } catch (err) {
        console.error('Error:' + err);
      }
      // appendSpreadsheet(gsheetRow);
      updateHasReportFeedback();
    }
  };

  let buttonText: string, isValidBtn: boolean;
  switch (unAnsweredCount) {
    case 0:
      buttonText = '의견 보내기';
      isValidBtn = true;
      break;
    case 1:
    case 2:
    case 3:
      buttonText = `질문이 ${unAnsweredCount}개 남았어요 (${unAnsweredCount}/3)`;
      isValidBtn = false;
      break;
    default:
      buttonText = '에러 발생';
      isValidBtn = false;
  }
  const authCredential = useRecoilValue(authCredentialState);

  const updateHasReportFeedback = () => {
    if (authUser?.userId) {
      const repo = new RemoteUser(authCredential);
      repo
        .updateUser({
          ...authUser,
          hasReportFeedback: true,
        })
        .catch((err) => console.error(err));
    }
  };

  const isValidFirstAnswer =
    typeof answerSheet.firstAnswer === 'number' && answerSheet.firstAnswer >= 0;
  return (
    <Box className={classes.root}>
      <SurveyScoreBox
        questionName={'first'}
        answerSheet={answerSheet}
        setAnswerSheet={setAnswerSheet}
      />
      {isValidFirstAnswer && (
        <Fade bottom distance="25px">
          <button
            className={classes.floatingSurveyBtn}
            style={{
              background: isValidBtn
                ? 'linear-gradient(90deg, #8ee4ff 7.93%, #8bb3ff 94.05%)'
                : '#C4C4C4',
            }}
            onClick={buttonClickHandler}
          >
            {buttonText}
          </button>
        </Fade>
      )}
      {isValidFirstAnswer && (
        <Box>
          <Fade bottom distance="25px">
            <div style={{ marginBottom: '80px' }}>
              <SurveyInputBox
                questionName={
                  answerSheet.firstAnswer > SATISFIED_SCORE ? 'second' : 'third'
                }
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
                textRef={(elem) => {
                  refs.current[0] = elem;
                }}
              />
              <SurveyInputBox
                questionName={'fourth'}
                answerSheet={answerSheet}
                setAnswerSheet={setAnswerSheet}
                textRef={(elem) => {
                  refs.current[1] = elem;
                }}
              />
            </div>
          </Fade>
        </Box>
      )}
    </Box>
  );
};
export default UserSatisfactionSurvey;
