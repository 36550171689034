import React from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { authUserState } from '../../states';
import { Button } from '../StyledComponents';
import { getJpgImageSrc } from '../styles';
import { device } from '../../utils/device';
import { useStyles } from './StyledComponents';
import { Routes } from '../../../config';
import { useHistory } from 'react-router-dom';
type Props = {
  onLeave: () => void;
};

export const Modal = styled.div`
  max-width: 495px;
  width: 466px;
  padding: 62px 55px;
  line-height: 17px;
  color: #000000;

  @media ${device.mobile} {
    padding: 57px 16px;
    width: calc(100vw - 64px);
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
  word-break: keep-all;

  @media ${device.mobile} {
    font-size: 2rem;
  }
`;
export const Contents = styled.div`
  font-weight: 500;
  margin-top: 40px;
  font-size: 2rem;
  line-height: 150%;
  text-align: center;
  word-break: keep-all;
  color: #4b515b;

  @media ${device.mobile} {
    margin-top: 27px;
    font-size: 1.4rem;
  }
`;

export const Img = styled.img`
  width: 348px;
  height: 348px;
  display: block;
  margin: 43px auto 74px;
  @media ${device.mobile} {
    width: 238px;
    height: 238px;
    margin: 60px auto 40px;
  }
`;
export const SurveyModal = ({ onLeave }: Props): JSX.Element => {
  const classes = useStyles();
  const authUser = useRecoilValue(authUserState);

  const history = useHistory();
  const surveyStart = () => {
    history.push(Routes.skinQuizUserSatisfactionSurvey);
  };

  return (
    <Modal>
      <Title>{authUser?.displayName}님을 위한 피부리포트</Title>
      <Contents>
        이번 피부 리포트 어떠셨나요?
        <br />
        후기를 들려주세요. 1분이면 끝나요.
      </Contents>
      <Img src={getJpgImageSrc('survey_modal')} />
      <Button
        isValid
        isStart
        className={classes.startBtn}
        onClick={surveyStart}
      >
        의견 보내기
      </Button>
      <br />
      <Button isValid className={classes.goBackBtn} onClick={onLeave}>
        다음에 할게요
      </Button>
    </Modal>
  );
};
