import { SkinTypeRecommend } from './models/SkinTypeRecommend';

const data: SkinTypeRecommend = {
  naiasin: {
    korean: '나이아신아마이드',
    tag: ['피부진정', '트러블케어', '색소침착개선'],
    description:
      '나이아신아마이드는 세라마이드와 피부 내 케라틴의 자연적인 생성을 활발하게 만들어 피부 톤을 고르게 만들어 피부를 매끄럽게 만들고, 더욱 화사하게 만들어 주는 성분입니다.',
  },
  ade: {
    korean: '아데노신',
    tag: ['탄력강화'],
    description:
      '아데노신은 진피층에 있는 콜라겐과 엘라스틴 생산량을 증가시켜 주름 개선 및 생성을 예방해 주면서 피부에 탄력을 부여 넣어주고 피부톤도 밝고 건강한 피부를 가꿀 수 있게 도와줍니다.',
  },
  hial: {
    korean: '히알루론산',
    tag: [
      '피부건조개선',
      '유수분밸런스조절',
      '피부진정',
      '피부장벽강화',
      '탄력강화',
    ],
    description:
      '히알루론산은 자기 무게의 1000배의 수분을 저장할 수 있음 피부 속 깊은 층에서 수분을 잡고 있는 역할을 하는 성분입니다. 히알루론 성분이 피부에 즉각적으로 수분을 공급하여 피지 생성의 균형을 잡아줍니다.',
  },
  vi: {
    korean: 'Vital-Peptide',
    tag: ['색소침착개선', '탄력강화'],
    description:
      '펩타이드 성분은 피부의 단백질 구성단위와 비슷해 꾸준히 사용 시 피부를 구성하는 단백질 화합에 도움을 줍니다. 식물 개화에 도움을 주는 플로리겐(florigen) 단백질의 펩타이드 Vital-Peptide는 피부 탄력에 도움을 줍니다.',
  },
  horang: {
    korean: '호랑가시나무추출물',
    tag: ['피부진정', '피부장벽강화'],
    description:
      '잎과 껍질에 카페인, 사포닌, 타닌 성분이 함유되어 있어 피부 염증 완화하고 피부톤과 피부결 개선에 효과적입니다.',
  },
  bee: {
    korean: '벌노랑이추출물',
    tag: ['트러블케어'],
    description:
      '벌노랑이는 예로부터 한의학에서 열을 내리고 기운을 내는데 사용되었습니다. 여드름균에 대한 항균력이 뛰어나며, 한껏 예민해진 피부 트러블 개선에 도움을 줍니다.',
  },
  bamboo: {
    korean: '대나무초추출물',
    tag: ['유수분밸런스조절', '색소침착개선'],
    description:
      '편백나무 숲보다 피톤치드 발생량이 약 2배 이상 많은 대나무 숲 대나무가 불어 넣는 맑은 기운을 전달합니다. 피부 컨디션 개선에 도움을 주며, 피부를 밝게 해주어 전체적으로 고른 안색을 나타내게 도와줍니다.',
  },
  sera: {
    korean: '세라마이드',
    tag: ['피부진정', '피부장벽강화', '탄력강화'],
    description:
      '피부에는 피부 장벽을 유지하도록 도움을 주는 세라마이드가 있습니다. 세라마이드는 피부의 수분 손실을 막아주며, 외부 환경으로부터 피부를 보호해 줍니다.',
  },
  wintergreen: {
    korean: '윈터그린잎추출물',
    tag: ['피지분비조절'],
    description:
      '윈터그린잎에는 메틸 살리실레이트가 많이 함유되어 있는데, 이것은 천연에서 추출할 수 있는 효과적인 항염 물질입니다. 피부 노폐물과 각질을 부드럽게 정리해 주며, 피부 수렴 효과가 있습니다.',
  },
  astrag: {
    korean: '아스트라갈린',
    tag: ['유수분밸런스조절', '색소침착개선', '탄력강화'],
    description:
      '아스트라갈린은 플라보노이드의 하나로 자연계에 극 미량 존재하는 희귀 생리 활성 물질입니다. 플라보노이드는 피부내 NO(Nitric Oxide)생성을 억제하여 항산화, 항염증 등의 작용을 한다고 알려져 있습니다. 감잎과 뽕잎, 적포도, 고사리 등에도 미량 함유되어 있는 것으로 밝혀졌습니다.',
  },
  propolice: {
    korean: '프로폴리스추출물',
    tag: ['피부진정', '트러블케어', '피부장벽강화'],
    description:
      '꿀벌이 벌집 내부를 보호하기 위해 식물이 분비하는 보호 물질과 자신의 침, 효소를 결합하여 만드는 물질로 피부 진정 및 영양 공급에 매우 효과적입니다.',
  },
  blacknut: {
    korean: '흑호두잎추출물·소나무잎추출물·회화나무잎추출물',
    tag: ['트러블케어', '색소침착개선', '탄력강화'],
    description:
      '피부 질환 개선 및 항염 및 항산화에 탁월한 효과를 나타냅니다. 외부 환경에 무너진 피부 밸런스를 회복시키는데 도움을 줍니다.',
  },
  island: {
    korean: '아이슬란드이끼추출물',
    tag: ['피부건조개선', '유수분밸런스조절', '피부진정', '피부장벽강화'],
    description:
      '건조하고 푸석푸석 메마른 피부에 오랜 시간 지속적으로 깊은 수분감을 더해주는 수분&진정 성분입니다. 피부의 천연 지질 장벽에 영양을 공급하여 수분을 보충합니다.',
  },
  jimo: {
    korean: '지모뿌리추출물',
    tag: ['탄력강화'],
    description:
      '지모추출물은 백합과에 속하는 지모에서 추출한 성분입니다. 사포닌이 다량 함유되어 있고, 지방 세포의 분화와 증식을 도와 피부 볼륨 효과가 있습니다.',
  },
  mushroom: {
    korean: '송이버섯추출물',
    tag: ['색소침착개선', '탄력강화'],
    description:
      '송이버섯추출물에는 다양한 영양성분을 함유하고 있어 피부 미용에 탁월하며 장수비타민 에르고티오네인을 많이 함유하고 있습니다. 에르고티오네인은 천연 아미노산의 일종으로 세포 노화를 억제하는 강력한 항산화 기능을 가진 물질로 알려져 있습니다.',
  },
  pantenol: {
    korean: '판테놀',
    tag: ['피부진정', '피부장벽강화'],
    description:
      '피부에 흡수되면서 비타민 B5로 바뀌면서 피부 장벽 강화에 도움을 주며,수분보유력이 뛰어나 피부보습에 큰 도움이 됩니다. 피부에 윤기와 보습을 제공하고 수분의 증발을 막아서 피부의 수분을 유지해 주는 능력을 갖고 있습니다.',
  },
  hpdr: {
    korean: 'HPDR',
    tag: ['피부장벽강화', '탄력강화'],
    description:
      '연어의 DNA에서 추출한 PDRN은 피부조직 재생을 활성화시키고, 피부 보호, 주름개선 등의 전반적인 피부 개선을 유도하는 성분입니다.',
  },
};
export default data;
