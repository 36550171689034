import React, { FormEvent, useEffect, useState } from 'react';
import { RemoteUser } from '../../../data/repositories/RemoteUser';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authCredentialState,
  errorMessageState,
  paymentCouponState,
  paymentPriceState,
  selectedMembershipProductState,
} from '../../states';
import { PaymentPrice } from '../../../domain/entities/Payment';
import { Consts } from '../../../config';
import { LoadingIcon } from '../StyledComponents';
import Coupon from '../../../domain/entities/Coupon';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0px 46px',
    fontSize: '1.4rem',

    [theme.breakpoints.up('sm')]: {
      margin: '0px 85px 0',
      fontSize: '1.6rem',
    },
  },

  title: {
    margin: '0px 0px 8px',
    fontSize: '1.8rem',
    fontWeight: 700,
  },

  form: {
    margin: '0px 4px',
    padding: '0 9px',
    background: '#F0F0F0',
    width: '100%',
    height: '3.4rem',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },

  input: {
    flex: '1',
    fontSize: '1.2rem',
    fontWeight: 'lighter',
    color: 'black',
    background: 'none',
  },

  submitButton: {
    'fontSize': '1.2rem',
    'fontWeight': 500,
    'padding': '4px 16px',
    'backgroundColor': 'white',
    'border': '1px black solid',
    'borderRadius': '1.5rem',
    'cursor': 'pointer',
    'right': '13%',
    'position': '-webkit-sticky',
    'color': '#000',
    '&:disabled': {
      color: 'grey',
    },
  },
}));

export const PromotionCodeInputSection = (): JSX.Element => {
  const [paymentCoupon, setPaymentCoupon] = useRecoilState(paymentCouponState);
  const authCredential = useRecoilValue(authCredentialState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const [paymentPrice, setPaymentPrice] = useRecoilState(paymentPriceState);
  const [promotionCode, setPromotionCode] = useState(paymentCoupon);
  const [isProcessing, setIsProcessing] = useState(false);
  const selectedMembershipProduct = useRecoilValue(
    selectedMembershipProductState,
  );
  const classes = useStyles();

  useEffect(() => {
    setPromotionCode(paymentCoupon);
  }, [paymentCoupon]);

  async function handleSubmit(event: FormEvent) {
    event.preventDefault();

    if (isProcessing) {
      return;
    }

    try {
      setIsProcessing(true);
      const repo = new RemoteUser(authCredential);
      const coupon = await repo.checkCoupon(
        promotionCode,
        selectedMembershipProduct.id.toString(),
      );
      if (coupon) {
        updateCoupon(coupon);
      } else {
        setErrorMessage('프로모션 코드가 일치하지 않습니다');
        updateCoupon(null);
      }
    } catch (err) {
      if (err.code === Consts.ERROR_INVALID_COUPON) {
        setErrorMessage('프로모션 코드가 일치하지 않습니다');
      } else if (err.code === Consts.ERROR_COUPON_EXPIRED) {
        setErrorMessage('프로모션 코드 유효기간이 지났습니다.');
      } else if (err.code === Consts.ERROR_COUPON_EXCEED_LIMIT) {
        setErrorMessage('프로모션 코드 사용횟수를 초과했습니다.');
      } else if (err.code === Consts.ERROR_COUPON_INVALID_PRODUCT) {
        setErrorMessage(
          <>
            해당 멤버십 플랜에는
            <br />
            사용할 수 없는 프로모션 코드입니다.
          </>,
        );
      } else if (err.code === Consts.ERROR_COUPON_INVALID_EMAIL) {
        setErrorMessage('프로모션 코드 사용 대상자가 아닙니다.');
      } else {
        setErrorMessage(
          '서버에 문제가 발생했습니다. 잠시 후 다시 시도해주세요. 문제가 지속되면 고객센터로 문의주세요.',
        );
      }
      updateCoupon(null);
    } finally {
      setIsProcessing(false);
    }
  }

  function updateCoupon(coupon: Coupon | null) {
    setPaymentPrice(
      new PaymentPrice({
        ...paymentPrice,
        promotion: coupon?.amount ?? 0,
      }),
    );
    setPaymentCoupon(coupon?.code ?? '');
  }

  return (
    <section className={classes.root}>
      <p className={classes.title}>할인 정보</p>
      <form onSubmit={handleSubmit} className={classes.form}>
        <input
          inputMode={'text'}
          placeholder={'코드를 입력해주세요'}
          value={promotionCode}
          onChange={({ target: { value } }) => setPromotionCode(value)}
          className={classes.input}
        />
        {!isProcessing && (
          <input
            type={'submit'}
            value={'적용'}
            className={classes.submitButton}
            disabled={promotionCode === undefined || promotionCode.length === 0}
          />
        )}
        {isProcessing && <LoadingIcon />}
      </form>
    </section>
  );
};
