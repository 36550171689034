import { SkinTypeCaution } from './models/SkinTypeRecommend';

const data: SkinTypeCaution = {
  hyORNT: {
    s0: {
      cautions: [
        { explanation: '피부 면역력이 높아 주의할 성분이 비교적 적습니다' },
      ],
    },
    s1: {
      cautions: [
        { explanation: '피부 면역력이 높아 주의할 성분이 비교적 적습니다' },
      ],
    },
    s2: {
      cautions: [
        { explanation: '피부 면역력이 높아 주의할 성분이 비교적 적습니다' },
      ],
    },
    s3: {
      cautions: [
        { explanation: '피부 면역력이 높아 주의할 성분이 비교적 적습니다' },
      ],
    },
    s4: {
      cautions: [
        { explanation: '피부 면역력이 높아 주의할 성분이 비교적 적습니다' },
      ],
    },
  },
  hyORNW: {
    s0: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s1: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s2: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s3: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s4: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
  },
  hyORPT: {
    s0: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s1: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s2: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s3: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s4: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
  },
  hyORPW: {
    s0: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s1: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s2: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s3: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
    s4: {
      cautions: [{ explanation: '피부 면역력이 높아 주의할 성분이 적습니다' }],
    },
  },
  hyOSNT: {
    s1: {
      cautions: [
        {
          name: '코코넛오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아밀신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '밍크오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '올레스-3',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '라놀린',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '이소스테아릭애씨드',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '벤질살리실레이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '이소데실올리에이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '라우레스-23',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아니스알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyOSNW: {
    s1: {
      cautions: [
        {
          name: '아몬드오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '소듐라우릴설페이트',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '벤질알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '콩오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '스테아레스-10',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '하이드록시시트로넬알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '아세틸레이티드라놀린',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '설페이티드캐스터오일',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '이소프로필리놀리에이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '라우레스-4',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '벤질신나메이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyOSPT: {
    s1: {
      cautions: [
        {
          name: '아보카도오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '라우레스-23',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '밀배아오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '라우릭애씨드',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아이소유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '아세틸레이티드라놀린알코올',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '쿠마린',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '이소프로필이소스테아레이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '올레스-3',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '파네솔',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyOSPW: {
    s1: {
      cautions: [
        {
          name: '옥수수오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '라우레스-4',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '시트랄',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '아마인유',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '올레익애씨드',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아밀신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '이소세틸스테아레이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '소듐라우릴설페이트',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '제라니올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '이소프로필미리스테이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '스테아레스-10',
          problem: '트러블',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '부틸페닐메틸프로피오날',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyDRNT: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '향료',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  hyDRNW: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '비에이치티',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  hyDRPT: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '프로필렌글라이콜',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  hyDRPW: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '향료',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  hyDSNT: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아밀신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '벤질살리실레이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '리날룰',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '나무이끼추출물',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyDSNW: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '벤질알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '벤질벤조에이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아밀신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyDSPT: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '쿠마린',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '시트로넬올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '벤질알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  hyDSPW: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '시트랄',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '제라니올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '헥실신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deORNT: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '비에이치티',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deORNW: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '프로필렌글라이콜',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deORPT: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '향료',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deORPW: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '비에이치티',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deOSNT: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '코코넛오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아밀신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '밍크오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '라놀린',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '벤질살리실레이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '이소데실올리에이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아니스알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deOSNW: {
    s1: {
      cautions: [
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아몬드오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '벤질알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '콩오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '하이드록시시트로넬알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아세틸레이티드라놀린',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '신남알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '이소프로필리놀리에이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '벤질신나메이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deOSPT: {
    s1: {
      cautions: [
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아보카도오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '밀배아오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아이소유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아세틸레이티드라놀린알코올',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '쿠마린',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '이소프로필이소스테아레이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '파네솔',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deOSPW: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '옥수수오일',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '시트랄',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아마인유',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '아밀신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '이소세틸스테아레이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '제라니올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '이소프로필미리스테이트',
          problem: '트러블',
          category: '수분증발차단제',
          explanation:
            '피부 표면에 앏은 막을 형성하여 수분증발차단을 위해 사용되는 성분으로, 모공을 막거나 트러블을 유발할 수 있습니다',
        },
        {
          name: '부틸페닐메틸프로피오날',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deDRNT: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '프로필렌글라이콜',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deDRNW: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '향료',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deDRPT: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '비에이치티',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deDRPW: {
    s0: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '프로필렌글라이콜',
          problem: '피부건조',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부 건조나 염증을 일으킬 수 있습니다',
        },
      ],
    },
  },
  deDSNT: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아니스알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '리모넨',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '시트랄',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deDSNW: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '하이드록시시트로넬알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '벤질신나메이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '메틸2-옥티노에이트',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deDSPT: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아이소유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '파네솔',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '알파-아이소메틸아이오논',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '소듐라우레스설페이트',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '하이드록시시트로넬알',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
  deDSPW: {
    s1: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아밀신나밀알코올',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s2: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '부틸페닐메틸프로피오날',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s3: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '참나무이끼추출물',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
    s4: {
      cautions: [
        {
          name: '트리클로산',
          problem: '피부건조',
          category: '보존제',
          explanation:
            '화장품 오염을 보호하기위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '트리에탄올아민',
          problem: '피부건조',
          category: '계면활성제',
          explanation:
            '다른 성분들을 잘 섞이게 만들기 위해 사용되는 성분으로, 피부 건조나 염증을 일으킬 수 있습니다',
        },
        {
          name: '아이소유제놀',
          problem: '피부자극',
          category: '착향제',
          explanation:
            '향을 내기 위해 사용되는 성분으로,피부에 가려움이나 자극을 줄 수 있습니다',
        },
      ],
    },
  },
};

export default data;
