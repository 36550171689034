import React from 'react';

type Props = {
  isOpened: boolean;
};

export const ToggleArrow = ({ isOpened }: Props): JSX.Element => {
  return (
    <span
      style={{
        display: 'inline-block',
        transition: 'all 0.3s ease',
        transform: isOpened && 'rotate(90deg)',
        marginRight: '0.3rem',
      }}
    >
      {`>`}
    </span>
  );
};
