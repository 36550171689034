import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  authCredentialState,
  authService,
  authUserState,
  loginEmailState,
} from '../states';
import { validateEmail } from '../utils/formValidations';
import {
  AuthTitle,
  Button,
  Card,
  CheckIcon,
  EyeIcon,
  EyeOffIcon,
  ForgotBlock,
  FormContent,
  Input,
  InputBlock,
  InputLabel,
  LoadingIcon,
  WarningMessage,
} from './StyledComponents';
import { Routes } from '../../config';
import { RemoteUser } from '../../data/repositories/RemoteUser';
import { User } from '../../domain/entities/User';

const welcomeText = (
  <h1>
    <strong>매니폴드</strong>를
    <br />
    시작해보세요 :-)
    <br />
    <br />
    <p>기존 회원은 가입하신 이메일을 입력해 주시고, 처음 방문</p>
    <p>하셨다면 회원가입에 사용될 이메일을 입력해 주세요.</p>
  </h1>
);

function LoginCard({
  successUrl,
  failureUrl,
  redirectToAfterSignUp,
}: {
  successUrl: string;
  failureUrl: string;
  redirectToAfterSignUp?: string;
}): JSX.Element {
  const auth = useRecoilValue(authService);
  const [authCredential, setAuthCredential] =
    useRecoilState(authCredentialState);
  const [loginEmail, setLoginEmail] = useRecoilState(loginEmailState);

  const [email, setEmail] = useState(loginEmail);
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [memberStatus, setMemberStatus] = useState(false);
  const [showWarningState, setShowWarningState] = useState(false);
  const [validateWithWarning, setValidateWithWarning] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [titleMessage, setTitleMessage] = useState(welcomeText);
  const [buttonText, setButtonText] = useState('다음');
  const [passwordError, setPasswordError] = useState(false);
  const history = useHistory();
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const { quizId } = authUser || {};
  const login = async () => {
    setButtonText((<LoadingIcon />) as any);
    try {
      const authCredential = await auth.signInWithEmailAndPassword(
        email,
        password,
      );
      setAuthCredential(authCredential);
      const repo = new RemoteUser(authCredential);

      const user: User = await repo.getUser(authCredential?.userId);
      setAuthUser({
        ...user,
        quizId: quizId || user.quizId,
      });
      history.push(successUrl);
    } catch (err) {
      console.error(err);
      if (err.code === 'auth/wrong-password') {
        setPasswordError(true);
      }
      setButtonText(buttonText);
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setLoginEmail(e.target.value);
    setMemberStatus(false);
    setButtonText('다음');
    setTitleMessage(welcomeText);
    validateEmail(
      e.target.value,
      validateWithWarning,
      setShowWarningState,
      setIsValid,
    );
    // validateOnChange && validateEmail(e);
  };

  const verifyEmail = async () => {
    setButtonText((<LoadingIcon />) as any);
    setLoginEmail(email);
    try {
      const repo = new RemoteUser(authCredential);
      const user = await repo.getUserByEmail(email);
      setButtonText('로그인하기');
      setMemberStatus(true);
      setTitleMessage(
        <h1>
          <strong>{user?.displayName}님</strong>,
          <br />
          다시 와주셨네요 :)
        </h1>,
      );
    } catch (err) {
      if (err?.status === 404) {
        setMemberStatus(false);
        history.push(failureUrl, { referrer: redirectToAfterSignUp });
      } else {
        console.error(err);
        setButtonText('다음');
      }
    }
  };

  const goForgotEmailPage = () => {
    history.push(Routes.forgotEmail);
  };

  const goForgotPasswordPage = () => {
    history.push(Routes.forgotPassword);
  };

  const onPasswordChange = (e) => {
    setPasswordError(false);
    setPassword(e.target.value);
  };

  const onShowPasswordChange = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (loginEmail) {
      setEmail(loginEmail);
      validateEmail(
        loginEmail,
        validateWithWarning,
        setShowWarningState,
        setIsValid,
      );
    }
  }, [loginEmail, validateWithWarning]);

  return (
    <Card>
      <AuthTitle>{titleMessage}</AuthTitle>
      <FormContent>
        <InputBlock>
          <InputLabel>이메일 주소</InputLabel>
          <Input
            type="email"
            value={email}
            onChange={onEmailChange}
            onBlur={() => setValidateWithWarning(true)}
            placeholder="example@email.com"
            showWarning={showWarningState}
          />
          <CheckIcon showCheck={isValid} style={{ top: '3.3rem' }} />
          <WarningMessage showWarning={showWarningState}>
            이메일 양식을 확인해주세요
          </WarningMessage>
        </InputBlock>
        {memberStatus && (
          <InputBlock>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={onPasswordChange}
              placeholder="password"
            />
            <EyeIcon
              showEye={!showPassword}
              shiftToLeft={password?.length > 5}
              onClick={onShowPasswordChange}
            />
            <EyeOffIcon
              showEyeOff={showPassword}
              shiftToLeft={password?.length > 5}
              onClick={onShowPasswordChange}
            />
            <CheckIcon showCheck={password?.length > 5} />
            <WarningMessage showWarning={passwordError}>
              비밀번호가 일치하지 않습니다. 다시 한번 확인 부탁드립니다.
            </WarningMessage>
          </InputBlock>
        )}
      </FormContent>
      {memberStatus ? (
        <>
          <Button
            onClick={login}
            isValid={password.length > 0}
            disabled={!password.length}
          >
            {buttonText}
          </Button>
          <ForgotBlock onClick={goForgotPasswordPage}>
            <p>비밀번호가 기억이 나지 않아요</p>
          </ForgotBlock>
        </>
      ) : (
        <>
          <Button onClick={verifyEmail} isValid={isValid} disabled={!isValid}>
            {buttonText}
          </Button>
          <ForgotBlock onClick={goForgotEmailPage}>
            <p>이메일이 기억이 나지 않아요</p>
          </ForgotBlock>
        </>
      )}
    </Card>
  );
}

export default LoginCard;
