import React from 'react';
import styled, { css } from 'styled-components';
const Container = styled.div`
  display: flex;
  align-items: center;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  width: 36px;
  height: 20px;
`;
const CheckBox = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
`;
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 20px;
  border-radius: 15px;
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: '';
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    margin: 2px;
    background: #ffffff;
    /* box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2); */
    transition: 0.2s;
  }
  ${CheckBox}:checked +& {
    background: #578ffc;

    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      margin-left: calc(36px - 16px - 2px);
      transition: 0.2s;
    }
  }
`;

const ToggleText = styled.div<{ showGreyText: boolean }>`
  margin-left: 10px;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;

  ${(props) =>
    props.showGreyText &&
    css`
      color: #c4c4c4;
    `}
`;

function Toggle({ onClick = undefined, useNotification = undefined }) {
  return (
    <Container>
      <CheckBoxWrapper>
        <CheckBox
          id="checkbox"
          type="checkbox"
          onClick={onClick}
          checked={!!useNotification}
        />
        <CheckBoxLabel htmlFor="checkbox" />
      </CheckBoxWrapper>
      <ToggleText showGreyText={!useNotification}>
        <span>{useNotification ? '알림받기' : '받지않기'}</span>
      </ToggleText>
    </Container>
  );
}
//signup 토글 on/off 설정하기!

export default Toggle;
