import { addBusinessDays } from 'date-fns';
export const DISABLED_EVENTDATES = [
  new Date('2022-04-29'),
  new Date('2022-04-30'),
  new Date('2022-05-01'),
  new Date('2022-05-02'),
  new Date('2022-05-03'),
  new Date('2022-05-04'),
  new Date('2022-05-05'),
];

export function isDisabledDate(date: Date): boolean {
  const isDisableEventDatesArray = DISABLED_EVENTDATES.map(
    (disabledEventDay) => {
      return (
        date.getFullYear() === disabledEventDay.getFullYear() &&
        date.getMonth() === disabledEventDay.getMonth() &&
        date.getDate() === disabledEventDay.getDate()
      );
    },
  );
  return isDisableEventDatesArray.reduce((bool, current) => bool || current);
}

export function getNewMinSelectableDayByEventDate() {
  const maxDisableEventDate = new Date(
    Math.max.apply(null, DISABLED_EVENTDATES),
  );
  return maxDisableEventDate.getHours() < 10
    ? addBusinessDays(maxDisableEventDate, 1)
    : addBusinessDays(maxDisableEventDate, 2);
}
