export const validateEmail = (
  value,
  validateWithWarning,
  setShowWarningState,
  setIsValid,
) => {
  const reg = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  if (validateWithWarning) {
    if (reg.test(value) === false) {
      setShowWarningState(true);
      setIsValid(false);
    } else if (reg.test(value) === true) {
      setShowWarningState(false);
      setIsValid(true);
    }
  } else {
    if (reg.test(value) === false) {
      setIsValid(false);
    } else if (reg.test(value) === true) {
      setIsValid(true);
    }
  }
};

export const validateBirthdate = (
  value,
  validateWithWarning,
  setShowBirthdateWarning,
  setIsValid,
  isValid,
) => {
  const reg = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/;
  if (validateWithWarning.birthdate) {
    if (reg.test(value) === false) {
      setShowBirthdateWarning(true);
      setIsValid({ ...isValid, birthdate: false });
    } else {
      setShowBirthdateWarning(false);
      setIsValid({ ...isValid, birthdate: true });
    }
  } else {
    if (reg.test(value) === false) {
      setIsValid({ ...isValid, birthdate: false });
    } else {
      setIsValid({ ...isValid, birthdate: true });
    }
  }
};

export const validatePhoneNumber = (
  value,
  validateWithWarning,
  setShowPhoneNumberWarning,
  setIsValid,
  isValid,
) => {
  const reg = /^01([0|1|6|7|8|9]?)-?([0-9]{3,4})-?([0-9]{4})$/;
  if (validateWithWarning.phoneNumber) {
    if (reg.test(value) === false) {
      setShowPhoneNumberWarning(true);
      setIsValid({ ...isValid, phoneNumber: false });
    } else {
      setShowPhoneNumberWarning(false);
      setIsValid({ ...isValid, phoneNumber: true });
    }
  } else {
    if (reg.test(value) === false) {
      setIsValid({ ...isValid, phoneNumber: false });
    } else {
      setIsValid({ ...isValid, phoneNumber: true });
    }
  }
};

export const validateName = (
  value,
  validateWithWarning,
  setShowNameWarning,
  setIsValid,
  isValid,
) => {
  const reg = /^[a-zA-Z가-힣]+$/;
  if (validateWithWarning.name) {
    if (reg.test(value) === false) {
      setShowNameWarning(true);
      setIsValid({ ...isValid, name: false });
    } else {
      setShowNameWarning(false);
      setIsValid({ ...isValid, name: true });
    }
  } else {
    if (reg.test(value) === false) {
      setIsValid({ ...isValid, name: false });
    } else {
      setIsValid({ ...isValid, name: true });
    }
  }
};

export const validatePassword = (
  value: string,
  validateWithWarning,
  setShowPasswordWarning,
  setIsValid,
  isValid,
) => {
  const valid = value.length >= 6;
  if (validateWithWarning.password) {
    if (valid) {
      setShowPasswordWarning(false);
      setIsValid({ ...isValid, password: true });
    } else {
      setShowPasswordWarning(true);
      setIsValid({ ...isValid, password: false });
    }
  } else {
    if (valid) {
      setIsValid({ ...isValid, password: true });
    } else {
      setIsValid({ ...isValid, password: false });
    }
  }
};
