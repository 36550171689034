import React from 'react';
import { Content, Input, InputBlock, PageTitle } from '../StyledComponents';
import { QuestionProps } from './QuestionProps';

function InputQuestion({
  question,
  answerSheet,
  setAnswerSheet,
}: QuestionProps) {
  const questionId = `question${question.id}`;

  const onChange = ({ target }) => {
    setAnswerSheet({
      ...answerSheet,
      [questionId]: target.value,
    });
  };

  if (question.id == 1) {
    return (
      <Content>
        <InputBlock>
          <PageTitle>
            <div style={{ textAlign: 'center', fontWeight: 'normal' }}>
              안녕하세요,
              <br />
              디지털 스킨케어를 시작할게요
              <br />
              <br />
            </div>
            {question.title}
          </PageTitle>
          <Input
            placeholder={question.placeholder}
            onChange={onChange}
            value={answerSheet[questionId]}
          />
        </InputBlock>
      </Content>
    );
  } else {
    return (
      <Content>
        <InputBlock>
          <PageTitle>{`${answerSheet?.question1}${question.title}`}</PageTitle>
          <Input
            placeholder={question.placeholder}
            onChange={onChange}
            value={answerSheet[questionId]}
          />
        </InputBlock>
      </Content>
    );
  }
}

export default InputQuestion;
