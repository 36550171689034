import React, { useEffect, useState } from 'react';
import { Content } from './StyledComponents';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  errorMessageState,
  kakaoFabState,
  quizBackgroundState,
  skinQuizAnswerSheetState,
} from '../states';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../config';
import QuestionContent from './questions/QuestionContent';
import ProgressBar from './ProgressBar';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import footerMessage from '../../assets/image/skinquiz_footer_message.svg';
import BottomButtons from './questions/BottomButtons';
import skinQuizData from '../../data/skinQuizData';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import ErrorDialog from '../components/ErrorDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      display: 'block',
      width: '13.5rem',
      height: '3.4rem',
      backgroundImage: `url(${footerMessage})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      marginBottom: '5rem',
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
  }),
);

class QuestionPageData {
  order: number;
  name: string;
}
const questionPages: QuestionPageData[] = [
  { order: 1, name: 'name' },
  { order: 2, name: 'basic' },
  // { order: 2, name: 'gender' },
  // { order: 3, name: 'age' },
  { order: 3, name: 'OD' },
  { order: 4, name: 'SR' },
  { order: 5, name: 'PN' },
  { order: 6, name: 'WT' },
  { order: 7, name: 'hyde' },
  { order: 8, name: 'overall' },
  { order: 9, name: 'product' },
];

const findQuestionPage = (name: string) => {
  return questionPages.find((item) => name === item.name);
};
const findNextQuestionPage = (name: string) => {
  const page = findQuestionPage(name);
  return page?.order < questionPages.length
    ? questionPages[page.order]
    : undefined;
};

export default function QuestionPage({ match }) {
  const history = useHistory();
  const classes = useStyles();
  const page = match?.params?.page || '';

  const skinQuizAnswerSheet = useRecoilValue(skinQuizAnswerSheetState);
  const setQuizBackground = useSetRecoilState(quizBackgroundState);
  const setKakaoFab = useSetRecoilState(kakaoFabState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const [redirectionPage, setRedirectionPage] = useState('');

  const questions = skinQuizData.filter((elem) =>
    elem.category === page,
  );

  const isStart = page === 'name';
  const isLast = page === 'product';
  const isDone = (): boolean => {
    const unchecked = questions.find((item) => {
      const input = skinQuizAnswerSheet[`question${item.id}`];
      const quizData = skinQuizData[item.id - 1];
      if (quizData.optional) {
        return false;
      } else if (Array.isArray(input)) {
        return !input || input.length == 0;
      } else {
        return !input;
      }
    });
    return !unchecked;
  };

  const onSubmit = async () => {
    if (isLast) {
      if (!skinQuizAnswerSheet.question1) {
        setErrorMessage('진행 정보가 초기화되었습니다. 다시 진행해주세요.');
        setRedirectionPage(Routes.skinQuiz);
      } else {
        history.push(Routes.skinQuizProcessing);
      }
    } else {
      const nextPage = findNextQuestionPage(page);
      if (nextPage) {
        history.push(`${Routes.skinQuiz}/${nextPage?.name}`);
      } else {
        history.push(Routes.notFound);
      }
    }
  };

  useEffect(() => {
    setQuizBackground(QuizBackground.WHITE);
    if (!page) {
      history.push(`${Routes.skinQuiz}/${questionPages[0].name}`);
    } else if (!findQuestionPage(page)) {
      history.push(Routes.notFound);
    }
  }, [history, page, setQuizBackground]);

  setKakaoFab(false);

  const onErrorDialogClosed = () => {
    setErrorMessage('');
    if (redirectionPage) {
      history.push(redirectionPage);
    }
  };

  return (
    <Content>
      <ProgressBar page={page} />
      <QuestionContent page={page} quizType="skin" />
      <BottomButtons
        page={page}
        isDone={isDone()}
        isStart={isStart}
        isLast={isLast}
        onSubmit={onSubmit}
      />
      <Box className={classes.footer} />
      <ErrorDialog onClose={onErrorDialogClosed} />
    </Content>
  );
}

export { questionPages, findQuestionPage, findNextQuestionPage };
