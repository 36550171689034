import firebase from 'firebase/app';

export interface AuthCredential {
  userId: string;
  idToken: string;
}
export abstract class AuthService {
  abstract onAuthStateChanged(
    func: (authUser: firebase.User | null) => void,
  ): firebase.Unsubscribe;

  abstract get authCredential(): Promise<AuthCredential>;

  abstract signOut(): Promise<void>;
  abstract createUserWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<AuthCredential>;

  abstract signInWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<AuthCredential>;

  abstract sendPasswordResetEmail(email: string): Promise<void>;
}
