import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled, { css } from 'styled-components';
import Toggle from '../utils/Toggle';

import handlePhoneNumber from '../utils/handlePhoneNumber';
import {
  validateBirthdate,
  validateName,
  validatePassword,
  validatePhoneNumber,
} from '../utils/formValidations';
import {
  Button,
  Card,
  CheckIcon,
  EyeIcon,
  EyeOffIcon,
  FormContent,
  Input,
  InputBlock,
  LoadingIcon,
  Main,
  WarningMessage,
} from './StyledComponents';
import { Consts, Routes } from '../../config';
import { authService, authUserState, loginEmailState } from '../states';
import { RemoteUser } from '../../data/repositories/RemoteUser';
import { HttpError } from '../utils/errors';
import { Check } from 'styled-icons/heroicons-outline';
import { Box, Container, Divider, Typography } from '@material-ui/core';
import { useStyles } from './style';
import { User } from '../../domain/entities/User';

const AgreementTextBlock = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
  color: #9c9c9c;
`;

const AgreementTitleBlock = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 11px;
  color: #000;
`;
const AgreementToggleBlock = styled.div`
  margin-left: 20px;
  display: flex;
`;

const CheckAgreementBlock = styled.div<{ showCheck: boolean }>`
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background-color: #c4c4c4;
  margin-top: 0.5rem;
  margin-left: 1.3rem;
  ${(props) =>
    props.showCheck &&
    css`
      background-color: #578ffc;
    `}
`;

const CheckAgreementIcon = styled(Check)<{ showCheck: boolean }>`
  display: none;
  ${(props) =>
    props.showCheck &&
    css`
      display: inline-block;
      color: #fff;
    `}
`;

function SignupPage(props): JSX.Element {
  const history = useHistory();
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [useNotification, setUseNotification] = useState(true);

  const auth = useRecoilValue(authService);
  const email = useRecoilValue(loginEmailState);

  const [signupButonText, setSignupButtonText] = useState('가입완료');
  const [showBirthdateWarning, setShowBirthdateWarning] = useState(false);
  const [showPhoneNumberWarning, setShowPhoneNumberWarning] = useState(false);
  const [showNameWarning, setShowNameWarning] = useState(false);
  const [showPasswordWarning, setShowPasswordWarning] = useState(false);
  const [validateWithWarning, setValidateWithWarning] = useState({
    birthdate: false,
    phoneNumber: false,
    name: false,
    password: false,
  });
  const [isValid, setIsValid] = useState({
    birthdate: false,
    phoneNumber: false,
    name: false,
    password: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [authUser, setAuthUser] = useRecoilState(authUserState);

  const isSkinQuiz = location.pathname.indexOf(Routes.skinQuiz) !== -1;
  // ---- functions below ---- //

  const goBackToLoginPage = () => {
    if (isSkinQuiz) {
      history.push(Routes.skinQuizLogin);
    } else {
      history.push(Routes.login);
    }
  };

  const handleToggleAction = ({ target }) => {
    setUseNotification(target.checked);
  };

  const onPasswordChange = ({ target }) => {
    setPassword(target.value);
    validatePassword(
      target.value,
      validateWithWarning,
      setShowPasswordWarning,
      setIsValid,
      isValid,
    );
  };

  const onPhoneNumberChange = ({ target }) => {
    const phoneNumber = handlePhoneNumber(target.value);
    setPhoneNumber(phoneNumber);
    validatePhoneNumber(
      phoneNumber,
      validateWithWarning,
      setShowPhoneNumberWarning,
      setIsValid,
      isValid,
    );
  };
  const onDisplayNameChange = ({ target }) => {
    setDisplayName(target.value);
    validateName(
      target.value,
      validateWithWarning,
      setShowNameWarning,
      setIsValid,
      isValid,
    );
  };
  const onBirthdateChange = ({ target }) => {
    setBirthdate(target.value);
    validateBirthdate(
      target.value,
      validateWithWarning,
      setShowBirthdateWarning,
      setIsValid,
      isValid,
    );
  };

  const registerUser = async () => {
    const goToNextPage = () => {
      if (props.location?.state?.referrer) {
        history.push(props.location?.state?.referrer);
      } else if (isSkinQuiz) {
        history.push(Routes.skinQuizSelectFirstDeliveryDate);
      } else {
        history.push(Routes.mypageSubscription);
      }
    };

    setSignupButtonText((<LoadingIcon />) as any);

    try {
      let repo = new RemoteUser();
      const phoneExistsUser = await repo
        .getUserByPhoneNumber(phoneNumber)
        .catch((err) => {
          if (err.status !== 404) {
            throw err;
          }
        });
      if (phoneExistsUser) {
        throw new HttpError(400, Consts.ERROR_PHONE_NUMBER_ALREADY_EXISTS);
      }

      const credential = await auth.createUserWithEmailAndPassword(
        email,
        password,
      );

      repo = new RemoteUser(credential);
      await repo.registerUser({
        userId: credential?.userId,
        displayName,
        phoneNumber,
        birthdate,
      });

      await auth.signInWithEmailAndPassword(email, password);

      // We need to call getUser here additionally because
      // App#onAuthStateChanged doesn't get called after auth.signInWithEmailAndPassword
      const user: User = await repo.getUser(credential?.userId);
      setAuthUser({
        ...user,
        quizId: authUser?.quizId,
      });

      goToNextPage();
    } catch (err) {
      console.error(err);
      const { code } = err;
      if (code === 'auth/email-already-in-use') {
        alert(
          '이미 가입되어있는 이메일 입니다. 혹시 가입하신적이 없으시면 고객센터로 문의주시기 바랍니다.',
        );
        setSignupButtonText('가입완료');
      } else if (
        code === 'auth/phone-number-already-exists' ||
        code === Consts.ERROR_PHONE_NUMBER_ALREADY_EXISTS
      ) {
        alert(
          '이미 등록된 전화번호 입니다. 혹시 가입하신적이 없으시면 고객센터로 문의주시기 바랍니다.',
        );
        setSignupButtonText('가입완료');
      } else {
        alert(
          '서버에서 오류가 발생하였습니다. 문제가 계속되면 고객센터로 문의주시기 바랍니다.',
        );
        setSignupButtonText('다시해보기');
      }
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Main useWhiteTextInToolbar={!isSkinQuiz}>
      <Container maxWidth="lg" className={classes.root}>
        <Card>
          <Typography className={classes.title}>
            처음이시네요
            <br />
            <span style={{ fontWeight: 'bold' }}>가입을 도와드릴게요 :)</span>
          </Typography>
          <FormContent className={classes.signupForm}>
            <InputBlock
              className={classes.signupInput}
              onClick={goBackToLoginPage}
            >
              <Input value={email} disabled placeholder="아이디/이메일" />
              <CheckIcon showCheck />
            </InputBlock>
            <InputBlock className={classes.signupInput}>
              <Input
                type={showPassword ? 'text' : 'password'}
                placeholder="비밀번호 (6자이상)"
                onChange={onPasswordChange}
                name="password"
                value={password}
                minLength={6}
                onBlur={() => {
                  setValidateWithWarning({
                    ...validateWithWarning,
                    password: true,
                  });
                }}
              />
              <EyeIcon
                showEye={!showPassword}
                shiftToLeft={password?.length > 5}
                onClick={handleShowPassword}
              />
              <EyeOffIcon
                showEyeOff={showPassword}
                shiftToLeft={password?.length > 5}
                onClick={handleShowPassword}
              />
              <CheckIcon showCheck={isValid.password} />
              <WarningMessage showWarning={showPasswordWarning}>
                비밀번호는 6자이상 입력해주세요
              </WarningMessage>
            </InputBlock>
            <InputBlock className={classes.signupInput}>
              {' '}
              <Input
                placeholder="휴대폰 번호 ( '-' 제외)"
                onChange={onPhoneNumberChange}
                onBlur={() => {
                  setValidateWithWarning({
                    ...validateWithWarning,
                    phoneNumber: true,
                  });
                }}
                value={phoneNumber}
                name="phoneNumber"
                maxLength={13}
                required
              />
              <CheckIcon showCheck={isValid.phoneNumber} />
              <WarningMessage showWarning={showPhoneNumberWarning}>
                휴대폰 번호 양식을 확인해주세요
              </WarningMessage>
            </InputBlock>
            <InputBlock className={classes.signupInput}>
              {' '}
              <Input
                placeholder="이름"
                onChange={onDisplayNameChange}
                onBlur={() => {
                  setValidateWithWarning({
                    ...validateWithWarning,
                    name: true,
                  });
                }}
                value={displayName}
                name="displayName"
                required
              />
              <CheckIcon showCheck={isValid.name} />
              <WarningMessage showWarning={showNameWarning}>
                이름은 한글,영문만 가능합니다
              </WarningMessage>
            </InputBlock>
            <InputBlock className={classes.signupInput}>
              {' '}
              <Input
                placeholder="생년월일 ( 예 : 901020 )"
                onChange={onBirthdateChange}
                onBlur={() => {
                  setValidateWithWarning({
                    ...validateWithWarning,
                    birthdate: true,
                  });
                }}
                value={birthdate}
                name="birthdate"
                maxLength={6}
                required
              />
              <CheckIcon showCheck={isValid.birthdate} />
              <WarningMessage showWarning={showBirthdateWarning}>
                생년월일 양식을 확인해주세요
              </WarningMessage>
            </InputBlock>
            <AgreementTextBlock>
              <AgreementTitleBlock>
                개인정보 수집 이용동의(필수)
                <CheckAgreementBlock
                  showCheck={agreeTerms}
                  onClick={() => setAgreeTerms(!agreeTerms)}
                >
                  <CheckAgreementIcon showCheck={agreeTerms} />
                </CheckAgreementBlock>
              </AgreementTitleBlock>
              <Box display="flex">
                <a
                  href="https://manifold.fit/terms"
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                  }}
                >
                  이용약관
                </a>
                <Divider
                  orientation="vertical"
                  flexItem
                  style={{ margin: '0 0.5rem' }}
                />
                <a
                  href="https://manifold.fit/privacy"
                  style={{
                    color: 'inherit',
                    textDecoration: 'underline',
                  }}
                >
                  개인정보 처리방침
                </a>
              </Box>
            </AgreementTextBlock>
            <AgreementTextBlock>
              <AgreementTitleBlock>
                매니폴드 혜택 알림받기
                <AgreementToggleBlock>
                  <Toggle
                    onClick={handleToggleAction}
                    useNotification={useNotification}
                  />
                </AgreementToggleBlock>
              </AgreementTitleBlock>
              매니폴드 혜택 알림에 동의하시면 다양한 신제품 출시 정보와 각종
              세일 및 이벤트등 다양한 혜택 정보를 받아보실 수 있습니다.
            </AgreementTextBlock>
          </FormContent>
          <Button
            className={classes.button}
            onClick={registerUser}
            isValid={
              isValid.phoneNumber &&
              isValid.birthdate &&
              isValid.name &&
              isValid.password &&
              agreeTerms
            }
            disabled={
              !isValid.phoneNumber ||
              !isValid.birthdate ||
              !isValid.name ||
              !isValid.password ||
              !agreeTerms
            }
          >
            {signupButonText}
          </Button>
        </Card>
      </Container>
    </Main>
  );
}

export default SignupPage;
