import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import packOpen from '../../../assets/image/pack_open.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import {
  selectedFirstDeliveryDateState,
  selectedMembershipProductState,
} from '../../states';
import { format } from 'date-fns';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '355px',
    height: '200px',
    opacity: '1',
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '9px',
    },
  },

  subject: {
    position: 'absolute',
    left: '112px',
    fontSize: '14px',
    fontWeight: 'bold',
  },

  title: {
    'width': '55px',
    'height': '57px',
    'opacity': '1',
    'position': 'absolute',
    'top': '39px',
    'left': '112px',
    'overflow': 'hidden',

    '& span': {
      width: '47px',
      color: 'rgba(94,94,94,1)',
      position: 'absolute',
      top: '0px',
      left: '0px',
      fontWeight: 'Regular',
      fontSize: '12px',
      opacity: '1',
      textAlign: 'left',
    },
  },

  description: {
    'width': '164px',
    'height': '284px',
    'position': 'absolute',
    'top': '39px',
    'left': '180px',
    'overflow': 'hidden',

    '& span': {
      color: 'rgba(94,94,94,1)',
      position: 'absolute',
      top: '0px',
      left: '0px',
      fontWeight: 'Regular',
      fontSize: '12px',
      opacity: '1',
      textAlign: 'left',
    },
  },

  images: {
    float: 'left',
    left: '2px',
  },

  row: {
    display: 'inline',
    fontSize: '1.3rem',
  },
}));

export const ProductInfo = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const selectedMembershipProduct = useRecoilValue(
    selectedMembershipProductState,
  );
  const firstDeliveryDate = format(
    useRecoilValue(selectedFirstDeliveryDateState) || new Date(),
    'yyyy년 MM월 dd일',
  );

  return (
    <section className={classes.root}>
      <div className={classes.images}>
        <img
          src={packOpen}
          alt={'상품 사진'}
          width={smUp ? '100px' : '90px'}
          height={smUp ? '100px' : '90px'}
        />
      </div>
      <span className={classes.subject}>{selectedMembershipProduct?.name}</span>
      <div>
        <div className={classes.title}>
          <span style={{ top: '0px' }}>첫 발송일</span>
          <span style={{ top: '20px' }}>발송 주기</span>
          <span style={{ top: '40px' }}>상품 구성</span>
        </div>
        <div className={classes.description}>
          <span style={{ top: '0px' }}>{firstDeliveryDate}</span>
          <span style={{ top: '20px' }}>14일마다</span>
          <div style={{ lineHeight: 'normal' }}>
            <span style={{ top: '40px' }}>
              <pre>
                {selectedMembershipProduct?.description}
                스타터 키트 무료 제공
              </pre>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};
