import React from 'react';

export interface LandingText {
  title: JSX.Element;
  subtitle: JSX.Element;
  buttonText: string;
  buttonClassName: string;
}

export const defaultLandingText: LandingText = {
  title: (
    <>
      스킨케어 유목민 생활
      <br />
      이제는 디지털로 끝내다.
      <br />
    </>
  ),
  subtitle: (
    <>
      인공지능과 전문가가
      <br />
      당신의 피부 타입과 알맞은 성분 추천까지.
      <br />
      모든 고민을 해결해드립니다.
      <br />
    </>
  ),
  buttonText: '스킨케어의 혁명 시작하기',
  buttonClassName: 'aButton',
};

export const altLandingText: LandingText = {
  title: (
    <>
      귀찮게 고르지 마세요.
      <br />
      피부에 딱 맞게 보내드려요
      <br />
    </>
  ),
  subtitle: (
    <>
      내 피부에 맞게 설계된 스킨케어
      <br />
      데이터 기반의 AI가 분석하고 제안합니다.
      <br />
    </>
  ),
  buttonText: '나의 피부상태 확인하기',
  buttonClassName: 'bButton',
};
