import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BodyDivider } from '../styles';
import { ResultScore } from '../../../domain/entities/SkinDiagnosis';
import RadarChart from '../charts/RadarChart';
import BarChart from '../charts/BarChart';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { skinScoreEngToKor } from '../../utils/translateName';
import { format } from 'date-fns';
import { SkinReportItem } from '../../../domain/entities/SkinReportList';
import ReportIndex from '../../../domain/entities/ReportIndex';

type RadarData = {
  data: any[];
  keys: string[];
  indexBy: string;
  color: string[];
};
type BarData = {
  key: string;
  score: any;
  result: any;
}[];
type Props = {
  userName?: string;
  radarData?: RadarData;
  barData?: BarData;
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    color: '#6A6A6A',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.1)',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },

  chartBox: {
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 10.3rem',
    },
  },

  radarChart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: '35rem',
    marginTop: '-1rem',
    marginBottom: '-1rem',
    [theme.breakpoints.up('sm')]: {
      height: '40rem',
    },
  },

  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '10px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginBottom: '5px',
    },
  },

  barChart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    paddingBottom: '30px',
  },
}));

const skinTypeTexts = {
  hy: '수분성',
  de: '탈수성',
  O: '지성',
  D: '건성',
  S1: '민감도',
  S2: '여드름/트러블',
  S3: '홍조/주사',
  S4: '습진/알레르기',
  R: '저항성',
  S: '민감성',
  P: '색소성',
  N: '비색소성',
  W: '주름성',
  T: '탄력성',
};
function formatDate(date: Date): string {
  return format(date, 'yyyy.MM.dd');
}

const getPreviousRadarData = (
  skinReports: SkinReportItem[],
  reportIndex: ReportIndex,
  radarScore: Map<string, ResultScore>,
  createdAt: number,
) => {
  if (!reportIndex.index || reportIndex.index === 0 || reportIndex.index === 1)
    return undefined;
  for (let i = reportIndex.dataIndex + 1; i < skinReports.length; i++) {
    // 이 데이터가 있는지?
    if (skinReports[i]?.diagnosis) {
      //있다면 해당 데이터 반환
      return getNthRadarData(skinReports[i]);
    }
  }
  // skinReports를 모두 돌았는데도 없다면 0차 반환
  return get0thRadarData(radarScore, createdAt);
};

export const getBarDataObj = (
  barScore: Map<string, ResultScore> | undefined,
): BarData | undefined => {
  if (!barScore) return undefined;
  const barData = Object.entries(barScore || {}).map(([key, value]) => ({
    key: key,
    score: value['score'],
    result: skinTypeTexts[value['result']],
  }));
  return barData;
};

export const getNthRadarDataObj = (
  skinReport: SkinReportItem,
  skinReports: SkinReportItem[],
  reportIndex: ReportIndex,
  radarScore: Map<string, ResultScore>,
  createdAt: number,
): RadarData | undefined => {
  if (
    !skinReport ||
    !skinReports ||
    !reportIndex ||
    !radarScore ||
    !createdAt
  ) {
    return undefined;
  }
  const radarDataArray = [];
  const currentSkinScore = getNthRadarData(skinReport);
  const previousSkinScore = getPreviousRadarData(
    skinReports,
    reportIndex,
    radarScore,
    createdAt,
  );

  if (!currentSkinScore || !previousSkinScore) return undefined;
  const keysArray = [
    ...Object.keys(previousSkinScore['수분보유력']),
    ...Object.keys(currentSkinScore['수분보유력']),
  ];
  Object.values(skinScoreEngToKor).map((name) => {
    radarDataArray.push({
      ...currentSkinScore[name],
      ...previousSkinScore[name],
      name: name,
    });
  });
  const color = ['#C4C4C4', '#656BFF'];

  const radarData: RadarData = {
    data: radarDataArray,
    keys: keysArray,
    indexBy: 'name',
    color: color,
  };
  return radarData;
};

export const get0thRadarDataObj = (
  radarScore: Map<string, ResultScore>,
  createdAt: number,
): RadarData | undefined => {
  if (!radarScore || !createdAt) return undefined;
  const radarDataArray = [];

  const currentSkinScore = get0thRadarData(radarScore, createdAt);
  const keysArray = [...Object.keys(currentSkinScore['수분보유력'])];
  Object.values(skinScoreEngToKor).map((name) => {
    radarDataArray.push({ ...currentSkinScore[name], name: name });
  });
  const color = ['#656BFF'];
  const radarData = {
    data: radarDataArray,
    keys: keysArray,
    indexBy: 'name',
    color: color,
  };
  return radarData;
};

const getNthRadarData = (skinReport: SkinReportItem) => {
  const score = skinReport?.diagnosis?.axisScore;
  const deadline = skinReport?.deadline;
  const dateText = deadline ? formatDate(new Date(deadline)) : '';
  const data = {};
  Object.entries(score || {}).map(
    ([key, value]) => (data[skinScoreEngToKor[key]] = { [dateText]: value }),
  );
  return data;
};

const get0thRadarData = (
  radarScore: Map<string, ResultScore>,
  createdAt: number,
) => {
  const dateText = createdAt ? formatDate(new Date(createdAt)) : '';
  const data = {};
  Object.entries(radarScore).map(
    ([key, value]) => (data[key] = { [dateText]: value }),
  );
  return data;
};

export const SkinChartBox = ({
  radarData,
  barData,
  userName,
}: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('xs'));

  if (!radarData || !barData || !userName) return <></>;
  return (
    <Box className={`${classes.box} ${classes.chartBox}`}>
      <Typography className={classes.title}>
        {userName}님의 피부 차트
      </Typography>
      <BodyDivider />
      <Box className={classes.radarChart}>
        <RadarChart dataObj={radarData} isOnMobile={smDown} />
      </Box>
      <Box className={classes.barChart}>
        <BarChart dataArr={barData} />
      </Box>
    </Box>
  );
};
