import {
  Box,
  Container,
  Divider,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import orderCompleteImage from '../../assets/image/order_complete.gif';
import orderCompleteGiftImage from '../../assets/image/order_complete_gift.gif';
import infoIcon from '../../assets/icon/icon_info.png';
import { Routes } from '../../config/routes';
import { Link } from 'react-router-dom';
import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';
import { paymentPriceState, subscriptionState } from '../states';
import { useRecoilValue } from 'recoil';
import { StoreBanner } from '../components/StoreBanner';
import { format } from 'date-fns';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '10rem',
    marginBottom: '0.3rem',
    backgroundColor: '#fff',
    textAlign: 'center',
    padding: '0 1.6rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '10rem',
      boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.1)',
      borderRadius: '5rem',
      minWidth: '44rem',
      maxWidth: '53.4rem',
      margin: '5rem 0 8rem 0',
      backgroundColor: '#fff',
      padding: '5.8rem 6.8rem 4.2rem',
    },
  },
  image: {
    width: '20rem',
    height: '20rem',
    marginBottom: '3.9rem',
    borderRadius: '3rem',
    [theme.breakpoints.up('sm')]: {
      width: '23rem',
      height: '23rem',
      marginBottom: '3.3rem',
    },
  },
  infoIcon: {
    width: '4rem',
    height: '4rem',
    marginBottom: '1.6rem',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 400,
    marginBottom: '2.4rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.2rem',
      fontWeight: 300,
    },
  },
  subtitle: {
    fontSize: '1.4rem',
    fontWeight: 400,
    // marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      fontWeight: 300,
    },
  },
  body1: {
    fontSize: '1.2rem',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '1.2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
    marginTop: '1.2rem',
    marginBottom: '2.2rem',
    color: '#c4c4c4',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    color: '#6A6A6A',
    alignItems: 'center',
    textAlign: 'center',

    boxSizing: 'border-box',
    border: '1px solid #F0F0F0',
    boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.1)',

    minWidth: '32.7rem',
    alignSelf: 'stretch',
    margin: '3.7rem 0',

    padding: '2.8rem 1.7rem 2.8rem',
    [theme.breakpoints.up('sm')]: {},
  },
  info: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: '2.4rem',
  },
  button: {
    width: '30rem',
    height: '4rem',
    border: '2.23148px solid transparent',
    boxSizing: 'border-box',
    borderRadius: '5.5rem',
    background:
      'linear-gradient(#fff,#fff) padding-box, linear-gradient(90deg, #8EE4FF 7.93%, #8BB3FF 94.05%)',
    margin: '3.6rem 0',
    fontSize: '1.4rem',
    fontWeight: 500,

    [theme.breakpoints.up('sm')]: {},
  },
}));

function OrderComplete() {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const paymentPrice = useRecoilValue(paymentPriceState);
  const orderImage =
    paymentPrice?.total > 0 ? orderCompleteImage : orderCompleteGiftImage;
  const subscription = useRecoilValue(subscriptionState);
  return (
    <Box className={classes.root}>
      <ScrollToTopOnMount />
      <Container className={classes.container}>
        <img className={classes.image} src={orderImage} />
        <Typography className={classes.title}>
          <span style={{ fontWeight: 'bold' }}>주문이</span> 완료되었습니다
        </Typography>
        <Typography className={classes.subtitle}>
          예상 발송일 |{' '}
          {subscription.periodStart &&
            format(subscription.periodStart, 'yyyy.MM.dd')}
        </Typography>
        <Typography className={classes.body1}>
          상품 발송이 완료되면 알림톡을 통해 알려드리겠습니다.
        </Typography>
        <Typography
          className={classes.body1}
          component={Link}
          to={Routes.mypageMembership}
          style={{
            marginTop: '1rem',
            marginBottom: '2.1rem',
            color: 'inherit',
            textDecoration: 'underline',
          }}
        >
          주문 상세 보기
        </Typography>
        <Divider flexItem style={{ borderBottom: '1px dashed #c4c4c4' }} />
        <Typography className={classes.body2}>
          서비스/배송 및 기타 문의가 있을 경우
          <br />
          고객센터로 문의 주시면 신속히 해결해드리겠습니다.
        </Typography>
        <Box className={classes.box}>
          <img className={classes.infoIcon} src={infoIcon} />
          <Typography className={classes.info}>
            <p>제품을 받으신 후 스킨로그로 피부를 기록해주세요.</p>
            <p>AI와 전문가들이</p>
            <p>새로운 스킨케어 솔루션을 드립니다.</p>
          </Typography>
          <Box mb="3.5rem" />
          <StoreBanner
            width={smUp ? '14.5rem' : '11.8rem'}
            height={smUp ? '4.3rem' : '3.5rem'}
            spacing={smUp ? '0.7rem' : '0.9rem'}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default OrderComplete;
