import { RemoteApiClient } from '../helpers/RemoteApiClient';
import { settings } from '../../config';
import { MembershipProduct } from '../../domain/entities/MembershipProduct';
import { RemoteMembershipProductMapper } from '../mappers/RemoteMembershipProductMapper';

export class RemoteMembershipProduct {
  constructor() {
    this._api = new RemoteApiClient({
      baseUrl: settings.apiEndpoint,
    });
  }

  private _api: RemoteApiClient;

  public async getMembershipProducts(): Promise<MembershipProduct[]> {
    const data = await this._api.request({
      method: 'get',
      path: `products`,
    });

    return data?.products?.map(
      RemoteMembershipProductMapper.toMembershipProduct,
    );
  }
}
