import React, { Dispatch } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import { Product } from '../../../domain/entities/Product';

type Props = {
  setProductDetailInfo: Dispatch<unknown>;
  products?: Product[];
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0px 16px',

    [theme.breakpoints.up('sm')]: {
      margin: '0px',
    },
  },

  itemContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'backgroundColor': 'white',
    'border': '1px #F0F0F0 solid',
    'boxShadow': '0 5px 23px #0000000C',
    'marginBottom': '6px',
    'padding': '15px 13px',
    'cursor': 'pointer',

    [theme.breakpoints.up('sm')]: {
      padding: '22px 27px',
    },

    '& > img': {
      width: '8.1rem',
      height: '14.5rem',
      objectFit: 'contain',

      [theme.breakpoints.up('sm')]: {
        width: '12rem',
        height: '21.5rem',
      },
    },
  },

  allTextContainer: {
    'flex': 1,
    'display': 'flex',
    'alignItems': 'start',
    'marginLeft': '16px',

    [theme.breakpoints.up('sm')]: {
      marginLeft: '51px',
    },

    '& > p': {
      fontSize: '1.2rem',
      fontWeight: 'lighter',
      marginRight: '18px',

      [theme.breakpoints.up('sm')]: {
        fontSize: '1.8rem',
      },
    },
  },

  moreBtnDiv: {
    color: 'black',
    height: '100px',
    margin: '0 0 auto 0',
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '23px',
    textAlign: 'right',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },

  descriptionContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'start',
    'flex': 1,
    'fontSize': '1.2rem',
    'lineHeight': '150%',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
    },
    '& strong': {
      fontWeight: 'bold',
    },
    '& > p:first-child': {
      color: 'black',
      marginBottom: '13px',
    },

    '& > p:nth-child(2)': {
      color: '#797979',
      fontWeight: 500,
    },

    '& > p:nth-child(3)': {
      color: 'black',
    },
  },
}));

export const ProductsSection = ({
  setProductDetailInfo,
  products,
}: Props): JSX.Element => {
  const classes = useStyles();

  function getTypeText(type: string): string {
    switch (type) {
      case 'booster':
        return '부스터';
      case 'cream':
        return '크림';
      case 'ampoule':
        return '앰플';
      default:
        return '';
    }
  }

  function getAmountText(type: string): string {
    switch (type) {
      case 'booster':
        return '1.5ml';
      case 'cream':
      case 'ampoule':
        return '15ml';
      default:
        return '';
    }
  }

  function returnProductDetailInfo(value) {
    return {
      subProductIngredint: value.subProductIngredint,
      subProductFeeling: value.subProductFeeling,
      subProductFormulation: value.subProductFormulation,
      formulationImage: value.formulationImage,
    };
  }

  return (
    <section className={classes.root}>
      {products?.map((value) => {
        const typeText = getTypeText(value.type);
        return (
          <article
            key={value.id}
            className={classes.itemContainer}
            onClick={() => setProductDetailInfo(returnProductDetailInfo(value))}
          >
            <img src={value.thumbnail?.mobile} alt={value.name} />
            <div className={classes.allTextContainer}>
              <div className={classes.descriptionContainer}>
                <p>
                  <strong>{typeText}</strong> {getAmountText(value.type)}
                </p>
                <p>
                  {value.name}
                  <br />
                  {value.shortDescription}
                </p>
              </div>
            </div>
            <div className={classes.moreBtnDiv}>
              <p>{'더보기 >'}</p>
            </div>
          </article>
        );
      })}
    </section>
  );
};
