import React, { Dispatch } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MembershipProduct } from '../../../domain/entities/MembershipProduct';
import { Collapse } from 'react-collapse';
import { Theme } from '@material-ui/core';

type Props = {
  product: MembershipProduct;
  isBest: boolean;
  selectedProduct: MembershipProduct | undefined;
  setSelectedProduct: Dispatch<MembershipProduct>;
};

type Description = {
  first: string;
  second: string;
  third?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    border: '1px #F0F0F0 solid',
    display: 'flex',
    alignItems: 'start',
    position: 'relative',
    cursor: 'pointer',
    padding: 'unset',
  },
  rootSoldOut: {
    fontFamily: "'Pretendard SemiBold',sans-serif",
    position: 'absolute',
    zIndex: 0,
    backgroundColor: 'rgba(179, 179, 179, 0.7)',
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    border: '0',
    color: 'white',
    textAlign: 'center',
    height: '100%',
  },
  box: {
    display: 'flex',
    padding: '15px',
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    },
  },
  radio: {
    'width': '1.3rem',
    'height': '1.3rem',
    'border': '1.6px #333333 solid',
    'borderRadius': '50%',
    'padding': '1px',
    'marginTop': '8px',

    [theme.breakpoints.up('sm')]: {
      marginTop: '11px',
      marginLeft: '12px',
    },

    '& > div': {
      width: '100%',
      height: '100%',
      backgroundColor: '#333333',
      borderRadius: '50%',
    },
  },

  descriptionContainer: {
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'start',
    'flex': 1,
    'paddingLeft': '10px',

    [theme.breakpoints.up('sm')]: {
      paddingLeft: '20px',
    },

    '& hr': {
      margin: '19px 0px 15px',
      border: 'none',
      width: '3.2rem',
      height: '3px',
      backgroundColor: 'black',

      [theme.breakpoints.up('sm')]: {
        margin: '35px 0px 25px',
        width: '4rem',
      },
    },
  },

  title: {
    fontSize: '1.5rem',
    marginTop: '5px',
    marginBottom: '7px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      marginTop: '8px',
      marginBottom: '13px',
    },
  },

  price: {
    'fontSize': '1.5rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
    },

    '& strong': {
      fontWeight: 'bold',
    },
  },
  discountRate: {
    color: '#FF0000',
    marginLeft: '11px',

    [theme.breakpoints.up('sm')]: {
      marginLeft: '14px',
    },
  },

  strikeThroughPrice: {
    'fontSize': '1.3rem',
    'color': '#9C9C9C',
    'textDecoration': 'line-through',
    'marginRight': '11px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      marginRight: '14px',
    },

    '& strong': {
      fontWeight: 'normal',
    },
  },

  shortDescription: {
    fontSize: '1.1rem',
    color: '#9C9C9C',
    padding: '3px',
    border: '1px #9C9C9C solid',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      padding: '4px 6px',
    },
  },

  descriptionList: {
    'marginBottom': '8px',

    '& > li': {
      'marginTop': '10px',
      'fontSize': '1.3rem',

      [theme.breakpoints.up('sm')]: {
        fontSize: '1.8rem',
        marginTop: '20px',
      },

      '& > span:nth-child(2)': {
        fontWeight: 'bold',
      },

      '& > span:nth-child(3)': {
        color: '#FF4444B2',
      },
    },
  },

  tagList: {
    'display': 'flex',
    'flexDirection': 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },

    '& > span': {
      'width': '3.2rem',
      'minHeight': '3.2rem',
      'padding': '5px 2px',
      'fontSize': '1rem',
      'fontWeight': 'bold',
      'lineHeight': '130%',
      'backgroundColor': '#9C9C9C',
      'color': 'white',
      'textAlign': 'center',
      'display': 'flex',
      'justifyContent': 'center',
      'alignItems': 'center',

      [theme.breakpoints.up('sm')]: {
        width: '5.1rem',
        minHeight: '5.1rem',
        padding: '8px 4px',
        fontSize: '1.4rem',
      },

      '&:not(:last-child)': {
        margin: '0 0 5px 0',

        [theme.breakpoints.up('sm')]: {
          margin: '0 7px 0 0',
        },
      },
    },
  },
}));

export const MembershipProductItem = ({
  product,
  isBest,
  selectedProduct,
  setSelectedProduct,
}: Props): JSX.Element => {
  const classes = useStyles();

  const isSelected = product.id === selectedProduct?.id;
  const descriptions: Description[] = product.description
    .trim()
    .split('\n')
    .map((value) => {
      const splitted = value.split('|');
      return {
        first: splitted[0],
        second: splitted[1],
        third: splitted.length > 2 ? splitted[2] : undefined,
      };
    });

  function calculatePercentage(
    remoteDiscountedPrice: number,
    remotePrice: number,
    precision = 2,
  ): string {
    return ((1 - remoteDiscountedPrice / remotePrice) * 100).toPrecision(
      precision,
    );
  }

  const isStart = product.name.includes('Start');
  const isPremium = product.name.includes('Premium');
  const price = product.price.toLocaleString();
  const discountRate = calculatePercentage(
    product.discountedPrice,
    product.price,
  );
  const discountedPrice = product.discountedPrice.toLocaleString();

  return (
    <Collapse isOpened={true}>
      <article
        className={classes.root}
        onClick={() => setSelectedProduct(product)}
      >
        {isPremium && (
          <div className={classes.rootSoldOut}>
            <div style={{ margin: 'auto' }}>
              <p
                style={{
                  fontSize: '40px',
                  fontWeight: 600,
                }}
              >
                SOLD OUT
              </p>
              {isSelected && (
                <>
                  <br />
                  <p
                    style={{
                      fontSize: '22px',
                    }}
                  >
                    일시 품절
                  </p>
                </>
              )}
            </div>
          </div>
        )}
        <div className={classes.box}>
          <div className={classes.radio}>
            <div style={{ display: isSelected ? 'block' : 'none' }} />
          </div>

          <div className={classes.descriptionContainer}>
            <p className={classes.title}>{product.name}</p>
            <p className={classes.price}>
              {!isStart && (
                <span className={classes.strikeThroughPrice}>
                  <strong>{price}</strong>원
                </span>
              )}
              <span>
                <strong>{discountedPrice}</strong>원
              </span>
              {!isStart && (
                <span className={classes.discountRate}>
                  <strong>{discountRate}%</strong>
                </span>
              )}
            </p>
            {isSelected && (
              <>
                <hr />
                <p className={classes.shortDescription}>
                  {product.shortDescription}
                </p>
                <ul className={classes.descriptionList}>
                  {descriptions.map((value) => (
                    <li key={value.first}>
                      <span>- {value.first} </span>
                      <span>{value.second}</span>
                      {value.third && <span>{value.third}</span>}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </article>
    </Collapse>
  );
};
