import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import infoIcon from '../../../assets/icon/icon_info.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { StoreBanner } from '../../components/StoreBanner';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '48px 16px',
    padding: '28px 0px 47px',
    border: '1px #F0F0F0 solid',
    boxShadow: '0 5px 23px #0000000C',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      margin: '0 0 48px 0',
      padding: '41px 0px 57px',
    },
  },

  infoIcon: {
    width: '4rem',
    height: '4rem',
    marginBottom: '16px',

    [theme.breakpoints.up('sm')]: {
      width: '5.8rem',
      height: '5.8rem',
      marginBottom: '23px',
    },
  },

  description: {
    fontSize: '1.4rem',
    margin: '0px 18px 35px',
    textAlign: 'center',
    lineHeight: '150%',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      margin: '0px 50px 51px',
    },
  },
}));

export const InstallSkinLogAppSection = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <section className={classes.root}>
      <img className={classes.infoIcon} src={infoIcon} alt={''} />
      <p className={classes.description}>
        제품을 받으신 후
        <br />
        스킨로그 어플로 피부를 기록해주세요.
        <br />
        쓰면 쓸수록 더 최적화된 스킨케어가 발송됩니다.
      </p>
      <StoreBanner
        width={smUp ? '14.5rem' : '11.8rem'}
        height={smUp ? '4.3rem' : '3.5rem'}
        spacing={smUp ? '0.7rem' : '0.9rem'}
      />
    </section>
  );
};
