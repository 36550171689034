import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import img from '../../assets/image/ios_copy_and_paste.jpg';
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 'auto',
    width: '310px',
    fontFamily: "'Pretendard',sans-serif",
    display: 'grid',
    padding: '59px 25px',
    flexDirection: 'column',
    gap: '27px',
  },
  buttonWrapper: {
    textAlign: 'center',
  },
  button: {
    color: '#000000',
    backgroundColor: 'white',
    padding: '7px 55px',
    border: '1px solid #000000',
    borderRadius: '43.9815px',

    fontSize: '18px',
  },
}));

export const InAppBrowserExceptionPage = (): JSX.Element => {
  const classes = useStyles();

  const copyByClipBoardAPI = (url: string) => {
    try {
      const t = document.createElement('textarea');
      document.body.appendChild(t);
      t.value = url;
      t.select();
      document.execCommand('copy');
      document.body.removeChild(t);
      alert(
        'URL주소가 복사되었습니다.\n\nSafari가 열리면 주소창을 길게 터치한 뒤, "붙여놓기 및 이동"를 누르면 정상적으로 이용하실 수 있습니다.',
      );
    } catch {
      alert(`복사 실패!`);
    }
  };

  const inappbrowserout = function () {
    copyByClipBoardAPI('https://manifold.fit/');
    location.href = 'x-web-search://?';
  };

  return (
    <div className={classes.root}>
      <p
        style={{
          fontSize: '20px',
          fontWeight: 700,
          lineHeight: '26px',
          margin: 'unset',
        }}
      >
        원활한 서비스 이용을 위해
        <br />
        Safari로 접속해 주세요 :)
      </p>
      <div
        style={{
          fontWeight: 500,
          lineHeight: '22.5px',
          fontSize: '15px',
          wordBreak: 'keep-all',
          color: '#999',
        }}
      >
        접속하신 브라우저는 서비스를 이용하시는데
        <br />
        불편을 겪으실 수 있으므로 Safari로
        <br />
        접속하시는 것을 권장해드립니다.
      </div>
      <div
        style={{
          fontWeight: 500,
          lineHeight: '18px',
          fontSize: '12px',
          color: '#1C77FF',
        }}
      >
        <span>1.아래 버튼을 클릭하여 Safari를 실행해주세요.</span>
        <br />
        <span>
          2.Safari가 열리면 주소창을 길게 터치한 뒤 &apos;붙여넣기 및
          이동&apos;을
        </span>
        <br />
        <span>누르면 정상적으로 이용하실 수 있습니다.</span>
      </div>
      <img src={img} />
      <div className={classes.buttonWrapper}>
        <button onClick={inappbrowserout} className={classes.button}>
          Safari에서 열기
        </button>
      </div>
    </div>
  );
};
