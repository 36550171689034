import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

import { Main } from './StyledComponents';
import { Routes } from '../../config';
import LoginCard from './LoginCard';
import { Container } from '@material-ui/core';
import { useStyles } from './style';

function LoginPage(props): JSX.Element {
  const classes = useStyles();
  const location = useLocation();

  const [successUrl, setSuccessUrl] = useState('');
  const [failureUrl, setFailureUrl] = useState('');

  let { redirectTo } =
    qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) || props.location?.state?.referrer;

  if (props.location?.state?.referrer?.pathname) {
    redirectTo = props.location?.state?.referrer?.pathname;
  }

  const isSkinQuiz = location.pathname.indexOf(Routes.skinQuiz) !== -1;
  useEffect(() => {
    if (isSkinQuiz) {
      setSuccessUrl(Routes.skinQuizSelectFirstDeliveryDate);
      setFailureUrl(Routes.skinQuizSignUp);
    } else {
      setSuccessUrl(Routes.root);
      setFailureUrl(Routes.signUp);
    }

    if (redirectTo) {
      setSuccessUrl(redirectTo);
    }
  }, [isSkinQuiz, redirectTo]);

  return (
    <Main style={{ flex: 1 }} useWhiteTextInToolbar={!isSkinQuiz}>
      <Container maxWidth="lg" className={classes.root}>
        <LoginCard
          successUrl={successUrl}
          failureUrl={failureUrl}
          redirectToAfterSignUp={redirectTo}
        />
      </Container>
    </Main>
  );
}

export default LoginPage;
