import React, { useState } from 'react';
import { useStyles } from './StyledComponents';
import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { BoxDivider } from '../styles';
import { UserSatisfactionSurveyAnswer } from '../../../domain/entities/UserSatisfactionSurvey';
type Props = {
  questionName: string;
  answerSheet: UserSatisfactionSurveyAnswer;
  setAnswerSheet: (v: UserSatisfactionSurveyAnswer) => void;
};
const ScoreButtonContainer = styled('div')`
  background-color: #fbfbfb;
  border-radius: 300px;
  text-align: center;
`;
const ScoreButton = styled('button')<{ clicked: boolean }>`
  font-weight: 400;
  background-color: ${(p) => (p.clicked ? '#8BB3FF' : '#FBFBFB')};
  color: ${(p) => (p.clicked ? 'white' : '#323130')};
  border-radius: 45px;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
`;
export const SurveyScoreBox = ({
  questionName,
  answerSheet,
  setAnswerSheet,
}: Props): JSX.Element => {
  const classes = useStyles();
  const scores = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [selectedScore, updateSelectedScore] = useState<number | undefined>();
  const [buttonActiveState, setButtonActiveState] = useState(
    new Array(11).fill(false, 0, 11),
  );

  const onClickHandler = (e: { target: HTMLInputElement }) => {
    const score = Number(e.target.attributes.getNamedItem('data-key')?.value);
    const stateArray = new Array(11).fill(false);
    stateArray[score] = true;

    if (
      selectedScore !== undefined &&
      ((selectedScore <= 8 && score >= 9) || (selectedScore >= 9 && score <= 8))
    ) {
      setAnswerSheet({
        ...answerSheet,
        secondAnswer: undefined,
        thirdAnswer: undefined,
        [questionName + 'Answer']: score,
      });
    } else {
      setAnswerSheet({
        ...answerSheet,
        [questionName + 'Answer']: score,
      });
    }

    updateSelectedScore(score);
    setButtonActiveState(stateArray);
  };

  const borderColor = answerSheet[questionName + 'Answer']
    ? classes.active
    : classes.inactive;
  return (
    <Box
      className={classes.border + ' ' + borderColor}
      style={{ marginBottom: 'unset' }}
    >
      <Box className={classes.innerBox}>
        <Typography className={classes.question}>
          피부 리포트를 친구나 지인 등 주변 사람들에게 추천할 의향이 얼마나
          되나요?
        </Typography>
        <BoxDivider style={{ margin: '20px 0' }} />
        <ScoreButtonContainer
          className={classes.scoreButtonContainer}
          onClick={(e: any) => onClickHandler(e)}
        >
          {scores.map((score) => {
            return (
              <ScoreButton
                className={classes.scoreButton}
                clicked={buttonActiveState[score]}
                key={score}
                data-key={score}
              >
                {score}
              </ScoreButton>
            );
          })}
        </ScoreButtonContainer>
        <Box className={classes.subtextBox}>
          <Typography className={classes.subtext}>절대 추천 안함</Typography>
          <Typography className={classes.subtext}>매우 추천함</Typography>
        </Box>
      </Box>
    </Box>
  );
};
