import React from 'react';
import {
  Box,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

import loadingImage from '../../assets/image/manifold_loading.gif';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      textAlign: 'center',
    },
    image: {
      width: '17.2rem',
      height: '17.2rem',
      marginTop: '9.2rem',
      marginBottom: '3.8rem',
      [theme.breakpoints.up('sm')]: {
        width: '25.6rem',
        height: '25.6rem',
        marginTop: '22rem',
      },
    },
    text: {
      fontSize: '1.7rem',
      lineHeight: '2.462rem',
      fontWeight: 300,
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.33rem',
        lineHeight: '3.33rem',
      },
    },
  }),
);

export default function PaymentLoading() {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <img className={classes.image} src={loadingImage} />
      <Typography className={classes.text}>
        주문이 진행중입니다
        <br />
        잠시만 기다려주세요
      </Typography>
    </Box>
  );
}
