import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, useMediaQuery } from '@material-ui/core';

import { Button, LoadingIcon } from '../StyledComponents';
import { useTheme } from '@material-ui/core/styles';

function BottomButtons({
  page,
  isDone = true,
  isStart = false,
  isLast = false,
  onSubmit = () => undefined,
}) {
  const history = useHistory();
  const [submitButtonText, setSubmitButtonText] = useState('');
  const [processing, setProcessing] = useState(false);
  const isMounted = useRef(false);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const onClick = () => {
    setProcessing(true);
    onSubmit()
      .then(() => {
        if (isMounted) {
          setProcessing(false);
        }
      })
      .catch((err) => {
        console.error(err);
        if (isMounted) {
          setProcessing(false);
        }
      });
  };

  const updateSubmitButton = () => {
    if (isStart) {
      setSubmitButtonText('시작');
    } else if (isLast) {
      setSubmitButtonText('결과 보기');
    } else {
      setSubmitButtonText('다음');
    }
  };

  useEffect(updateSubmitButton);

  return (
    <Box display="flex" style={{ marginBottom: smUp ? '230px' : 0 }}>
      <Button onClick={() => history.goBack()} isValid>
        뒤로
      </Button>
      <Button
        onClick={onClick}
        isValid={isDone}
        isStart={isStart}
        disabled={!isDone}
      >
        {processing ? <LoadingIcon /> : submitButtonText}
      </Button>
    </Box>
  );
}

export default BottomButtons;
