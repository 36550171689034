import React, { useEffect } from 'react';
import {
  Box,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button } from './StyledComponents';
import { useHistory } from 'react-router-dom';

import { useStyles as useSkinQuizStyles, getPngImageSrc } from './styles';
import { Routes } from '../../config';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import { quizBackgroundState } from '../states';
import { useSetRecoilState } from 'recoil';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    borderRadius: '10rem',
    textAlign: 'center',
  },

  title: {
    fontSize: '2rem',
    lineHeight: '3rem',
    fontWeight: 'bold',
    marginTop: '76px',
    marginBottom: '29px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      lineHeight: '45px',
    },
  },
  descriptionBlue: {
    marginTop: '50px',
    fontSize: '1.4rem',
    color: '#578FFC',
    fontWeight: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },

  description: {
    fontSize: '1.6rem',
    // color: '#C4C4C4',
    fontWeight: 400,
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },

  descriptionSm: {
    fontSize: '1.3rem',
    color: '#C4C4C4',
    fontWeight: 500,
    marginBottom: '40px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      marginBottom: '67px',
    },
  },

  button: {
    margin: '51px auto 29px',
    width: '235px',
    height: '4.2rem',
    [theme.breakpoints.up('sm')]: {
      margin: '74px auto 45px',
    },
  },
  mobileManifoldImage: {
    margin: 'auto auto 0',
    [theme.breakpoints.up('sm')]: {
      width: '617px',
    },
  },
  mobileText: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'contents',
    },
  },
}));

export default function SkinQuizStart() {
  const history = useHistory();
  const classes = {
    ...useSkinQuizStyles(),
    ...useStyles(),
  };
  const setQuizBackground = useSetRecoilState(quizBackgroundState);

  const goSkinQuiz = () => {
    history.push(Routes.skinQuizName);
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  useEffect(() => {
    setQuizBackground(QuizBackground.WHITE);
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={classes.root}>
      <Typography className={classes.title}>
        <p>전문가들이 함께 만든</p>
        <p>피부 타입 테스트</p>
      </Typography>
      <Typography className={classes.description}>
        <p>과학적으로 개발된 질문에 답하고</p>
        <p>
          32가지 중 나의 피부 타입을 확인하세요.
        </p>
      </Typography>
      <Typography className={classes.descriptionBlue}>
        평균 4분 정도 소요됩니다.
      </Typography>

      <Button
        className={`${classes.button} expertButton`}
        isValid
        onClick={goSkinQuiz}
      >
        시작하기
      </Button>

      <Typography className={classes.descriptionSm}>
        정확한 의학적 피부 진단은 의사와 상담하세요
      </Typography>
      <img
        className={classes.mobileManifoldImage}
        src={getPngImageSrc(
          isDesktop
            ? 'home/desktop_chart_manifold'
            : 'home/mobile_chart_manifold',
        )}
        alt={''}
      />
    </Box>
  );
}
