import React, { useEffect } from 'react';
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../../config';
import loadingImage from '../../assets/image/loading_cube_optimized.gif';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  authCredentialState,
  authUserState,
  kakaoFabState,
  skinQuizAnswerSheetState,
  skinQuizSimpleAnswerSheetState,
  skinQuizSolutionState,
} from '../states';
import { RemoteQuiz } from '../../data/repositories/RemoteQuiz';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    body: {
      textAlign: 'center',
    },
    button: {
      width: Number(theme.typography.button.width) * 1.5 + 'rem',
    },
  }),
);

export default function ProcessingPage() {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const answerSheet = useRecoilValue(skinQuizAnswerSheetState);
  const simpleAnswerSheet = useRecoilValue(skinQuizSimpleAnswerSheetState);
  const setSkinQuizSolution = useSetRecoilState(skinQuizSolutionState);
  const setKakaoFab = useSetRecoilState(kakaoFabState);
  const authCredential = useRecoilValue(authCredentialState);
  const [authUser, setAuthUser] = useRecoilState(authUserState);

  const isSimple = location.search.includes('simple');

  useEffect(() => {
    const repo = new RemoteQuiz(authCredential);
    repo
      .submitSkinQuiz(
        isSimple ? simpleAnswerSheet : answerSheet,
        isSimple ? 'skin_simple' : 'skin_email',
      )
      .then(async (skinQuizResult) => {
        if (skinQuizResult?.quizId) {
          const result = await repo.getSkinQuizSolution(skinQuizResult?.quizId);
          if (!result) {
            history.replace(Routes.notFound);
          } else {
            setAuthUser({
              ...authUser,
              quizId: skinQuizResult?.quizId,
            });
            setSkinQuizSolution(result);

            setTimeout(
              () =>
                history.replace({
                  pathname: Routes.skinQuizResultUser,
                  // pathname: Routes.skinQuizResult,
                  search: location.search,
                }),
              3000,
            );
          }
        } else {
          history.replace(Routes.notFound);
        }
      })
      .catch((err) => {
        console.error(err);
        history.replace(Routes.notFound);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  setKakaoFab(false);

  const userName = isSimple
    ? simpleAnswerSheet.question1
    : answerSheet?.question1;
  return (
    <Box
      display="flex"
      flexGrow="1"
      flexDirection="column"
      justifyContent="stretch"
      alignItems="top"
      marginTop="3.5rem"
    >
      <Box flexGrow="1">
        <Typography variant="h2">Optimizing</Typography>
      </Box>
      <Box flexGrow="1" textAlign="center">
        <img
          src={loadingImage}
          style={{ mixBlendMode: 'multiply' }}
          width="93%"
          alt={''}
        />
      </Box>
      <Box flexGrow="1">
        <Typography variant="h4" className={classes.body}>
          지금 매니폴드의 인공지능이
        </Typography>
        <Typography variant="h4" className={classes.body}>
          {`${userName} 님의 피부를 열심히 분석중입니다.`}
        </Typography>
      </Box>
    </Box>
  );
}
