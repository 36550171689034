import React from 'react';
import styled from 'styled-components';
import { device } from '../utils/device';

const ProgressBarBlock = styled.div`
  width: 38rem;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  text-align: right;
  @media ${device.mobile} {
    width: 23.7rem;
    font-size: 1.2rem;
    margin-top: 41px;
  }
`;

const ProgressBarBox = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 0.6rem;
  background-color: #c4c4c4;
  border-radius: 3.8rem;
`;

const Filler = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  background: linear-gradient(
    90.21deg,
    #b1beff 0.18%,
    #acf0ff 99.81%,
    rgba(91, 91, 91, 0) 99.82%
  );
  border-radius: 3.8rem;
  transition: width 450ms;
`;

interface SimpleProgressBarProps {
  page: number;
  total: number;
}

function SimpleProgressBar({
  page,
  total,
}: SimpleProgressBarProps): JSX.Element {
  // const skinQuizAnswerSheet = useRecoilValue(skinQuizAnswerSheetState);
  const progress = Math.ceil((page / total) * 100).toString();
  return page === 1 ? (
    <></>
  ) : (
    <ProgressBarBlock>
      {`${page - 1}/${total - 1} 완료`}
      <ProgressBarBox>
        <Filler width={progress + '%'} />
      </ProgressBarBox>
    </ProgressBarBlock>
  );
}

export default SimpleProgressBar;
