import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { Box, Container, useMediaQuery, useTheme } from '@material-ui/core';
import { QuizItem } from '../../../data/models/QuizModel';
import { useEffect } from 'react';
import { ToggleArrow } from '../../components/ToggleArrow';

const images = require.context('../../../assets/image/skinquiz', true, /.png$/);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '3.7rem 1.6rem 0',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: 0,
      },
    },
    toggle: {
      fontSize: '1.4rem',
      color: '#578FFC',
      textAlign: 'left',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '3rem',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '1.2rem',
      paddingTop: '1rem',
      width: '29.6rem',
      lineHeight: '1.7rem',
      [theme.breakpoints.up('sm')]: {
        width: 'auto',
        alignItems: 'start',
        margin: '0 3.7rem',
      },
    },
    image: {
      width: '100%',
      marginTop: '2rem',
      [theme.breakpoints.up('sm')]: {
        width: '45rem',
        height: '30rem',
        objectFit: 'cover',
      },
    },
  }),
);

export function getTipImageSrc(image: string) {
  try {
    return images(`./${image}`).default;
  } catch (err) {
    return '';
  }
}

interface QuestionTipProps {
  question: QuizItem;
}

export default function QuestionTip({ question }: QuestionTipProps) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const handleClick = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(false);
  }, [question]);

  const { tip } = question;
  return (
    <Box className={classes.root}>
      <Box className={classes.toggle} onClick={handleClick}>
        <ToggleArrow isOpened={checked} />
        {` TIP.${tip?.title}`}
      </Box>
      <Fade in={checked} mountOnEnter unmountOnExit>
        <Container className={classes.container}>
          {smUp ? tip?.body : tip?.bodyMobile}
          <img className={classes.image} src={getTipImageSrc(tip?.image)} />
        </Container>
      </Fade>
    </Box>
  );
}
