import React from 'react';
import {
  Backdrop,
  Box,
  Dialog,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { BodyDivider } from '../skinquiz/styles';
import { Button } from '../skinquiz/StyledComponents';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '30.2rem',
    padding: '4.1rem 3.6rem',
    [theme.breakpoints.up('sm')]: {
      minWidth: '53.4rem',
      minHeight: '38.9rem',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '4.1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '4.4rem',
    },
  },
  body: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
    color: '#6A6A6A',
    lineHeight: '150%',
    marginTop: '4.1rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      lineHeight: '2.7rem',
      marginBottom: '7.4rem',
    },
  },
  button: {
    fontSize: '1.7rem',
    fontWeight: 500,
    margin: '4.1rem 0 0',
    minWidth: '25rem',
    minHeight: '4rem',
  },
}));

interface DialogProps {
  open: boolean;
  message: JSX.Element;
  onClose: (e?: any) => void;
}

export default function AlertDialog(props: DialogProps): JSX.Element {
  const classes = useStyles();
  const { open, message, onClose } = props;
  return (
    <Dialog open={!!open} onClose={props.onClose}>
      <Box className={classes.root}>
        <Typography className={classes.title}>알림</Typography>
        <BodyDivider />
        <Typography className={classes.body}>{message}</Typography>
        <Button className={classes.button} onClick={onClose} isValid>
          확인
        </Button>
      </Box>
      <Backdrop open={!!open} onClick={onClose}></Backdrop>
    </Dialog>
  );
}
