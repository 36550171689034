export interface EventParams {
  [key: string]: any;
}

export interface UserProperties {
  loading_mode?: string;
}

export abstract class AnalyticsService {
  abstract logEvent(name: string, params?: EventParams): Promise<void>;
  abstract setUserProperties(props: UserProperties): Promise<void>;
  abstract setCurrentScreen(screenName: string): Promise<void>;
  abstract setUserId(uid: string): Promise<void>;
}
