import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import LoadingScreen from 'react-loading-screen';

import { Routes } from '../../config';
import { authCredentialState, authLoadingState } from '../states';
import loadingImage from '../../assets/image/manifold_loading.gif';

function PrivateRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const authLoading = useRecoilValue(authLoadingState);
  const authCredential = useRecoilValue(authCredentialState);
  const pathname = location.pathname.startsWith(Routes.skinQuiz)
    ? Routes.skinQuizLogin
    : Routes.login;

  if (authLoading) {
    return (
      <LoadingScreen
        loading={authLoading}
        spinnerColor="#c4c4c4"
        logoSrc={loadingImage}
      ></LoadingScreen>
    );
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        authCredential ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname, state: { referrer: location } }} />
        )
      }
    />
  );
}

export default PrivateRoute;
