import React from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container, createStyles, makeStyles, Theme } from '@material-ui/core';

import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';
import { useRecoilValue } from 'recoil';
import { quizBackgroundState } from '../states';
import QuestionPage from './QuestionPage';
import { Routes } from '../../config';
import ResultPage from './ResultPage';
import LoginPage from '../login/LoginPage';
import SignupPage from '../login/SignupPage';
import ProcessingPage from './ProcessingPage';

import gradientPNG from '../../assets/image/bg_gradient1.png';
import { device } from '../utils/device';
import SentEmailPage from './SentEmailPage';
import ShareResultPage from './ShareResultPage';
import SimpleQuestionPage from './SimpleQuestionPage';
import PrivateRoute from '../utils/PrivateRoute';
import PaymentPage from './payment/PaymentPage';
import { SolutionPage2 } from './solution/SolutionPage2';
import SelectFirstDeliveryDatePage from './selectfirstdeliverydate/SelectFirstDeliveryDatePage';
import { AddressPage2 } from './address/AddressPage2';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import SkinQuizStart from './SkinQuizStart';
import NthResultPage from './NthResultPage';
import UserSatisfactionSurvey from './userSurvey/UserSatisfactionSurvey';
import { SurveyFinish } from './userSurvey/SurveyFinish';

const Main = styled.div<{ quizBackground: QuizBackground | undefined }>`
  display: flex;
  min-height: 100vh;
  @media ${device.desktop} {
    min-width: 36rem;
  }
  height: 100%;
  background-color: ${(props) =>
    props.quizBackground === QuizBackground.GREY ? '#FAFAFA' : 'white'};
  justify-content: center;
  position: relative;

  /* align-items: center; */
  // @media ${device.desktop} {
  background-image: ${(props) =>
    props.quizBackground === QuizBackground.GRADIENT
      ? `linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%), url(${gradientPNG})`
      : 'none'};
  background-size: cover;
  background-position: center;
  // }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      marginTop: '10rem',
      padding: 0,
      position: 'relative',
      justifyContent: 'space-between',
      alignItems: 'center',

      [theme.breakpoints.up('sm')]: {
        marginTop: '11rem',
      },
    },
  }),
);

function SkinQuiz() {
  const classes = useStyles();
  const quizBackground = useRecoilValue(quizBackgroundState);

  return (
    <Main quizBackground={quizBackground}>
      <Container maxWidth="lg" className={classes.root}>
        <ScrollToTopOnMount />
        <Switch>
          <Route exact path={Routes.skinQuiz} component={SkinQuizStart} />
          <Route path={Routes.skinQuizProcessing} component={ProcessingPage} />
          <Route
            path={`${Routes.skinQuizResultShare}/:quizId`}
            component={ShareResultPage}
          />
          <Route
            path={Routes.skinQuizResultUser}
            component={ResultPage}
            exact
          />
          <Route
            path={`${Routes.skinQuizResult}/:index`}
            component={NthResultPage}
          />
          <Route
            path={`${Routes.skinQuizSolution}/:index`}
            component={SolutionPage2}
          />
          <Route
            path={Routes.skinQuizLatestOrderSolution}
            component={SolutionPage2}
          />
          <Route
            path={Routes.skinQuizUserSatisfactionSurvey}
            component={UserSatisfactionSurvey}
            exact
          />
          <Route
            path={Routes.skinQuizUserSatisfactionSurveyFinish}
            component={SurveyFinish}
          />
          <Route path={Routes.skinQuizLogin} component={LoginPage} />
          <Route path={Routes.skinQuizSignUp} component={SignupPage} />
          <PrivateRoute
            path={Routes.skinQuizAddress}
            component={AddressPage2}
          />
          <PrivateRoute
            path={Routes.skinQuizSelectFirstDeliveryDate}
            component={SelectFirstDeliveryDatePage}
          />
          <PrivateRoute path={Routes.skinQuizPayment} component={PaymentPage} />
          <Route path={Routes.skinQuizSentEmail} component={SentEmailPage} />
          <Route
            exact
            path={`${Routes.skinQuizSimple}`}
            component={SimpleQuestionPage}
          />
          <Route
            path={`${Routes.skinQuizSimple}/:page`}
            component={SimpleQuestionPage}
          />
          <Route path={`${Routes.skinQuiz}/:page`} component={QuestionPage} />
          <Route path="*">
            <Redirect to="/notfound" />
          </Route>
        </Switch>
      </Container>
    </Main>
  );
}

export default SkinQuiz;
