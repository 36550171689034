import React from 'react';
import { Box, Typography, TextField } from '@material-ui/core';
import { useStyles } from './StyledComponents';
import { BoxDivider } from '../styles';
import styled from 'styled-components';
import { UserSatisfactionSurveyAnswer } from '../../../domain/entities/UserSatisfactionSurvey';

import userSurveyQuestion from '../../../data/userSurveyQuestion';
type Props = {
  textRef: React.Ref<HTMLInputElement>;
  questionName: string;
  answerSheet: UserSatisfactionSurveyAnswer;
  setAnswerSheet: (v: UserSatisfactionSurveyAnswer) => void;
};
const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E0E0',
    },
    '&:hover fieldset': {
      borderColor: '#E0E0E0',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#E0E0E0',
    },
  },
});
export const SurveyInputBox = ({
  textRef,
  questionName,
  answerSheet,
  setAnswerSheet,
}: Props): JSX.Element => {
  const classes = useStyles();

  const onChange = ({ target }) => {
    setAnswerSheet({
      ...answerSheet,
      [questionName + 'Answer']: target.value,
    });
  };
  const borderColor = answerSheet[questionName + 'Answer']
    ? classes.active
    : classes.inactive;
  return (
    <Box className={classes.border + ' ' + borderColor}>
      <Box className={classes.innerBox}>
        <Typography className={classes.question}>
          {userSurveyQuestion[questionName + 'Question']}
        </Typography>
        <BoxDivider style={{ margin: '20px 0' }} />
        <CssTextField
          multiline
          rows={2}
          InputProps={{ classes: { input: classes.input } }}
          fullWidth
          placeholder={userSurveyQuestion[questionName + 'PlaceHolder']}
          variant="outlined"
          onChange={onChange}
          inputRef={textRef}
        />
      </Box>
    </Box>
  );
};
