import React, { useEffect } from 'react';
import {
  Content,
  PageTitle,
  BlueSquareButton,
  BlueSquareButtonBlock,
} from '../StyledComponents';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../config';
import { QuestionProps } from './QuestionProps';

function SelectableQuestion({
  question,
  answerSheet,
  setAnswerSheet,
}: QuestionProps) {
  const history = useHistory();
  const name = answerSheet.question1;
  const selected = answerSheet[`question${question.id}`];

  useEffect(() => {
    if (!name) {
      history.push(Routes.skinQuizName);
    }
  }, [history, name, answerSheet]);

  const onClick = ({ target }) => {
    setAnswerSheet({
      ...answerSheet,
      [`question${question.id}`]: parseInt(target.id),
    });
  };

  return (
    <Content>
      <PageTitle>{`${question.title.replace(
        '$name',
        answerSheet?.question1.toString(),
      )}`}</PageTitle>
      <BlueSquareButtonBlock>
          {
            question.options.map(elem => {
                const id = elem.id
                return (
                <BlueSquareButton
                key={id}
                id={id.toString()}
                onClick={onClick}
                selected={selected === id}
            >
                {elem.text}
            </BlueSquareButton>)
            })
          }
      </BlueSquareButtonBlock>
    </Content>
  );
}

export default SelectableQuestion;