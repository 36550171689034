function handlePhoneNumber(string) {
  const number = string.replace(/[^0-9]/g, '');
  let phone = '';

  if (number.length < 4) {
    phone = number;
  } else if (number.length < 7) {
    phone = number.substring(0, 3) + '-' + number.substring(3);
  } else if (number.length < 11) {
    phone =
      number.substring(0, 3) +
      '-' +
      number.substring(3, 6) +
      '-' +
      number.substring(6);
  } else {
    phone =
      number.substring(0, 3) +
      '-' +
      number.substring(3, 7) +
      '-' +
      number.substring(7, 11);
  }
  return phone;
}

export default handlePhoneNumber;
