import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Mail, KakaoTalk } from '@styled-icons/remix-fill';
import { Plus, Minus } from '@styled-icons/feather';
import contents from '../../data/faqData';
import {
  Container,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'flexGrow': 1,
    'display': 'flex',
    'flexDirection': 'column',
    'alignItems': 'stretch',

    'backgroundColor': '#f2f2f2',

    '& strong': {
      fontWeight: 'bold',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    padding: '10rem 1.8rem 2.9rem',
  },
  title: {
    fontSize: '2.4rem',
    fontWeight: 600,
    marginBottom: '0.9rem',
  },
  subtitle: {
    fontSize: '1.7rem',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2.3rem 0 2.6rem 0',
    width: '15.3rem',
  },
  icon: {
    width: '4rem',
    height: '4rem',
  },
  menuBox: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  menuItem: {
    fontSize: '1.7rem ',
    color: '#C4C4C4',
    margin: '0 1.2rem',
    lineHeight: '3rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '2.8rem',
    paddingBottom: '10rem',
    backgroundColor: '#fff',
  },
  articleBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderTop: '1px solid #c4c4c4',
  },
  articleTitle: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: '1.3rem',
    lineHeight: '1.8rem',
    margin: '2.5rem 1.8rem',
  },
  articleIcon: {
    width: '3rem',
    minWidth: '3rem',
    marginRight: '1.8rem',
  },
  articleBody: {
    width: '100%',
    padding: '0 1.6rem',
    backgroundColor: '#f2f2f2',
    maxHeight: 0,
    overflow: 'hidden',
    fontSize: '1.3rem',
    lineHeight: '1.8rem',
    fontWeight: 400,
  },
}));

const Main = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: center;
  /* align-items: center; */
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  margin-top: 150px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PageHeader = styled.div`
  /* background-color: #c4c4c4; */
  padding: 60px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;
const PageTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 4.8rem;
  line-height: 150%;
`;
const HeaderMessage = styled.div`
  margin-top: 20px;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 150%;
`;
const HeaderIcons = styled.div`
  margin-top: 50px;
  display: flex;
  /* background-color: #531fcb; */
`;
const IconContainer = styled.div`
  /* background-color: yellow; */
  margin: 0 30px;
  width: 140px;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;
const MailIcon = styled(Mail)`
  width: 30px;
  margin-bottom: 6px;
`;
const KakaoIcon = styled(KakaoTalk)`
  width: 30px;
  margin-bottom: 6px;
`;

const FAQContent = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
`;
const NavBar = styled.div`
  width: 100%;
  padding: 0 200px;
  display: flex;
  justify-content: space-between;
`;
const NavMenuItem = styled.div<{ name: string; selected: boolean }>`
  /* width: 150px; */
  font-weight: 500;
  font-size: 2.5rem;
  text-align: center;
  color: #c4c4c4;
  cursor: pointer;

  ${(props) =>
    props.selected &&
    css`
      color: #000;
    `}
`;

const SubSections = styled.div`
  margin-top: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const ArticleContainer = styled.div<{ name: string }>`
  width: 100%;
  border-top: 1px solid #c4c4c4;
  display: flex;
  flex-direction: column;
`;
const ArticleTitle = styled.div<{ showBody: boolean }>`
  width: 100%;
  height: 60px;
  font-weight: 300;
  font-size: 2rem;
  display: flex;
  align-items: center;
  ${(props) =>
    props.showBody &&
    css`
      font-weight: 500;
    `}
`;
const TitleIcon = styled.div`
  margin-left: 30px;
  margin-right: 16px;
`;
const PlusIcon = styled(Plus)`
  width: 3rem;
`;
const MinusIcon = styled(Minus)`
  width: 3rem;
`;
const ArticleBody = styled.div<{ showBody: boolean }>`
  width: 100%;
  padding: 0 120px;
  background-color: #f2f2f2;
  max-height: 0;
  overflow: hidden;

  p {
    font-weight: 400;
    font-size: 2rem;
    line-height: 150%;
  }

  ${(props) =>
    props.showBody &&
    css`
      max-height: 100%;
      padding: 60px 75px;
    `}

  transition: max-height .4s ease-in-out 0s, padding .4s ease-in-out 0s;
`;

function Support() {
  const theme = useTheme();
  const classes = useStyles();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const sections = contents.map((each) => each.name);
  const [currentContent, setCurrentContent] = useState(sections[0]);
  const [currentArticles, setCurrentArticles] = useState(
    contents.filter((each) => each.name === sections[0])[0]?.articles,
  );

  const [currentArticleID, setCurrentArticleID] = useState(undefined);

  const extractArticles = (section) => {
    return contents.filter((each) => each.name === section)[0]?.articles;
  };

  const changeCurrentContent = (event) => {
    const section = event.target.innerText;
    setCurrentContent(section);
    setCurrentArticles(extractArticles(section));
  };

  const selectCurrentID = (id) => {
    currentArticleID === id
      ? setCurrentArticleID(undefined)
      : setCurrentArticleID(id);
  };

  const openKakaoChat = () => {
    window['Kakao'].Channel.chat({
      channelPublicId: '_cSxaxoxb',
    });
  };

  const sendEmail = () => {
    window.location.href = 'mailto:support_manifold@artlab.ai';
  };

  const menues = [
    '제품',
    '회원정보',
    '서비스 및 멤버십',
    '주문 및 결제',
    '반품 및 환불',
  ];

  function SupportMobile() {
    return (
      <Container className={classes.root}>
        <Box className={classes.header}>
          <Typography className={classes.title}>고객센터</Typography>
          <Typography className={classes.subtitle}>
            도움이 필요하신가요?
          </Typography>
          <Box className={classes.iconBox}>
            <MailIcon className={classes.icon} onClick={sendEmail} />
            <KakaoIcon className={classes.icon} onClick={openKakaoChat} />
          </Box>
          <Box className={classes.menuBox}>
            {menues.map((menu) => (
              <Box
                key={menu}
                className={classes.menuItem}
                style={currentContent === menu ? { color: '#000' } : {}}
                onClick={changeCurrentContent}
              >
                {menu}
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.content}>
          {currentArticles.map((article: any) => (
            <Box
              key={article.id}
              id={article.id}
              className={classes.articleBox}
              onClick={() => selectCurrentID(article.id)}
            >
              <Box
                className={classes.articleTitle}
                style={
                  article.id === currentArticleID ? { fontWeight: 500 } : {}
                }
              >
                {article.id === currentArticleID ? (
                  <MinusIcon className={classes.articleIcon} />
                ) : (
                  <PlusIcon className={classes.articleIcon} />
                )}
                {article.name}
              </Box>
              <Box
                className={classes.articleBody}
                style={
                  article.id === currentArticleID
                    ? { maxHeight: '100%', padding: '4.5rem 1.6rem' }
                    : {}
                }
                dangerouslySetInnerHTML={{ __html: article.body }}
              />
            </Box>
          ))}
        </Box>
      </Container>
    );
  }

  if (smUp) {
    return (
      <Main>
        <ContentContainer>
          <PageHeader>
            <PageTitle>고객센터</PageTitle>
            <HeaderMessage>도움이 필요하신가요?</HeaderMessage>
            <HeaderIcons>
              <IconContainer onClick={sendEmail}>
                <MailIcon />
                이메일 상담
                <br />
                support_manifold@artlab.ai
              </IconContainer>
              <IconContainer onClick={openKakaoChat}>
                <KakaoIcon />
                카카오톡 상담
                <br />
                @매니폴드
              </IconContainer>
            </HeaderIcons>
          </PageHeader>
          <FAQContent>
            <NavBar>
              {menues.map((menu) => (
                <NavMenuItem
                  key={menu}
                  name={menu}
                  selected={currentContent === menu}
                  onClick={changeCurrentContent}
                >
                  {menu}
                </NavMenuItem>
              ))}
            </NavBar>
            <SubSections>
              {currentArticles.map((article: any) => (
                <ArticleContainer
                  key={article.id}
                  id={article.id}
                  name={article.id}
                  onClick={() => selectCurrentID(article.id)}
                >
                  <ArticleTitle showBody={article.id === currentArticleID}>
                    <TitleIcon>
                      {article.id === currentArticleID ? (
                        <MinusIcon />
                      ) : (
                        <PlusIcon />
                      )}
                    </TitleIcon>
                    {article.name}
                  </ArticleTitle>
                  <ArticleBody
                    showBody={article.id === currentArticleID}
                    dangerouslySetInnerHTML={{ __html: article.body }}
                  />
                </ArticleContainer>
              ))}
            </SubSections>
          </FAQContent>
        </ContentContainer>
      </Main>
    );
  } else {
    return SupportMobile();
  }
}

export default Support;
