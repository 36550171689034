import React from 'react';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: '36rem',
    padding: '3.7rem 1.6rem 2rem 1.6rem',
    color: '#000',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#fff',
      maxWidth: '120rem',
      width: '120rem',
      padding: '0 30.7rem 20.9rem',
      marginBottom: '102px',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    color: '#6A6A6A',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.1)',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },
  shareBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },
  typeBox: {
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 10.3rem 4rem 10.3rem',
    },
  },
  simpleText: {
    'position': 'absolute',
    'color': '#000',
    'fontSize': '1.6rem',
    'lineHeight': '2.8rem',
    'left': 0,
    'right': 0,
    'top': '40%',
    '& span': {
      fontWeight: 'bold',
    },
  },
  simpleCover: {
    'position': 'absolute',
    'background': 'rgba(255, 255, 255, 0.95)',
    'filter': 'blur(4px)',
    '-webkit-filter': 'blur(1px)',
    '-moz-filter': 'blur(1px)',
    '-o-filter': 'blur(1px)',
    '-ms-filter': 'blur(1px)',
    'width': '100%',
    'height': '100%',
  },
  icon: {
    width: '4rem',
    height: '4rem',
  },
  commentBox: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {},
  },
  chartBox: {
    alignItems: 'stretch',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 10.3rem',
    },
  },
  ingredientBox: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: '5.9rem',
      paddingRight: '5.9rem',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  headerDate: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
    padding: '0.4rem 1rem',
    border: '1px solid #F0F0F0',
    borderRadius: '4px',
    boxSizing: 'border-box',
    color: '#6A6A6A',
  },
  headerTitle: {
    'fontSize': '2rem',
    'fontWeight': 'bold',
    'color': '#000',
    'marginTop': '1.2rem',
    'marginBottom': '1.9rem',
    '& p': {
      fontWeight: 'normal',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '1.3rem',
    },
  },
  headerBody: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '1.8rem',
    marginBottom: '1.2rem',
    color: '#6A6A6A',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      marginBottom: '3.85rem',
      lineHeight: '2.7rem',
    },
  },
  shareFont: {
    fontSize: '1.3rem',
    fontWeight: 400,
    lineHeight: '2rem',
    marginBottom: '1.2rem',
    color: '#6A6A6A',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      marginBottom: '6rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },
  shareTitle: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    marginTop: '4rem',
    marginBottom: '2rem',
    color: '#050505',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      color: '#050505',
      fontWeight: 'bold',
      fontSize: '2.6rem',
      marginTop: '6rem',
      marginBottom: '4rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },
  shareTextField: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    marginTop: '8rem',
    color: '#050505',
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      color: '#050505',
      fontWeight: 'bold',
      fontSize: '2.6rem',
      marginTop: '8rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '5px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginBottom: '9px',
    },
  },

  body: {
    marginBottom: '19px',
    color: '#000000',
    fontSize: '1.2rem',
    lineHeight: '1.738rem',
    fontWeight: 'normal',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
  },
  boxSummary: {
    color: '#000000',
    fontSize: '1.2rem',
    fontWeight: 'normal',
    lineHeight: '1.73rem',
    textAlign: 'center',
    marginBottom: '19px',
    /* black/manifold_black_01 */

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2rem',
    },
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  listBullet: {
    width: '0.6rem',
    height: '0.6rem',
    minWidth: '0.6rem',
    borderRadius: '50%',
    background: '#C4C4C4',
    marginRight: '0.8rem',
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '2.1rem',
    padding: '0 1.7rem',
    // height:'126px',
    // marginBottom: '1.1rem',/
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2.4rem',
    },
  },
  routineList: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    fontSize: '1.3rem',
    lineHeight: '1.9rem',
    margin: '1rem 0',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      lineHeight: '2.179rem',
    },
  },
  routineListBullet: {
    height: '2.4rem',
    width: '2.4rem',
    minWidth: '2.4rem',
    border: '0.1rem solid',
    borderRadius: '50%',
    margin: '0.7rem 2rem 0.7rem 0',
    // padding: '0.1rem 0 0 0',
    textAlign: 'center',
  },
  routineListItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.2rem',
    wordBreak: 'keep-all',
  },
  ctaButton: {
    height: '4.8rem',
    width: '100%',
    background: 'linear-gradient(90deg, #8EE4FF 7.93%, #8BB3FF 94.05%)',
    borderRadius: '2px',
    borderWidth: '0px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#fff',
    margin: '1.2rem 0',
    whiteSpace: 'nowrap',
  },
  symbolImage: {
    width: '16.4rem',
    height: '16.8rem',
    margin: '1.2rem 0',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '29.4rem',
    },
  },
  skinTypeImage: {
    width: '7.5rem',
    margin: '1 0.1rem',
    [theme.breakpoints.up('sm')]: {
      margin: '0 0.5rem',
    },
  },
  ingredientImage: {
    width: '6rem',
    height: '6rem',
    marginRight: '1.2rem',
    borderRadius: '50%',
    objectFit: 'cover',
    [theme.breakpoints.up('sm')]: {
      width: '8.8rem',
      height: '8.8rem',
      marginRight: '4.3rem',
    },
  },
  bottomImage: {
    height: '38.1rem',
    margin: '1.5rem 0rem',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      height: '52.8rem',
    },
  },
  barChart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },

  modalRoot: {
    transform: 'translateZ(0)',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 2 2.6rem 0',
    width: '20.3rem',
    [theme.breakpoints.up('sm')]: {
      width: '30rem',
    },
  },
  modalBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: '32.8rem',
    maxWidth: '60rem',
    [theme.breakpoints.up('sm')]: {},
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5rem 0',
    [theme.breakpoints.up('sm')]: {},
  },
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'stretch',
    textAlign: 'center',
    padding: '1rem 3rem',
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 15rem',
    },
  },
  termsBox: {
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'bottom',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },
  emailInput: {
    backgroundColor: '#F0F0F0',
    margin: '1rem 0',
    textAlign: 'center',
    fontSize: '1.2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.7rem',
    },
  },

  modalButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '4rem',
    fontSize: '1.7rem',
    fontWeight: 500,
    border: '0.1rem solid #000',
    borderRadius: '5.5rem',
    marginTop: '2rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  shareButton: {
    width: '20.4rem',
    height: '4rem',
    border: '0.22rem solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5.5rem',
    marginTop: '2rem',
    marginBottom: '5rem',
    fontSize: '1.5rem',
    fontWeight: 500,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '25.6rem',
      height: '4.5rem',
      fontSize: '2rem',
      fontWeight: 500,
      justifyContent: 'center',
    },
  },
  boxRoot: {
    width: '28.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '50.6rem',

      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  boxShareUrl: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  divider: {
    margin: '1rem 3.3rem',
    [theme.breakpoints.up('sm')]: {
      margin: '1rem 5.7rem',
    },
  },
  textFieldShareUrl: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      resize: 'both',
    },
  },
  desktophide: {
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  ingredientLabel: {
    verticalAlign: 'inherit',
    marginLeft: '5px',
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '15px',
      verticalAlign: 'middle',
    },
  },
  cautiontext: {
    textAlign: 'end',
    color: '#C4C4C4',
    fontWeight: 400,
    fontSize: '11px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
}));

export const DateLabel = (props: { date: Date }) => {
  const classes = useStyles();
  const daysInWeek = {
    1: '월',
    2: '화',
    3: '수',
    4: '목',
    5: '금',
    6: '토',
    0: '일',
  };
  return (
    <Box className={classes.headerDate}>
      {`${props.date.getFullYear()}.${
        props.date.getMonth() + 1
      }.${props.date.getDate()} (${daysInWeek[props.date.getDay()]})`}
    </Box>
  );
};

type Props = {
  userName: string;
  reportDate: Date;
};

function Header({ userName, reportDate }: Props): JSX.Element {
  const classes = useStyles();

  const isSimple = false;

  return (
    <Box className={classes.header} style={{ border: 'none' }}>
      <DateLabel date={reportDate} />
      <Typography className={classes.headerTitle}>
        {userName}님
        <span style={{ fontWeight: 'normal' }}>을 위한 피부 리포트</span>
      </Typography>
      <Typography className={classes.headerBody}>
        {isSimple
          ? '더 자세한 결과는'
          : '피부타입은 날씨와 라이프 스타일에 따라 변화하므로'}
        <br />
        {isSimple
          ? '전문가 피부테스트로 확인해보세요'
          : '2주마다 주기적인 테스트를 권장합니다'}
      </Typography>
    </Box>
  );
}
export default Header;
