import { MembershipProduct } from '../../domain/entities/MembershipProduct';

export class RemoteMembershipProductMapper {
  public static toMembershipProduct(data: any): MembershipProduct {
    return {
      id: data.id,
      name: data.name,
      price: data.regularPrice,
      discountedPrice: data.price,
      description: data.description,
      shortDescription: data.shortDescription,
    };
  }
}
