import firebase from 'firebase/app';
import 'firebase/auth';

import { AuthCredential, AuthService } from './AuthService';
import { firebaseConfig } from '../../config';

firebase.initializeApp(firebaseConfig);

export default class FirebaseAuth extends AuthService {
  onAuthStateChanged(
    func: (authUser: firebase.User | null) => void,
  ): firebase.Unsubscribe {
    return firebase.auth().onAuthStateChanged(func);
  }

  get authCredential(): Promise<AuthCredential> {
    return this.toAuthCredential(firebase.auth().currentUser);
  }

  async signOut(): Promise<void> {
    await firebase.auth().signOut();
  }

  async createUserWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<AuthCredential> {
    const { user } = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    return { idToken: await user.getIdToken(), userId: user.uid };
  }

  async signInWithEmailAndPassword(
    email: string,
    password: string,
  ): Promise<AuthCredential> {
    const { user } = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    return this.toAuthCredential(user);
  }

  async sendPasswordResetEmail(email: string): Promise<void> {
    await firebase.auth().sendPasswordResetEmail(email);
  }

  private async toAuthCredential(user: firebase.User): Promise<AuthCredential> {
    return user
      ? {
          userId: user?.uid,
          idToken: await user.getIdToken(),
        }
      : undefined;
  }
}
