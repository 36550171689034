import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';
import styled from 'styled-components';
import { device } from '../../utils/device';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'margin': '65px 16px 0px',

    [theme.breakpoints.up('sm')]: {
      margin: '92px 0px 0px 0px',
    },

    '& .ReactCollapse--collapse': {
      transition: 'height 250ms ease',
      marginBottom: '16px',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
    },
  },

  title: {
    'fontSize': '2rem',
    'textAlign': 'center',
    'marginBottom': '12px',
    'lineHeight': '2.9rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '21px',
      lineHeight: '4.3rem',
    },

    '& > strong': {
      fontWeight: 'bold',
    },
  },

  subtitle: {
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '28px',
    fontWeight: 'lighter',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      marginBottom: '31px',
    },
  },

  qnaList: {
    'margin': '12px 13px 0px',
    'fontSize': '1.2rem',

    [theme.breakpoints.up('sm')]: {
      margin: '10px 18px 0px',
      fontSize: '1.5rem',
    },

    '& > li': {
      'cursor': 'pointer',
      'lineHeight': '140%',

      '& strong': {
        fontWeight: 'bold',
      },
    },

    '& .ReactCollapse--collapse': {
      transition: 'height 250ms ease',
      marginBottom: '13px',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '20px',
      },
    },

    '& p': {
      fontSize: '13px',
      lineHeight: '19px',
      display: 'block',
      color: 'black',
    },
  },
}));

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const BarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & + & {
    margin-top: 18px;
  }

  @media ${device.laptop} {
    & + & {
      margin-top: 10px;
    }
  }
`;
const BarText = styled.div`
  width: 20%;
  display: block;
  justify-content: center;
  font-weight: 400;
  font-size: 1rem;

  &.result {
    font-weight: bold;
  }
  ,
  &.else > p {
    color: #c4c4c4;
  }
`;

const BarUpperLabel = styled.div`
  position: absolute;
  top: -23px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
`;
const BarBox = styled.div`
  width: 100%;

  height: 20px;
  display: flex;
  align-items: center;
  z-index: 1;

  background-color: #faf8f7;
  border-radius: 3px;
  @media ${device.laptop} {
    height: 17px;
  }
`;

const BarBoxWrapper = styled.div`
  position: relative;
  width: 60%;
`;

const BarFiller = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  height: 100%;
  background-color: ${(props) => props.color};
  transition: width 450ms;
  border-radius: 3px;
`;
const BarLabel = styled.div`
  margin-left: 6px;
  font-weight: 400;
  font-size: 10px;
  color: black;
  @media ${device.laptop} {
    font-size: 7px;
  }
`;

const COLORS = ['#B5B8FF', '#FFCAD7', '#D2FFF4', '#FFC38B', '#E3BFFF'];

const SKINSTATUS = {
  수분보유력: [
    ['De', '탈수분성'],
    ['Hy', '수분성'],
  ],
  유분보유력: [
    ['D', '건성'],
    ['O', '지성'],
  ],
  저항성: [
    ['S', '민감'],
    ['R', '저항'],
  ],
  비색소성: [
    ['P', '색소'],
    ['N', '비색소'],
  ],
  탄력성: [
    ['W', '주름'],
    ['T', '탄력'],
  ],
};

function BarChart({ dataArr }): JSX.Element {
  const classes = useStyles();

  return (
    <Container className={classes.qnaList}>
      {dataArr.map((each, index) => (
        <BarItem key={index}>
          <BarText className={each.score >= 55 ? 'else' : 'result'}>
            {' '}
            <p>{SKINSTATUS[each.key][0][0]}</p>
            <p>{SKINSTATUS[each.key][0][1]}</p>
          </BarText>

          <BarBoxWrapper>
            <BarBox>
              <BarFiller width={`${each.score}%`} color={COLORS[index]} />

              <BarLabel>{Math.round(each.score)}</BarLabel>
              <BarUpperLabel>{each.key}</BarUpperLabel>
            </BarBox>
          </BarBoxWrapper>

          <BarText className={each.score >= 55 ? 'result' : 'else'}>
            <p>{SKINSTATUS[each.key][1][0]}</p>
            <p>{SKINSTATUS[each.key][1][1]}</p>
          </BarText>
        </BarItem>
      ))}
    </Container>
  );
}

export default BarChart;
