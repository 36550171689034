import { QuizItem } from './models/QuizModel';
import React from 'react';

const data: QuizItem[] = [
  {
    id: 1,
    idSub: 0,
    title: '피부 리포트에 사용될 이름을 알려주세요',
    placeholder: '이름',
    category: 'basic',
    categoryKR: '기본 질문',
    key: 'NAME',
    type: 'input',
    options: [],
  },
  {
    id: 2,
    idSub: 1,
    title: '평상시 피부상태는 어떤가요?',
    placeholder: '',
    category: 'OD',
    categoryKR: '지성-건성',
    key: 'basic',
    type: 'radio',
    options: [
      { id: 1, text: '주로 건조하다고 느낌' },
      { id: 2, text: '딱히 건조하지도 기름지지도 않음' },
      { id: 3, text: '이마와 코만 번들거림' },
      { id: 4, text: '전체적으로 번들거림' },
    ],
  },
  {
    id: 3,
    idSub: 2,
    title: '화장품을 사용한 후 가렵거나 따가웠던 적이 있나요?',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'symptom',
    type: 'radio',
    options: [
      { id: 1, text: '항상' },
      { id: 2, text: '자주' },
      { id: 3, text: '가끔' },
      { id: 4, text: '그런 적 없음' },
      { id: 5, text: '잘 모름' },
    ],
  },
  {
    id: 4,
    idSub: 3,
    title: '트러블 or 여드름이 얼마나 자주 생기나요?',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'trouble',
    type: 'radio',
    options: [
      { id: 1, text: '얼굴 전반적으로 트러블이 자주 생김' },
      { id: 2, text: '특정 부위(이마, 턱, 또는 볼)에 자주 생김' },
      { id: 3, text: '날씨나 스트레스로 인해 아주 가끔 생김' },
      { id: 4, text: '전혀 나지 않음' },
    ],
  },
  {
    id: 5,
    idSub: 4,
    title:
      '매운 음식을 먹거나 격한 운동을 하면 얼굴이 붉어지나요? 혹은 홍조 증상이 있나요? (최근 1년)',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'flushing',
    type: 'radio',
    options: [
      { id: 1, text: '항상 있음' },
      { id: 2, text: '자주 있음' },
      { id: 3, text: '종종 있음' },
      { id: 4, text: '전혀 없음' },
      { id: 5, text: '잘 모르겠음' },
    ],
    tip: {
      title: '홍조 증상이란?',
      body: '혈관 확장으로 얼굴이 일시적으로 붉어지는 현상으로 화끈거리는 열감이 동반될 수 있습니다.',
      bodyMobile:
        '혈관 확장으로 얼굴이 일시적으로 붉어지는 현상으로 화끈거리는 열감이 동반될 수 있습니다.',
      image: 'simple_tip_4.png',
    },
  },
  {
    id: 6,
    idSub: 5,
    title:
      '아토피 피부염, 습진 또는 접촉성 피부염 등의 증상이 있나요?(최근 1년)',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'contact',
    type: 'radio',
    options: [
      { id: 1, text: '항상 있음' },
      { id: 2, text: '자주 있음' },
      { id: 3, text: '종종 있음' },
      { id: 4, text: '전혀 없음' },
      { id: 5, text: '잘 모르겠음' },
    ],
    tip: {
      title: '접촉성 피부염이란?',
      body: (
        <>
          외부 물질과 접촉한 후 생기는 피부의 염증 반응으로 피부가 붉게 변하고
          가려우며 심한 경우
          <br />
          진물이 날 수 있습니다. 크게 자극성/알레르기성으로 나뉩니다.
        </>
      ),
      bodyMobile:
        '외부 물질과 접촉한 후 생기는 피부의 염증 반응으로 피부가 붉게 변하고 가려우며 심한 경우 진물이 날 수 있습니다. 크게 자극성/알레르기성으로 나뉩니다.',
      image: 'simple_tip_5.png',
    },
  },
  {
    id: 7,
    idSub: 6,
    title: '얼굴에 기미, 잡티가 잘 보이나요?',
    placeholder: '',
    category: 'PN',
    categoryKR: '색소-비색소',
    key: 'freckles',
    type: 'radio',
    options: [
      { id: 1, text: '얼굴 전반적으로 있음' },
      { id: 2, text: '특정 부위 (코, 뺨 등)에 있음' },
      { id: 3, text: '잘 모르겠음' },
      { id: 4, text: '전혀 없음' },
    ],
    tip: {
      title: '기미란?',
      body: (
        <>
          주로 얼굴에 생기는 불규칙한 모양의 거무스름한 빛깔의 색소 병변으로
          흔히 광대 부위에
          <br />
          양쪽으로 나타납니다.
        </>
      ),
      bodyMobile:
        '주로 얼굴에 생기는 불규칙한 모양의 거무스름한 빛깔의 색소 병변으로 흔히 광대 부위에 양쪽으로 나타납니다.',
      image: 'simple_tip_6.png',
    },
  },
  {
    id: 8,
    idSub: 7,
    title: '얼굴에 주름이나 잔주름이 보이나요?',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'wrinkle',
    type: 'radio',
    options: [
      { id: 1, text: '굵은 주름이 확연히 눈에 띔' },
      { id: 2, text: '눈가와 입가에 주름이 보임' },
      { id: 3, text: '잘 모르겠음' },
      { id: 4, text: '없음' },
    ],
  },
  {
    id: 9,
    idSub: 8,
    title: '얼굴이 건조하고 땅기는 증상이 얼마나 자주 있나요?(최근 2주)',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'dry',
    type: 'radio',
    options: [
      { id: 1, text: '항상 사막같이 건조함' },
      { id: 2, text: '종종 건조함' },
      { id: 3, text: '거의 없음' },
      { id: 4, text: '전혀 없음' },
    ],
  },
  {
    id: 10,
    idSub: 9,
    title: '당신의 피부고민을 모두 선택해주세요.',
    placeholder: '',
    category: 'overall',
    categoryKR: '종합',
    key: 'concerns',
    type: 'check',
    options: [
      { id: 1, text: '색소침착(기미,잡티)' },
      { id: 2, text: '홍조' },
      { id: 3, text: '트러블' },
      { id: 4, text: '피부 건조' },
      { id: 5, text: '주름/탄력' },
      { id: 6, text: '피지/모공' },
    ],
    tip: {
      title: '색소침착이란?',
      body: '피부에 염증이 생긴 후 해당 부위의 염증이 없어지면서 갈색의 자국이 남는 경우를 의미합니다.',
      bodyMobile:
        '피부에 염증이 생긴 후 해당 부위의 염증이 없어지면서 갈색의 자국이 남는 경우를 의미합니다.',
      image: 'simple_tip_9.png',
    },
  },
];

export default data;
