import { SkinReportContents } from './models/SkinReportContents';

const data: SkinReportContents = {
  hyORNT: {
    typeJewelry: '다이아몬드',
    type: '보석 중의 보석, 부러움을 살 건강하고 이상적인 피부입니다.',
    careRoutine: [
      '유수분 밸런스를 지키기 위해 가벼운 보습제를 사용하세요.',
      '트러블이 고민인 경우 과도한 피지 분비로 인해 여드름이 나타날 수 있으므로 유분감이 적은 제품을 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 건강한 편에 속합니다. ',
      '적당한 유수분 밸런스를 갖추고 있으며 피부 고민이 적은 편입니다.',
    ],
    typeTags: ['#부드러운지성피부', ' #모공확대위험', ' #블랙헤드발생가능'],
    typeComment:
      '이상적인 피부 타입으로, 고른 피부톤을 보유하며 피부 노화 위험이 낮은 건강한 피부입니다.',
    typeRatio: 4.0,
    typeExplanation:
      'hyORNT는 수분성(Hydrated), 지성(Oily), 저항(Resistive), 비색소(Nonpigmented), 탄력(Tight) 피부를 의미합니다.',
    explanation: [
      '피부톤이 일정하며, 주름이 적어 나이가 들어 보이지 않는 흠 잡을 데 없는 피부입니다.',
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '피부가 건조하지 않으며, 색소침착도 잘 일어나지 않아 비교적 피부 관리가 쉬운 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
      '햇빛에 피부가 타기보다는 붉어지는편입니다.',
      '전반적으로 트러블이 적은 편이나 유분이 증가하는 상황(예를 들어 여름철)에서 모공이 커지거나 블랙헤드가 생길 수 있습니다.',
    ],
  },
  hyOSNT: {
    typeJewelry: '루비',
    type: '맑고 탄력 있지만 예민한 빛깔을 띠는 루비와 닮은 피부입니다.',
    careRoutine: [
      '민감성 피부를 보완할 수 있는 자극적이지 않은 순한 제품을 권장합니다.',
      '피지 분비를 조절할 수 있으면서도 자극이 적은 가벼운 스킨케어 제품을 사용해주세요.',
      "트러블 케어를 위한 '항산화제'나 '항염증제재' 성분을 추천합니다.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블에 대한 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #여드름_염증', ' #민감함'],
    typeComment:
      '트러블 및 염증 관리에 신경 써야 하지만, 고른 피부톤을 보유한 노화 위험이 낮은 피부입니다.',
    typeRatio: 9.5,
    typeExplanation:
      'hyOSNT는 수분성(Hydrated), 지성(Oily), 민감(Sensitive), 비색소(Nonpigmented), 탄력(Tight) 피부를 의미합니다.',
    explanation: [
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부가 건조하지 않으며, 색소침착도 잘 일어나지 않는 피부입니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  hyORNW: {
    typeJewelry: '진주',
    type: '부드럽고 윤택하여 은은한 광채가 나는 진주와 닮은 피부입니다.',
    careRoutine: [
      '유수분 밸런스 조절을 위한 스킨케어 제품 사용을 권장합니다.',
      '주름의 외형을 줄이기 위해 강한 성분을 추천합니다.',
      "잘 맞는 성분은 '레티노이드' 계열과 '알파 하이드록시산' 그리고 'AHA' 입니다.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 탄력이 부족한 편에 속합니다. ',
      '피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #때 이른 잔주름', ' #약간의 여드름'],
    typeComment:
      '피부 탄력을 위한 관리가 필요하지만, 피부톤이 고르고 유수분 밸런스가 좋은 건강한 피부입니다.',
    typeRatio: 1.5,
    typeExplanation:
      'hyORNW는 수분성(Hydrated), 지성(Oily), 저항(Resistive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부가 건조하지 않으며, 색소침착도 잘 일어나지 않아 비교적 피부 관리가 쉬운 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  hyOSNW: {
    typeJewelry: '가넷',
    type: '쉽게 붉어질 수 있지만 투명하고 맑은 가넷과 닮은 피부입니다.',
    careRoutine: [
      '민감성 피부를 보완할 수 있는 자극적이지 않은 순한 제품을 권장합니다.',
      '피지 분비를 조절할 수 있으면서도 자극이 적은 가벼운 스킨케어 제품을 사용해주세요.',
      "염증이 있어 피부 노화에 취약하므로 '국소 항산화제', '야간 레티노이드', '항염증' 제품을 추천합니다.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블에 대한 관리와 유수분 밸런스를 통한 탄력 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #홍조, 발진, 붉은기', ' #주름'],
    typeComment:
      '고른 피부톤을 갖고 있지만, 트러블 염증과 피부 탄력 관리에 관심이 필요한 피부입니다.',
    typeRatio: 4.0,
    typeExplanation:
      'hyOSNW는 수분성(Hydrated), 지성(Oily), 민감(Sensitive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부가 건조하지 않으며, 색소침착도 잘 일어나지 않는 피부입니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  hyORPT: {
    typeJewelry: '호박석',
    type: '잡티가 신경 쓰이지만, 탄력적이고 촉촉한 빛깔을 띄는 호박석과 닮은 피부입니다.',
    careRoutine: [
      '유수분 밸런스 조절을 위한 스킨케어 제품 사용을 권장합니다.',
      '자외선 차단제는 필수. 비교적 주름과 자극에 덜 취약하지만 피부톤을 고르게 하기 위해서는 매일 발라주세요.',
      '피부톤을 맑게 해주고 색소침착에 도움이 되는 미백 제품 사용을 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬운 편에 속합니다. ',
      '피부 미백 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #기미, 잡티', ' #어두워진 피부'],
    typeComment:
      '기미나 흉터로 인한 색소침착에 대한 관리가 필요하지만, 유수분 밸런스가 좋은 건강한 피부입니다.',
    typeRatio: 0.5,
    typeExplanation:
      'hyORPT는 수분성(Hydrated), 지성(Oily), 저항(Resistive), 색소(Pigmented), 탄력(Tight) 피부를 의미합니다\n',
    explanation: [
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  hyOSPT: {
    typeJewelry: '호안석',
    type: '호안석과 같이 윤택하고 탄탄하지만 색소침착과 민감함이 엿보이는 피부입니다.',
    careRoutine: [
      '민감성 피부를 보완할 수 있는 자극적이지 않은 순한 제품을 권장합니다.',
      '미백 성분이 포함된 저자극 스킨케어를 사용해주세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블과 착색(색소 침착)에 대한 관리가 필요합니다.',
    ],
    typeTags: ['#민감', ' #여드름', ' #검은 점'],
    typeComment:
      '피부를 갖고 있지만, 트러블 염증과 색소침착 미백에 관리가 필요한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'hyOSPT는 수분성(Hydrated), 지성(Oily), 민감(Sensitive), 색소(Pigmented), 탄력(Tight) 피부를 의미합니다.\n',
    explanation: [
      '여드름이 자주 생기고 여드름 흉터로 인한 착색이 있을 수 있습니다.',
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  hyORPW: {
    typeJewelry: '벽옥',
    type: '주름과 색소가 관찰되지만 부드럽게 빛나는 벽옥을 닮은 피부입니다.',
    careRoutine: [
      '자외선 차단제는 필수. 주름개선과 피부톤을 고르게 하기 위해서는 매일 발라주세요.',
      "우선적으로, 피부톤을 맑게 해주고 색소침착에 도움이 되는 '나이아신아마이드'와 같은 성분이 들어간 미백 제품 사용을 추천합니다.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬우며 탄력이 부족한 편에 속합니다. ',
      '피부 미백과 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #갈색 반점', ' #넓어진 모공'],
    typeComment:
      '피부 노화 예방을 위한 미백 및 탄력 관리가 필요하지만, 유수분 밸런스가 좋고 피부 트러블이 적은 건강한 피부입니다.',
    typeRatio: 0.5,
    typeExplanation:
      'hyORPW는 수분성(Hydrated), 지성(Oily), 저항(Resistive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '나이가 들수록 광노화로 인한 갈색 반점 등의 다양한 색소 병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
    ],
  },
  hyOSPW: {
    typeJewelry: '자수정',
    type: '까탈스럽고 어두운 빛이 보이지만 유·수분 밸런스가 좋은 자수정을 닮은 피부.',
    careRoutine: [
      '피부톤을 맑게 개선하기 위해 저자극의 자외선차단제 및 미백 제품을 추천합니다.',
      "우선적으로 미백과 주름 개선 효과를 모두 갖고 있는 '나이아신아마이드' 같은 성분이 들어한 제품을 사용하되 저자극 제품으로 선택해주세요.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블에 대한 관리가 우선 필요하며, 단계적으로 미백과 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#민감', ' #색소침착', ' #노화'],
    typeComment:
      '트러블 염증 관리를 우선 집중하고 단계적으로 피부 미백과 탄력 개선에 관심이 필요한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'hyOSPW는 수분성(Hydrated), 지성(Oily), 민감(Sensitive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '여드름이 자주 생기고 여드름 흉터로 인한 착색이 있을 수 있습니다.',
      '수분 보유량이 높은 부드러운 지성 피부로 가벼운 보습제만 사용해도 피부의 수분감을 느낄 수 있습니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '나이가 들수록 광노화로 인한 갈색 반점 등의 다양한 색소 병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
    ],
  },
  hyDRNT: {
    typeJewelry: '옥',
    type: '수분감만 더한다면 아름다운 빛과 탄탄함이 돋보이는 옥과 같은 이상적인 피부입니다.',
    careRoutine: [
      '건조한 피부를 관리하기 위해 충분한 유분 보습이 필요합니다.',
      '주기적으로 각질 관리를 해주세요.',
      '자외선 차단제는 필수입니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 건조하지만 건강한 편에 속합니다. ',
      '피부 고민이 비교적 적은 편으로, 유수분 밸런스 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #각질', ' #일광화장 쉬움'],
    typeComment:
      '부족한 유분을 채워주면 이상적인 피부 타입으로, 고른 피부톤을 보유하며 피부 노화 위험이 낮은 건강한 피부입니다.',
    typeRatio: 5.5,
    typeExplanation:
      'hyDRNT는 수분성(Hydrated), 건성(Dry), 저항(Resistive), 비색소(Nonpigmented), 탄력(Tight) 피부를 의미합니다\n',
    explanation: [
      '피부톤이 일정하며, 주름이 적어 나이가 들어 보이지 않는 흠 잡을 데 없는 피부입니다.',
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '평소에는 피부가 건조하지 않으며, 색소침착도 잘 일어나지 않아 비교적 피부 관리가 쉬운 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  hyDSNT: {
    typeJewelry: '오팔',
    type: '유분을 보충하고 민감함에 신경 쓴다면 특유의 맑고 탄력적인 매력이 돋보일 오팔과 같은 피부입니다.',
    careRoutine: [
      '피부 보습에 집중적으로 유의해주세요.',
      '유분 보충으로 피부 바깥층을 보호하는게 좋습니다.',
      '건조함과 민감성을 낮추기 위해 순한 보습제와 항산화제, 항염증제재 사용을 권장합니다.',
      '알코올, 멘톨, 페퍼민트 등의 성분을 피해주세요.',
      '피부를 건조하게 하는 냉난방을 주의해주세요.',
      '지나친 클렌징 사용과 세면 습관이 피부 장벽을 더 파괴할 수 있으므로 각별히 유의해주세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정과 피부 장벽강화를 위한 관리가 필요합니다.',
    ],
    typeTags: ['#매우 건조함', ' #각질', ' #민감'],
    typeComment:
      '피부의 유분 보충과 트러블 및 염증 관리에 신경 써야 하지만, 고른 피부톤을 보유한 노화 위험이 낮은 피부입니다.',
    typeRatio: 8.5,
    typeExplanation:
      'hyDSNT는 수분성(Hydration), 건성(Dry), 민감성(Sensitive), 비색소침착성(Nonpigmented), 탄력성(Tight) 피부를 의미합니다.\n',
    explanation: [
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '평소에는 피부가 건조하지 않으며, 색소침착도 잘 일어나지 않아 비교적 피부 관리가 쉬운 피부입니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  hyDRNW: {
    typeJewelry: '대리석',
    type: '맑지만 다소 건조해 주름이 관찰되는 대리석과 같은 피부입니다.',
    careRoutine: [
      '초기에 적절한 보습을 해주시면 주름 예방과 피부 노화 방지에 중요한 역할을 합니다.',
      '항산화제와 자외선 차단제를 매일 사용하시길 권장합니다.',
      '야간 레티노이드 등이 포함된 주름 개선 제품을 추천드립니다.',
      '건조한 피부를 관리하기 위해 충분한 유분 보습이 필요합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 탄력이 부족한 편에 속합니다. 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #잔주름', ' #민감성피부경험'],
    typeComment:
      '피부 탄력을 위한 관리와 유분 보충이 필요하지만, 피부톤이 고른 건강한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'hyDRNW는 수분성(Hydrated), 건성(Dry), 저항(Resistive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다',
    explanation: [
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '평소에는 피부가 건조하지 않으며, 색소침착도 잘 일어나지 않아 비교적 피부 관리가 쉬운 피부입니다.',
      '피부의 유분기가 없으면 잔주름이 더 두드러져 보입니다.',
      '큰 트러블 없이 탄력을 유지해 왔던 피부이나, 시간이 지날수록 건조함과 얼굴에 발생하는 색소성 병변, 잔주름이 고민이 되는 피부입니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  hyDSNW: {
    typeJewelry: '장미수정',
    type: '민감한 빛과 주름이 엿보이지만, 장미수정과같이 맑은 빛깔을 가진 피부입니다.',
    careRoutine: [
      '탄력을 개선시키되 자극적이지 않은 화장품을 사용해주세요.',
      '피부의 유분을 보충하는 순한 보습제 사용을 권장합니다.',
      '피부장벽의 재생을 돕는 보습제 및 항산화제, 항염증 화장품을 추천합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정과 유수분 밸런스를 통한 피부 탄력 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #주름', ' #민감'],
    typeComment:
      '고른 피부톤을 갖고 있지만, 유분보충과 트러블 염증 및 피부 탄력 관리에 관심이 필요한 피부입니다.',
    typeRatio: 3.0,
    typeExplanation:
      'hyDSNW는 수분성(Hydrated), 건성(Dry), 민감(Sensitive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '평소에는 피부가 건조하지 않으며, 색소침착도 잘 일어나지 않아 비교적 피부 관리가 쉬운 피부입니다.',
      '피부의 유분기가 없으면 잔주름이 더 두드러져 보입니다.',
      '나이 들수록 건조함과 잔주름이 고민이 되는 피부입니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  hyDRPT: {
    typeJewelry: '사금석',
    type: '침착된 색소와 부족한 유분이 아쉽지만, 탄력적이고 건강함이 돋보이는 사금석과 같은 피부입니다.',
    careRoutine: [
      '건조한 피부를 관리하기 위해 충분한 유분 보습이 필요합니다.',
      '고르지 못한 피부톤을 맑고 투명하게 개선하기 위해 자외선 차단제와 미백 제품의 사용을 추천합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬운 편에 속합니다. ',
      '피부 미백 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #기미, 다크 써클', ' #가려움'],
    typeComment:
      '탄력 있는 피부를 가지고 있지만, 기미나 흉터로 인한 색소침착에 대한 관리와 유분 보충이 필요 피부입니다.',
    typeRatio: 1.5,
    typeExplanation:
      'hyDRPT는 수분성(Hydrated), 건성(Dry), 저항(Resistive), 색소(Pigmented), 탄력(Tight) 피부를 의미합니다',
    explanation: [
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '큰 트러블 없이 탄력을 유지해 왔던 피부이나, 시간이 지날수록 건조함과 얼굴에 발생하는 색소성 병변이 고민이 되는 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  hyDSPT: {
    typeJewelry: '마노',
    type: '침착된 색소와 민감함이 엿보이지만, 탄력이 돋보이는 마노와 닮은 피부입니다.',
    careRoutine: [
      '피부 염증이 재발하고 피부 톤이 고르지 못한 특징을 가지고 있어, 건조함과 염증케어에 집중해주세요.',
      '건조함과 염증케어를 어느정도 잡은 뒤에 색소침착 케어에 신경써주세요.',
      '피부 보습에 집중적으로 유의해주세요.',
      '민감성을 낮추기 위해 순한 보습제, 항염증 화장품의 사용을 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정 및 착색(색소 침착)에 대한 관리가 필요합니다.',
    ],
    typeTags: ['#건조와 가려움', ' #민감', ' #색소침착, 다크서클'],
    typeComment:
      '탄력 있는 피부를 갖고 있지만, 유분보충과 트러블 염증 및 색소침착 미백에 대한 관리가 필요한 피부입니다.',
    typeRatio: 3.0,
    typeExplanation:
      'hyDSPT는 수분성(Hydrated), 건성(Dry), 민감(Sensitive), 색소침착(Pigmented), 탄력(Tight) 피부를 의미합니다.',
    explanation: [
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '나이가 들수록 건조함과 얼굴에 발생하는 색소성 병변이 고민이 되는 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  hyDRPW: {
    typeJewelry: '수초석',
    type: '다소 건조하고 주름과 색소가 관찰되지만 비교적 건강한 수초석을 닮은 피부입니다.',
    careRoutine: [
      '건조하고 주름진 피부를 예방하고 치료하는 것이 중요합니다.',
      '건조한 피부를 관리하기 위해 충분한 유분 보습이 필요합니다.',
      '고르지 못한 피부톤을 맑고 투명하게 개선하기 위해 자외선 차단제와 미백 제품의 사용을 추천합니다.',
      '주름 및 탄력 개선을 위해 항산화제 및 약한 AHA 함유 스킨케어를 사용해주세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬우며 탄력이 부족한 편에 속합니다. ',
      '피부 미백과 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: [
      '#건조함과 갈라짐',
      '#다양한 크기의 검은 반점',
      '#얇은 피부, 주름',
    ],
    typeComment:
      '피부에 부족한 유분 보충과 미백 및 탄력 관리가 필요하지만, 피부 트러블이 적은 피부입니다.',
    typeRatio: 0.5,
    typeExplanation:
      'hyDRPW는 수분성(Hydrated), 건성(Dry), 저항(Resistive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '현재는 수분성이지만  상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '큰 트러블 없던 피부이나, 시간이 지날수록 건조함과 얼굴에 발생하는 색소성 병변, 잔주름이 고민이 되는 피부입니다.',
      '피부의 유분기가 없으면 잔주름이 더 두드러져 보입니다.',
      '나이가 들수록 광노화로 인한 갈색 반점 등의 다양한 색소 병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
    ],
  },
  hyDSPW: {
    typeJewelry: '사도닉스',
    type: '달아오르고 주름이 진 모습이 관찰되지만 원석과 같은 가능성을 가진 피부입니다.',
    careRoutine: [
      '자극적이지 않은 미백, 탄력 개선 화장품을 사용해주세요.',
      '피부의 유분을 보충하는 순한 보습제를 추천합니다.',
      '건조함과 염증을 악화시키지 않고 색소침착과 주름을 치료하는 피부관리 요법이 필요합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정 관리가 우선 필요하며, 단계적으로 착색(색소 침착)과 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#유분 부족', ' #민감', ' #색소침착, 잔주름'],
    typeComment:
      '피부의 유분보충과 트러블 염증 관리를 우선 집중하고 단계적으로 피부 미백과 탄력 개선에 관심이 필요한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'hyDSPW는 수분성(Hydrated), 건성(Dry), 민감(Sensitive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '피부의 유분이 부족해 건조한 계절에 수분이 증발하기 쉬운 상태로 미세한 각질이 생기기도 합니다.',
      '현재는 수분성이지만 상황에 따라 건조한 피부로 변할 수 있는 상태입니다.',
      '피부의 유분기가 없으면 잔주름이 더 두드러져 보입니다.',
      '나이가 들수록 건조함과 얼굴에 발생하는 색소성 병변, 잔주름이 고민이 되는 피부입니다.',
      '나이가 들수록 광노화로 인한 갈색 반점 등의 다양한 색소 병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
    ],
  },
  deORNT: {
    typeJewelry: '사파이어',
    type: '피부 속 수분에 신경 쓴다면 사파이어와 같이 아름다운 빛깔과 탄력성을 보여줄 수 있는 이상적인 피부입니다.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 스킨케어를 사용해주세요.',
      '경우에 따라 과도한 피지 분비로 여드름이 나타날 수 있으므로 유분감이 적은 제품을 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 수분이 부족하지만 건강한 편에 속합니다. ',
      '피부 고민이 적은 편으로, 유수분 밸런스 관리가 필요합니다.',
    ],
    typeTags: ['#수분부족형지성', ' #모공이 커짐', ' #블랙헤드'],
    typeComment:
      '속 당김과 번들거림이 공존해서 종종 트러블이 생기나 고른 피부톤을 보유하며 피부 노화 위험이 낮은 건강한 피부입니다.',
    typeRatio: 4.0,
    typeExplanation:
      'deORNT는 탈수분성(Dehydrated), 지성(Oily), 저항(Resistive), 비색소(Nonpigmented), 탄력(Tight) 피부를 의미합니다.',
    explanation: [
      '때때로 여드름이 심하게 올라오기도 하지만 조금만 신경 쓰면 오래가지는 않습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
      '트러블은 잘 일어나지 않는 편입니다.',
    ],
  },
  deOSNT: {
    typeJewelry: '산호석',
    type: '유분기와 염증으로 인한 예민함이 있지만 맑고 탄력적인 산호석을 닮은 피부입니다.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 화장품의 사용을 권장하며 유분기가 많은 제품은 제외해주세요.',
      '민감성 피부를 보완할 수 있는 자극적이지 않은 제품을 사용해야 합니다.',
      '저자극의 피지 분비를 조절할 수 있는 가벼운 스킨케어 제품을 추천합니다.',
      '트러블 케어를 위한 항산화제나 항염증제재 화장품 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블에 대한 관리가 필요합니다.',
    ],
    typeTags: ['#수분부족형지성', ' #여드름', ' #민감'],
    typeComment:
      '피부의 속 당김 해결을 위한 유수분 밸런스 관리와 트러블 및 염증 관리에 신경 써야 하지만, 고른 피부톤을 가지고 있고 주름이 적은 노화 위험이 낮은 피부입니다.',
    typeRatio: 9.5,
    typeExplanation:
      'deOSNT는 탈수분성(Dehydrated), 지성(Oily), 민감(Sensitive), 비색소(Nonpigmented), 탄력(Tight) 피부를 의미합니다.',
    explanation: [
      '빈번한 여드름, 좁쌀 여드름이 발생합니다.',
      '본인에게 맞는 화장품을 찾는 데 어려움이 있을 수 있습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  deORNW: {
    typeJewelry: '래브라도라이트',
    type: '번들거림과 잔주름이 관찰되나 빛나는 건강함이 돋보이는 래브도라이트와 닮은 피부입니다.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 스킨케어를 사용해주세요.',
      '경우에 따라 과도한 피지 분비로 여드름이 나타날 수 있으므로 유분감이 적은 제품을 권장합니다.',
      '주름의 외형을 줄이기 위한 성분을 추천합니다.',
      "잘 맞는 성분은 '레티노이드' 계열과 '알파 하이드록시산' 그리고 'AHA' 입니다.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 탄력이 부족한 편에 속합니다. 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #잔주름', ' #속건조'],
    typeComment:
      '피부톤은 고른 편이지만 피부 탄력과 유수분 관리가 필요한 피부입니다.',
    typeRatio: 1.5,
    typeExplanation:
      'deORNW는 탈수성(Dehydrated), 지성(Oily), 저항(Resistive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '때때로 여드름이 심하게 올라오기도 하지만 조금만 신경 쓰면 오래가지는 않습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  deOSNW: {
    typeJewelry: '호피석',
    type: '수분, 트러블, 탄력에 관심을 갖는다면 맑은 피부톤이 더 돋보일 수 있는 호피석과 같은 피부입니다.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 화장품의 사용을 권장하며 유분기가 많은 제품은 제외해주세요.',
      '민감성 피부를 보완할 수 있는 자극적이지 않은 제품을 사용해야 합니다.',
      '저자극의 피지 분비를 조절할 수 있는 가벼운 스킨케어를 추천합니다.',
      "염증이 있어 피부 노화에 취약하므로 '국소 항산화제', '야간 레티노이드', '항염증' 제품을 추천합니다.",
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블 관리와 유수분 밸런스를 통한 피부 탄력 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #민감', ' #잔주름'],
    typeComment:
      '고른 피부톤을 갖고 있지만, 유수분 밸런스, 트러블 염증 및 피부 탄력 관리에 관심이 필요한 피부입니다.',
    typeRatio: 4.0,
    typeExplanation:
      'deOSNW는 탈수분성(Dehydrated), 지성(Oily), 민감(Sensitive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '빈번한 여드름, 좁쌀 여드름이 발생합니다.',
      '본인에게 맞는 화장품을 찾는 데 어려움이 있을 수 있습니다',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  deORPT: {
    typeJewelry: '터키석',
    type: '속이 건조하고 색소침착이 다소 있지만 탄탄하고 건강한 터키석과 닮은 피부입니다.',
    careRoutine: [
      '유수분 밸런스 조절을 위한 스킨케어 제품 사용을 권장합니다.',
      '피부 속 수분을 보충해줄 수 있는 화장품을 사용하되 유분기가 많은 제품은 제외해주세요.',
      '비교적 주름과 자극에 덜 취약하지만 피부톤을 고르게 하기 위해서는 매일 자외선 차단제가 필요합니다.',
      '피부톤을 맑게 해주고 색소침착에 도움이 되는 미백 제품 사용을 추천합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬운 편에 속합니다. ',
      '피부 미백 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#속건조', ' #기미', ' #여드름'],
    typeComment:
      '피부 탄력이 좋지만, 기미나 흉터로 인한 색소침착과 유수분 밸런스 관리가 필요한 피부입니다.',
    typeRatio: 0.5,
    typeExplanation:
      'deORPT는 탈수분성(Dehydrated), 지성(Oily), 저항(Resistive), 색소(Pigmented), 탄력(Tight) 피부를 의미합니다\n',
    explanation: [
      '때때로 여드름이 심하게 올라오기도 하지만 조금만 신경 쓰면 오래가지는 않습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  deOSPT: {
    typeJewelry: '표범석',
    type: '수분, 트러블, 색소의 문제들을 안고 있지만, 탄력성의 장점을 가진 표범석 같은 피부입니다.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 화장품의 사용을 권장하며 유분기가 많은 제품은 제외해주세요.',
      '민감성 피부를 보완할 수 있는 자극적이지 않은 제품을 사용해야 합니다.',
      '피부 개선을 위해 저자극의 미백 성분이 포함된 스킨케어 제품을 추천합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블과 착색(색소 침착)에 대한 관리가 필요합니다.',
    ],
    typeTags: ['#수분부족형지성', ' #여드름', ' #검은 점'],
    typeComment:
      '피부 탄력이 좋지만 유수분 밸런스, 염증성 트러블 및 색소침착에 대한 관리가 필요한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'deOSPT는 탈수분성(Dehydrated), 지성(Oily), 민감(Sensitive), 색소(Pigmented), 탄력(Tight) 피부를 의미합니다.\n',
    explanation: [
      '빈번한 여드름, 좁쌀여드름이 발생합니다.',
      '빈번한 여드름 발생하고 여드름 흉터로 인한 착색이 있을 수 있습니다.',
      '본인에게 맞는 화장품을 찾는데 어려움이 있을 수 있습니다',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부 표면은 유분으로 번들거려보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '피지분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을수도 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  deORPW: {
    typeJewelry: '공작석',
    type: '피부 속 수분과 색소, 탄력 문제가 있지만, 공작석의 견고함을 닮은 건강한 피부입니다.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 화장품을 사용하되 유분기가 많은 제품은 제외해주세요.',
      '유수분 밸런스 조절을 위한 스킨케어 제품을 사용해주세요.',
      '주름개선과 피부톤을 고르게 하기 위해 매일 자외선 차단제가 필요합니다.',
      '피부톤을 맑게 해주고 색소침착에 도움이 되는 미백 제품 사용을 추천합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬우며 탄력이 부족한 편에 속합니다. ',
      '피부 미백과 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#번들거림', ' #갈색반점', ' #주름'],
    typeComment:
      '평소 피부 트러블은 적지만 유수분 밸런스 관리와 색소침착 및 주름 관리를 통해 피부 노화를 예방해야 하는 피부입니다.',
    typeRatio: 0.5,
    typeExplanation:
      'deORPW는 탈수분성(Dehydrated), 지성(Oily), 저항(Resistive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '때때로 여드름이 심하게 올라오기도 하지만 조금만 신경 쓰면 오래가지는 않습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '나이가 들수록 광노화로 인한 갈색반점 등의 다양한 색소병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
    ],
  },
  deOSPW: {
    typeJewelry: '각력질벽옥',
    type: '수분, 탄력, 트러블의 많은 관리가 필요하지만. 원석과 같은 가능성을 가진 피부에요.',
    careRoutine: [
      '피부 속 수분을 보충해줄 수 있는 화장품의 사용을 권장하며 유분기가 많은 제품은 제외해주세요.',
      '민감성 피부를 보완할 수 있는 자극적이지 않은 제품을 사용해야 합니다.',
      '피부톤을 맑게 개선하기 위해 저자극의 자외선차단제 및 미백 제품을 추천합니다.',
      '저자극의 탄력 개선 화장품을 사용해주세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 트러블 관리가 우선 필요하며, 단계적으로 착색(색소 침착)과 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#잔주름', ' #여드름', ' #민감'],
    typeComment:
      '피부의 유수분 밸런스와 트러블 염증 관리를 우선 집중하고 단계적으로 피부 미백과 탄력 개선에 관심이 필요한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'deOSPW는 탈수분성(Dehydrated), 지성(Oily), 민감(Sensitive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '빈번한 여드름, 좁쌀 여드름이 발생합니다.',
      '본인에게 맞는 화장품을 찾는 데 어려움이 있을 수 있습니다',
      '빈번한 여드름 발생하고 여드름 흉터로 인한 착색이 있을 수 있습니다.',
      '피부 표면은 유분으로 번들거려 보이지만 피부 속은 수분 부족으로 건조하게 느껴지는 피부입니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '여드름이나 반복적인 트러블이 나타나고 피지로 인해 번들거리는 피부입니다.',
      '주로 유분기와 염증이 특징적으로 나타납니다.',
      '피부톤이 고르지 않고 종종 발생한 피부 트러블로 인한 색소침착과 흉터가 존재합니다.',
      '나이가 들수록 광노화로 인한 갈색 반점 등의 다양한 색소 병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '경우에 따라 종종 트러블이 나타나는데 피지 관리를 하지 않으면, 모공이 커지거나, 블랙헤드 발생할 수 있습니다.',
      '얼굴이 번들거리며 자외선 차단제가 기름지게 느껴질 수 있습니다.',
      '피지 분비로 인해 넓어진 모공과 번들거리는 피부입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
    ],
  },
  deDRNT: {
    typeJewelry: '수정',
    type: '유수분 관리만 잘해준다면 맑고 탄력적인 모습이 이상적인 수정과 같은 피부입니다.',
    careRoutine: [
      '피부의 수분이 부족하므로 수분 보충이 우선적으로 필요합니다.',
      '피부 바깥층에서 수분 손실을 방지할 능력이 부족한 상태이므로, 수분 보충 이후 보습을 유지하는 것이 중요합니다.',
      '각질제거제와 자외선 차단제 사용을 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 유수분이 부족한 편에 속합니다. ',
      '피부 고민은 비교적 적은 편으로, 유수분 밸런스 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #일광화상이 쉬움', ' #각질'],
    typeComment:
      '부족한 유수분을 모두 채워주면 이상적인 피부 타입으로, 고른 피부톤을 보유하며 피부 노화 위험이 낮은 건강한 피부입니다.',
    typeRatio: 5.5,
    typeExplanation:
      'deDRNT는 탈수분성(Dehydrated), 건성(Dry), 저항(Resistive), 비색소(Nonpigmented), 탄력(Tight) 피부를 의미합니다\n',
    explanation: [
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  deDSNT: {
    typeJewelry: '토파즈',
    type: '다소 까칠하지만 빛나는 피부톤과 탄력성이 돋보이는 토파즈를 닮은 피부에요.',
    careRoutine: [
      '피부의 수분이 부족하므로 수분 보충이 우선적으로 필요합니다.',
      '피부 바깥층에서 수분 손실을 방지할 능력이 부족한 상태이므로, 수분 보충 이후 보습을 유지하는 것이 중요합니다.',
      '피부 장벽기능이 약하거나 손상되기 쉬워 염증이나 알레르기 반응이 흔하므로 장벽기능 개선 관리가 필요합니다.',
      '건조함과 민감성을 낮추기 위해 순한 보습제, 항산화제, 항염증제재 제품을 사용해주세요.',
      '알코올, 멘톨, 페퍼민트 등의 성분을 피해주세요.',
      '피부를 건조하게 하는 냉난방을 주의해주세요.',
      '지나친 클렌징 사용과 세면 습관이 피부 장벽을 더 파괴할 수 있으므로 각별히 유의해주세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정과 피부 장벽강화를 위한 관리가 필요합니다.',
    ],
    typeTags: ['#좁쌀여드름', ' #민감', ' #피부건조'],
    typeComment:
      '피부에 부족한 유수분 보충과 트러블 및 염증 관리에 신경 써야 하지만, 고른 피부톤을 보유한 노화 위험이 낮은 피부입니다.',
    typeRatio: 8.5,
    typeExplanation:
      'deDSNT는 탈수성(Dehydration), 건성(Dry), 민감성(Sensitive), 비색소침착성(Nonpigmented), 탄력성(Tight) 피부를 의미합니다.',
    explanation: [
      '건조함이 자주 느껴지며 피부가 예민하고 각질이 보이는 편입니다.',
      '주로 큰 여드름보다는 좁쌀 여드름이 나타날 가능성이 높습니다.',
      '습진, 건조함, 가려움, 각질, 붉음증 등의 피부 문제를 경험할 수 있습니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '피부 톤이 어둡지 않고 일정하며, 주름/잔주름이 적은 피부입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  deDRNW: {
    typeJewelry: '블루레이스',
    type: '건조와 주름이 생기기 쉬우나 블루레이스와 같은 맑은 톤이 돋보이는 피부입니다.',
    careRoutine: [
      '피부 노화 방지를 위해 충분한 유수분 보충과 보습, 주름 예방 케어에 신경써주세요.',
      '피부의 수분이 부족하므로 수분 보충이 우선적으로 필요합니다.',
      '피부 바깥층에서 수분 손실을 방지할 능력이 부족한 상태이므로, 수분과 유분을 모두 보충하는 보습제 사용을 권장합니다.',
      '항산화제와 자외선 자단체 매일 사용을 권장하며, 야간 레티노이드 등이 포함된 주름 개선 제품을 사용하세요.',
      '건조한 피부를 관리하기 위해 유분 보충 보습제의 사용은 필수입니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 탄력이 부족한 편에 속합니다. 유수분 밸런스와 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #잔주름', ' #고른 피부톤'],
    typeComment:
      '피부 탄력을 위한 관리와 유수분 보충이 필요하지만, 피부톤이 고른 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'deDRNW는 탈수분성(Dehydrated), 건성(Dry), 저항(Resistive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다\n',
    explanation: [
      '피부 표면의 유분이 부족해 수분이 증발하기 쉬워 얼굴이 건조해지며, 잔주름이 생기며 탄력이 떨어지는 피부입니다.',
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '큰 트러블 없이 탄력을 유지해 왔던 피부이나, 시간이 지날수록 건조함과 얼굴에 발생하는 잔주름이 고민이 되는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
    ],
  },
  deDSNW: {
    typeJewelry: '아라고나이트',
    type: '다소 민감하지만, 탄력과 유·수분 관리로 맑은 톤을 돋보이게 할 수 있는 원석과 같은 피부입니다.',
    careRoutine: [
      '피부의 수분이 부족하므로 수분 보충이 우선적으로 필요하며, 유분 보충도 함께 케어해야 합니다.',
      '피부 장벽기능이 약한상태로 염증 반응이 자주 발생할 수 있기 때문에 피부 장벽 기능 개선 관리가 필요합니다.',
      '피부장벽재생에 도움이 되는 보습제, 항산화제나 항염증제재 제품을 사용해주세요.',
      '자극적이지 않은 탄력 개선 화장품 사용을 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정과 유수분 밸런스를 통한 피부 탄력 관리가 필요합니다.',
    ],
    typeTags: ['#매우 건조함', ' #민감', ' #주름'],
    typeComment:
      '고른 피부톤을 갖고 있지만, 유수분 보충과 염증성 트러블 및 피부 탄력 관리에 관심이 필요한 피부입니다.',
    typeRatio: 3.0,
    typeExplanation:
      'deDSNW는 탈수성(Dehydrated), 건성(Dry), 민감(Sensitive), 비색소(Nonpigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '건조함이 자주 느껴지며 피부가 예민하고 각질이 보이는 편입니다.',
      '습진, 건조함, 가려움, 각질, 붉음증 등의 피부 문제를 경험할 수 있습니다.',
      '주로 큰 여드름보다는 좁쌀 여드름이 나타날 가능성이 높습니다.',
      '피부 표면의 유분이 부족해 수분이 증발하기 쉬워 얼굴이 건조해지며, 잔주름이 생기며 탄력이 떨어지는 피부입니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '나이 들수록 건조함과 잔주름이 고민이 되는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '햇빛에 피부가 타기보다는 붉어지는 편입니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
    ],
  },
  deDRPT: {
    typeJewelry: '블랙스피넬',
    type: '색소 침착이 고민되지만 탄력 있는 아름다움을 가진 블랙스피넬과 닮았네요.',
    careRoutine: [
      '피부의 수분이 부족하므로 수분 보충이 우선적으로 필요합니다.',
      '피부 바깥층에서 수분 손실을 방지할 능력이 부족한 상태이므로, 수분과 유분을 모두 보충하는 보습제 사용을 권장합니다.',
      '고르지 못한 피부톤을 맑고 투명하게 개선하기 위해 자외선 차단제와 미백 제품을 사용해주세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬운 편에 속합니다. ',
      '유수분 밸런스와 피부 미백 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #기미, 주끈깨', ' #각질'],
    typeComment:
      '피부 탄력은 좋지만, 기미나 흉터로 인한 색소침착에 대한 관리와 유수분 보충이 필요한 피부입니다.',
    typeRatio: 1.5,
    typeExplanation:
      'deDRPT는 탈수분성(Dehydrated), 건성(Dry), 저항(Resistive), 색소(Pigmented), 탄력(Tight) 피부를 의미합니다',
    explanation: [
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '큰 트러블 없이 탄력을 유지해 왔던 피부이나, 시간이 지날수록 건조함과 얼굴에 발생하는 색소성 병변이 고민이 되는 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  deDSPT: {
    typeJewelry: '오닉스',
    type: '쉽게 민감해질 수 있지만, 탄력이 좋아 매끈한 오닉스와 같은 피부입니다.',
    careRoutine: [
      '피부 염증이 재발하고 피부 톤이 고르지 못한 것이 특징으로, 피부의 수분 보충과 유분 보충을 우선 집중해야 합니다.',
      '피부의 수분이 부족하므로 수분 보충에 힘써주세요.',
      '피부 바깥층에서 수분 손실을 방지할 능력이 부족한 상태이므로, 수분 보충 이후 보습을 유지하는 것이 중요합니다.',
      '피부 장벽기능이 약하거나 손상되기 쉬워 염증이나 알레르기 반응이 흔하므로 장벽기능 개선 관리가 필요합니다.',
      '건조함과 민감성을 낮추기 위해 순한 보습제, 항산화제, 항염증제재 사용을 권장합니다.',
      '피부의 유수분 보충과 함께 피부를 맑게 하는 미백 제품 사용 권장합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정 및 미백 관리가 필요합니다.',
    ],
    typeTags: ['#건조와 가려움', ' #가려움', ' #색소침착'],
    typeComment:
      '피부 탄력은 좋지만 유수분 보충과 염증성 트러블 및 색소침착에 대한 관리가 필요한 피부입니다.',
    typeRatio: 3.0,
    typeExplanation:
      'deDSPT는 탈수분성(Dehydrated), 건성(Dry), 민감(Sensitive), 색소침착(Pigmented), 탄력(Tight) 피부를 의미합니다.\n',
    explanation: [
      '건조함이 자주 느껴지며 피부가 예민하고 각질이 보이는 편입니다.',
      '주로 큰 여드름보다는 좁쌀 여드름이 나타날 가능성이 높습니다.',
      '습진, 건조함, 가려움, 각질, 붉음증 등의 피부 문제를 경험할 수 있습니다.',
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '나이 들수록 건조함과 얼굴에 발생하는 색소성 병변이 고민이 되는 피부입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '주름이 별로 없고, 피부는 탄력적입니다.',
    ],
  },
  deDRPW: {
    typeJewelry: '소다라이트',
    type: '세월의 흔적이 묻어있지만 개성 있고 아름다운 소다라이트와 닮았습니다.',
    careRoutine: [
      '건조하고 주름진 피부를 예방하고 치료하는 것이 중요합니다.',
      '피부의 수분이 부족하므로 수분 보충을 우선적으로 케어해주세요.',
      '피부 바깥층에서 수분 손실을 방지할 능력이 부족한 상태이므로, 수분 보충과 유분 보충이 가능한 보습제 사용을 권장합니다.',
      '고른 피부 톤으로 개선하기 위해 미백 제품과 자외선 차단제의 사용을 꾸준히 해주세요.',
      '주름 및 탄력 개선을 위해 항산화제 및 약한 AHA 함유 제품을 사용하세요.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 착색(색소 침착)이 일어나기 쉬우며 탄력이 부족한 편에 속합니다. ',
      '피부 미백과 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#건조함', ' #얇은 피부', ' #각질'],
    typeComment:
      '피부 트러블은 적은 편이지만 피부에 부족한 유수분을 보충하고 미백 및 탄력 관리가 필요한 피부입니다.',
    typeRatio: 0.5,
    typeExplanation:
      'deDRPW는 탈수분성(Dehydrated), 건성(Dry), 저항(Resistive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.\n',
    explanation: [
      '피부 표면의 유분이 부족해 수분이 증발하기 쉬워 얼굴이 건조해지며, 잔주름이 생기며 탄력이 떨어지는 피부입니다.',
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '나이가 들수록 광노화로 인한 갈색 반점 등의 다양한 색소 병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '큰 트러블 없던 피부이나, 시간이 지날수록 건조함과 얼굴에 발생하는 색소성 병변, 잔주름이 고민이 되는 피부입니다.',
      '트러블이 잘 발생하지 않고 피부톤이 균일하고 맑은 피부입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
    ],
  },
  deDSPW: {
    typeJewelry: '매화석',
    type: '까다롭지만 그만큼 충분히 빛날 준비가 되어있는 매화석같은 피부입니다.',
    careRoutine: [
      '피부의 수분이 부족하므로 수분 보충이 우선적으로 필요하며, 유분 보충도 함께 케어해야 합니다.',
      '피부 장벽기능이 약한상태로 염증 반응이 자주 발생할 수 있기 때문에 피부 장벽 기능 개선 관리 필요합니다.',
      '자극적이지 않은 미백, 탄력 개선 화장품 사용을 권장합니다.',
      '건조함과 염증을 악화시키지 않고 색소침착과 주름을 치료하는 피부관리 요법이 필요합니다.',
    ],
    careSummary: [
      '테스트 결과에 따르면 이 피부타입은 상대적으로 민감한 편에 속합니다. ',
      '피부 진정 관리가 우선 필요하며, 단계적으로 미백과 피부 탄력 개선을 위한 안티에이징 관리가 필요합니다.',
    ],
    typeTags: ['#건조함, 푸석푸석함', ' #좁쌀 여드름', ' #민감'],
    typeComment:
      '피부의 유수분 보충과 염증성 트러블 관리를 우선 집중하고, 단계적으로 피부 미백과 탄력 개선에 관심이 필요한 피부입니다.',
    typeRatio: 2.0,
    typeExplanation:
      'deDSPW는 탈수성(Dehydrated), 건성(Dry), 민감(Sensitive), 색소(Pigmented), 주름(Wrinkle prone) 피부를 의미합니다.',
    explanation: [
      '건조함이 자주 느껴지며 피부가 예민하고 각질이 보이는 편입니다.',
      '주로 큰 여드름보다는 좁쌀 여드름이 나타날 가능성이 높습니다.',
      '습진, 건조함, 가려움, 각질, 붉음증 등의 피부 문제를 경험할 수 있습니다.',
      '피부 표면의 유분이 부족해 수분이 증발하기 쉬워 얼굴이 건조해지며, 잔주름이 생기며 탄력이 떨어지는 피부입니다.',
      '피부의 유분과 수분이 모두 부족하여 건조함을 크게 느끼고 푸석푸석하다고 느끼는 피부입니다.',
      '피부가 건조하고 각질이 일어날 수 있습니다.',
      '얼굴 붉음과 홍조 또는 지루성 피부염이 있고 화장품에 의한 트러블이나 작열감이 있을 수 있습니다.',
      '피부가 예민하여 화장품에 의한 발적, 작열감, 화끈거림, 가려움, 따끔거림, 발진 또는 알레르기성 접촉 피부염과 같은 증상을 경험할 수 있습니다.',
      '피부의 수분이 부족해지면 잔주름이 심해질 수 있습니다.',
      '나이 들수록 건조함과 얼굴에 발생하는 색소성 병변, 잔주름이 고민이 되는 피부입니다.',
      '나이가 들수록 광노화로 인한 갈색반점 등의 다양한 색소병변이 관찰되고, 피부가 얇아지며 탄력이 떨어져 주름/잔주름이 관찰됩니다.',
      '피부톤은 맑고 고른 편이지만, 종종 건조함을 느낄 수 있고 잔주름이 생기기 쉬운 피부타입입니다.',
      '피부의 유분이 부족하여 수분이 증발하기 쉬운 상태입니다.',
      '화장품에 의해 피부에 트러블, 발진이 일어나거나 작열감 또는 알레르기성 반응이 있을 수도 있습니다.',
      '얼굴에 다양한 갈색반과 기미, 다크 써클, 염증이나 상처에 의한 착색 병변 발생할 수 있습니다.',
      '피부 탄력이 떨어지고, 눈가에 잔주름이 보일 수 있습니다.',
    ],
  },
};
export default data;
