import React from 'react';
import { Box, Container, makeStyles, Theme, Typography } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'transparent',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '6.8rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      marginTop: '13.4rem',
    },
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    marginBottom: '4.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '3.5rem',
      marginBottom: '5.1rem',
    },
  },
  body: {
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '2.1rem',
    color: '#6A6A6A',
    marginBottom: '18.9rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      lineHeight: '3rem',
    },
  },
  Button: {
    cursor:'pointer',
    width:'250px',
    margin:'2rem auto 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '4rem',
    fontSize: '1.7rem',
    fontWeight: 500,
    border: '0.1rem solid #000',
    borderRadius: '0.5rem',
  },
}));

function SentEmailPage(): JSX.Element {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography className={classes.title}>감사합니다.</Typography>
      <Typography className={classes.body}>
        앞으로 더 좋은 솔루션을 찾기 위해
        <br />
        노력하고 다시 돌아오겠습니다 :-)
      </Typography>
      <Box className={classes.Button} onClick={()=>{history.back()}}>
        이전 페이지로 돌아가기
      </Box>
    </Container>
  );
}

export default SentEmailPage;
