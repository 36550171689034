import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Consts, Routes } from '../../../config';
import {
  authCredentialState,
  authUserState,
  errorDialogButtonTextState,
  errorMessageState,
  paymentCouponState,
  paymentPriceState,
  selectedFirstDeliveryDateState,
  selectedMembershipProductState,
  solutionProductsState,
  subscriptionState,
} from '../../states';
import { Container, makeStyles, Theme, Typography } from '@material-ui/core';
import { RemoteUser } from '../../../data/repositories/RemoteUser';
import ErrorDialog from '../../components/ErrorDialog';
import OrderComplete from '../../order/OrderComplete';
import { Subscription } from '../../../domain/entities/Subscription';
import AlertDialog from '../../components/AlertDialog';
import PaymentLoading from '../../order/PaymentLoading';
import { PromotionCodeInputSection } from './PromotionCodeInputSection';
import { PaymentFlowHeader } from '../PaymentFlowHeader';
import { PricingBox } from './PricingBox';
import { PaymentMethod } from '../../../domain/entities/PaymentMethod';
import { SelectPaymentMethodSection } from './SelectPaymentMethodSection';
import BootPay from 'bootpay-js';
import { ProductInfo } from '../address/ProductInfo';
import { AddressBox } from './AddressBox';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',

    [theme.breakpoints.up('sm')]: {
      width: '534px',
      borderRadius: '5rem',
      boxShadow: '0px 5px 23px rgba(0, 0, 0, 0.05)',
      marginBottom: '104px',
    },
  },

  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },

  bigDivider: {
    backgroundColor: '#F6F6F6',
    margin: '41px 0 30px',
    width: '100%',
    height: '10px',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  bottomText: {
    display: 'block',
    textAlign: 'center',
    color: '#578FFC',
    fontSize: '1.2rem',
    margin: '74px auto 0',
  },

  button: {
    'display': 'block',
    'width': 'calc(100% - 32px)',
    'height': '4rem',
    'margin': '17px 16px 10rem',
    'borderRadius': '5.5rem',
    'border': '2px solid black',
    'color': 'black',
    'fontSize': '1.7rem',
    'fontWeight': 500,
    'background': 'none',
    'cursor': 'pointer',

    [theme.breakpoints.up('sm')]: {
      width: '36.9rem',
      height: '4.9rem',
      fontWeight: 'bold',
      borderRadius: '4.2rem',
      border: '1px solid black',
      margin: '17px auto 10rem',
    },

    '&:disabled': {
      border: '2px solid #9C9C9C',
      color: '#9C9C9C',
    },
  },
}));

export default function PaymentPage() {
  const history = useHistory();
  const classes = useStyles();

  const authUser = useRecoilValue(authUserState);
  const authCredential = useRecoilValue(authCredentialState);
  const solutionProducts = useRecoilValue(solutionProductsState);
  const [subscription, setSubscription] = useRecoilState(subscriptionState);
  const paymentPrice = useRecoilValue(paymentPriceState);
  const paymentCoupon = useRecoilValue(paymentCouponState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const setErrorDialogButtonText = useSetRecoilState(
    errorDialogButtonTextState,
  );
  const selectedMembershipProduct = useRecoilValue(
    selectedMembershipProductState,
  );

  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [redirectionPage, setRedirectionPage] = useState('');
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(true);
  const [paid, setPaid] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);

  const isSubscriber = Subscription.isActive(subscription);

  const firstDeliveryDate = useRecoilValue(selectedFirstDeliveryDateState);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(undefined);

  useEffect(() => {
    if (!selectedMembershipProduct || !firstDeliveryDate) {
      setErrorMessage('결제 진행 정보가 없습니다. 다시 시도해 주세요.');
      setRedirectionPage(Routes.skinQuizSolution);
    }
  }, [
    firstDeliveryDate,
    selectedMembershipProduct,
    setErrorMessage,
    setRedirectionPage,
  ]);

  function handleAlertDialogClose() {
    history.push(Routes.mypageSubscription);
  }

  const handleErrorDialogClose = () => {
    setErrorMessage('');
    setErrorDialogButtonText('');
    if (redirectionPage) {
      if (redirectionPage.startsWith('https')) {
        window.open(redirectionPage);
      } else {
        history.replace(redirectionPage);
      }
    }
    setRedirectionPage('');
  };

  const onPayClicked = async () => {
    if (isProcessingPayment) {
      return;
    }

    if (paymentPrice.total <= 0) {
      await doFreePayment();
    } else {
      await doNormalPayment();
    }
  };

  async function doFreePayment() {
    try {
      await completeFreePayment(subscription.subscriptionId);
    } catch (err) {
      if (err.code === Consts.ERROR_INVALID_SUBSCRIPTION) {
        setErrorMessage(
          '유효하지 않은 구독 상태입니다. MY 페이지로 안내합니다.',
        );
        setRedirectionPage(Routes.mypage);
      } else if (err.code === Consts.ERROR_INVALID_ORDER) {
        setErrorMessage(
          '유효하지 않은 주문 상태입니다. MY 페이지로 안내합니다.',
        );
        setRedirectionPage(Routes.mypage);
      } else if (err.code == Consts.ERROR_NOT_FOUND_SUBSCRIPTION) {
        setErrorMessage(
          '구독 정보에 오류가 발생했습니다. 고객센터로 문의주세요.',
        );
      } else if (err.code == Consts.ERROR_NOT_FOUND_ORDER) {
        setErrorMessage(
          '주문 정보에 오류가 발생했습니다. 고객센터로 문의주세요.',
        );
      } else {
        setErrorMessage(
          '서버에 문제가 발생했습니다. 잠시 후 다시 시도해주세요. 문제가 지속되면 고객센터로 문의주세요.',
        );
      }
    } finally {
      setIsProcessingPayment(false);
    }
  }

  async function completeFreePayment(
    subscriptionId: string,
    receiptId?: string,
  ) {
    setIsProcessingPayment(true);
    const repo = new RemoteUser(authCredential);
    await repo.createSubscriptionBilling(subscriptionId, receiptId);
    setPaid(true);
    // Enhanced Ecommerce
    window['dataLayer'].push({ ecommerce: null });
    window['dataLayer'].push({
      event: 'payCompleted',
      ecommerce: {
        purchase: {
          actionField: {
            id: subscriptionId,
            revenue: paymentPrice.total.toString(),
            coupon: paymentCoupon,
          },
          products: solutionProducts
            ?.filter(
              (item) =>
                item.type !== 'subscription' &&
                item.type !== 'subscriptionItem',
            )
            .map((item) => {
              return {
                name: item.name,
                id: item.id,
                quantity: 1,
              };
            }),
        },
      },
    });

    repo
      .getSubscription(subscriptionId)
      .then((subscription) => {
        setSubscription(subscription);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function completePayment(subscriptionId: string, receiptId?: string) {
    setIsProcessingPayment(true);
    const repo = new RemoteUser(authCredential);
    setPaid(true);
    // Enhanced Ecommerce
    window['dataLayer'].push({ ecommerce: null });
    window['dataLayer'].push({
      event: 'payCompleted',
      ecommerce: {
        purchase: {
          actionField: {
            id: subscriptionId,
            revenue: paymentPrice.total.toString(),
            coupon: paymentCoupon,
          },
          products: solutionProducts
            ?.filter(
              (item) =>
                item.type !== 'subscription' &&
                item.type !== 'subscriptionItem',
            )
            .map((item) => {
              return {
                name: item.name,
                id: item.id,
                quantity: 1,
              };
            }),
        },
      },
    });

    repo
      .getSubscription(subscriptionId)
      .then((subscription) => {
        setSubscription(subscription);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function doNormalPayment() {
    const price = paymentPrice.total;
    const applicationId = process.env.REACT_APP_APPLICATION_ID;
    const name = selectedMembershipProduct.name;
    const method =
      paymentMethod === PaymentMethod.CARD
        ? 'card'
        : paymentMethod === PaymentMethod.KAKAO_PAY
        ? 'kakao'
        : 'npay';
    const items = solutionProducts
      ?.filter(
        (item) =>
          item.type !== 'subscription' && item.type !== 'subscriptionItem',
      )
      .map((item) => {
        return {
          item_name: item.name,
          qty: 1,
          unique: item.id.toString(),
          price: 0,
        };
      });
    const subscriptionId = subscription.subscriptionId;
    const orderId = `${authUser?.userId}:${subscription.invoiceId}`;
    const userInfo = {
      username: authUser?.displayName,
      email: authUser?.email,
      phone: authUser?.phoneNumber,
    };

    try {
      // 실제로 subscriptionBilling을 생성하려는 목적이 아닌,
      // 금액이 청구되기 전 다른 에러들이 없나 체크하는 용도
      const repo = new RemoteUser(authCredential);
      await repo.createSubscriptionBilling(subscriptionId, 'dummy_receipt_id');
    } catch (err) {
      if (err.code !== Consts.ERROR_INVALID_RECEIPT_ID) {
        // 예상치 못한 우리 서버 쪽 에러가 발생했으니 결제를 중단한다. (e.g. 인증 토큰 만료)
        setErrorMessage(
          <>
            [{err.code}]
            <br />
            <strong>예상치 못한 오류가 발생하였습니다.</strong>
            <br />
            이용에 불편을 드려 죄송합니다.
            <br />
            카카오톡 고객센터로 문의해 주시면 바로 도와드리겠습니다.
          </>,
        );
        setErrorDialogButtonText('문의하기');
        setRedirectionPage('https://pf.kakao.com/_cSxaxoxb/chat');
        return;
      }
    }

    // 결제를 진행한다.
    BootPay.request({
      price: price,
      application_id: applicationId,
      name: name,
      pg: 'nicepay',
      method: method,
      items: items,
      order_id: orderId,
      user_info: userInfo,
    })
      .confirm(async (data) => {
        BootPay.transactionConfirm(data);
      })
      .done(async (data) => {
        try {
          await completePayment(subscriptionId, data?.receipt_id);
        } finally {
          setIsProcessingPayment(false);
        }
      })
      .error((data) => {
        console.error(data);
        setErrorMessage(
          data?.msg ??
            '예상치 못한 오류가 발생했습니다. 새로고침 후 다시 시도해주세요.',
        );
      });
  }

  useEffect(() => {
    if (!authCredential) {
      history.push(Routes.skinQuizLogin);
    }

    if (isSubscriber) {
      setAlertOpen(true);
      return;
    }
  }, [authCredential, history, isSubscriber]);

  useEffect(() => {
    async function createSubscription(
      membershipProductId: string,
      firstDeliveryDate: Date,
    ): Promise<Subscription> {
      const orderItems = solutionProducts
        ?.filter(
          (item) =>
            item.type !== 'subscription' && item.type !== 'subscriptionItem',
        )
        .map((item) => {
          return { productId: item.id, productName: item.name, quantity: 1 };
        });
      const repo = new RemoteUser(authCredential);
      try {
        return await repo.createSubscription(
          membershipProductId,
          orderItems,
          firstDeliveryDate,
          paymentCoupon,
        );
      } catch (err) {
        if (err.code === Consts.ERROR_SUBSCRIPTION_ALREADY_EXISTS) {
          setAlertOpen(true);
          setRedirectionPage(Routes.mypage);
        } else if (err.code === Consts.ERROR_INVALID_COUPON) {
          setErrorMessage(
            `유효하지 않은 프로모션 코드 입니다. [${paymentCoupon}: ${err.message}]`,
          );
          setRedirectionPage(Routes.skinQuizSolution);
        } else if (err.code === Consts.ERROR_COUPON_EXPIRED) {
          setErrorMessage('프로모션 코드 유효기간이 지났습니다.');
          setRedirectionPage(Routes.skinQuizSolution);
        } else if (err.code === Consts.ERROR_COUPON_EXCEED_LIMIT) {
          setErrorMessage('프로모션 코드 사용횟수를 초과했습니다.');
          setRedirectionPage(Routes.skinQuizSolution);
        } else if (err.code === Consts.ERROR_COUPON_INVALID_PRODUCT) {
          setErrorMessage(
            <>
              해당 멤버십 플랜에는
              <br />
              사용할 수 없는 프로모션 코드입니다.
            </>,
          );
        } else if (err.code === Consts.ERROR_COUPON_INVALID_EMAIL) {
          setErrorMessage('프로모션 코드 사용 대상자가 아닙니다.');
        } else {
          setErrorMessage(
            '서버에 문제가 발생했습니다. 잠시 후 다시 시도해주세요. 문제가 지속되면 고객센터로 문의주세요.',
          );
        }
      }
    }

    if (!selectedMembershipProduct || !firstDeliveryDate) {
      return;
    }

    setIsCreatingSubscription(true);
    createSubscription(
      selectedMembershipProduct.id.toString(),
      firstDeliveryDate,
    )
      .then((newSubscription) => {
        setSubscription(new Subscription(newSubscription));
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsCreatingSubscription(false);
      });
  }, [
    authCredential,
    firstDeliveryDate,
    paymentCoupon,
    selectedMembershipProduct,
    setErrorMessage,
    setSubscription,
    solutionProducts,
  ]);

  if (paid) {
    return <OrderComplete />;
  } else if (isCreatingSubscription || isProcessingPayment) {
    return (
      <Container maxWidth="lg" className={`${classes.loading}`}>
        <PaymentLoading />
        <ErrorDialog onClose={handleErrorDialogClose} />
      </Container>
    );
  }

  return (
    <main className={classes.root}>
      <PaymentFlowHeader highlightCount={2} />
      <ProductInfo />
      <AddressBox />
      <PromotionCodeInputSection />
      <PricingBox
        title={selectedMembershipProduct?.name}
        hasCouponUsed={paymentCoupon ? true : false}
      />
      <div className={classes.bigDivider} />
      <SelectPaymentMethodSection
        setPaymentMethod={setPaymentMethod}
        selectedPaymentMethod={paymentMethod}
      />
      <Typography className={classes.bottomText}>
        주문 내용을 확인하였으며, 결제에 동의합니다
      </Typography>
      <button
        className={classes.button}
        disabled={
          paymentPrice.total > 0 &&
          (paymentMethod === undefined || subscription === undefined)
        }
        onClick={onPayClicked}
      >
        결제하기
      </button>
      <AlertDialog
        open={alertOpen}
        message={
          <p>
            이미 멤버십 회원입니다.
            <br />
            MY 멤버십 페이지에서,
            <br />
            이용중인 혜택을 확인해 보세요.
          </p>
        }
        onClose={handleAlertDialogClose}
      />
      <ErrorDialog onClose={handleErrorDialogClose} />
    </main>
  );
}
