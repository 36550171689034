import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

// lodash => throttle 적용
export default function useScroll() {
  const [scrollY, setScrollY] = useState<number>(0);

  const listener = () => {
    setScrollY(window.pageYOffset);
  };

  useEffect(() => {
    const throttledFn = throttle(listener);
    window.addEventListener('scroll', throttledFn, { passive: true });
    return () => {
      window.removeEventListener('scroll', throttledFn);
    };
  });

  return {
    scrollY,
  };
}
