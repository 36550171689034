import React, { useEffect } from 'react';
import {
  Content,
  PageTitle,
  IconOptionButton,
  IconOptionButtonBlock
} from '../StyledComponents';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../config';
import { QuestionProps } from './QuestionProps';
import { getPngImageSrc } from '../styles';

function IconQuestion({
  question,
  answerSheet,
  setAnswerSheet,
}: QuestionProps) {
  const history = useHistory();
  const name = answerSheet.question1;
  const selected = answerSheet[`question${question.id}`];
    const maleDefault = getPngImageSrc('skinquiz/male_default');
    const maleSelected = getPngImageSrc('skinquiz/male_selected');
    const femaleDefault = getPngImageSrc('skinquiz/female_default');
    const femaleSelected = getPngImageSrc('skinquiz/female_selected');
  useEffect(() => {
    if (!name) {
      history.push(Routes.skinQuizName);
    }
  }, [history, name, answerSheet]);

  const onClick = ({ target }) => {
    setAnswerSheet({
      ...answerSheet,
      [`question${question.id}`]: parseInt(target.id),
    });
  };

  const getImageSrc = (gender:1|2, selected:boolean) =>{
    if (gender === 1) {//female 
        return selected ? femaleSelected : femaleDefault
    }
    else {//male 
        return selected ? maleSelected : maleDefault
    }
  }
  return (
    <Content>
      <PageTitle>{`${question.title.replace(
        '$name',
        answerSheet?.question1.toString(),
      )}`}</PageTitle>
      <IconOptionButtonBlock>
          {
            question.options.map(elem => {
                const id = elem.id
                return (
                <IconOptionButton
                key={id}
                id={id.toString()}
                onClick={onClick}
                src={getImageSrc(id as 1|2, id === selected)}
            />)
            })
        }
        </IconOptionButtonBlock>
    </Content>
  );
}

export default IconQuestion;