import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { authUserState } from '../../states';
import { Collapse } from 'react-collapse';
import { ToggleArrow2 } from '../../components/ToggleArrow2';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: '0px 46px 35px',
    fontSize: '1.4rem',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      margin: '0px 85px 35px',
      fontSize: '1.6rem',
    },
  },

  title: {
    margin: '0px 0px 8px',
    fontSize: '1.8rem',
    fontWeight: 700,
  },

  name: {
    width: '100%',
    top: '64px',
    left: '51px',
    fontSize: '16px',
    padding: '10px 3px',
    display: 'flex',
  },

  address: {
    width: '100%',
    color: 'rgba(156,156,156,1)',
    top: '89px',
    left: '51px',
    display: 'flex',
    fontSize: '14px',
    paddingLeft: '3px',
  },

  detail: {
    color: 'rgba(156,156,156,1)',
    fontSize: '14px',
    width: '400px',
    paddingTop: '7px',
    paddingLeft: '3px',
  },

  arrow: {
    position: 'absolute',
    cursor: 'pointer',
    top: '0px',
    right: '20px',
  },

  foldIcon: {
    boxSizing: 'content-box',
    transition: 'transform 0.5s ease',
    cursor: 'pointer',

    width: '2.8rem',
    height: '1.9rem',
    padding: '45px',
  },

  rotated: {
    transform: 'rotateZ(180deg)',
  },
}));

export const AddressBox = (): JSX.Element => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');

  const [authUser] = useRecoilState(authUserState);
  useEffect(() => {
    const { recipientName, recipientPhoneNumber, address1, address2 } =
      authUser?.address || {};
    setName(recipientName);
    setPhoneNumber(recipientPhoneNumber || authUser?.phoneNumber);
    setAddress1(address1);
    setAddress2(address2);
  }, [authUser?.address, authUser?.displayName, authUser?.phoneNumber]);

  const [isBoxShown, setIsBoxShown] = useState(false);

  return (
    <section className={classes.root}>
      <span className={classes.title}>배송지</span>
      <span className={classes.name}>
        {name}({phoneNumber})
      </span>
      <span className={classes.address}>{address1}</span>
      {isBoxShown && <div className={classes.detail}>{address2}</div>}
      <ul className={classes.arrow}>
        <li onClick={() => setIsBoxShown(!isBoxShown)}>
          <Collapse isOpened={true}>
            <ToggleArrow2 isOpened={isBoxShown} />
          </Collapse>
        </li>
      </ul>
    </section>
  );
};
