import React, { Dispatch, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MembershipProduct } from '../../../domain/entities/MembershipProduct';
import { MembershipProductItem } from './MembershipProductItem';
import { Collapse } from 'react-collapse';
import { Theme } from '@material-ui/core';
import { ToggleArrow } from '../../components/ToggleArrow';

type Props = {
  products: MembershipProduct[];
  selectedProduct: MembershipProduct | undefined;
  setSelectedProduct: Dispatch<MembershipProduct>;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'margin': '65px 16px 0px',

    [theme.breakpoints.up('sm')]: {
      margin: '92px 0px 0px 0px',
    },

    '& .ReactCollapse--collapse': {
      transition: 'height 250ms ease',
      marginBottom: '16px',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '25px',
      },
    },
  },

  title: {
    'fontSize': '2rem',
    'textAlign': 'center',
    'marginBottom': '12px',
    'lineHeight': '2.9rem',

    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '21px',
      lineHeight: '4.3rem',
    },

    '& > strong': {
      fontWeight: 'bold',
    },
  },

  subtitle: {
    fontSize: '1.2rem',
    textAlign: 'center',
    marginBottom: '28px',
    fontWeight: 'lighter',

    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
      marginBottom: '31px',
    },
  },

  qnaList: {
    'margin': '12px 13px 0px',
    'fontSize': '1.2rem',

    [theme.breakpoints.up('sm')]: {
      margin: '10px 38px 0px',
      fontSize: '1.5rem',
    },

    '& > li': {
      'cursor': 'pointer',
      'lineHeight': '140%',

      '& strong': {
        fontWeight: 'bold',
      },
    },

    '& .ReactCollapse--collapse': {
      transition: 'height 250ms ease',
      marginBottom: '13px',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '20px',
      },
    },

    '& p:first-child': {
      color: '#578FFC',
    },

    '& p:nth-child(2)': {
      fontWeight: 'lighter',
      paddingTop: '5px',
      paddingLeft: '1rem',
    },
  },
}));

export const MembershipProductsSection = ({
  products,
  selectedProduct,
  setSelectedProduct,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [isThirdQuestionOpen, setIsThirdQuestionOpen] = useState(false);

  return (
    <section className={classes.root}>
      <p className={classes.title}>
        <strong>매니폴드 스킨케어 멤버십</strong>으로
        <br />
        귀찮음을 해결하세요
      </p>
      {/* <p className={classes.subtitle}>*전 제품 무료배송 (일부 품목 제외)</p> */}
      {products?.map((value, index) => (
        <MembershipProductItem
          key={value.id}
          product={value}
          isBest={index === 1}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      ))}
      <ul className={classes.qnaList}>
        <li onClick={() => setIsThirdQuestionOpen(!isThirdQuestionOpen)}>
          <Collapse isOpened={true}>
            <p>
              <ToggleArrow isOpened={isThirdQuestionOpen} />
              선물권은 무엇인가요?
            </p>
            {isThirdQuestionOpen && (
              <p>
                소중한 다른 분에게 개인 맞춤형 스킨케어가 가능한{' '}
                <strong>매니폴드 스킨케어_Start권</strong>을 선물할 수 있는
                개인화된 프로모션 코드를 발급해드립니다.
              </p>
            )}
          </Collapse>
        </li>
      </ul>
    </section>
  );
};
