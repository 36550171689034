import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
import { Routes } from '../../config';
import FaceBookImg from '../../assets/image/facebook.png';
import KakaoImg from '../../assets/image/kakao.png';
import ShareImg from '../../assets/image/share.png';
import NoFeedback from '../../assets/image/noFeedBack.svg';
import Header, { DateLabel } from './resultpage/ResultHeader';

import {
  authCredentialState,
  authLoadingState,
  authUserState,
  quizBackgroundState,
  skinReportListState,
  skinQuizSolutionState,
  reportIndexState,
} from '../states';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Box,
  Container,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Button } from './StyledComponents';
import styled from 'styled-components';
import { RemoteQuiz } from '../../data/repositories/RemoteQuiz';
import loadingImage from '../../assets/image/manifold_loading.gif';
import {
  getBarDataObj,
  getNthRadarDataObj,
  SkinChartBox,
} from './resultpage/SkinChartBox';
import { SkinChartSummaryBox } from './resultpage/SkinChartSummaryBox';
import { RemoteUser } from '../../data/repositories/RemoteUser';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import HitmapImgSlider from './resultpage/HitmapImgSlider';
import ResultPage from './ResultPage';
import skinReportContents from '../../data/skinReportContents';
import { ResultScore } from '../../domain/entities/SkinDiagnosis';
import FramedImgBox from './resultpage/FramedImgBox';
import { skinScoreEngToKor } from '../utils/translateName';
import { IngredientRecommendForN } from './resultpage/IngredientRecommendBox';
import {
  getCautionIngredient,
  IngredientCautionBox,
} from './resultpage/IngredientCautionBox';
import { RoutineForN } from './resultpage/RoutineBox';
import { SurveyModalLeavingGuard } from './userSurvey/SurveyModalLeavingGuard';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: '36rem',
    padding: '3.7rem 1.6rem 2rem 1.6rem',
    color: '#000',
    wordBreak: 'keep-all',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#fff',
      maxWidth: '120rem',
      width: '120rem',
      padding: '4.5rem 30rem',
      marginBottom: '102px',
    },
  },

  shareBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },

  icon: {
    width: '4rem',
    height: '4rem',
  },

  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },

  headerTitle: {
    'fontSize': '2rem',
    'fontWeight': 'bold',
    'color': '#000',
    'marginTop': '1.2rem',
    'marginBottom': '1.5rem',
    '& p': {
      fontWeight: 'normal',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      marginBottom: '2.2rem',
    },
  },
  headerBodyStrong: {
    fontSize: '1.4rem',
    fontWeight: 500,
    lineHeight: '2rem',
    marginBottom: '1rem',
    color: '#6A6A6A',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      lineHeight: '2.6rem',
    },
  },
  headerBody: {
    fontSize: '1.2rem',
    fontWeight: 400,
    lineHeight: '1.8rem',
    color: '#6A6A6A',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
      lineHeight: '2.7rem',
    },
  },
  noFeedbackImg: {
    width: '163px',
    margin: '40px 0',
    [theme.breakpoints.up('sm')]: {
      width: '231px',
      margin: '74px 0',
    },
  },
  shareFont: {
    fontSize: '1.3rem',
    fontWeight: 400,
    lineHeight: '2rem',
    marginBottom: '1.2rem',
    color: '#6A6A6A',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.4rem',
      marginBottom: '6rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },
  shareTitle: {
    fontSize: '1.7rem',
    fontWeight: 'bold',
    lineHeight: '2rem',
    marginTop: '4rem',
    marginBottom: '2rem',
    color: '#050505',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      color: '#050505',
      fontWeight: 'bold',
      fontSize: '2.6rem',
      marginTop: '6rem',
      marginBottom: '4rem',
      lineHeight: '2.7rem',
      textAlign: 'center',
    },
  },

  title: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#000',
    marginBottom: '5px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginBottom: '9px',
    },
  },

  ctaButton: {
    height: '4.8rem',
    width: '100%',
    maxWidth: '356px',
    background: 'linear-gradient(90deg, #8EE4FF 7.93%, #8BB3FF 94.05%)',
    borderRadius: '2px',
    borderWidth: '0px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#fff',
    margin: '4rem 0',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      margin: '4.5rem auto',
    },
  },

  modalRoot: {
    transform: 'translateZ(0)',
  },
  iconBox: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1rem 2 2.6rem 0',
    width: '20.3rem',
    [theme.breakpoints.up('sm')]: {
      width: '30rem',
    },
  },
  modalBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    minWidth: '32.8rem',
    maxWidth: '60rem',
    [theme.breakpoints.up('sm')]: {},
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '5rem 0',
    [theme.breakpoints.up('sm')]: {},
  },
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'stretch',
    textAlign: 'center',
    padding: '1rem 3rem',
    [theme.breakpoints.up('sm')]: {
      padding: '1rem 15rem',
    },
  },
  termsBox: {
    display: 'flex',
    fontSize: '1.2rem',
    justifyContent: 'center',
    alignItems: 'center',
    verticalAlign: 'bottom',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
    },
  },
  emailInput: {
    backgroundColor: '#F0F0F0',
    margin: '1rem 0',
    textAlign: 'center',
    fontSize: '1.2rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.7rem',
    },
  },

  modalButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '4rem',
    fontSize: '1.7rem',
    fontWeight: 500,
    border: '0.1rem solid #000',
    borderRadius: '5.5rem',
    marginTop: '2rem',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

  shareButton: {
    width: '20.4rem',
    height: '4rem',
    border: '0.22rem solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5.5rem',
    marginTop: '2rem',
    marginBottom: '5rem',
    fontSize: '1.5rem',
    fontWeight: 500,
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '25.6rem',
      height: '4.5rem',
      fontSize: '2rem',
      fontWeight: 500,
      justifyContent: 'center',
    },
  },
  boxRoot: {
    width: '28.4rem',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '50.6rem',

      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  boxShareUrl: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  divider: {
    margin: '1rem 3.3rem',
    [theme.breakpoints.up('sm')]: {
      margin: '1rem 5.7rem',
    },
  },
}));

const KakaoIcon = styled.img`
  width: 40px;
  margin-bottom: 6px;
  cursor: pointer;
`;

const ExternalLinkIcon = styled.img`
  width: 40px;
  margin-bottom: 6px;
  cursor: pointer;
`;

const FaceBookIcon = styled.img`
  width: 40px;
  margin-bottom: 6px;
  cursor: pointer;
`;

FaceBookIcon.defaultProps = {
  src: FaceBookImg,
};

KakaoIcon.defaultProps = {
  src: KakaoImg,
};
ExternalLinkIcon.defaultProps = {
  src: ShareImg,
};

const NoFeedbackContent = (props: {
  index: number;
  userName: string;
  deadline: number;
}) => {
  const classes = useStyles();
  const history = useHistory();
  function CTAButton({ text }): JSX.Element {
    return (
      <Button
        className={classes.ctaButton}
        onClick={() =>
          history.push(`${Routes.skinQuizSolution}/:${props.index}`)
        }
        isValid
      >
        {text}
      </Button>
    );
  }
  const dateNumber = props.deadline;
  const reportDate = new Date(dateNumber);
  return (
    <Container maxWidth="lg" className={classes.root}>
      <Box className={classes.header} style={{ border: 'none' }}>
        <DateLabel date={reportDate} />
        <Typography className={classes.headerTitle}>
          {props.userName}님
          <span style={{ fontWeight: 'normal' }}>을 위한 피부 리포트</span>
        </Typography>
        <Typography
          style={{ color: '#4B515B' }}
          className={classes.headerBodyStrong}
        >
          피부기록 업데이트를 하지 않으셨어요
        </Typography>
        <Typography className={classes.headerBody}>
          피부기록 업데이트를 하지 않으신 경우
          <br />
          지난 회차와 동일한 제품이 제공됩니다
        </Typography>
        <img src={NoFeedback} className={classes.noFeedbackImg} />
        <Typography className={classes.headerBodyStrong}>
          피부기록을 업데이트하고
          <br />
          피부변화를 분석한 피부 리포트를 받아보세요
        </Typography>
        <CTAButton text="스킨케어 제품 확인하기" />
      </Box>
    </Container>
  );
};

const FeedbackContent = (props: { index: number; dataIndex: number }) => {
  const history = useHistory();
  const classes = useStyles();
  const skinReportList = useRecoilValue(skinReportListState);
  const skinReports = skinReportList?.reports;
  const {
    axisScore,
    axisType,
    skinType,
    goodIngredients,
    careIngredients,
    careSkinType,
    careRoutines,
    axisPhoto,
  } = skinReportList?.reports[props.dataIndex]?.diagnosis || {};
  const skinReport = skinReports[props.dataIndex];
  const createdAt = skinReport?.createdAt;
  const dateNumber = skinReport.deadline;
  const skinQuizSolution = useRecoilValue(skinQuizSolutionState);
  const userName = skinQuizSolution?.userName;
  const radarScore = new Map<string, ResultScore>();
  const barScore = new Map<string, ResultScore>();
  const reportDate = new Date(dateNumber);
  const authCredential = useRecoilValue(authCredentialState);
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const [whenState, updateWhenState] = useState(true);
  const cautionIngredient = getCautionIngredient(careIngredients, careSkinType);
  const reportIndex = { dataIndex: props.dataIndex, index: props.index };

  function CTAButton({ text }): JSX.Element {
    return (
      <Button
        className={classes.ctaButton}
        onClick={() =>
          history.push(`${Routes.skinQuizSolution}/:${props.index}`)
        }
        isValid
      >
        {text}
      </Button>
    );
  }

  useEffect(() => {
    if (authUser?.userId) {
      const repo = new RemoteUser(authCredential);
      repo
        .getUser(authUser?.userId)
        .then((value) => {
          if (value) {
            setAuthUser(value);
          } else {
            history.replace(Routes.mypageSkinQuiz);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      history.replace(Routes.mypageSkinQuiz);
    }
  }, [authCredential, authUser?.userId, history, setAuthUser]);

  useEffect(() => {
    if (authUser?.settings?.reportFeedbackTime) updateWhenState(false);
  }, [authUser]);

  Object.keys(skinScoreEngToKor).map((key) => {
    barScore[skinScoreEngToKor[key]] = {
      score: axisScore[key],
      result: axisType[key],
    };
    radarScore[skinScoreEngToKor[key]] = axisScore[key];

    delete barScore[key];
    delete radarScore[key];
  });

  const radarData = getNthRadarDataObj(
    skinReport,
    skinReports,
    reportIndex,
    radarScore,
    createdAt,
  );
  const barData = getBarDataObj(barScore);
  return (
    <>
      <LoadingScreen
        loading={!skinQuizSolution || !skinReportList}
        spinnerColor="#c4c4c4"
        logoSrc={loadingImage}
      >
        <SurveyModalLeavingGuard
          when={whenState}
          navigate={(path) => {
            history.push(path);
          }}
          shouldBlockNavigation={(location) => {
            if (
              !location.pathname.includes(Routes.skinQuizResult) &&
              !location.pathname.includes(Routes.skinQuizSolution) &&
              !location.pathname.includes(Routes.skinQuizUserSatisfactionSurvey)
            ) {
              return true;
            }
            return false;
          }}
        />
        <Container maxWidth="lg" className={classes.root}>
          <Header reportDate={reportDate} userName={userName} />
          <HitmapImgSlider axisPhoto={axisPhoto} />
          <SkinChartSummaryBox
            typeComment={skinReportContents[skinType].typeComment}
            skinType={skinType}
            userName={userName}
          />
          <SkinChartBox
            radarData={radarData}
            barData={barData}
            userName={userName}
          />
          <IngredientRecommendForN
            userName={userName}
            skinType={skinType}
            goodIngredients={goodIngredients}
          />
          <IngredientCautionBox
            userName={userName}
            skinType={skinType}
            cautionIngredient={cautionIngredient}
          />
          <RoutineForN userName={userName} careRoutines={careRoutines} />
          <FramedImgBox />
          <CTAButton text="확인" />
        </Container>
      </LoadingScreen>
    </>
  );
};

function NthResultPage({ match }): JSX.Element {
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('xs'));

  const setQuizBackground = useSetRecoilState(quizBackgroundState);
  const authUser = useRecoilValue(authUserState);
  const isAuthLoading = useRecoilValue(authLoadingState);
  const authCredential = useRecoilValue(authCredentialState);

  const [skinReportList, setSkinReportList] =
    useRecoilState(skinReportListState);
  const [skinQuizSolution, setSkinQuizSolution] = useRecoilState(
    skinQuizSolutionState,
  );
  const setReportIndex = useSetRecoilState(reportIndexState);
  const index = parseInt(match.params.index?.split(':')[1]);
  const dataIndex = skinReportList?.reports?.length + 1 - index;
  const userName = skinQuizSolution?.userName;
  const { deadline } = skinReportList?.reports[dataIndex] || {};
  useEffect(() => {
    if (typeof index === 'number' && typeof dataIndex === 'number')
      setReportIndex({ index: index, dataIndex: dataIndex });
  }, [index, dataIndex, setReportIndex]);

  useEffect(() => {
    if (!skinReportList && !isAuthLoading) {
      if (authUser?.userId) {
        const repo = new RemoteUser(authCredential);
        repo
          .getUserSkinReportList(authUser?.userId)
          .then((value) => {
            if (value) {
              setSkinReportList(value);
            } else {
              history.replace(Routes.skinQuiz);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        history.replace(Routes.mypageSkinQuiz);
      }
    }
    // mypageSkinQuiz
  }, [
    authCredential,
    authUser?.userId,
    history,
    isAuthLoading,
    match.params,
    setSkinReportList,
    skinReportList,
  ]);

  useEffect(() => {
    if (!skinQuizSolution?.diagnosis && !isAuthLoading) {
      if (authUser?.quizId) {
        const repo = new RemoteQuiz(authCredential);
        repo
          .getSkinQuizSolution(authUser?.quizId)
          .then((value) => {
            if (value) {
              setSkinQuizSolution(value);
            } else {
              history.replace(Routes.skinQuiz);
            }
          })
          .catch((err) => {
            console.error(err);
            history.replace(Routes.skinQuiz);
          });
      } else {
        history.replace(Routes.skinQuiz);
      }
    }
  }, [
    authCredential,
    authUser?.quizId,
    history,
    isAuthLoading,
    match.params,
    setSkinQuizSolution,
    skinQuizSolution?.diagnosis,
  ]);

  useEffect(() => {
    if (smDown) {
      setQuizBackground(QuizBackground.WHITE);
    } else {
      setQuizBackground(QuizBackground.GRADIENT);
    }
  }, [setQuizBackground, smDown]);
  return (
    <LoadingScreen
      loading={!skinQuizSolution || !skinReportList}
      spinnerColor="#c4c4c4"
      logoSrc={loadingImage}
    >
      {index === 1 ? (
        <ResultPage />
      ) : skinReportList?.reports[dataIndex]?.diagnosis?.axisScore ? (
        <FeedbackContent index={index} dataIndex={dataIndex} />
      ) : (
        <NoFeedbackContent
          index={index}
          userName={userName}
          deadline={deadline}
        />
      )}
    </LoadingScreen>
  );
}

export default NthResultPage;
