import { TotalQuizCount } from '../../domain/entities/Quiz';
import { SkinQuizResult } from '../../domain/entities/SkinQuizResult';
import { SkinQuizSolution } from '../../domain/entities/SkinQuizSolution';

export class RemoteQuizMapper {
  public static toSkinQuizResult(data: any): SkinQuizResult {
    return {
      quizId: data.quiz_id,
      diagnosis: data.diagnosis,
    };
  }
  public static toSkinQuizSolution(data: any): SkinQuizSolution {
    return {
      userName: data.userName,
      quizType: data.quizType,
      products: data.products,
      diagnosis: data.diagnosis,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
    };
  }
  public static toTotalQuizCount(data: any): TotalQuizCount {
    return {
      count: data.count,
    };
  }
}
