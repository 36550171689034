import { SkinQuizAnswerSheet } from '../entities/SkinQuizAnswerSheet';
import { SkinQuizResult } from '../entities/SkinQuizResult';
import { SkinQuizSolution } from '../entities/SkinQuizSolution';

export type QuizType = 'skin_email' | 'skin_simple';
export abstract class QuizRepository {
  public abstract submitSkinQuiz(
    answerSheet: SkinQuizAnswerSheet,
    quizType?: QuizType,
  ): Promise<SkinQuizResult>;

  public abstract getSkinQuizSolution(
    quizId: string,
  ): Promise<SkinQuizSolution>;

  public abstract sendSkinQuizResultEmail(
    quizId: string,
    email: string,
  ): Promise<void>;
}
