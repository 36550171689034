import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    justifyContent: 'unset',
  },
  border: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '348px',
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid transparent',
    borderRadius: '10px',
    boxShadow: '0px 5px 23px rgba(0, 0, 0, 0.05)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    marginBottom: '18.5px',
  },
  inactive: {
    backgroundImage:
      'linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(to right, #FFFFFF, #FFFFFF)',
  },
  active: {
    backgroundImage:
      'linear-gradient(#FFFFFF, #FFFFFF), linear-gradient(to right, #8EE4FF, #8BB3FF)',
  },
  innerBox: {
    width: '100%',
    padding: '20px 18px',
    textAlign: 'center',
  },
  question: {
    fontWeight: 'bold',
    fontSize: '1.3rem',
    lineHeight: '1.9rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    textAlign: 'center',
    wordBreak: 'keep-all',
  },
  input: {
    height: 200,
    fontSize: '1.2rem',
  },
  scoreButtonContainer: {
    width: '284px',
    padding: '7px 22px',
    marginBottom: '20px',
    display: 'inline-flex',
    justifyContent: 'space-between',
    borderRadius: '35px',
  },
  scoreButton: {
    fontSize: '1.1rem',
    width: '23px',
    height: '23px',
    borderRadius: '35px',
  },
  subtextBox: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: '14px',

    color: '#6A6A6A',
  },
  floatingSurveyBtn: {
    color: '#fff',
    maxWidth: '348px',
    border: 0,
    borderRadius: '2px',
    borderWidth: '0px',
    fontSize: '1.6rem',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    width: '100%',
    height: '4.8rem',
    marginTop: '-4.8rem' /*same as height*/,
    marginBottom: '20px' /*to avoid going under the bottom:20px we want*/,
    position: 'sticky',
    top: 'calc(100vh - 4.8rem - 20px)',
    zIndex: 2,
  },
  startBtn: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4.8rem',
    background: 'linear-gradient(#8EE4FF, #8BB3FF)',
    zIndex: 1,
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    borderWidth: '0px',
    whiteSpace: 'nowrap',
    width: '100%',
    margin: 'auto',
  },
  goBackBtn: {
    fontSize: '1.6rem',
    fontWeight: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '4.8rem',
    zIndex: 1,
    color: '#6A6A6A',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    borderWidth: '0px',
    whiteSpace: 'nowrap',
    width: '100%',
    margin: 'auto',
    textDecorationLine: 'underline',
  },
}));
