import React from 'react';
import downArrow from '../../../src/assets/image/home/img_down_arrow.png';
type Props = {
  isOpened: boolean;
};

export const ToggleArrow2 = ({ isOpened }: Props): JSX.Element => {
  return (
    <span
      style={{
        display: 'inline-block',
        marginRight: '0.3rem',
      }}
    >
      <img src={downArrow} />
    </span>
  );
};
