import { FAQItem } from './models/FAQ';
import faqimg from '../assets/image/booster_howtouse.jpg';

const data: FAQItem[] = [
  {
    id: 901001000,
    name: '서비스 및 멤버십',
    articles: [
      {
        id: 901001001,
        name: '매니폴드(manifold)는 무엇인가요?',
        body: `<p>
매니폴드는 개인의 피부 상태를 분석하여 최적의 성분과 조합을 추천하는 <strong>디지털 스킨케어 서비스</strong>입니다. 
</p>`,
      },
      {
        id: 901001002,
        name: '디지털 스킨케어가 무슨 뜻인가요?',
        body: `<p>
핸드폰 하나만으로 피부 상태 분석, 개인별 맞춤형 스킨케어 추천, 피부 분석 보고서를 제공하며,<br/>
오프라인에서의 <strong>피부관리 경험을 온라인으로 제공</strong>하는 웹&앱 서비스입니다.
</p>`,
      },
      {
        id: 901001003,
        name: '매니폴드 멤버십 스킨케어는 무엇이 다른가요?',
        body: `<p>
매니폴드는 모든 사람의 각기 다른 피부 유형을 기반으로 개개인의 피부 데이터를 분석해 최적의 스킨케어를<br/>추천하는 멤버십 서비스입니다. 
<br/><br/>
1️⃣ 피부 테스트 
<br/>
매니폴드 피부 테스트를 통해 256개의 타입 중 나의 피부 상태를 확인합니다.
<br/><br/>
2️⃣ 맞춤형 스킨케어
<br/>
테스트 결과를 기반으로 내 피부에 맞게 설계된 첫번째 스킨케어를 사용해보세요.
<br/><br/>
3️⃣ 피부 변화 기록
<br/>
피부기록 앱, '스킨로그'를 통해 맞춤형 스킨케어 만족도와 피부 변화를 기록해주시면 다음 제품에 반영됩니다.
<br/><br/>
4️⃣ 스킨케어 업데이트 완료
<br/>
새롭게 도착한 업데이트된 스킨케어를 사용 하시면서 피부 기록을 해주세요. 쓰면 쓸수록 내 피부에 꼭 맞게<br/>최적화됩니다.
</p>`,
      },
      {
        id: 901001004,
        name: '피부타입을 어떻게 알 수 있나요?',
        body: `<p>
피부 전문가와 수만개의 데이터를 기반으로 개발한 매니폴드의 특별한 테스트를 통해 피부 타입을 확인해보세요. 
<br/><br/>
피부 테스트를 완료하시면 현재 내 피부 상태 설명과 잘 맞는 성분 및 스킨케어 추천을 무료로 받아보실 수 있습니다.
</p>`,
      },
      {
        id: 901001005,
        name: '피부 테스트는 무료인가요?',
        body: '<p>매니폴드 피부 테스트는 무료이며, 별도의 회원가입 없이 누구나 할 수 있습니다.</p>',
      },
      {
        id: 901001006,
        name: '피부타입이 256개나 되나요?',
        body: `<p>
피부는 생각보다 복잡하며 개인마다 다른 특성을 가지고 있습니다. 또한, 환경에 따라 쉽게 변화하는<br/>요소도 있습니다. 
<br/><br/>
유분, 수분, 탄력, 민감, 색소로 이뤄진 5가지 기본 지표와 민감성과 트러블 정도의 각 유형을 나눠<br/><strong>총 256개의 피부타입</strong>을 분류하고 있습니다.
</p>`,
      },
      {
        id: 901001007,
        name: '스킨로그 앱은 무엇이죠? 무료인가요?',
        body: `<p>
매니폴드 멤버십 고객에게 제공되는 혜택으로, 안전하고 편리하게 피부를 기록하고 데이터로 저장하여 관리할 수<br/>있게 하는 앱 서비스입니다. 
<br/><br/>
스킨로그 앱은 무료로 제공되며 매니폴드 멤버십 고객들에게는 프리미엄 서비스가 별도로 제공됩니다. 
</p>`,
      },
      {
        id: 901001008,
        name: '피부 기록을 언제 해야 좋은가요?',
        body: `<p>
피부는 개인의 라이프스타일에 따라 가장 편한 시간을 정하여 주기적으로 기록하는게 좋습니다.<br/>또한, 되도록 일정한 장소와 조명을 동일하게 조성하여 같은 시간대에 촬영하는것을 추천드립니다. 
<br/><br/>
선블록이나 메이크업을 완전히 지운 세안 후 상태가 피부를 가장 정확하게 기록할 수 있으며,<br/>아무것도 바르지 않은 상태에서 30분 이내 촬영이 가장 이상적인 타이밍입니다.
</p>`,
      },
      {
        id: 901001009,
        name: '발송 주기 변경이 무엇인가요?',
        body: `<p>
매니폴드 멤버십의 기본 서비스인 스킨케어 3종 세트는 평균 약 2주 분량으로 구성되어 있지만,<br/>개인마다 사용량이 달라 사용기간이 달라질 수 있기에 발송 주기를 변경할 수 있습니다. 
<br/><br/>
발송 주기는 MY 멤버십에서 변경 가능하며, 이번 발송일과 다음 발송일 사이의 기간이 사용주기로 세팅되어<br/>주기적으로 스킨케어를 받아보실 수 있습니다.
</p>`,
      },
      {
        id: 901001010,
        name: '피부기록 업데이트가 무엇이죠? 꼭 해야하나요? ',
        body: `<p>
매니폴드는 개인의 피부 상태와 사용 만족도를 기반으로 데이터를 학습하기 때문에, 내 피부에 꼭 맞는 제품을 받고 싶다면 주기적인 피부 기록을 권장합니다.
</p>`,
      },
      {
        id: 901001011,
        name: '피부기록을 어디에서 하나요?',
        body: `<p>
스킨케어를 받은 후에 피부기록을 시작해주세요.<br/>매니폴드 MY 페이지 > MY 멤버십 페이지에서 '피부기록 업데이트' 버튼을 누르시면 <strong>스킨로그 앱</strong>으로 이동하며,<br/>피드백에 응답해주셔야 다음 스킨케어가 업데이트 됩니다.
</p>`,
      },
      {
        id: 901001012,
        name: '피부기록 업데이트 안하면 어떻게 되나요?',
        body: `<p>
바로 이전에 받은 스킨케어와 동일한 제품으로 배송됩니다. 새롭게 처방되는 스킨케어를 원하신다면<br/>꼭 피부기록 업데이트를 해주세요!
</p>`,
      },
    ],
  },
  {
    id: 901002000,
    name: '주문 및 결제',
    articles: [
      {
        id: 901002001,
        name: '주문을 취소/환불/교환하고 싶어요.',
        body: `<p>
카카오톡 플러스친구, 매니폴드(@manifold) 또는 매니폴드 고객센터(support_manifold@artlab.ai)로 주문취소/환불/교환을 요청해주시기 바랍니다.
</p>`,
      },
      {
        id: 901002002,
        name: '배송은 얼마나 걸리나요?',
        body: '<p>발송 후 수령까지 1~3일(영업일 기준) 정도의 기간이 소요됩니다.<br/>또한, 지역 및 예외적인 사유 발생에 따라 다소 지연될 가능성이 있습니다.</p>',
      },
      {
        id: 901002003,
        name: '주문한 상품의 배송지를 변경하고 싶어요.',
        body: `<p>
발송이 되지 않은 상태의 주문에 한해서 배송지 변경이 가능합니다. 카카오톡 플러스친구, 매니폴드(@manifold) 또는 매니폴드 고객센터(support_manifold@artlab.ai)로 문의주시면 확인 후 안내해 드리겠습니다. 
</p>`,
      },
    ],
  },
  {
    id: 901003000,
    name: '회원정보',
    articles: [
      {
        id: 901003001,
        name: '회원탈퇴는 어떻게 하나요?',
        body: `<p>
카카오톡 플러스친구, 매니폴드(@manifold)로 문의주시면 확인 후 안내해 드리겠습니다.
</p>`,
      },
      {
        id: 901003002,
        name: '회원 정보 변경은 어디서 할 수 있나요?',
        body: "<p>매니폴드 웹사이트 로그인 ➡ 상단의 '<strong>MY 페이지</strong>'를 클릭 ➡ '<strong>회원정보 수정</strong>' 메뉴에서 정보 수정이 가능합니다.</p>",
      },
      {
        id: 901003003,
        name: '아이디/비밀번호가 기억나지 않아요.',
        body: `<p>
아이디/비밀번호 분실 시 로그인 페이지의 '<strong>이메일이/비밀번호가 기억이 나지 않아요</strong>' 버튼을 눌러주세요. 
<br/>
재설정이 어려운 경우 카카오톡 플러스친구, 매니폴드(@manifold)로 문의해 주세요.
</p>`,
      },
    ],
  },
  {
    id: 901004000,
    name: '반품 및 환불',
    articles: [
      {
        id: 901004001,
        name: '제품 사용에 따른 피부 트러블이 발생한 경우 환불이 가능한가요?',
        body: `<p>
매니폴드 고객센터로 연락주세요. 바로 고객님의 피부에 재처방 된 스킨케어를 배송해드립니다. 
<br/><br/>
환불을 원하실경우 고객센터로 증상이 나타난 피부의 전후 사진과 (날짜가 확인될 수 있는) 계좌 정보를 함께 보내주시면 제품 회수 후 환불 처리를 도와드립니다.
<br/><br/>
화장품이 맞지 않을 경우 원인으로 추정되는 제품의 사용을 중단하면 본래의 피부 상태로 호전되오니 <strong>트러블 발생 시 사용을 즉시 중지</strong>해 주세요. 
</p>`,
      },
      {
        id: 901004002,
        name: '배송과정에서 파손제품을 받았어요.',
        body: '<p>배송받으신 후 30일 이내 고객센터로 파손제품의 사진을 보내주시면 확인 후 파손품의 회수 및 새 제품을 발송해드립니다.<br>단, 파손품을 버리거나 배송 후 30일 경과시 교환 처리가 어려우니 양해를 바랍니다.</p>',
      },
      {
        id: 901004003,
        name: '단순 변심으로 인한 교환/환불이 가능한가요?',
        body: '<p>개봉 및 사용하지 않은 제품의 교환/반품 접수는 배송받은 일로부터 7일 이내 가능합니다.<br>단순 변심에 의한 교환/반품의 경우 왕복 배송비 6천 원이 부과됩니다.</p>',
      },
      {
        id: 901004004,
        name: '멤버십 결제 취소 하고 싶어요.',
        body: `<p>
멤버십 결제 취소는 <strong>첫번째 발송 당일 오전 10시 이전</strong>만 가능합니다. 
<br/>
결제 취소가 가능한 상태라면 카카오톡 플러스친구, 매니폴드(@manifold)로 문의해 주세요.
</p>`,
      },
    ],
  },
  {
    id: 901005000,
    name: '제품',
    articles: [
      {
        id: 901005001,
        name: '민감성 피부가 사용해도 안전한가요?',
        body: '<p>매니폴드의 전 제품은 피부 안전성 테스트를 거치고 유해성분을 첨가하지 않은, 안심하고 사용하실 수 있는 제품입니다.<br>단, 피부과 진료 중인 분께서는 전문의와 상의 후 사용하기를 권장합니다.</p>',
      },
      {
        id: 901005002,
        name: '매니폴드 화장품 사용 Tip',
        body: `<p>
제품을 처음 사용할 때, 부스터를 앰플에 넣고 잘 흔들어 섞어주세요.
매일 아침과 저녁 세안 후 앰플을 먼저 두드리듯이 바르고 다음으로 크림을 피부 결에 따라 발라주세요. 
<br/><br/>
👉 1회 적정 사용량
앰플: 깊게 2회 펌핑, 극건조한 날에만 3번 펌핑하세요.
크림: 완두콩 크기로 짜서 사용하세요.
<br/><br/>
👉 자세한 사용 방법은 <a target=_blank href = https://www.youtube.com/watch?v=5li2QaRhXaI&t=37s>영상</a>을 참고해 주세요.
</p>`,
      },
      {
        id: 901005003,
        name: '앰플과 부스터는 왜 따로 있나요?',
        body: `<p>
기본적인 효능의 앰플과 개개인의 피부 고민에 맞춰진 고농축 부스터를 섞어 내 피부에 딱 맞는 앰플+부스터를 조합할 수 있기 때문입니다. 
</p>`,
      },
      {
        id: 901005004,
        name: '부스터는 어떻게 개봉하나요?',
        body:
          '<p>1️⃣ OPEN 화살표 방향을 따라 흰색 뚜껑을 위로 올려줍니다.<br>2️⃣ 흰색 뚜껑을 잡고 시계방향으로 돌려줍니다.<br>3️⃣ 알루미늄 가운데 틀이 벌어지면서 쉽게 제거 할 수 있습니다.<br>4️⃣ 알루미늄이 제거되고 남은 고무덮개를 열어줍니다.<br>5️⃣ 부스터를 앰플과 섞고 나에게 맞춤 성분을 편하게 사용합니다.<br><br>더 자세한 내용은 부스터 사용 가이드를 참고해 주세요.</p> <img src =' +
          faqimg +
          ' width =100%,alt ="booster_howtouse"/>',
      },
    ],
  },
];

export default data;
