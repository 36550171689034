import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { AddressFormItem } from './AddressFormItem';
import { validateName, validatePhoneNumber } from '../../utils/formValidations';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  authCredentialState,
  authUserState,
  skinQuizAnswerSheetState,
} from '../../states';
import handlePhoneNumber from '../../utils/handlePhoneNumber';
import styled from 'styled-components';
import useExternalScript from '../../utils/useExternalScript';
import { LoadingIcon } from '../../login/StyledComponents';
import { UserAddress } from '../../../domain/entities/UserAddress';
import { RemoteUser } from '../../../data/repositories/RemoteUser';

type Props = {
  onSaved: () => void;
  margin: string;
  showPlaceholder: boolean;
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  title: {
    height: '24px',
    left: '51px',
    top: '28px',
    fontStyle: 'normal',
    lineHeight: '150%',
    fontSize: '1.8rem',
    fontWeight: 700,
  },

  box: {
    height: '375px',
    left: '68px',
    top: '71px',
    borderRadius: '5px',
    margin: '18px 18px',
  },

  button: {
    'display': 'block',
    'margin': '36px auto 0',
    'width': '100%',
    'height': '4rem',
    'borderRadius': '5.5rem',
    'border': '2px solid black',
    'color': 'black',
    'fontSize': '1.7rem',
    'fontWeight': 500,
    'background': 'none',
    'cursor': 'pointer',

    [theme.breakpoints.up('sm')]: {
      width: '36.9rem',
      height: '4.9rem',
      fontWeight: 'bold',
      borderRadius: '4.2rem',
      border: '1px solid black',
    },

    '&:disabled': {
      border: '1px solid #9C9C9C',
      color: '#9C9C9C',
    },
  },
}));

const DaumAddress = styled.div`
  display: none;
  border: 1px solid;
  width: 100%;
  position: relative;
`;
const DaumCloseButton = styled.img`
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: -1px;
  z-index: 1;
`;

const namePlaceholderText = '이름을 입력해주세요';
const phonePlaceholderText = '연락처를 입력해주세요';
const address1PlaceholderText = '주소를 입력해주세요';
const address2PlaceholderText = '상세주소를 입력해주세요';

export const AddressForm2 = ({
  onSaved,
  margin,
  showPlaceholder,
}: Props): JSX.Element => {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [postcode, setPostcode] = useState('');
  const [note, setNote] = useState('');
  const [isValid, setIsValid] = useState({
    name: true,
    phoneNumber: true,
  });
  const [showNameWarning, setShowNameWarning] = useState(false);
  const [showPhoneNumberWarning, setShowPhoneNumberWarning] = useState(false);
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const skinQuizAnswerSheet = useRecoilValue(skinQuizAnswerSheetState);
  const [buttonText, setButtonText] = useState('확인');
  const [processing, setProcessing] = useState(false);
  const authCredential = useRecoilValue(authCredentialState);
  const [namePlaceholder, setNamePlaceholder] = useState<string>(undefined);
  const [phonePlaceholder, setPhonePlaceholder] = useState<string>(undefined);
  const [address1Placeholder, setAddress1Placeholder] =
    useState<string>(undefined);
  const [address2Placeholder, setAddress2Placeholder] =
    useState<string>(undefined);

  const nameInputRef = useRef<HTMLInputElement>();
  const phoneNumberInputRef = useRef<HTMLInputElement>();
  const address1InputRef = useRef<HTMLInputElement>();
  const daumAddressRef = useRef<HTMLDivElement>();
  const address2InputRef = useRef<HTMLInputElement>();
  const noteInputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  useEffect(() => {
    const {
      recipientName,
      recipientPhoneNumber,
      address1,
      address2,
      postcode,
      note,
    } = authUser?.address || {};
    setName(
      recipientName ||
        authUser?.displayName ||
        String(skinQuizAnswerSheet?.question1),
    );
    setPhoneNumber(recipientPhoneNumber || authUser?.phoneNumber);
    setAddress1(address1);
    setAddress2(address2);
    setPostcode(postcode);
    setNote(note);
  }, [
    authUser?.address,
    authUser?.displayName,
    authUser?.phoneNumber,
    skinQuizAnswerSheet?.question1,
  ]);

  const validateWithWarning = {
    name: true,
    phoneNumber: true,
  };
  const isDone =
    !!isValid.name && !!isValid.phoneNumber && !!address1 && !!address2;

  useExternalScript(
    'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js',
  );

  function onNameChanged(event: ChangeEvent<HTMLInputElement>) {
    setName(event.target.value);
    validateName(
      event.target.value,
      validateWithWarning,
      setShowNameWarning,
      setIsValid,
      isValid,
    );
  }

  function onNameBlurred() {
    setNamePlaceholder(namePlaceholderText);
  }

  function onNameSubmitted() {
    phoneNumberInputRef.current?.focus();
  }

  function onPhoneNumberChanged(event: ChangeEvent<HTMLInputElement>) {
    const handled = handlePhoneNumber(event.target.value);
    setPhoneNumber(handled);
    validatePhoneNumber(
      phoneNumber,
      validateWithWarning,
      setShowPhoneNumberWarning,
      setIsValid,
      isValid,
    );
  }

  function onPhoneNumberSubmitted() {
    address1InputRef.current?.focus();
  }

  function onPhoneNumberBlurred() {
    setPhonePlaceholder(phonePlaceholderText);
  }

  function onAddress1Focused() {
    const element_wrap = daumAddressRef.current;
    if (!element_wrap) {
      return;
    }

    const daum = window['daum'];
    // 현재 scroll 위치를 저장해놓는다.
    const currentScroll = Math.max(
      document.body.scrollTop,
      document.documentElement.scrollTop,
    );
    new daum.Postcode({
      oncomplete: function (data) {
        // 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.
        // 각 주소의 노출 규칙에 따라 주소를 조합한다.
        // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
        let addr = ''; // 주소 변수
        //사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
        if (data.userSelectedType === 'R') {
          // 사용자가 도로명 주소를 선택했을 경우
          addr = data.roadAddress;
        } else {
          // 사용자가 지번 주소를 선택했을 경우(J)
          addr = data.jibunAddress;
        }
        // 우편번호와 주소 정보를 해당 필드에 넣는다.
        setAddress1(addr);
        setPostcode(data.zonecode);

        // 커서를 상세주소 필드로 이동한다.
        address2InputRef.current?.focus();
        // iframe을 넣은 element를 안보이게 한다.
        // (autoClose:false 기능을 이용한다면, 아래 코드를 제거해야 화면에서 사라지지 않는다.)
        element_wrap.style.display = 'none';
        // 우편번호 찾기 화면이 보이기 이전으로 scroll 위치를 되돌린다.
        document.body.scrollTop = currentScroll;
      },
      // 우편번호 찾기 화면 크기가 조정되었을때 실행할 코드를 작성하는 부분. iframe을 넣은 element의 높이값을 조정한다.
      onresize: function (size) {
        element_wrap.style.height = size.height + 'px';
      },
      width: '100%',
      height: '100%',
    }).embed(element_wrap);
    // iframe을 넣은 element를 보이게 한다.
    element_wrap.style.display = 'block';
  }

  function closeDaumPostcode() {
    if (daumAddressRef.current) {
      daumAddressRef.current.style.display = 'none';
    }
  }

  function onAddress1Submitted() {
    address2InputRef.current?.focus();
  }

  function onAddress1Blurred() {
    setAddress1Placeholder(address1PlaceholderText);
  }

  function onAddress2Changed(event: ChangeEvent<HTMLInputElement>) {
    setAddress2(event.target.value);
  }

  function onAddress2Submitted() {
    noteInputRef.current?.focus();
  }

  function onAddress2Blurred() {
    setAddress2Placeholder(address2PlaceholderText);
  }

  function onNoteChanged(event: ChangeEvent<HTMLInputElement>) {
    setNote(event.target.value);
  }

  function onNoteSubmitted() {
    if (isDone) {
      return onButtonClicked();
    }
  }

  async function onButtonClicked() {
    if (processing) {
      return;
    }

    try {
      setProcessing(true);
      setButtonText((<LoadingIcon />) as any);

      const address: UserAddress = {
        recipientName: name,
        recipientPhoneNumber: phoneNumber,
        address1,
        address2,
        postcode,
        note,
      };
      const repo = new RemoteUser(authCredential);
      await repo.updateUserAddress(authUser.userId, address);

      setAuthUser({
        ...authUser,
        address,
      });
      onSaved();
    } catch (err) {
      console.error(err);
    } finally {
      setProcessing(false);
      setButtonText('확인');
    }
  }

  return (
    <section className={classes.root} style={{ margin: margin }}>
      <div className={classes.title}>배송지 입력</div>
      <div className={classes.box}>
        <AddressFormItem
          label={'받는사람'}
          value={name}
          placeholder={showPlaceholder && namePlaceholder}
          onValueChanged={onNameChanged}
          onSubmit={onNameSubmitted}
          onBlurred={onNameBlurred}
          warning={
            showNameWarning && '이름은 영문과 한글만 사용하실 수 있습니다.'
          }
          inputRef={nameInputRef}
        />
        <AddressFormItem
          label={'연락처'}
          value={phoneNumber}
          placeholder={showPlaceholder && phonePlaceholder}
          onValueChanged={onPhoneNumberChanged}
          onSubmit={onPhoneNumberSubmitted}
          onBlurred={onPhoneNumberBlurred}
          warning={showPhoneNumberWarning && '휴대폰 번호 양식을 확인해주세요'}
          maxLength={13}
          inputRef={phoneNumberInputRef}
        />
        <AddressFormItem
          label={'주소'}
          value={address1}
          placeholder={showPlaceholder && address1Placeholder}
          onFocused={onAddress1Focused}
          onSubmit={onAddress1Submitted}
          onBlurred={onAddress1Blurred}
          inputRef={address1InputRef}
        />
        <DaumAddress ref={daumAddressRef}>
          <DaumCloseButton
            src="//t1.daumcdn.net/postcode/resource/images/close.png"
            id="btnFoldWrap"
            onClick={closeDaumPostcode}
            alt="접기 버튼"
          />
        </DaumAddress>
        <AddressFormItem
          label={'상세주소'}
          value={address2}
          placeholder={showPlaceholder && address2Placeholder}
          onValueChanged={onAddress2Changed}
          onSubmit={onAddress2Submitted}
          onBlurred={onAddress2Blurred}
          inputRef={address2InputRef}
        />
        <AddressFormItem
          label={'배송요청사항'}
          value={note}
          onValueChanged={onNoteChanged}
          onSubmit={onNoteSubmitted}
          inputRef={noteInputRef}
        />
        <button
          className={classes.button}
          disabled={!isDone}
          onClick={onButtonClicked}
        >
          {buttonText}
        </button>
      </div>
    </section>
  );
};
