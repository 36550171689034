import React, { RefObject, useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSetRecoilState } from 'recoil';
import { kakaoFabBottomExtraMarginState } from '../../states';
import { Theme, useMediaQuery } from '@material-ui/core';
import { Routes } from '../../../config';
import { useHistory } from 'react-router';
import { FloatingButton } from '../StyledComponents';
import useScroll from '../../utils/useScroll';

type Props = {
  hasSelectedProduct: boolean;
  membershipProductsSectionRef: RefObject<HTMLElement>;
};

const height = '4.8rem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '16px',
    marginRight: '16px',
    height: height,
    width: 'calc(100% - 32px)',
    background: 'linear-gradient(#8EE4FF, #8BB3FF)',
    zIndex: 1,
    color: 'white',
    border: 'none',
    cursor: 'pointer',

    // maxWidth: '348px',
    borderRadius: '2px',
    borderWidth: '0px',
    whiteSpace: 'nowrap',

    [theme.breakpoints.up('sm')]: {
      width: '58.5rem',
      margin: 'auto',
      left: 'calc((100vw - 60rem) / 2)',
    },
  },
}));

export const FloatingBuyButton = ({
  hasSelectedProduct,
  membershipProductsSectionRef,
}: Props): JSX.Element => {
  const classes = useStyles();
  const setKakaoFabBottomExtraMargin = useSetRecoilState(
    kakaoFabBottomExtraMarginState,
  );
  const history = useHistory();
  const { scrollY } = useScroll();
  const scrollBottomY = scrollY + window.innerHeight - 100;
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [limitY, setLimitY] = useState(0);
  useEffect(() => {
    smUp ? setLimitY(3150) : setLimitY(2200);
  }, [smUp]);
  useEffect(() => {
    setKakaoFabBottomExtraMargin(`${height} + 1rem`);
    return () => {
      setKakaoFabBottomExtraMargin(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onClicked() {
    if (!hasSelectedProduct) {
      membershipProductsSectionRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    } else {
      history.push(Routes.skinQuizSelectFirstDeliveryDate)
    }
  }
  return (
    <FloatingButton
      onClick={onClicked}
      isValid
      scrollY={scrollBottomY}
      limitY={limitY}
      className={classes.root}
    >
      지금 구매시 스타터 키트 무료 제공
    </FloatingButton>
  );
};
