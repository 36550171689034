import { useEffect } from 'react';

const useExternalScript = (scriptURL: string) => {
  useEffect(() => {
    const scriptId = 'daum-postcode-script';
    let existingScript = document.getElementById(scriptId);
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = scriptURL;
      script.id = scriptId;
      document.body.append(script);
      script.onload = () => {
        existingScript = document.getElementById(scriptId);
      };
    }
    return () => {
      if (existingScript) {
        document.body.removeChild(existingScript);
      }
    };
  }, [scriptURL]);
};

export default useExternalScript;
