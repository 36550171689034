import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { notFoundMatchState } from '../states';
import backgroundPNG from '../../assets/image/orderBackground.png';
import { Routes } from '../../config';

const Main = styled.div`
  display: flex;
  height: 100vh;
  background-color: #fff;
  background-image: url(${backgroundPNG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  justify-content: center;
`;

const ContentContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  margin-top: 190px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const TitleBlock = styled.div`
  margin-top: 2rem;
  text-align: center;
  line-height: 150%;
  font-size: 120px;
  font-weight: 500;
`;

const TextBlock = styled.div`
  margin-top: 2rem;
  text-align: center;
  line-height: 150%;
  font-size: 25px;
  font-weight: 500;
`;
const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;
const HomeButton = styled.button`
  cursor: pointer;
  margin-bottom: 140px;
  background: none;
  width: 190px;
  height: 42px;
  border: 2px solid #000;
  border-radius: 50px;
  color: #000;
  font-weight: 500;
  font-size: 18px;
`;

function NotFound(): JSX.Element {
  const history = useHistory();
  const matchNotFound = useRouteMatch();
  const setNotFoundMatchState = useSetRecoilState(notFoundMatchState);
  const resetNotFoundMatchState = useResetRecoilState(notFoundMatchState);

  useEffect(() => {
    setNotFoundMatchState(matchNotFound);
    return () => {
      resetNotFoundMatchState();
    };
  }, [matchNotFound, resetNotFoundMatchState, setNotFoundMatchState]);

  const goHome = () => {
    history.push(Routes.root);
  };

  return (
    <Main>
      <ContentContainer>
        <TitleBlock>404</TitleBlock>
        <TextBlock>
          찾을 수 없는 페이지입니다.
          <br />
          요청하신 페이지는 사라졌거나, 잘못된 경로를 이용하셨어요 :)
        </TextBlock>
        <ButtonBlock>
          <HomeButton onClick={goHome}>홈으로 이동</HomeButton>
        </ButtonBlock>
      </ContentContainer>
    </Main>
  );
}

export default NotFound;
