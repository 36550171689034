import { OrderItem } from './Order';

export type SubscriptionStatus =
  | 'pending'
  | 'active'
  | 'on-hold'
  | 'cancelled'
  | 'pending-cancel'
  | 'expired';

export type BillingPeriod = 'day' | 'week' | 'month' | 'year';

interface ISubscription {
  subscriptionId: string;
  invoiceId: string;
  status: SubscriptionStatus;
  periodStart: number;
  periodPrev: number;
  periodNext: number;
  periodEnd: number;
  billingPeriod: BillingPeriod;
  billingInterval: number;
  items?: OrderItem[];
}

export class Subscription implements ISubscription {
  constructor(fields: ISubscription) {
    Object.assign(this, fields);
  }

  subscriptionId: string;
  invoiceId: string;
  status: SubscriptionStatus;
  periodStart: number;
  periodPrev: number;
  periodNext: number;
  periodEnd: number;
  billingPeriod: BillingPeriod;
  billingInterval: number;
  items?: OrderItem[];

  static isActive(sub: Subscription): boolean {
    return sub?.status === 'active';
  }
}
