import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    maxWidth: '79rem',
    alignItems: 'center',
    // marginTop: '5.8rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '8.935rem',
    },
  },
  title: {
    fontSize: '3.2rem',
    lineHeight: '4.8rem',
    alignSelf: 'stretch',
    marginBottom: '5.4rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '13.089rem',
    },
  },
  signupForm: {
    paddingTop: 0,
  },
  signupInput: {
    marginBottom: '1.7rem',
  },
  button: {
    marginTop: '5.4rem',
    height: '4rem',
    [theme.breakpoints.up('sm')]: {
      height: '4.9rem',
    },
  },
}));
