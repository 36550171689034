import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import styled, { ThemeProvider } from 'styled-components';
import { Reset } from 'styled-reset';
import LoadingScreen from 'react-loading-screen';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Routes } from './config';
import Branding from './app/branding';
import Support from './app/support/Support';
import { GlobalStyle } from './app/utils/Style';
import Footer from './app/components/Footer';
import NotFound from './app/errors/NotFound';
import SkinQuiz from './app/skinquiz';
import FirebaseAuth from './data/services/FirebaseAuth';
import {
  analyticsService,
  authCredentialState,
  authLoadingState,
  authService,
  authUserState,
  errorDialogButtonTextState,
  errorMessageState,
  kakaoFabState,
  landingTextState,
  showCardOnlyState,
  skinQuizSolutionState,
  subscriptionState,
  skinReportListState,
} from './app/states';
import { RemoteUser } from './data/repositories/RemoteUser';
import MenuBar from './app/components/MenuBar';
import loadingImage from './assets/image/manifold_loading.gif';
import { KakaoFab } from './app/components/KakaoFab';
import { FirebaseConfig } from './data/services/FirebaseConfig';
import { FirebaseAnalytics } from './data/services/FirebaseAnalytics';
import { AuthCredential } from './data/services/AuthService';
import firebase from 'firebase/app';
import { User } from './domain/entities/User';
import { ConfigKey } from './data/services/ConfigService';
import {
  newNormalLandingText,
  convenienceLandingText,
} from './data/landingPage';
import { HomePage } from './app/home/HomePage';

const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
  height: 100%;
  min-height: 100vh;
`;

const auth = new FirebaseAuth();
const config = new FirebaseConfig();
const analytics = new FirebaseAnalytics();

declare module '@material-ui/core/styles/createBreakpoints' {
  // noinspection JSUnusedGlobalSymbols
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    tablet: true;
    desktopSm: true;
    desktopMd: true;
    desktopLg: true;
  }
}

function App(): JSX.Element {
  const location = useLocation();
  const [authCredential, setAuthCredential] =
    useRecoilState(authCredentialState);
  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const setSubscription = useSetRecoilState(subscriptionState);
  const setAuthService = useSetRecoilState(authService);
  const setAnalyticsService = useSetRecoilState(analyticsService);
  const setKakaoFab = useSetRecoilState(kakaoFabState);
  const setLandingText = useSetRecoilState(landingTextState);
  const setSkinQuizSolution = useSetRecoilState(skinQuizSolutionState);
  const setSkinReportList = useSetRecoilState(skinReportListState);
  const setErrorMessage = useSetRecoilState(errorMessageState);
  const setErrorDialogButtonText = useSetRecoilState(
    errorDialogButtonTextState,
  );

  const [authLoading, setAuthLoading] = useRecoilState(authLoadingState);

  const { quizId } = authUser || {};
  const [curFirebaseUser, setCurFirebaseUser] = useState<
    firebase.User | undefined
  >(undefined);

  let theme = createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#000',
      },
      secondary: {
        main: '#c4c4c4',
      },
    },
    typography: {
      htmlFontSize: 10,
      fontFamily: 'Noto Sans KR, sans-serif;',
      button: {
        textTransform: 'none',
        fontSize: '1.8rem',
        width: 15,
        height: 4.5,
      },
      h2: {
        fontWeight: 'bold',
        fontSize: '3.0rem',
        textAlign: 'center',
      },
      h3: {
        fontSize: '2.5rem',
        textAlign: 'center',
      },
      h4: {
        fontSize: '2rem',
        textAlign: 'center',
      },
    },
    spacing: (factor) => `${0.25 * factor}rem`,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
        // 아래부터 커스텀 값들
        tablet: 810,
        desktopSm: 1200,
        desktopMd: 1536,
        desktopLg: 1920,
      },
    },
  });
  theme = responsiveFontSizes(theme);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const setShowCardOnly = useSetRecoilState(showCardOnlyState);

  useEffect(() => {
    const isSkinQuiz = location.pathname.slice(0, 9) === Routes.skinQuiz;
    const showCardOnly = isSkinQuiz || !isDesktop;
    setShowCardOnly(showCardOnly);
  }, [isDesktop, location.pathname, setShowCardOnly]);

  useEffect(() => {
    setAuthService(auth);
  }, [setAuthService]);

  useEffect(() => {
    return auth.onAuthStateChanged(
      async (firebaseUser: firebase.User | null) => {
        if (firebaseUser && curFirebaseUser?.uid !== firebaseUser?.uid) {
          setCurFirebaseUser(firebaseUser);
          const authCredential: AuthCredential = {
            userId: firebaseUser.uid,
            idToken: await firebaseUser.getIdToken(),
          };
          setAuthCredential(authCredential);

          try {
            const repo = new RemoteUser(authCredential);
            const user: User = await repo.getUser(firebaseUser.uid);
            setAuthUser({
              ...user,
              quizId: quizId || user.quizId,
            });
          } catch (err) {
            console.error(err);
          }

          setAuthLoading(false);
        } else if (!firebaseUser) {
          setCurFirebaseUser(firebaseUser);
          setAuthLoading(false);

          if (curFirebaseUser) {
            // user logged out
            setAuthCredential(undefined);
            setAuthUser(undefined);
            setSubscription(undefined);
            setSkinQuizSolution(undefined);
            setSkinReportList(undefined);
          }
        }
      },
    );
  }, [
    curFirebaseUser,
    quizId,
    setAuthCredential,
    setAuthLoading,
    setAuthUser,
    setSkinQuizSolution,
    setSubscription,
    setSkinReportList,
  ]);

  useEffect(() => {
    if (authUser?.subscriptionId && authCredential) {
      const repo = new RemoteUser(authCredential);
      repo
        .getSubscription(authUser.subscriptionId)
        .then((subscription) => {
          setSubscription(subscription);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setSubscription(undefined);
    }
  }, [authCredential, authUser?.subscriptionId, setSubscription]);

  useEffect(() => {
    config
      .fetch()
      .then(() => {
        const value = config?.getString(ConfigKey.landingText);
        if (value === 'convenience') {
          setLandingText(convenienceLandingText);
        } else {
          setLandingText(newNormalLandingText);
        }
      })
      .catch((err) => {
        console.error(err);
        setLandingText(newNormalLandingText);
      });

    setAnalyticsService(analytics);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // ReactGA.initialize("UA-151722030-7");
    // ReactGA.pageview(window.location.pathname + window.location.search);
    if (!window['Kakao'].isInitialized()) {
      window['Kakao'].init('51d8e82f8fcd998d2a452cd1bc3c1204');
    }
  }, []);

  useEffect(() => {
    setKakaoFab(true);
  }, [location.pathname, setKakaoFab]);

  // Clear error message whenever location changes.
  useEffect(() => {
    setErrorMessage('');
    setErrorDialogButtonText('');
  }, [location, setErrorDialogButtonText, setErrorMessage]);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <LoadingScreen
          loading={authLoading /*|| configLoading*/}
          spinnerColor="#c4c4c4"
          logoSrc={loadingImage}
        >
          <RootDiv>
            <Reset />
            <GlobalStyle />
            <MenuBar />
            <Switch>
              <Route exact path={Routes.root} component={HomePage} />
              {/* <Route
                path={Routes.forgotEmailResult}
                component={ForgotEmailResult}
              />
              <Route path={Routes.forgotEmail} component={ForgotEmail} />
              <Route
                path={Routes.forgotPasswordResult}
                component={ForgotPasswordResult}
              />
              <Route path={Routes.forgotPassword} component={ForgotPassword} /> */}
              <Route path={Routes.skinQuiz} component={SkinQuiz} />
              <Route path={Routes.story} component={Branding} />
              <Route path={Routes.support} component={Support} />
              {/* <PrivateRoute path={Routes.mypage} component={MyPage} /> */}
              {/* <Route exact path={Routes.login} component={LoginPage} />
              <Route path={Routes.signUp} component={SignupPage} /> */}
              <Route path="*" component={NotFound} />
            </Switch>
            <Footer />
            <KakaoFab />
          </RootDiv>
        </LoadingScreen>
      </ThemeProvider>
    </MuiThemeProvider>
  );
}
export default App;
