import React, { ChangeEvent, FocusEventHandler, Ref } from 'react';
import { makeStyles, Theme } from '@material-ui/core';

type Props = {
  label: string;
  value: string;
  placeholder?: string;
  maxLength?: number;
  onValueChanged?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlurred?: FocusEventHandler<HTMLInputElement>;
  onFocused?: FocusEventHandler<HTMLInputElement>;
  onSubmit?: () => void;
  warning?: string;
  inputRef?: Ref<HTMLInputElement>;
};

export const useStyles = makeStyles((theme: Theme) => ({
  root: {},

  label: {
    display: 'block',
    color: '#9C9C9C',
    fontSize: '1.2rem',
    marginBottom: '7px',
  },

  inputContainer: {
    position: 'relative',
  },

  input: {
    width: '100%',
    fontSize: '1.7rem',
    color: 'black',
    borderBottom: '0.5px solid black',
    paddingBottom: '4px',
  },

  placeholder: {
    position: 'absolute',
    left: 0,
    bottom: '7px',
    fontSize: '1rem',
    fontWeight: 300,
    color: 'red',
  },

  warning: {
    display: 'block',
    color: 'red',
    fontWeight: 300,
    fontSize: '1.2rem',
    minHeight: '2.3rem',
    paddingTop: '4px',
  },
}));

export const AddressFormItem = ({
  label,
  placeholder,
  value,
  onValueChanged,
  maxLength,
  onBlurred,
  onFocused,
  onSubmit,
  warning,
  inputRef,
}: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <form
      className={classes.root}
      onSubmit={(event) => {
        event.preventDefault();
        if (onSubmit) {
          onSubmit();
        }
      }}
    >
      <span className={classes.label}>{label}</span>
      <div className={classes.inputContainer}>
        <input
          className={classes.input}
          value={value}
          onChange={onValueChanged}
          maxLength={maxLength}
          onBlur={onBlurred}
          onFocus={onFocused}
          ref={inputRef}
        />
        {(!value || value.length === 0) && (
          <span className={classes.placeholder}>{placeholder}</span>
        )}
      </div>
      <span className={classes.warning}>{warning}</span>
    </form>
  );
};
