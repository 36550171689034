import { QuizItem } from './models/QuizModel';

const data: QuizItem[] = [
  {
    id: 1,
    idSub: 1,
    title: '피부 리포트에 사용될 이름을 알려주세요',
    placeholder: '이름',
    category: 'name',
    categoryKR: '이름',
    key: 'name',
    type: 'input',
    options: [],
  },
  {
    id: 2,
    idSub: 3,
    title: '성별과 연령을 알려주세요',
    placeholder: '',
    category: 'basic',
    categoryKR: '기본 질문',
    key: 'gender',
    type: 'selectable',
    options: [
      { id: 1, text: '여성' },
      { id: 2, text: '남성' },
    ],
  },
  {
    id: 3,
    idSub: 4,
    title: '',
    placeholder: '',
    category: 'basic',
    categoryKR: '기본 질문',
    key: 'age',
    type: 'selectable',
    options: [
      { id: 1, text: '10대 이하' },
      { id: 2, text: '20대' },
      { id: 3, text: '30대' },
      { id: 4, text: '40대' },
      { id: 5, text: '50대' },
      { id: 6, text: '60대 이상' },
    ],
  },
  {
    id: 4,
    idSub: 1,
    title: ' 피부에 모공이 어느 정도로 보이나요?',
    placeholder: '',
    category: 'OD',
    categoryKR: '지성-건성',
    key: 'pores',
    type: 'radio',
    options: [
      { id: 1, text: '잘 보이지 않음' },
      { id: 2, text: '미세하게 보임' },
      { id: 3, text: 'T존(이마와 코) 주변에 조금 보임' },
      { id: 4, text: '두드러지게 잘 보임' },
    ],
  },
  {
    id: 5,
    idSub: 2,
    title: ' 이마와 코 부분에 유분기가 있나요?(최근 2주간)',
    placeholder: '',
    category: 'OD',
    categoryKR: '지성-건성',
    key: 'nose',
    type: 'radio',
    options: [
      { id: 1, text: '전혀 없음' },
      { id: 2, text: '종종 있음' },
      { id: 3, text: '자주 있음' },
      { id: 4, text: '항상 있음' },
    ],
  },
  {
    id: 6,
    idSub: 3,
    title: ' 보습제를 바른 뒤 2~3시간 후 양 볼의 상태는 어떤가요?(최근 2주간)',
    placeholder: '',
    category: 'OD',
    categoryKR: '지성-건성',
    key: 'cheeks',
    type: 'radio',
    options: [
      {
        id: 1,
        text: '많이 건조하며 각질이 일어나기도 함',
      },
      { id: 2, text: '번들거리지도 않고 건조하지도 않음 ' },
      { id: 3, text: '전체적으로 조금 번들거림' },
      { id: 4, text: '기름지고 번들거림' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 7,
    idSub: 1,
    title: ' 여드름이나 좁쌀 여드름 등의 트러블이 발생하나요?(최근 1년)',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'acne',
    type: 'radio',
    options: [
      { id: 1, text: '주 1회 이상 발생' },
      { id: 2, text: '월 1회 이상 발생' },
      { id: 3, text: '거의 없음' },
      { id: 4, text: '전혀 없음' },
    ],
  },
  {
    id: 8,
    idSub: 2,
    title: ' 안면 홍조, 혈관 확장 등의 증상이 있나요? (최근 1년) ',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'redish',
    type: 'radio',
    options: [
      { id: 1, text: '항상 있음' },
      { id: 2, text: '자주 있음' },
      {
        id: 3,
        text: '종종 있음',
      },
      { id: 4, text: '전혀 없음' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 9,
    idSub: 3,
    title:
      ' 아토피 피부염, 습진 또는 접촉성 피부염 등의 증상이 있나요?(최근 1년)',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'atopy',
    type: 'radio',
    options: [
      {
        id: 1,
        text: '항상 있음',
      },
      {
        id: 2,
        text: '자주 있음',
      },
      {
        id: 3,
        text: '종종 있음',
      },
      { id: 4, text: '전혀 없음' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 10,
    idSub: 4,
    title: ' 운동, 음주, 격한 감정 등에 의해 얼굴이 붉어지나요?',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'neck',
    type: 'radio',
    options: [
      { id: 1, text: '항상 붉어짐' },
      { id: 2, text: '자주 붉어짐' },
      { id: 3, text: '가끔 붉어짐' },
      { id: 4, text: '전혀 그런 적 없음' },
    ],
  },
  {
    id: 11,
    idSub: 5,
    title: ' 화장품을 사용한 후 발진, 가려움증, 따끔거리는 증상이 나타나나요?',
    placeholder: '',
    category: 'SR',
    categoryKR: '민감-저항',
    key: 'itchy',
    type: 'radio',
    options: [
      { id: 1, text: '항상 나타남' },
      { id: 2, text: '자주 나타남' },
      { id: 3, text: '가끔 나타남' },
      { id: 4, text: '전혀 나타나지 않음 ' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 12,
    idSub: 1,
    title: ' 햇빛에 의해 얼굴의 잡티와 기미가 짙어지나요?',
    caption:
      '(주의: 잡티, 기미, 주근깨의 발생이 심하여 자외선 차단제를 사용하는 중이라면, “1”로 답하세요)',
    placeholder: '',
    category: 'PN',
    categoryKR: '색소-비색소',
    key: 'darkSpots',
    type: 'radio',
    options: [
      { id: 1, text: '많이 짙어짐' },
      { id: 2, text: '약간 짙어짐' },
      { id: 3, text: '잡티와 기미가 있으나 자외선차단제 사용' },
      { id: 4, text: '잡티와 기미가 없음' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 13,
    idSub: 2,
    title: ' 자외선이 노출되는 부위에 기미 등이 있나요?',
    placeholder: '',
    category: 'PN',
    categoryKR: '색소-비색소',
    key: 'chest',
    type: 'radio',
    options: [
      { id: 1, text: '아주 많음(16개 이상)' },
      { id: 2, text: '많음(6~15개)' },
      { id: 3, text: '조금(1~5개)' },
      { id: 4, text: '없음' },
    ],
  },
  {
    id: 14,
    idSub: 3,
    title:
      ' 피부에 색소침착이 있는 편인가요? (eg. 사타구니, 겨드랑이, 팔꿈치 등)',
    placeholder: '',
    category: 'PN',
    categoryKR: '색소-비색소',
    key: 'sun',
    type: 'radio',
    options: [
      { id: 1, text: '심하게 착색되어 있음' },
      { id: 2, text: '다소 착색되어 있음' },
      { id: 3, text: '거의 착색되지 않음' },
      { id: 4, text: '전혀 착색되지 않음' },
    ],
  },
  {
    id: 15,
    idSub: 4,
    title: ' 치료가 필요한 심각한 기미 증상이 있었나요?',
    placeholder: '',
    category: 'PN',
    categoryKR: '색소-비색소',
    key: 'brownSpots',
    type: 'radio',
    options: [
      {
        id: 1,
        text: '매우 심각한 증상(치료 받았으나 여전히 증상이 있음)',
      },
      {
        id: 2,
        text: '심각한 증상(치료 받을 때 호전됨)',
      },
      { id: 3, text: '심각하진 않지만, 기미가 있음' },
      { id: 4, text: '해당 사항 없음' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 16,
    idSub: 1,
    title: ' 얼굴에 주름이나 잔주름이 보이나요?',
    placeholder: '',
    category: 'WT',
    categoryKR: '주름-탄력',
    key: 'wrinkles',
    type: 'radio',
    options: [
      {
        id: 1,
        text: '굵은 주름이 확연히 눈에 띔',
      },
      {
        id: 2,
        text: '눈가와 입가에 주름이 보임',
      },
      { id: 3, text: '눈가와 입가에 미세한 얇은 주름이 보임' },
      {
        id: 4,
        text: '보이지 않음',
      },
    ],
  },
  {
    id: 17,
    idSub: 2,
    title: ' 1년 중 2주 이상 야외에서 피부를 그을린 햇수가 얼마나 되나요?',
    placeholder: '',
    category: 'WT',
    categoryKR: '주름-탄력',
    key: 'tan',
    type: 'radio',
    options: [
      { id: 1, text: '10년 초과' },
      { id: 2, text: '5~10년' },
      { id: 3, text: '1~5년' },
      { id: 4, text: '없다' },
    ],
  },
  {
    id: 18,
    idSub: 3,
    title: ' 일상생활 중 햇빛에 노출되는 시간은 하루에 얼마나 되나요?',
    placeholder: '',
    category: 'WT',
    categoryKR: '주름-탄력',
    key: 'sunExposure',
    type: 'radio',
    options: [
      {
        id: 1,
        text: '많음(1시간 이상)',
      },
      {
        id: 2,
        text: '보통(30분~1시간)',
      },

      {
        id: 3,
        text: '적음(30분 이하)',
      },
      {
        id: 4,
        text: '거의 없음(수분 이내 노출 또는 자외선 차단제 사용)',
      },
    ],
  },
  {
    id: 19,
    idSub: 4,
    title: ' 담배 또는 전자 담배를 얼마나 자주 피우나요? (간접 흡연 포함)',
    placeholder: '',
    category: 'WT',
    categoryKR: '주름-탄력',
    key: 'smoke',
    type: 'radio',
    options: [
      { id: 1, text: '매일 피움' },
      { id: 2, text: '가끔 피움' },
      {
        id: 3,
        text: '과거 피운 적이 있으나(5갑 이상) 현재는 없음',
      },
      { id: 4, text: '피운 적이 없거나 평생 5갑 이하' },
    ],
  },
  {
    id: 20,
    idSub: 5,
    title: ' 지속해서 자외선 차단제를 사용하시나요?',
    placeholder: '',
    category: 'WT',
    categoryKR: '주름-탄력',
    key: 'sunScream',
    type: 'radio',
    options: [
      { id: 1, text: '거의 사용하지 않음' },
      {
        id: 2,
        text: '장시간 야외활동이 있을 때만 사용(1주일에 1~2일 이하)',
      },
      { id: 3, text: '외출 시에만 사용(1주일에 3~6일)' },
      { id: 4, text: '매일 사용' },
    ],
  },
  {
    id: 21,
    idSub: 1,
    title: ' 얼굴이 건조하고 땅기는 증상이 얼마나 자주 있나요?(최근 2주)',
    placeholder: '',
    category: 'hyde',
    categoryKR: '수분-탈수',
    key: 'pull',
    type: 'radio',
    options: [
      { id: 1, text: '항상 있음' },
      { id: 2, text: '가끔 있음' },
      { id: 3, text: '거의 없음' },
      { id: 4, text: '전혀 없음' },
    ],
  },
  {
    id: 22,
    idSub: 2,
    title: ' 입 주변 또는 볼에 각질이 일어나는 증상이 있나요?(최근 2주)',
    placeholder: '',
    category: 'hyde',
    categoryKR: '수분-탈수',
    key: 'lips',
    type: 'radio',
    options: [
      { id: 1, text: '항상 그렇다' },
      { id: 2, text: '가끔 그렇다' },
      { id: 3, text: '거의 없다' },
      { id: 4, text: '전혀 없다' },
    ],
  },
  {
    id: 23,
    idSub: 3,
    title: ' 얼굴의 피부가 건조해 가렵거나 긁고 싶을 때가 있나요?(최근 2주)',
    placeholder: '',
    category: 'hyde',
    categoryKR: '수분-탈수',
    key: 'driedItchy',
    type: 'radio',
    options: [
      { id: 1, text: '항상 그렇다' },
      { id: 2, text: '가끔 그렇다' },
      { id: 3, text: '거의 없다' },
      { id: 4, text: '전혀 없다' },
    ],
  },
  {
    id: 24,
    idSub: 4,
    title:
      ' 건조한 환경에서 보습 제품을 사용하지 않을 경우 피부 상태는 어떤가요?(최근 2주)',
    placeholder: '',
    category: 'hyde',
    categoryKR: '수분-탈수',
    key: 'mist',
    type: 'radio',
    options: [
      { id: 1, text: '매우 건조한 느낌이 들고 갈라짐' },
      { id: 2, text: '다소 건조함' },
      { id: 3, text: '적당한 밸런스가 유지되는 것 같음' },
      { id: 4, text: '항상 윤기가 흐르며 세안 직후라도 땅기지 않음' },
      { id: 5, text: '잘 모르겠음' },
    ],
  },
  {
    id: 25,
    idSub: 1,
    title: '본인의 전반적인 피부 상태를 평가해주세요',
    placeholder: '',
    category: 'overall',
    categoryKR: '종합',
    key: 'status',
    type: 'radio',
    options: [
      { id: 1, text: '매우 나쁨' },
      { id: 2, text: '나쁨' },
      { id: 3, text: '보통' },
      { id: 4, text: '좋음' },
      { id: 5, text: '매우 좋음' },
    ],
  },
  {
    id: 26,
    idSub: 2,
    title: ' 당신의 피부고민을 모두 선택해주세요',
    placeholder: '',
    category: 'overall',
    categoryKR: '종합',
    key: 'concerns',
    type: 'check',
    options: [
      { id: 1, text: '색소침착(기미,잡티)' },
      { id: 2, text: '홍조' },
      { id: 3, text: '트러블' },
      { id: 4, text: '피부 건조' },
      { id: 5, text: '주름/탄력' },
      { id: 6, text: '피지/모공' },
    ],
  },
  {
    id: 27,
    idSub: 3,
    title: ' 일평균 수면시간이 얼마나 되나요?(최근 2주)',
    placeholder: '',
    category: 'overall',
    categoryKR: '종합',
    key: 'sleep',
    type: 'radio',
    options: [
      { id: 1, text: '4시간 미만' },
      { id: 2, text: '4~6시간' },
      { id: 3, text: '6~8시간' },
      { id: 4, text: '8시간 이상' },
    ],
  },
  {
    id: 28,
    idSub: 4,
    title: ' 스트레스를 얼마나 받고 계신가요?(최근 2주)',
    placeholder: '',
    category: 'overall',
    categoryKR: '종합',
    key: 'stress',
    type: 'radio',
    options: [
      { id: 1, text: '전혀 받지 않음' },
      { id: 2, text: '스트레스가 없는 편' },
      { id: 3, text: '보통이다' },
      { id: 4, text: '스트레스가 많은 편' },
      { id: 5, text: '스트레스가 매우 많음' },
    ],
  },
  {
    id: 29,
    idSub: 1,
    title: ' 기초 화장품의 어떤 면을 중요하게 생각하시나요?(중복선택 가능)',
    placeholder: '',
    category: 'product',
    categoryKR: '화장품',
    key: 'skincare',
    type: 'check',
    options: [
      { id: 1, text: '마무리감(매트/촉촉/보송 등의 피부 느낌)' },
      { id: 2, text: '유수분감(적당한 유수분 밸런스)' },
      { id: 3, text: '흡수력(피부에 빠르게 흡수)' },
      { id: 4, text: '보습력(수분을 오래 유지)' },
      { id: 5, text: '기능성(효능 원료)' },
      { id: 6, text: '성분(피부에 도움이 되는 성분)' },
    ],
  },
  {
    id: 30,
    idSub: 2,
    title:
      ' 지금까지 만족하며 사용하는 스킨케어(토너, 에센스, 로션, 크림 등) 화장품의 브랜드 및 제품명을 알려주세요',
    placeholder:
      '바이애콤 퓨어 카밍 앰플(정말 트러블을 잠재워줌), 잇츠스킨 파워10 VC(제형이 산뜻해서 가볍게 바르기 좋았음)',
    category: 'product',
    categoryKR: '화장품',
    key: 'likes',
    type: 'text',
    options: [],
    optional: true,
  },
  {
    id: 31,
    idSub: 3,
    title: ' 나와 가장 맞지 않았던 스킨케어 브랜드와 제품명을 알려주세요',
    placeholder:
      '더페이스샵 닥터벨머 거즈 마스크(붙이는 순간 얼굴이 너무 따가웠음), 아벤느 SOS 크림(트러블에 좋은 제품이라고 해서 샀는데 제형이 너무 꾸덕했음).',
    category: 'product',
    categoryKR: '화장품',
    key: 'dislikes',
    type: 'text',
    options: [],
    optional: true,
  },
  {
    id: 32,
    idSub: 6,
    title: ' 최근 1년 동안 어떤 여드름이 관찰되나요?(중복선택 가능)',
    placeholder: '',
    category: 'SR',
    categoryKR: '여드름',
    key: 'acne',
    type: 'check',
    options: [
      { id: 1, text: '알갱이 형태의 피지가 산화하고 검게 변한 블랙헤드' },
      { id: 2, text: '알갱이 형태로 모낭 속에 하얀 피지가 뭉친 화이트헤드' },
      { id: 3, text: '닭살처럼 오돌토돌하게 난 좁쌀 여드름' },
      {
        id: 4,
        text: '피지가 단단히 뭉쳤으나 아직 곪지는 않은 여드름',
      },
      { id: 5, text: '염증 반응이 진행되어 고름이 보이는 여드름' },
      { id: 6, text: '여드름이나 트러블이 나지 않음' },
    ],
  },
];

export default data;
