import styled, { css, keyframes } from 'styled-components';
import { Check } from '@styled-icons/heroicons-outline';
import { Spinner } from '@styled-icons/icomoon/Spinner';
import { device } from '../utils/device';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${device.mobile} {
    width: 100%;
  }
`;

export const PageTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 2.9rem;
  margin-bottom: 4rem;
  white-space: pre-wrap;
  @media ${device.mobile} {
    font-size: 1.6rem;
    line-height: 2.3rem;
    margin-bottom: 3.6rem;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  align-items: center;
  @media ${device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 33px;
  }
`;

export const Input = styled.input`
  width: 280px;
  border: 1px solid #000;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 9px;

  @media ${device.mobile} {
    width: 160px;
    height: 38px;
    font-size: 16px;
  }
`;

export const ButtonBlock = styled.div`
  display: flex;
`;

export const Button = styled.button<{
  isValid: boolean;
  isStart?: boolean;
}>`
  background-color: transparent;
  width: 19rem;
  height: 4.2rem;
  border: 1.75926px solid #c4c4c4;
  border-radius: 4.39815rem;
  color: #c4c4c4;
  font-weight: 500;
  font-size: 1.8rem;

  & + & {
    margin-left: 1.2rem;
  }

  &.single {
    width: 100rem;
  }
  ${(props) =>
    props.isValid &&
    css`
      border-color: #000;
      color: #000;
      cursor: pointer;
    `}

  ${(props) =>
    props.isStart &&
    props.isValid &&
    css`
      color: #fff;
      background: linear-gradient(90deg, #8ee4ff 7.93%, #8bb3ff 94.05%);
      border: 0;
    `}
    ${(props) =>
    props.isStart &&
    !props.isValid &&
    css`
      color: #fff;
      background: linear-gradient(
        90deg,
        rgba(142, 228, 255, 0.4) 7.93%,
        rgba(139, 179, 255, 0.4) 94.05%
      );
      border: 0;
    `}

  @media ${device.mobile} {
    width: 14.056rem;
    & + & {
      margin-left: 0.88rem;
    }

    &.single {
      width: 100rem;
    }
    margin-bottom: 5rem;
  }
`;

export const FloatingButton = styled.button<{
  isValid: boolean;
  scrollY?: number;
  limitY?: number;
}>`
  background-color: transparent;
  width: 19rem;
  height: 4.2rem;
  border: 1.75926px solid #c4c4c4;
  border-radius: 4.39815rem;
  color: #c4c4c4;
  font-weight: 500;
  font-size: 1.8rem;

  position: fixed;
  bottom: ${20}px;
  ${(props) =>
    props.limitY < props.scrollY &&
    css`
      position: static;
      margin-bottom: 25px;
    `}

  & + & {
    margin-left: 1.2rem;
  }

  &.single {
    width: 100rem;
  }
  ${(props) =>
    props.isValid &&
    css`
      border-color: #000;
      color: #000;
      cursor: pointer;
      color: #fff;
      background: linear-gradient(90deg, #8ee4ff 7.93%, #8bb3ff 94.05%);
      border: 0;
    `}
  ${(props) =>
    !props.isValid &&
    css`
      color: #fff;
      background: linear-gradient(
        90deg,
        rgba(142, 228, 255, 0.4) 7.93%,
        rgba(139, 179, 255, 0.4) 94.05%
      );
      border: 0;
    `}

  @media ${device.mobile} {
    width: 14.056rem;

    &.single {
      width: 100rem;
    }
  }
`;
export const IconOptionButtonBlock = styled.div`
display: flex;
gap:8px;
flex-direction: row;
flex-wrap: nowrap;
`
export const IconOptionButton = styled.img`
`

export const BlueSquareButtonBlock = styled.div`
width:270px;
  display: flex;
  gap:8px;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 50px;
`;
export const SquareButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const BlueSquareButton = styled.button<{ selected: boolean }>`
  width: 131px;
  height: 37px;
  background-color:#F9F9F9;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  padding: 9px;
  cursor: pointer;
  color:#C4C4C4;

  ${(props) =>
    props.selected &&
    css`
      background: #74A0F7;
      color:#fff;
    `}


`;

export const SquareButton = styled.button<{ selected: boolean }>`
  width: 280px;
  border: 1px solid #fff;
  background-color: #fff;
  font-size: 18px;
  font-weight: 400;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 9px;
  cursor: pointer;

  & + & {
    margin-top: 16px;
  }

  ${(props) =>
    props.selected &&
    css`
      border: 1px solid #000;
    `}

  @media ${device.mobile} {
    width: 100px;
    height: 45px;
    & + & {
      margin-top: 8px;
    }
  }
`;

export const QuestionBlock = styled.div`
  margin-top: 5rem;
  margin-bottom: 6.2rem;
  padding: 0 8px;
  width: 100%;
`;

export const QuestionContainer = styled.div`
  width: 645px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  opacity: 0.8;
  & + & {
    margin-top: 12px;
  }
  @media ${device.mobile} {
    width: 100%;

    & + & {
      margin-top: 5px;
    }
  }
`;

export const QuestionTitleBlock = styled.div`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  span {
    font-size: 10px;
  }
  @media ${device.mobile} {
    margin-bottom: 27px;
  }
`;
export const QuestionCaption = styled.div``;

export const QuestionOptionsBlock = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  @media ${device.mobile} {
    padding-left: 0;
  }
`;

export const OptionItemContainer = styled.div`
  display: flex;

  cursor: pointer;
  & + & {
    margin-top: 15px;
  }
`;

export const OptionItemLabel = styled.label<{ htmlFor: any }>`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #201f1e;
  align-items: flex-start;
  cursor: pointer;

  @media ${device.mobile} {
    align-items: flex-start;
  }
`;
export const OptionItemCheckBox = styled.div`
  width: 20px;
  min-width: 20px;
  height: 20px;
  border: 1px solid #323130;
  border-radius: 2px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OptionItemRadioInput = styled.input`
  visibility: hidden;
  height: 0;
  width: 0;
`;

export const OptionItemContent = styled.div`
  margin-left: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #201f1e;
  display: flex;
  align-items: center;
`;

export const CheckIcon = styled(Check)<{ showCheck: boolean }>`
  width: 0px;
  color: #605e5c;
  ${(props) =>
    props.showCheck &&
    css`
      width: 13px;
    `}
`;

export const TextArea = styled.textarea`
  margin-left: 10px;
  padding: 8px;
  width: 560px;
  height: 160px;
  resize: none;
  @media ${device.mobile} {
    width: 100%;
    margin: 0;
  }
`;

export const spin = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

export const LoadingIcon = styled(Spinner)`
  width: 20px;
  animation: 2s linear ${spin} infinite;
`;
