import Coupon from '../../domain/entities/Coupon';
import { Invoice } from '../../domain/entities/Invoice';
import { Order, OrderItem } from '../../domain/entities/Order';
import Shipping from '../../domain/entities/Shipping';
import { Subscription } from '../../domain/entities/Subscription';
import { User, UserItem } from '../../domain/entities/User';
import { RegisterUserResult } from '../../domain/repositories/UserRepository';

export class RemoteUserMapper {
  public static toRegisterUserResult(data: any): RegisterUserResult {
    return {
      userId: data.userId,
      isExists: data.isExists,
      createdAt: data.createdAt,
    };
  }

  public static toUser(data: any): User {
    return {
      userId: data.userId,
      email: data.email,
      displayName: data.displayName,
      phoneNumber: data.phoneNumber,
      gender: data.gender,
      birthdate: data.birthdate,
      address: data.address,
      settings: data.settings,
      quizId: data.quizId,
      subscriptionId: data.subscriptionId,
      subscriptionStatus: data.subscriptionStatus,
    };
  }

  public static toUserItem(data: any): UserItem {
    const item = data?.users[0] || {};
    return {
      userId: item.userId,
      email: item.email,
      displayName: item.displayName,
      createdAt: item.createdAt,
    };
  }

  public static toSubscription(data: any): Subscription {
    return {
      subscriptionId: data.subscriptionId,
      invoiceId: data.invoiceId,
      status: data.status,
      periodStart: data.periodStart,
      periodPrev: data.periodPrev,
      periodNext: data.periodNext,
      periodEnd: data.periodEnd,
      billingPeriod: data.period,
      billingInterval: data.interval,
    } as Subscription;
  }

  public static toInvoice(data: any): Invoice {
    return {
      userId: data.userId,
      invoiceId: data.invoiceId,
      subscriptionId: data.subscriptionId,
      orderId: data.orderId,
      reserveId: data.reserveId,
      status: data.status,
      price: data.price,
      periodStart: data.periodStart,
      periodEnd: data.periodEnd,
      receiptUrl: data.receiptUrl,
    };
  }

  public static toOrder(data: any): Order {
    return {
      orderId: data.orderId,
      status: data.status,
      price: data.price,
      items: data.items?.map(RemoteUserMapper.toOrderItem),
      shipping: RemoteUserMapper.toShipping(data.shipping?._data),
    };
  }

  public static toOrderItem(data: any): OrderItem {
    return {
      productId: data.productId,
      productName: data.productName,
      quantity: data.quantity,
    };
  }

  public static toShipping(data: any): Shipping {
    return {
      firstName: data.firstName,
      address1: data.address1,
      address2: data.address2,
      postcode: data.postcode,
      phoneNumber: data.phoneNumber,
      country: data.country,
      customerNote: data.customerNote,
    };
  }

  public static toCoupon(data: any): Coupon {
    return {
      code: data.code,
      amount: data.amount,
      discountType: data.discountType,
    };
  }
}
