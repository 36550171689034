import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { BoxDivider } from '../styles';
import { useStyles } from './IngredientBox';
import { ReportContent } from '../../../domain/entities/SkinDiagnosis';

type PropsFor0 = {
  userName: string;
  reportContent: ReportContent;
};

export function RoutineFor0({
  userName,
  reportContent,
}: PropsFor0): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>
        {userName}님을 위한 스킨케어 루틴 추천
      </Typography>
      <BoxDivider />
      <Box className={classes.routineList}>
        {reportContent?.careRoutine?.map((text, index) => (
          <Box key={text} className={classes.routineListItem}>
            <span className={classes.routineListBullet}>{index + 1}</span>
            <span>
              {index == 2 && (
                <span style={{ fontWeight: 'bold' }}>{userName}</span>
              )}
              {index == 2 && '님은, '}
              {text}
            </span>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

type PropsForN = {
  userName: string;
  careRoutines: string[];
};
export function RoutineForN({
  userName,
  careRoutines,
}: PropsForN): JSX.Element {
  const classes = useStyles();

  return (
    <Box className={classes.box}>
      <Typography className={classes.title}>
        {userName}님을 위한 스킨케어 루틴 추천
      </Typography>
      <BoxDivider />
      <Box className={classes.routineList}>
        {careRoutines?.map((text, index) => (
          <Box key={text} className={classes.routineListItem}>
            <span className={classes.routineListBullet}>{index + 1}</span>
            <span>
              {index == 2 && (
                <span style={{ fontWeight: 'bold' }}>{userName}</span>
              )}
              {index == 2 && '님은, '}
              {text}
            </span>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
