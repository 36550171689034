import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingScreen from 'react-loading-screen';
import { Routes } from '../../config';
import { quizBackgroundState } from '../states';
import { useSetRecoilState } from 'recoil';
import { Container, makeStyles, Theme } from '@material-ui/core';
import { RemoteQuiz } from '../../data/repositories/RemoteQuiz';
import loadingImage from '../../assets/image/manifold_loading.gif';
import { SkinQuizSolution } from '../../domain/entities/SkinQuizSolution';
import {
  get0thRadarDataObj,
  getBarDataObj,
  SkinChartBox,
} from './resultpage/SkinChartBox';
import { SkinChartSummaryBox } from './resultpage/SkinChartSummaryBox';
import { QuizBackground } from '../../domain/entities/QuizBackground';
import Header from './resultpage/ResultHeader';
import { IngredientRecommendFor0 } from './resultpage/IngredientRecommendBox';
import {
  getCautionIngredient,
  IngredientCautionBox,
} from './resultpage/IngredientCautionBox';
import { RoutineFor0 } from './resultpage/RoutineBox';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    maxWidth: '36rem',
    padding: '2rem 1.6rem',
    color: '#000',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '120rem',
      width: '120rem',
      padding: '0 30.7rem',
    },
  },
}));

function ShareResultPage({ match }): JSX.Element {
  const history = useHistory();
  const classes = useStyles();

  const [skinQuizSolution, setSkinQuizSolution] = useState<SkinQuizSolution>({
    userName: '',
    quizType: '',
    products: [],
    diagnosis: {},
  });

  const quizId = match.params.quizId;
  const {
    barScore,
    radarScore,
    skinType,
    reportContent,
    reportImage,
    prescription,
  } = skinQuizSolution.diagnosis || {};
  const createdAt = skinQuizSolution?.createdAt;
  const setQuizBackground = useSetRecoilState(quizBackgroundState);
  setQuizBackground(QuizBackground.GRADIENT);

  const expertop1 = [];
  const expertop2 = [];
  const expertoplenth = reportContent?.explanation.length;

  reportContent?.explanation.forEach(function (item, index) {
    if (index <= expertoplenth / 2) {
      expertop1.push(item);
    } else {
      expertop2.push(item);
    }
  });

  useEffect(() => {
    (async () => {
      if (!quizId) {
        return;
      }

      const repo = new RemoteQuiz();
      try {
        const result = await repo.getSkinQuizSolution(quizId);
        if (!result) {
          history.replace(Routes.notFound);
        }

        setSkinQuizSolution(result);
      } catch (err) {
        console.error(err);
        history.replace(Routes.notFound);
      }
    })();
  }, [history, quizId, setSkinQuizSolution]);

  const userName = skinQuizSolution?.userName;
  const dateNumber = skinQuizSolution?.createdAt;
  const reportDate = dateNumber ? new Date(dateNumber) : new Date();
  const cautionIngredient = getCautionIngredient(
    skinType?.sscore?.sarray?.split(''),
    skinType?.total,
  );
  const radarData = get0thRadarDataObj(radarScore, createdAt);
  const barData = getBarDataObj(barScore);

  return (
    <LoadingScreen
      loading={!skinQuizSolution?.diagnosis?.reportContent}
      spinnerColor="#c4c4c4"
      logoSrc={loadingImage}
    >
      <Container maxWidth="lg" className={classes.root}>
        <Header reportDate={reportDate} userName={userName} />
        <SkinChartSummaryBox
          typeComment={reportContent?.typeComment}
          skinType={skinType?.total}
          userName={userName}
        />
        <SkinChartBox
          radarData={radarData}
          barData={barData}
          userName={userName}
        />
        <IngredientRecommendFor0
          userName={userName}
          skinType={skinType?.total}
          reportImage={reportImage}
          prescription={prescription}
        />
        <IngredientCautionBox
          userName={userName}
          skinType={skinType?.total}
          cautionIngredient={cautionIngredient}
        />
        <RoutineFor0 userName={userName} reportContent={reportContent} />
        <br />
        <br />
        <br />
      </Container>
    </LoadingScreen>
  );
}

export default ShareResultPage;
