import { UserSatisfactionSurveyQuestion } from '../domain/entities/UserSatisfactionSurvey';

const data: UserSatisfactionSurveyQuestion = {
  firstQuestion:
    '피부 리포트를 친구나 지인 등 주변 사람들에게 추천할 의향이 얼마나 되나요?',
  secondQuestion:
    '그렇게 느끼셨다니 너무 감동스럽네요🙏 \n해당 추천 점수를 주신 이유는 무엇인가요?',
  thirdQuestion:
    '추천 점수를 높이기 위해서 우리가 무엇을 할 수 있을까요? 자유롭게 얘기해주세요.',
  fourthQuestion: '서비스와 제품에 대해 자유롭게 얘기해주세요.',
  secondPlaceHolder:
    '피부 리포트의 내용(피부 차트, 추천/주의 성분 등) 중 어떤 내용을 추천하고 싶으신가요?',
  thirdPlaceHolder:
    '피부 리포트를 받아 보고 난 뒤 \n개선 혹은 추가되었으면 하는 부분들을 말씀해주세요.',
  fourthPlaceHolder:
    '모든 문의/교환 상담 등은 꼭 카카오톡(@매니폴드)으로 와주세요🙏',
};

export default data;
