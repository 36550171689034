import React, { useEffect, useRef, useState } from 'react';
import loadingImage from '../../../assets/image/manifold_loading.gif';
import { makeStyles } from '@material-ui/core/styles';
import ScrollToTopOnMount from '../../utils/ScrollToTopOnMount';
import { HeaderSection } from './HeaderSection';
import { ProductsSection } from './ProductsSection';
import { ProductDetailModal } from './ProductDetailModal';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import LoadingScreen from 'react-loading-screen';
import {
  authCredentialState,
  authLoadingState,
  authUserState,
  quizBackgroundState,
  selectedMembershipProductState,
  skinQuizSolutionState,
  solutionProductsState,
  subscriptionState,
  skinReportListState,
  reportIndexState,
} from '../../states';
import { MembershipProductsSection } from './MembershipProductsSection';
import { FeatureGraphicSection } from './FeatureGraphicSection';
import { InstallSkinLogAppSection } from './InstallSkinLogAppSection';
import { FloatingBuyButton } from './FloatingBuyButton';
import { Dialog, Fade, Theme } from '@material-ui/core';
import { Subscription } from '../../../domain/entities/Subscription';
import { MembershipProduct } from '../../../domain/entities/MembershipProduct';
import { RemoteMembershipProduct } from '../../../data/repositories/RemoteMembershipProduct';
import { Routes } from '../../../config';
import { useHistory } from 'react-router';
import { RemoteQuiz } from '../../../data/repositories/RemoteQuiz';
import { useLocation } from 'react-router-dom';
import { RemoteUser } from '../../../data/repositories/RemoteUser';
import { QuizBackground } from '../../../domain/entities/QuizBackground';
import { ProductDetailInfo } from '../../../domain/entities/ProductDetailInfo';

import solutionCalm from '../../../assets/image/solutionCalm.svg';
import solutionColor from '../../../assets/image/solutionColor.svg';
import solutionDry from '../../../assets/image/solutionDry.svg';
import solutionHyde from '../../../assets/image/solutionHyde.svg';
import solutionPiji from '../../../assets/image/solutionPiji.svg';
import solutionSkinwall from '../../../assets/image/solutionSkinwall.svg';
import solutionTension from '../../../assets/image/solutionTension.svg';
import solutionTruble from '../../../assets/image/solutionTruble.svg';
import { SurveyModalLeavingGuard } from '../userSurvey/SurveyModalLeavingGuard';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'white',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '120rem',
      boxShadow: '0 5px 23px #0000000C',
      marginBottom: '285px',
    },
  },

  contentContainer: {
    [theme.breakpoints.up('sm')]: {
      width: '58.5rem',
      margin: '0px auto',
    },
  },

  productDetailImage: {
    maxWidth: '31.7rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '66.1rem',
    },
  },
  itemContainer: {
    // display: 'flex',
    'alignItems': 'center',
    'backgroundColor': 'white',
    'border': '1px #F0F0F0 solid',
    'boxShadow': '0 5px 23px #0000000C',
    'marginBottom': '6px',
    'padding': '22px 17px',
    'cursor': 'pointer',

    [theme.breakpoints.up('sm')]: {
      padding: '40px 32.5px',
    },

    '& > img': {
      width: '8.1rem',
      height: '14.5rem',
      objectFit: 'contain',

      [theme.breakpoints.up('sm')]: {
        width: '12rem',
        height: '21.5rem',
      },
    },
  },
  solutionImgContainer: {
    margin: '35px auto',
    display: 'inline-block',
    textAlign: 'center',
    width: '100%',
  },

  solutionImg: {
    width: '70px',
    height: '70px',
    background: '#FFFFFF',
    [theme.breakpoints.up('sm')]: {
      width: '108px',
      height: '108px',
    },
  },
  subtitle: {
    'fontSize': '1.5rem',
    // marginTop: '13px',
    'textAlign': 'center',
    'color': '#000000',
    'lineHeight': '2.25rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      lineHeight: '2.9rem',
    },

    '& strong': {
      fontWeight: 'bold',
    },
  },
}));

export const SolutionPage2 = ({ match }): JSX.Element => {
  const classes = useStyles();

  const subscription = useRecoilValue(subscriptionState);
  const isSubscriber = Subscription.isActive(subscription);

  const [membershipProduct, setMembershipProduct] = useRecoilState(
    selectedMembershipProductState,
  );
  const [membershipProducts, setMembershipProducts] = useState<
    MembershipProduct[]
  >([]);
  const selectedMembershipProduct = membershipProducts.find(
    (value) => value.id === membershipProduct?.id,
  );
  const isSelectedMembershipPremium =
    selectedMembershipProduct?.name.includes('Premium');
  const [productDetailInfo, setProductDetailInfo] = useState<
    ProductDetailInfo | undefined
  >(undefined);
  const membershipProductsSectionRef = useRef();
  const setQuizBackground = useSetRecoilState(quizBackgroundState);

  const [authUser, setAuthUser] = useRecoilState(authUserState);
  const isAuthLoading = useRecoilValue(authLoadingState);
  const authCredential = useRecoilValue(authCredentialState);
  const [skinQuizSolution, setSkinQuizSolution] = useRecoilState(
    skinQuizSolutionState,
  );
  const [solutionProducts, setSolutionProducts] = useRecoilState(
    solutionProductsState,
  );
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [skinReportList, setSkinReportList] =
    useRecoilState(skinReportListState);
  const skinReports = skinReportList?.reports;
  const setReportIndex = useSetRecoilState(reportIndexState);
  const index = parseInt(match.params.index?.split(':')[1]);
  const dataIndex = skinReportList?.reports?.length + 1 - index;

  const [whenState, updateWhenState] = useState(false);

  useEffect(() => {
    setReportIndex({ index: index, dataIndex: dataIndex });
  }, [index, dataIndex, setReportIndex]);

  useEffect(() => {
    setIsLoading(true);

    if (isAuthLoading) {
      return;
    }
    if (location.pathname === Routes.skinQuizLatestOrderSolution) {
      const repo = new RemoteUser(authCredential);
      repo
        .getLastCompletedMembershipOrderProducts(authUser?.userId)
        .then(({ products, createdAt }) => {
          setSolutionProducts(
            products?.filter(
              (item) =>
                item.type !== 'subscription' &&
                item.type !== 'subscriptionItem',
            ),
          );
          // setSolutionSuggestedAt(createdAt);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error(err);
          history.replace(Routes.skinQuizSolution + '/:1');
        });

      // 0차면 (index === 1)(기존과 동일)
    } else if (index === 1) {
      if (!skinQuizSolution?.products) {
        if (authUser?.quizId) {
          const repo = new RemoteQuiz(authCredential);
          repo
            .getSkinQuizSolution(authUser?.quizId)
            .then((value) => {
              if (value) {
                setSkinQuizSolution(value);
                setSolutionProducts(
                  value.products?.filter(
                    (item) =>
                      item.type !== 'subscription' &&
                      item.type !== 'subscriptionItem',
                  ),
                );
                setIsLoading(false);
              } else {
                history.replace(Routes.skinQuiz);
              }
            })
            .catch((err) => {
              console.error(err);
              history.replace(Routes.skinQuiz);
            });
        } else {
          history.replace(Routes.skinQuiz);
        }
      } else {
        setSolutionProducts(
          skinQuizSolution?.products?.filter(
            (item) =>
              item.type !== 'subscription' && item.type !== 'subscriptionItem',
          ),
        );
        setIsLoading(false);
      }
    }
    // n차면 솔루션데이터 대신 skinReportList데이터를 SolutionProducts state에 set하기
    else {
      if (!skinReportList?.reports[dataIndex]) {
        // console.log('n차, 데이터 없음');
        if (dataIndex) {
          const repo = new RemoteUser(authCredential);
          repo
            .getUserSkinReportList(authUser?.userId)
            .then((value) => {
              if (value.reports.length > 0) {
                setSkinReportList(value);
              } else {
                history.replace(Routes.skinQuiz);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          history.replace(Routes.skinQuiz);
        }
      } else {
        // console.log('n차, 데이터 있음');

        const getReportData = () => {
          for (let i = dataIndex; i < skinReports.length; i++) {
            // 이 데이터가 있는지?
            if (skinReports[i]?.products) {
              //있다면 해당 데이터 반환
              return skinReports[i].products;
            }
          }
          // skinReports를 모두 돌았는데도 없다면 0차 반환
          return skinQuizSolution?.products;
        };
        setSolutionProducts(
          getReportData().filter(
            (item) =>
              item.type !== 'subscription' && item.type !== 'subscriptionItem',
          ),
        );
        setIsLoading(false);
      }
    }
  }, [
    authCredential,
    authUser?.quizId,
    authUser?.userId,
    dataIndex,
    history,
    index,
    isAuthLoading,
    location.pathname,
    setSkinQuizSolution,
    setSkinReportList,
    setSolutionProducts,
    skinQuizSolution?.products,
    skinReportList?.reports,
    skinReports,
  ]);

  useEffect(() => {
    setQuizBackground(QuizBackground.WHITE);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const repo = new RemoteMembershipProduct();
    repo
      .getMembershipProducts()
      .then((products) => {
        setMembershipProducts(products);
      })
      .catch((err) => {
        console.error(err);
        setMembershipProducts([]);
      });
  }, []);

  const userName = skinQuizSolution?.userName || authUser?.displayName;
  const skinConcerns = skinQuizSolution?.diagnosis.skinConcerns;
  const carePoint = skinQuizSolution?.diagnosis.reportImage.carePoint;
  const solutionImages = {
    solutionCalm: solutionCalm,
    solutionColor: solutionColor,
    solutionDry: solutionDry,
    solutionHyde: solutionHyde,
    solutionPiji: solutionPiji,
    solutionSkinwall: solutionSkinwall,
    solutionTension: solutionTension,
    solutionTruble: solutionTruble,
  };

  useEffect(() => {
    if (authUser?.userId) {
      const repo = new RemoteUser(authCredential);
      repo
        .getUser(authUser?.userId)
        .then((value) => {
          if (value) {
            setAuthUser(value);
          } else {
            history.replace(Routes.mypageSkinQuiz);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      history.replace(Routes.mypageSkinQuiz);
    }
  }, [authCredential, authUser?.userId, history, setAuthUser]);

  useEffect(() => {
    if (skinReports?.length > 0 && !authUser?.settings?.reportFeedbackTime) {
      updateWhenState(true);
    }
  }, [authUser?.settings?.reportFeedbackTime, skinReports]);

  return (
    <>
      <main className={classes.root}>
        <LoadingScreen
          loading={isLoading}
          spinnerColor="#c4c4c4"
          logoSrc={loadingImage}
        >
          <SurveyModalLeavingGuard
            when={whenState}
            navigate={(path) => {
              history.push(path);
            }}
            shouldBlockNavigation={(location) => {
              if (
                !location.pathname.includes(Routes.skinQuizResult) &&
                !location.pathname.includes(Routes.skinQuizSolution) &&
                !location.pathname.includes(
                  Routes.skinQuizUserSatisfactionSurvey,
                )
              ) {
                return true;
              }
              return false;
            }}
          />
          <ScrollToTopOnMount />
          <div className={classes.contentContainer}>
            <HeaderSection />
            <article className={classes.itemContainer}>
              <p className={classes.subtitle}>{userName}님의 피부고민인</p>
              <p className={classes.subtitle}>
                {skinConcerns?.length > 1 && (
                  <strong>{skinConcerns[1]}와(과) </strong>
                )}
                {skinConcerns && <strong>{skinConcerns[0]}을(를)</strong>}
              </p>
              <p className={classes.subtitle}>
                <strong>해결</strong>해주는 최적의 스킨케어 조합입니다.
              </p>
              <div className={classes.solutionImgContainer}>
                {carePoint?.length &&
                  carePoint.map((elem: any) => (
                    <img
                      key={elem}
                      className={classes.solutionImg}
                      src={solutionImages[elem]}
                    />
                  ))}
              </div>
              <ProductsSection
                setProductDetailInfo={setProductDetailInfo}
                products={solutionProducts}
              />
            </article>
            <div ref={membershipProductsSectionRef} />
            {!isSubscriber && (
              <MembershipProductsSection
                products={membershipProducts}
                selectedProduct={selectedMembershipProduct}
                setSelectedProduct={setMembershipProduct}
              />
            )}
            <FeatureGraphicSection />
            {isSubscriber && <InstallSkinLogAppSection />}
          </div>

          {!isSubscriber && (
            <FloatingBuyButton
              hasSelectedProduct={
                selectedMembershipProduct !== undefined &&
                !isSelectedMembershipPremium
              }
              membershipProductsSectionRef={membershipProductsSectionRef}
            />
          )}

          <Dialog
            open={productDetailInfo !== undefined}
            onClose={() => setProductDetailInfo(undefined)}
          >
            <Fade in={productDetailInfo !== undefined}>
              <ProductDetailModal productDetailInfo={productDetailInfo} />
            </Fade>
          </Dialog>
        </LoadingScreen>
      </main>
    </>
  );
};
