import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { Keys } from '../config';
import { AnalyticsService } from '../data/services/AnalyticsService';
import { AuthCredential, AuthService } from '../data/services/AuthService';
import { Invoice } from '../domain/entities/Invoice';
import { IPaymentPrice, PaymentPrice } from '../domain/entities/Payment';
import { SkinQuizAnswerSheet } from '../domain/entities/SkinQuizAnswerSheet';
import { SkinQuizSolution } from '../domain/entities/SkinQuizSolution';
import { Subscription } from '../domain/entities/Subscription';
import { User } from '../domain/entities/User';
import { Membership } from '../domain/entities/Membership';
import { MembershipProduct } from '../domain/entities/MembershipProduct';
import // defaultLandingText,
// LandingText,
'../domain/entities/LandingText';
import { defaultLandingText, LandingText } from '../data/landingPage';
import { Product } from '../domain/entities/Product';
import { QuizBackground } from '../domain/entities/QuizBackground';
import { SkinReportList } from '../domain/entities/SkinReportList';
import ReportIndex from '../domain/entities/ReportIndex';

const authStore = recoilPersist({
  key: Keys.authStore,
  storage: localStorage,
});

const quizStore = recoilPersist({
  key: Keys.quizStore,
  storage: localStorage,
});

export const authService = atom<AuthService>({
  key: Keys.authService,
  default: undefined,
});

export const analyticsService = atom<AnalyticsService>({
  key: Keys.analyticsService,
  default: undefined,
});

export const authCredentialState = atom<AuthCredential>({
  key: Keys.authCrdentialState,
  default: undefined,
  effects_UNSTABLE: [authStore.persistAtom],
});

export const authUserState = atom<User | undefined>({
  key: Keys.authUserState,
  default: undefined,
});

export const authLoadingState = atom<boolean>({
  key: Keys.authLoadingState,
  default: true,
});

export const subscriptionState = atom<Subscription | undefined>({
  key: Keys.subscriptionState,
  default: undefined,
});

export const upcomingInvoiceState = atom<Invoice>({
  key: Keys.upcomingInvoiceState,
  default: undefined,
});

export const skinQuizAnswerSheetState = atom<SkinQuizAnswerSheet>({
  key: Keys.skinQuizAnswerSheetState,
  default: {},
  effects_UNSTABLE: [quizStore.persistAtom],
});

export const skinQuizSimpleAnswerSheetState = atom<SkinQuizAnswerSheet>({
  key: Keys.skinQuizSimpleAnswerSheetState,
  default: {},
  effects_UNSTABLE: [quizStore.persistAtom],
});

export const skinQuizSolutionState = atom<SkinQuizSolution | undefined>({
  key: Keys.skinQuizSolutionState,
  default: undefined,
});

export const solutionProductsState = atom<Product[] | undefined>({
  key: Keys.solutionProductsState,
  default: undefined,
});

export const showCardOnlyState = atom<boolean>({
  key: Keys.showCardOnlyState,
  default: true,
});

export const loginEmailState = atom({
  key: Keys.loginEmailState,
  default: '',
  effects_UNSTABLE: [authStore.persistAtom],
});

export const forgotUserState = atom({
  key: Keys.forgotUserState,
  default: {
    email: '',
    createdAt: '',
  },
});

export const paymentCouponState = atom({
  key: Keys.paymentCouponState,
  default: '',
});

const paymentPriceAtom = atom<IPaymentPrice>({
  key: Keys.paymentPriceState,
  default: {
    price: 39000,
    discount: 0,
    promotion: 0,
  },
});

export const paymentPriceState = selector<PaymentPrice>({
  key: Keys.paymentPriceSelector,
  get: ({ get }) => new PaymentPrice(get(paymentPriceAtom)),
  set: ({ set }, newValue) => set(paymentPriceAtom, newValue),
});

export const appBarTitleState = atom({
  key: Keys.appBarTitleState,
  default: '',
});

export const errorMessageState = atom<string | JSX.Element>({
  key: Keys.errorMessageState,
  default: '',
});

export const errorDialogButtonTextState = atom<string>({
  key: Keys.errorDialogButtonTextState,
  default: '',
});

export const kakaoFabState = atom({
  key: Keys.kakaoFabState,
  default: true,
});

export const kakaoFabBottomExtraMarginState = atom<string | undefined>({
  key: Keys.kakaoFabBottomExtraMarginState,
  default: undefined,
});

export const quizBackgroundState = atom<QuizBackground | undefined>({
  key: 'quizBackgroundState',
  default: undefined,
});

export const notFoundMatchState = atom({
  key: 'notFoundMatchState',
  default: {},
});

export const userMembershipsState = atom<Membership[]>({
  key: Keys.userMembershipsState,
  default: [],
});

export const selectedMembershipProductState = atom<
  MembershipProduct | undefined
>({
  key: Keys.selectedMembershipProductState,
  default: undefined,
});

export const selectedFirstDeliveryDateState = atom<Date | undefined>({
  key: Keys.selectedFirstDeliveryDateState,
  default: undefined,
});

export const landingTextState = atom<LandingText>({
  key: Keys.landingTextState,
  default: defaultLandingText,
});

export const useWhiteTextInToolbarState = atom<boolean>({
  key: Keys.useWhiteTextInToolbarState,
  default: false,
});

export const skinReportListState = atom<SkinReportList | undefined>({
  key: Keys.skinReportListState,
  default: undefined,
});

export const skinSubjectState = atom<string | undefined>({
  key: Keys.skinSubjectState,
  default: 'hydration',
});

export const currentImgPartOrderState = atom<string | undefined>({
  key: Keys.currentImgPartOrderState,
  default: undefined,
});

export const reportIndexState = atom<ReportIndex | undefined>({
  key: Keys.reportIndexState,
  default: {
    index: 1,
    dataIndex: 0,
  },
});

export const eventPopupState = atom({
  key: Keys.eventPopupState,
  default: {},
  effects_UNSTABLE: [authStore.persistAtom],
});
