export class Keys {
  static readonly authStore = 'authStore';
  static readonly quizStore = 'quizStore2';
  static readonly authService = 'authService';
  static readonly analyticsService = 'analyticsService';
  static readonly authCrdentialState = 'authCrendentialState';
  static readonly authUserState = 'authUserState';
  static readonly authLoadingState = 'authLoadingState';
  static readonly subscriptionState = 'subscriptionState';
  static readonly userOrdersState = 'userOrdersState';
  static readonly userMembershipsState = 'userMembershipsState';
  static readonly upcomingInvoiceState = 'upcomingInvoiceState';
  static readonly loginEmailState = 'loginEmailState';
  static readonly forgotUserState = 'forgotUserState';
  static readonly skinQuizCategoryState = 'skinQuizCategoryState';
  static readonly skinQuizAnswerSheetState = 'skinQuizAnswerSheetState';
  static readonly skinQuizSimpleAnswerSheetState =
    'skinQuizSimpleAnswerSheetState';
  static readonly skinQuizSolutionState = 'skinQuizSolutionState';
  static readonly solutionProductsState = 'solutionProductsState';
  static readonly viewModeState = 'viewModeState';
  static readonly showCardOnlyState = 'showCardOnlyState';
  static readonly paymentPriceState = 'paymentPriceState';
  static readonly paymentPriceSelector = 'paymentPriceSelector';
  static readonly paymentCouponState = 'paymentCouponState';
  static readonly appBarTitleState = 'appBarTitleState';
  static readonly errorMessageState = 'errorMessageState';
  static readonly errorDialogButtonTextState = 'errorDialogButtonTextState';
  static readonly kakaoFabState = 'kakaoFabState';
  static readonly kakaoFabBottomExtraMarginState =
    'kakaoFabBottomExtraMarginState';
  static readonly selectedMembershipProductState =
    'selectedMembershipProductState';
  static readonly selectedFirstDeliveryDateState =
    'selectedFirstDeliveryDateState';
  static readonly landingTextState = 'landingTextState';
  static readonly useWhiteTextInToolbarState = 'useWhiteTextInToolbarState';
  static readonly skinReportListState = 'skinReportListState';
  static readonly skinSubjectState = 'skinSubjectState';
  static readonly reportIndexState = 'reportIndexState';
  static readonly currentImgPartOrderState = 'currentImgPartOrderState';
  static readonly eventPopupState = 'eventPopupState';
}
