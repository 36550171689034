export class Routes {
  static readonly root = '/';
  static readonly notFound = '/not-found';
  static readonly login = '/login';
  static readonly forgotEmail = '/login/forgot-email';
  static readonly forgotEmailResult = '/login/forgot-email/result';
  static readonly forgotPassword = '/login/forgot-password';
  static readonly forgotPasswordResult = '/login/forgot-password/result';
  static readonly skinQuiz = '/skinquiz';
  static readonly skinQuizSimple = '/skinquiz/simple';
  static readonly skinQuizName = '/skinquiz/name';
  static readonly skinQuizGender = '/skinquiz/gender';
  static readonly skinQuizAge = '/skinquiz/age';
  static readonly skinQuizBasic = '/skinquiz/basic';
  static readonly skinQuizOverall = '/skinquiz/overall';
  static readonly skinQuizProduct = '/skinquiz/products';
  static readonly skinQuizProcessing = '/skinquiz/processing';
  static readonly skinQuizResult = '/skinquiz/result';
  static readonly skinQuizResultUser = '/skinquiz/result/user';
  static readonly skinQuizResultShare = '/skinquiz/result/share';
  static readonly skinQuizSolution = '/skinquiz/solution';
  static readonly skinQuizLatestOrderSolution =
    '/skinquiz/latest-order-solution';
  static readonly skinQuizUserSatisfactionSurvey =
    '/skinquiz/user-satisfaction-survey';
  static readonly skinQuizUserSatisfactionSurveyFinish =
    '/skinquiz/user-satisfaction-survey/finish';
  static readonly skinQuizReturn = '/skinquiz/return';
  static readonly skinQuizLogin = '/skinquiz/login';
  static readonly skinQuizSignUp = '/skinquiz/signup';
  static readonly skinQuizAddress = '/skinquiz/address';
  static readonly skinQuizSelectFirstDeliveryDate =
    '/skinquiz/select-first-delivery-date';
  static readonly skinQuizPayment = '/skinquiz/payment';
  static readonly skinQuizSentEmail = '/skinquiz/sent-email';
  static readonly story = '/story';
  static readonly support = '/support';
  static readonly mypage = '/mypage';
  static readonly mypageUser = '/mypage/user';
  static readonly mypageUserAddress = '/mypage/user/address';
  static readonly mypageUserOptOut = '/mypage/user/optout';
  static readonly mypageSubscription = '/mypage/subscription';
  static readonly mypageSubscriptionCancel = '/mypage/user/subscription/cancel';
  static readonly mypageSubscriptionPause = '/mypage/user/subscription/pause';
  static readonly mypageSubscriptionResume = '/mypage/user/subscription/resume';
  static readonly mypageSubscriptionDate = '/mypage/user/subscription/date';
  static readonly mypageSubscriptionDateRestart =
    '/mypage/user/subscription/date/restart';
  static readonly mypageSubscriptionInterval =
    '/mypage/user/subscription/interval';
  static readonly mypageSkinQuiz = '/mypage/skinquiz';
  static readonly mypageMembership = '/mypage/membership';
  static readonly mypageMembershipChangeDeliveryDate =
    '/mypage/membership/change-delivery-date';
  static readonly aboutMembership = '/about-membership';

  static readonly signUp = '/signup';

  static readonly useToolbarMenu = (path) =>
    [
      Routes.login,
      Routes.signUp,
      Routes.mypageUser,
      Routes.mypageUserAddress,
      Routes.mypageUserOptOut,
    ].indexOf(path) === -1;
}
