import React from 'react';
import {
  CheckIcon,
  OptionItemCheckBox,
  OptionItemContainer,
  OptionItemLabel,
  OptionItemRadioInput,
  QuestionContainer,
  QuestionOptionsBlock,
  QuestionTitleBlock,
} from '../StyledComponents';
import { QuestionProps } from './QuestionProps';

function RadioQuestion({
  question,
  answerSheet,
  setAnswerSheet,
}: QuestionProps) {
  const questionId = `question${question.id}`;

  const onClick = (question, target) => {
    const newInputs = { ...answerSheet };
    newInputs[`question${question.id}`] = target.htmlFor;
    setAnswerSheet(newInputs);
  };

  const showCheck = (option) => {
    return answerSheet[questionId] === String(option.id);
  };

  return (
    <QuestionContainer key={question.id}>
      <QuestionTitleBlock>{`${question.idSub}.${question.title}`}</QuestionTitleBlock>
      <QuestionOptionsBlock>
        {question.options.map((option) => (
          <OptionItemContainer key={option.id}>
            <OptionItemRadioInput />
            <OptionItemLabel
              htmlFor={option.id}
              onClick={({ currentTarget }) => onClick(question, currentTarget)}
            >
              <OptionItemCheckBox>
                <CheckIcon showCheck={showCheck(option)} />
              </OptionItemCheckBox>
              {option.text}
            </OptionItemLabel>
          </OptionItemContainer>
        ))}
      </QuestionOptionsBlock>
    </QuestionContainer>
  );
}

export default RadioQuestion;
