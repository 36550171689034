import React from 'react';
import Fab from '@material-ui/core/Fab';
import { useRecoilValue } from 'recoil';
import { kakaoFabBottomExtraMarginState, kakaoFabState } from '../states';
import KakaoIcon from '../../assets/icon/icon_kakaotalk.png';
import { useMediaQuery, useTheme } from '@material-ui/core';

export function KakaoFab() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const kakaoFab = useRecoilValue(kakaoFabState);
  const bottomExtraMargin = useRecoilValue(kakaoFabBottomExtraMarginState);

  const openKakaoChat = () => {
    window['Kakao'].Channel.chat({
      channelPublicId: '_cSxaxoxb',
    });
  };

  const right = smUp ? '4.4rem' : '2rem';
  const bottom = `calc(${smUp ? '8.8rem' : '2rem'} + ${
    bottomExtraMargin !== undefined ? bottomExtraMargin : '0px'
  })`;

  return (
    <Fab
      aria-label="kakaotalk"
      size={smUp ? 'large' : 'medium'}
      style={{
        right,
        bottom,
        position: 'fixed',
        display: !kakaoFab ? 'none' : undefined,
        zIndex: 99,
      }}
    >
      <img src={KakaoIcon} onClick={openKakaoChat} style={{ width: '100%' }} />
    </Fab>
  );
}
