import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { Theme } from '@material-ui/core';
import { DeliveryDateInfoItem } from './DeliveryDateInfoItem';

type Props = {
  selectedDate: Date;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    marginLeft: '5px',
    marginTop: '17px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'start',

    [theme.breakpoints.up('sm')]: {
      marginTop: '0',
    },
  },

  divider: {
    width: '30px',
    height: '2px',
    margin: '0',
    backgroundColor: 'black',
    border: 'none',

    [theme.breakpoints.up('sm')]: {
      height: '3px',
    },
  },
}));

export const DeliveryDateInfo = ({ selectedDate }: Props): JSX.Element => {
  const classes = useStyles();

  function formatDate(date: Date): string {
    return format(date, 'yyyy.MM.dd');
  }

  return (
    <section className={classes.root}>
      <hr className={classes.divider} />
      <DeliveryDateInfoItem
        title={'첫 발송일'}
        subtitle={formatDate(selectedDate)}
      />
      <DeliveryDateInfoItem title={'발송 주기'} subtitle={'14일마다'} />
    </section>
  );
};
