import React from 'react';
import { makeStyles, Theme, } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        paddingTop: '42px',

        [theme.breakpoints.up('sm')]: {
            paddingTop: '90px',
        },
    },

    title: {
        display: 'block',
        textAlign: 'center',
        fontSize: '2rem',
        lineHeight: '131%',
        fontWeight: 'bold',

        [theme.breakpoints.up('sm')]: {
            fontSize: '3.2rem',
            lineHeight: '150%',
        },
    },
}));

export const DeliveryDateHeader = (): JSX.Element => {
    const classes = useStyles();

    return (
        <section className={classes.root}>
            <span className={classes.title}>첫 발송일 선택</span>
        </section>
    );
};
