import {
  QuizRepository,
  QuizType,
} from '../../domain/repositories/QuizRepository';
import { RemoteApiClient } from '../helpers/RemoteApiClient';
import { SkinQuizAnswerSheet } from '../../domain/entities/SkinQuizAnswerSheet';
import { AuthCredential } from '../services/AuthService';
import { settings } from '../../config';
import { RemoteQuizMapper } from '../mappers/RemoteQuizMapper';
import { SkinQuizSolution } from '../../domain/entities/SkinQuizSolution';
import { SkinQuizResult } from '../../domain/entities/SkinQuizResult';
import { TotalQuizCount } from '../../domain/entities/Quiz';

export class RemoteQuiz extends QuizRepository {
  constructor(authCredential?: AuthCredential) {
    super();

    this._api = new RemoteApiClient({
      baseUrl: settings.apiEndpoint,
      authCredential,
    });
  }

  private _api: RemoteApiClient;

  public async submitSkinQuiz(
    answerSheet: SkinQuizAnswerSheet,
    quizType: QuizType = 'skin_email',
  ): Promise<SkinQuizResult> {
    const data = await this._api.request({
      method: 'post',
      path: 'quizzes',
      data: {
        quizType,
        answer_sheet: answerSheet,
      },
    });
    return RemoteQuizMapper.toSkinQuizResult(data);
  }

  public async getSkinQuizSolution(quizId: string): Promise<SkinQuizSolution> {
    const data = await this._api.request({
      method: 'get',
      path: `quizzes/${quizId}/solution`,
    });
    return RemoteQuizMapper.toSkinQuizSolution(data);
  }

  public async getTotalQuizCount(): Promise<TotalQuizCount> {
    const data = await this._api.request({
      method: 'get',
      path: `quizzes/count`,
    });
    return RemoteQuizMapper.toTotalQuizCount(data);
  }

  public async sendSkinQuizResultEmail(
    quizId: string,
    email: string,
  ): Promise<void> {
    await this._api.request({
      method: 'post',
      path: `quizzes/${quizId}/sendEmail`,
      data: {
        email,
      },
    });
  }
}
