import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import featureImage from '../../../assets/image/skinquiz_solution_feature_image.png';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'marginTop': '62px',
    'marginBottom': '48px',
    'backgroundColor': '#F6F6F6',
    'textAlign': 'center',
    'paddingBottom': '34px',

    [theme.breakpoints.up('sm')]: {
      marginTop: '86px',
    },

    '& > img': {
      width: '100vw',
      height: 'calc(100vw / (360 / 296))',
      objectFit: 'cover',

      [theme.breakpoints.up('sm')]: {
        width: '58.5rem',
        height: '50.8rem',
      },
    },

    '& strong': {
      fontWeight: 'bold',
    },

    '& > p': {
      'marginBottom': '18px',
      'fontSize': '1.4rem',
      'lineHeight': '135%',

      [theme.breakpoints.up('sm')]: {
        marginBottom: '34px',
        fontSize: '1.8rem',
      },

      '&:nth-child(2)': {
        marginTop: '38px',
        fontSize: '2rem',

        [theme.breakpoints.up('sm')]: {
          marginTop: '51px',
          fontSize: '3rem',
        },
      },
    },
  },
}));

export const FeatureGraphicSection = (): JSX.Element => {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <img src={featureImage} alt={''} />
      <p>
        당신만을 위한 <strong>피부 버틀러</strong>
      </p>
      <p>
        피부 관리 어렵고 귀찮으셨다면,
        <br />
        매니폴드 AI가 알아서 다 해드려요.
      </p>
      <p>
        내 피부 상태와 잘 맞는 성분의 3종 세트가
        <br />
        주기적으로 집 앞으로 배송되는
        <br />
        <strong>매니폴드 AI 스킨케어 멤버십을 경험</strong>해보세요.
      </p>
    </section>
  );
};
