import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BoxDivider } from './../styles';

type Props = {
  typeComment?: string;
  skinType?: string;
  userName?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    display: 'flex',
    flexDirection: 'column',
    color: '#6A6A6A',
    alignItems: 'center',
    textAlign: 'center',
    padding: '2rem 1.6rem',
    boxShadow: '0px 4px 29px rgba(0, 0, 0, 0.1)',
    margin: '1.3rem 0',
    [theme.breakpoints.up('sm')]: {
      padding: '2rem 7.2rem',
    },
  },

  title: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.8rem',
    },
  },

  subtitle: {
    fontSize: '1.4rem',
    fontWeight: 'bold',
    color: '#000',
    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      fontWeight: 'normal',
    },
  },

  body: {
    color: '#6a6a6a',
    fontSize: '1.4rem',
    fontWeight: 'normal',
    lineHeight: '2.4rem',
    textAlign: 'left',
    marginBottom: '15px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.7rem',
      lineHeight: '2.8rem',
      textAlign: 'center',
    },
  },

  skinType: {
    fontSize: '1.4rem',
    lineHeight: '2rem',
    fontWeight: 'bold',
    border: '1px solid #F0F0F0',
    boxSizing: 'border-box',
    borderRadius: '4px',
    padding: '2px 10px 4px',
    color: '#8D93FF',

    [theme.breakpoints.up('sm')]: {
      fontWeight: 'bold',
      border: '2.16px solid #F0F0F0',
      boxSizing: 'border-box',
      borderRadius: '4px',
      padding: '4.3px 21.5px 8.7px 21.5px',
      fontSize: '3rem',
      lineHeight: '44px',
      color: '#8D93FF',
    },
  },

  isMobileSubtitle: {
    fontWeight: 'normal',
    [theme.breakpoints.up('sm')]: {
      fontWeight: 'bold',
    },
  },
  desktophide: {
    display: 'block',

    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export const SkinChartSummaryBox = ({
  typeComment,
  skinType,
  userName,
}: Props): JSX.Element => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <Typography className={classes.subtitle}>
        <span style={{ fontWeight: 'bold' }}>{userName}</span>
        <span className={classes.isMobileSubtitle}>님의 피부는 32가지 중 </span>
        <br />
        <br />
        <span className={classes.skinType}>{`${skinType} `}</span>
        <br />
        <br />
        <span
          style={{ marginBottom: '10px' }}
          className={classes.desktophide + ' ' + classes.isMobileSubtitle}
        >
          타입에 해당합니다.
        </span>
      </Typography>

      <BoxDivider className={classes.desktophide} />
      <Typography className={classes.body}>
        {skinType}는 {typeComment}
      </Typography>
    </Box>
  );
};
