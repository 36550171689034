import React, { useState } from 'react';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import { getIconImageSrc } from '../styles';
import Slider from 'react-slick';
const imgParttoKor = {
  head: '이마',
  left: '왼볼',
  mouth: '입',
  nose: '코',
  right: '오른볼',
};
const imgPartOrder = [
  'cropHead',
  'cropLeft',
  'cropMouth',
  'cropNose',
  'cropRight',
];
const skinSubjectObj = {
  수분보유력: 'hydration',
  유분보유력: 'oily',
  저항성: 'sensitive',
  비색소성: 'pigmentation',
  탄력성: 'wrinkle',
};
import { skinTypePhoto } from '../../../domain/entities/SkinReportList';
import styled from 'styled-components';

const HitMapButton = styled('button')<{ clicked: boolean }>`
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 400;

  background-color: ${(p) => (p.clicked ? '#8BB3FF' : '#F1F1F1')};
  color: ${(p) => (p.clicked ? 'white' : '#323130')};
  border-radius: 45px;
  transition: all 150ms ease;
  cursor: pointer;
  border: none;
`;
const HitMapButtonContainer = styled('div')`
  background-color: #f1f1f1;
  border-radius: 300px;
  text-align: center;
`;
const ImgSlider = styled(Slider)`
  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 601px) {
    .slick-track .slick-slide {
      height: 500px;
    }
  }
  @media only screen and (max-width: 600px) {
    .slick-track .slick-slide {
      height: 284px;
    }
  }
`;
export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    textAlign: 'center',
    margin: 'auto',
    border: 'none',
  },
  hitmapImgContainer: {
    width: '284px',
    height: '284px',
    backgroundColor: '#EBEBEB',
    lineHeight: '4px',

    [theme.breakpoints.up('sm')]: {
      width: '500px',
      height: '500px',
    },
  },
  hitmapImgSlider: {
    width: 'inherit',
    maxHeight: '100%',
    isplay: 'flex',
    margin: 'auto',
  },

  hitmapBackground: {
    width: '284px',
    lineHeight: '284px',
    verticalAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
      lineHeight: '500px',
    },
  },
  hitmapImg: {
    width: '100%',
    height: '100%',
    verticalAlign: 'middle',
  },
  hitmapDesc: {
    marginTop: '11px',
    marginBottom: '24px',
    fontSize: '1.2rem',
    color: '#6A6A6A',
    lineHeight: '155%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '9px',
      fontSize: '1.6rem',
    },
  },
  hitMapButtonContainer: {
    width: '284px',
    padding: '7px 4px',
    display: 'inline-flex',
    // gridTemplateColumns:'repeat(5, 1fr)',
    justifyContent: 'space-between',
    borderRadius: '35px',
    [theme.breakpoints.up('sm')]: {
      width: '500px',
      padding: '8px 5px',
      borderRadius: '53px',
    },
  },
  hitMapButton: {
    fontSize: '1.1rem',
    padding: '7px 3px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.6rem',
      margin: '0 4px',
      padding: '9px 13px',
    },
  },
  chevronBtn: {
    width: '30px',
    height: '30px',
    margin: 'auto',
  },
}));

const ChevRonNextButton = (props): JSX.Element => {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={getIconImageSrc(`chevron-right-30`)}
    />
  );
};
const ChevRonPrevButton = (props): JSX.Element => {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      onClick={onClick}
      src={getIconImageSrc(`chevron-left-30`)}
    />
  );
};

const ImgPartName = (props: { currentImgPartOrder }): JSX.Element => {
  const currentImgPartName =
    imgParttoKor[Object.keys(imgParttoKor)[props.currentImgPartOrder]];
  return (
    <Typography style={{ margin: '14px', color: '#C4C4C4' }}>
      {currentImgPartName}
    </Typography>
  );
};

const HitMapImg = (props: {
  skinSubject: string;
  axisPhoto: skinTypePhoto;
}): JSX.Element => {
  const classes = useStyles();
  const [currentImgPartOrder, setCurrentImgPartOrder] = useState<number>(0);

  const settings = {
    nextArrow: <ChevRonNextButton />,
    prevArrow: <ChevRonPrevButton />,
    afterChange: (current: number) => {
      setCurrentImgPartOrder(current);
    },
  };

  return (
    <>
      <Box className={classes.hitmapImgContainer} style={{ display: 'flex' }}>
        <ImgSlider
          {...settings}
          style={{
            width: 'inherit',
            maxHeight: 'inherit',
            isplay: 'flex',
            margin: 'auto',
          }}
        >
          {imgPartOrder.map((elem, index) => {
            return (
              <div
                key={index}
                style={{
                  display: 'flex',
                  height: 'auto',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  className={classes.hitmapImg}
                  src={props.axisPhoto[props.skinSubject][elem]}
                />
              </div>
            );
          })}
        </ImgSlider>
      </Box>
      <ImgPartName currentImgPartOrder={currentImgPartOrder} />
    </>
  );
};

// 히트맵이미지슬라이더가 들어갈 컴포넌트
function HitmapImgSlider(props: { axisPhoto: skinTypePhoto }): JSX.Element {
  const classes = useStyles();
  const [currentSkinSubject, setCurrentSkinSubject] = useState('hydration');
  const [buttonActiveState, setButtonActiveState] = useState([
    true,
    false,
    false,
    false,
    false,
  ]);

  const onClickHandler = (e) => {
    const target = (e.target as HTMLTextAreaElement).innerText;
    if (target.length > 5) return;
    const targetIndex = Object.keys(skinSubjectObj).indexOf(target);
    const stateArray = new Array(5).fill(false);
    stateArray[targetIndex] = true;
    setButtonActiveState(stateArray);
    setCurrentSkinSubject(skinSubjectObj[target]);
  };

  return (
    <Box className={classes.root}>
      <HitMapImg skinSubject={currentSkinSubject} axisPhoto={props.axisPhoto} />
      <HitMapButtonContainer
        className={classes.hitMapButtonContainer}
        onClick={(e) => onClickHandler(e)}
      >
        <HitMapButton
          className={classes.hitMapButton}
          clicked={buttonActiveState[0]}
          key="Hydration"
        >
          수분보유력
        </HitMapButton>
        <HitMapButton
          className={classes.hitMapButton}
          clicked={buttonActiveState[1]}
          key="Oily"
        >
          유분보유력
        </HitMapButton>
        <HitMapButton
          className={classes.hitMapButton}
          clicked={buttonActiveState[2]}
          key="Sensitive"
        >
          저항성
        </HitMapButton>
        <HitMapButton
          className={classes.hitMapButton}
          clicked={buttonActiveState[3]}
          key="Pigment"
        >
          비색소성
        </HitMapButton>
        <HitMapButton
          className={classes.hitMapButton}
          clicked={buttonActiveState[4]}
          key="Wrinkle"
        >
          탄력성
        </HitMapButton>
      </HitMapButtonContainer>

      <Typography className={classes.hitmapDesc}>
        보내주신 모든 사진 데이터를 기반으로 처방되지만
        <br />
        피부 리포트에는 가장 최근 사진만 보여집니다
      </Typography>
    </Box>
  );
}

export default HitmapImgSlider;
