import React, { Dispatch } from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { PaymentMethod } from '../../../domain/entities/PaymentMethod';
import imgNaverPay from '../../../assets/image/skinquiz/img_naver_pay.png';
import imgKakaoPay from '../../../assets/image/skinquiz/img_kakao_pay.png';
import useMediaQuery from '@material-ui/core/useMediaQuery';

type Props = {
  setPaymentMethod: Dispatch<PaymentMethod>;
  selectedPaymentMethod?: PaymentMethod;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '0 42px',
    margin: '8px 0 0',
    [theme.breakpoints.up('sm')]: {
      padding: '35px 85px',
    },
  },

  title: {
    display: 'block',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    margin: '18px 0 18px',
  },

  button: {
    width: '100%',
    height: '3.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    border: '1.5px solid #C4C4C4',
    marginBottom: '5px',
    background: 'none',
    cursor: 'pointer',

    [theme.breakpoints.up('sm')]: {
      marginBottom: '7px',
    },
  },

  selected: {
    border: '1.5px solid black',
    cursor: 'inherit',
  },
}));

export const SelectPaymentMethodSection = ({
  setPaymentMethod,
  selectedPaymentMethod,
}: Props): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <section className={classes.root}>
      <span className={classes.title}>결제 수단</span>
      <button
        className={`${classes.button} ${
          selectedPaymentMethod === PaymentMethod.CARD && classes.selected
        }`}
        onClick={() => setPaymentMethod(PaymentMethod.CARD)}
      >
        신용 / 체크 카드
      </button>
      <button
        className={`${classes.button} ${
          selectedPaymentMethod === PaymentMethod.NAVER_PAY && classes.selected
        }`}
        onClick={() => setPaymentMethod(PaymentMethod.NAVER_PAY)}
      >
        <img
          src={imgNaverPay}
          alt={'네이버 페이'}
          width={smUp ? '51px' : '43px'}
          height={smUp ? '20px' : '17px'}
        />
      </button>
      <button
        className={`${classes.button} ${
          selectedPaymentMethod === PaymentMethod.KAKAO_PAY && classes.selected
        }`}
        onClick={() => setPaymentMethod(PaymentMethod.KAKAO_PAY)}
      >
        <img
          src={imgKakaoPay}
          alt={'카카오 페이'}
          width={smUp ? '49px' : '42px'}
          height={smUp ? '15px' : '13px'}
        />
      </button>
    </section>
  );
};
