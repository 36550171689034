import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ScreenSize } from './ScreenSize';

export default function useScreenSize(): ScreenSize {
  const theme = useTheme();
  const mobileUp = useMediaQuery(theme.breakpoints.up(810));
  const desktopSmUp = useMediaQuery(theme.breakpoints.up(1200));

  if (!mobileUp) {
    return ScreenSize.MOBILE;
  } else if (!desktopSmUp) {
    return ScreenSize.DESKTOP_SM;
  } else {
    return ScreenSize.DESKTOP_MD;
  }
}
