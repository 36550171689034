import { Divider, makeStyles, Theme, withStyles } from '@material-ui/core';

const icons = require.context('../../assets/image', true, /.svg$/);
const pngs = require.context('../../assets/image', true, /.png$/);
const jpgs = require.context('../../assets/image', true, /.jpg$/);

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.8rem',
    marginBottom: '0.8rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1.6rem',
    marginBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      padding: '3rem 8rem',
    },
  },
  box: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'stretch',
    flexGrow: 1,
    alignItems: 'center',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: '1rem 2rem 2rem 2rem',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 400,
    marginTop: '2rem',
    marginBottom: '2rem',
  },
  subtitle: {
    marginTop: '2rem',
    fontWeight: 'bold',
  },
  body1: {
    maxWidth: '40rem',
    marginTop: '2rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60rem',
    },
  },
  body2: {
    marginTop: '2rem',
    fontFamily: 'Nanum Myeongjo;',
    textAlign: 'left',
  },
  body3: {
    marginTop: 'rem',
    fontFamily: 'Nanum Myeongjo;',
    textAlign: 'center',
  },
  body4: {
    marginTop: 'rem',
    fontFamily: 'Nanum Myeongjo;',
    // textAlign: 'center',
    margin: '0.8rem',
  },
  body5: {
    marginTop: 'rem',
    fontFamily: 'Nanum Myeongjo;',
    textAlign: 'left',
    margin: '0.8rem',
  },
  skinImage: {
    width: '8rem',
    height: '8rem',
    marginBottom: '2rem',
    margin: '-0.1rem',
    [theme.breakpoints.up('sm')]: {
      width: '16rem',
      height: '16rem',
      marginBottom: '2rem',
      margin: '-0.8rem',
    },
  },
  careImage: {
    width: '10rem',
    height: '10rem',
    marginBottom: '2rem',
    margin: '-1.2rem',
    [theme.breakpoints.up('sm')]: {
      width: '18rem',
      height: '18rem',
      marginBottom: '2rem',
      margin: '-1.5rem',
    },
  },
  ingredientContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignSelf: 'stretch',
    },
  },
  ingredientBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    marginTop: '5rem',
    marginBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '18.7rem',
    },
  },
  ingredientImage: {
    marginBottom: '2rem',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '18.7rem',
      height: '12.3rem',
    },
  },
  ingredientTitle: {
    marginTop: '1rem',
    marginBottom: '1rem',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.5rem',
    },
  },
  ingredientBody: {
    fontFamily: 'Nanum Myeongjo;',
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      fontSize: '1.3rem',
      maxWidth: '18.7rem',
    },
  },
  introProfile: {
    width: '14.4rem',
    height: '28.9rem',
    margin: '0.6rem 0.8rem',
  },
  creamImage: {
    width: '26.2rem',
    height: '26.2rem',
    marginTop: '1.9rem',
    [theme.breakpoints.up('sm')]: {
      width: '45rem',
      height: '45rem',
    },
  },
  productImage: {
    width: '10rem',
    height: '11rem',
    marginRight: '1rem',
    [theme.breakpoints.up('sm')]: {
      width: '16.5rem',
      height: '16.5rem',
      marginRight: '2rem',
    },
  },
  productSubTitle: {
    fontWeight: 'bold',
    marginBottom: '0.5rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '1rem',
    },
  },
  arrowDown: {
    marginTop: '3.5rem',
    marginBottom: '2.0rem',
    width: 0,
    height: 0,
    borderLeft: '2rem solid transparent',
    borderRight: '2rem solid transparent',
    borderTop: '3rem solid #c9c9c9',
    [theme.breakpoints.up['sm']]: {},
  },
  tagBox: {
    display: 'flex',
    padding: '0.6rem 0.6rem 0.6rem',
    marginLeft: '0.5rem',
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderRadius: '44.6px',
  },
  productContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  productBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    borderColor: '#c9c9c9',
    borderStyle: 'solid',
    borderWidth: 'thin',
    marginBottom: '1.3rem',
    padding: '1.4rem 1.8rem',
    backgroundColor: '#fff',

    [theme.breakpoints.up['sm']]: {
      height: '23.9rem',
      width: '64.7rem',
      padding: '3.7rem 2.9rem',
    },
  },
  paymentBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    borderColor: '#c9c9c9',
    borderStyle: 'solid',
    borderWidth: 'thin',
    padding: '2rem',
    marginBottom: '6.9rem',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {
      padding: '7rem 12.3rem',
      marginBottom: '14rem',
    },
  },
  paymentImage: {
    width: '100%',
    marginBottom: '1.3rem',
  },
  paymentCard: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'border-bottom: 0.5px solid #c4c4c4',
    padding: '1rem',
    margin: '1rem 0',
  },
  paymentCardInput: {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: 'border-bottom: 0.5px solid #c4c4c4',
    paddingBottom: '0.6rem',
    margin: '1rem 0',
    fontSize: theme.typography.h4.fontSize,
    [theme.breakpoints.up('sm')]: {
      paddingBottom: '1rem',
    },
  },
  divider: {
    flexGrow: 1,
    height: 2,
    margin: '0',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '5rem',
    [theme.breakpoints.up('sm')]: {
      marginTop: '7rem',
    },
  },
  button: {
    width: '20rem',
    [theme.breakpoints.up('sm')]: {
      width: '40rem',
    },
  },
}));

export const HeadDivider = withStyles({
  root: {
    alignSelf: 'stretch',
    margin: '1rem 0',
    height: '1px',
    border: 'none',
    backgroundColor: '#C4C4C4',
  },
})(Divider);

export const BodyDivider = withStyles({
  root: {
    alignSelf: 'stretch',
    margin: '1rem 0',
    border: '1px solid #F0F0F0',
  },
})(Divider);
export const BoxDivider = withStyles({
  root: {
    alignSelf: 'stretch',
    margin: '1rem 0',
    border: '1px solid #F3F3F3',
  },
})(Divider);

export function getPngImageSrc(imageName: string) {
  try {
    return pngs(`./${imageName}.png`).default;
  } catch (err) {
    return '';
  }
}

export function getJpgImageSrc(imageName: string) {
  try {
    return jpgs(`./${imageName}.jpg`).default;
  } catch (err) {
    return '';
  }
}

export function getIconImageSrc(imageName: string) {
  try {
    return icons(`./${imageName}.svg`).default;
  } catch (err) {
    return '';
  }
}
