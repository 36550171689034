import React from 'react';
import { useStyles } from './StyledComponents';
import { Box } from '@material-ui/core';
import { getJpgImageSrc } from '../styles';
import { Button } from '../StyledComponents';
import { UserSatisfactionSurveyAnswer } from '../../../domain/entities/UserSatisfactionSurvey';
import { Title, Contents, Img } from './SurveyModal';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../config';
type Props = {
  questionName: string;
  answerSheet: UserSatisfactionSurveyAnswer;
  setAnswerSheet: (v: UserSatisfactionSurveyAnswer) => void;
};

export const SurveyFinish = ({
  questionName,
  answerSheet,
  setAnswerSheet,
}: Props): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();

  const finishSurvey = () => {
    history.replace(Routes.mypageSkinQuiz);
  };
  return (
    <Box
      className={classes.innerBox}
      style={{ maxWidth: '392px', minWidth: 'unset' }}
    >
      <Title>의견 보내주셔서 감사해요!</Title>
      <Contents>
        소중한 의견은 꼭 반영하여
        <br />더 나은 피부 리포트를 만들어갈게요
      </Contents>
      <Img src={getJpgImageSrc('survey_finish')} />
      <Button
        isValid
        isStart
        className={classes.startBtn}
        onClick={finishSurvey}
      >
        완료
      </Button>
    </Box>
  );
};
