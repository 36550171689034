import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import { FirstSection } from './FirstSection';
// import { SecondSection } from './SecondSection';
// import { ThirdSection } from './ThirdSection';
// import { FourthSection } from './FourthSection';
// import { SeventhSection } from './SeventhSection';

// import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';
// import { Theme } from '@material-ui/core';
// import { FifthSection } from './FifthSection';
// import { SixthSection } from './SixthSection';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../config';

// const useStyles = makeStyles((theme: Theme) => ({
//   root: {
//     'background': 'white',
//     'paddingTop': '7rem',
//     'overflowX': 'hidden',
//     'fontFamily': "'Pretendard',sans-serif",
//     '& strong': {
//       fontWeight: 'bold',
//     },
//   },
//   margin: {
//     height: '0px',
//     [theme.breakpoints.up('tablet')]: {
//       height: '25px',
//     },
//     [theme.breakpoints.up('desktopSm')]: {
//       height: '54px',
//     },
//   },
// }));

export const HomePage = (): JSX.Element => {
  const history = useHistory();
  history.push(Routes.skinQuiz);
  return (
    <>
      {/* <EventPopup /> */}
      {/* <main className={classes.root}>
        <ScrollToTopOnMount />
        <FirstSection />
        <div className={classes.margin}></div>
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FifthSection />
        <SixthSection />
        <SeventhSection />
      </main> */}
    </>
  );
};
