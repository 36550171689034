import React from 'react';
import {
  Box,
  Button,
  Hidden,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Routes } from '../../config';
import styled from 'styled-components';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';
import section1Video from '../../assets/video/introBrandingMain.mp4';
import section2ImageLarge from '../../assets/image/branding/section2Large.png';
import section2ImageSmall from '../../assets/image/branding/section2Small.png';
import section3ImageLarge from '../../assets/image/branding/section3Large.png';
import section3ImageSmall from '../../assets/image/branding/section3Small.png';
import section4ImageLarge from '../../assets/image/branding/section4Large.png';
import section4ImageSmall from '../../assets/image/branding/section4Small.png';
import section6ImageLarge1 from '../../assets/image/branding/section6Large1.png';
import section6ImageLarge2 from '../../assets/image/branding/section6Large2.png';
import section6ImageLarge3 from '../../assets/image/branding/section6Large3.png';
import section6ImageLarge4 from '../../assets/image/branding/section6Large4.png';
import section6ImageSmall1 from '../../assets/image/branding/section6Small1.png';
import section6ImageSmall2 from '../../assets/image/branding/section6Small2.png';
import section6ImageSmall3 from '../../assets/image/branding/section6Small3.png';
import section6ImageSmall4 from '../../assets/image/branding/section6Small4.png';
import section7ImageLarge from '../../assets/image/branding/section7Large.png';
import section7ImageSmall from '../../assets/image/branding/section7Small.png';
import section8ImageLarge from '../../assets/image/branding/section8Large.png';
import section8ImageSmall from '../../assets/image/branding/section8Small.png';
import Fade from 'react-reveal/Fade';
import ScrollToTopOnMount from '../utils/ScrollToTopOnMount';
import section1SubLogo from '../../assets/image/branding/logoManifold.png';
import { useRecoilValue } from 'recoil';
import { landingTextState } from '../states';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    backgroundColor: '#fff',
    [theme.breakpoints.up('sm')]: {},
  },
  section1: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '7rem',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'stretch',
      position: 'relative',
      marginTop: '7rem',
    },
  },
  section1Video: {
    width: '300%',
    position: 'relative',
    alignContent: 'center',
    marginLeft: '-100%',
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'stretch',
      position: 'relative',
      width: '100%',
      // display: 'flex',
      margin: 0,
    },
  },
  section1VideoBox: {
    overflow: 'hidden',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      marginTop: '10rem',
    },
  },
  section1SubLogo: {
    backgroundSize: 'contain',
    backgroundImage: `url(${section1SubLogo})`,
    width: '75px',
    display: 'block',
    margin: 'auto',
    height: '70.49px',

    [theme.breakpoints.up('sm')]: {
      backgroundSize: 'contain',
      backgroundImage: `url(${section1SubLogo})`,
      width: '138.92px',
      display: 'block',
      margin: 'auto',
      height: '129.17px',
    },
  },

  section1Sub1Text1: {
    display: 'flex',
    flexDirection: 'column',
    color: '#4F4F4F',
    marginBottom: '3rem',
    lineHeight: '2.2rem',
    letterSpacing: '0rem',
    fontSize: '18px',
    marginTop: '3rem',
    textAlign: 'center',
    fontWeight: 600,
    [theme.breakpoints.up('sm')]: {
      letterSpacing: '0px',
      fontSize: '35px',
      fontWeight: 500,
      lineHeight: '52.5px',
      textAlign: 'center',
      marginTop: '5rem',
      fontStyle: 'normal',
    },
  },
  section1Sub1Text2: {
    display: 'flex',
    flexDirection: 'column',
    color: '#4F4F4F',
    marginBottom: '3rem',
    lineHeight: '1.8rem',
    letterSpacing: '0.1rem',
    fontSize: '14px',
    fontWeight: 350,
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
      fontWeight: 400,
      textAlign: 'center',
      letterSpacing: '0px',
      lineHeight: '3rem',
    },
  },
  section1Sub1Text3Box: {
    [theme.breakpoints.up('sm')]: {},
  },

  section1Sub1Text3: {
    display: 'flex',
    flexDirection: 'column',
    color: '#4F4F4F',
    marginBottom: '3rem',
    lineHeight: '3.1rem',
    letterSpacing: '0.2rem',
    fontSize: '2rem',
    fontWeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '15px',
      fontWeight: 400,
      textAlign: 'center',
      letterSpacing: '0px',
      lineHeight: '2.5rem',
      marginTop: '80px',
    },
  },
  section1Sub1Text3M: {
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
    marginBottom: '8rem',
    lineHeight: '1.7rem',
    letterSpacing: '0.05rem',
    fontSize: '12px',
    fontWeight: 'normal',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {},
  },

  section1Sub1Text3MTitle: {
    display: 'flex',
    flexDirection: 'column',
    color: '#000000',
    marginBottom: '3rem',
    lineHeight: '1.7rem',
    letterSpacing: '0.05rem',
    fontSize: '16px',
    fontWeight: 700,
    marginTop: '8rem',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {},
  },
  section1Body: {
    top: '300px',
    position: 'absolute',
    [theme.breakpoints.up('sm')]: {
      height: '392px',
      mixblendmode: 'normal',
      top: '250px',
      width: '1000px',
    },
  },
  section2: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  section2Image: {
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  section2Text: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3.8rem 2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      margin: '150px 60px 10px 0px',
      alignItems: 'start',
      position: 'absolute',
      right: '10%',
    },
  },
  section2Title: {
    marginBottom: '2.7rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
      letterSpacing: '0.1rem',
      marginBottom: '4.7rem',
      fontSize: '30px',
      fontWeight: 550,
    },
  },
  section2Body: {
    marginBottom: '8rem',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '1.8em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '2.3rem',
      textAlign: 'left',
    },
  },
  section3: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  section3Image: {
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  section3Text: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3.8rem 2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      margin: '300px 0 10px 0px',
      position: 'absolute',
      left: '15%',
    },
  },
  section3Title: {
    marginBottom: '2.7rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      color: '#ffffff',
      textAlign: 'left',
      letterSpacing: '0.1rem',
      marginBottom: '4.7rem',
      fontSize: '30px',
      fontWeight: 550,
    },
  },
  section3Body: {
    marginBottom: '6rem',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '1.8em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      color: '#ffffff',
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '2.3rem',
      textAlign: 'left',
    },
  },
  section4: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  section4Image: {
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  section4Text: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3.8rem 2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      margin: '350px 60px 10px 0px',
      alignItems: 'start',
      position: 'absolute',
      right: '10%',
    },
  },
  section4Title: {
    marginBottom: '2.7rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      color: '#3E3E3E',
      textAlign: 'left',
      letterSpacing: '0.1rem',
      marginBottom: '4.7rem',
      fontSize: '30px',
      fontWeight: 550,
    },
  },
  section4Body: {
    marginBottom: '7rem',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '1.8em',
    textAlign: 'center',

    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '2.3rem',
      textAlign: 'left',
    },
  },
  section5: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#ffffff',
      // flexDirection: 'row',
      position: 'relative',
      margin: '0',
    },
  },
  section5Image: {
    flexDirection: 'row',
    // margin: '5px 5px 5px 5px',
    width: '90%',
    // textAlign: 'center',
    marginBottom: '10rem',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '0px',
      width: '100%',
      margin: 0,
    },
  },
  section5Text: {
    display: 'flex',
    flexDirection: 'column',
    margin: '3.8rem 2rem 1rem',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  section5Title: {
    marginBottom: '2.7rem',
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center',
    marginTop: '3rem',
    [theme.breakpoints.up('sm')]: {
      color: '#0000000',
      textAlign: 'center',
      letterSpacing: '0.1rem',
      marginBottom: '4.7rem',
      fontSize: '30px',
      fontWeight: 550,
    },
  },
  section5Body: {
    marginBottom: '4rem',
    fontSize: '14px',
    fontWeight: 'normal',
    lineHeight: '1.8em',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      fontSize: '16px',
      fontWeight: 300,
      lineHeight: '2.3rem',
      textAlign: 'center',
    },
  },
  section6: {
    display: 'flex',
    // flexDirection: 'column-reverse',
    justifyContent: 'center',
    backgroundColor: '#F8F8F8',
    [theme.breakpoints.up('sm')]: {
      backgroundColor: '#ffffff',
      flexDirection: 'row',
      margin: '0',
    },
  },
  section6Imageslider: {
    flexDirection: 'row',
    margin: '25px',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  section7: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    backgroundColor: '#ffffffff',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  section7Image: {
    flexDirection: 'row',
    // margin: '25px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  section8: {
    display: 'flex',
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    backgroundColor: '#ffffffff',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  section8Image: {
    flexDirection: 'row',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
    },
  },
  section9: {
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffffff',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  section9Body: {
    fontWeight: 100,
    fontSize: '1.8rem',
    textAlign: 'center',
    color: '#626262',
    marginTop: '3rem',
    marginBottom: '3rem',
    [theme.breakpoints.up('sm')]: {
      color: '#626262',
      fontWeight: 400,
      fontSize: '4.7rem',
      marginTop: '-10rem',
      marginBottom: '3rem',
    },
  },
  buttonSection4: {
    width: '20.4rem',
    height: '4rem',
    border: '0.22rem solid #000000',
    boxSizing: 'border-box',
    borderRadius: '5.5rem',
    marginTop: '0rem',
    marginBottom: '5rem',
    fontSize: '1.4rem',
    fontWeight: 700,

    [theme.breakpoints.up('sm')]: {
      marginBottom: '10rem',
      marginLeft: '2rem',
      width: '40.4rem',
      height: '6.5rem',
      fontSize: '2.3rem',
      fontWeight: 700,
    },
  },
  bottomButton: {
    marginBottom: '10.8rem',
    alignItems: 'center',
    marginTop: '2rem',
    [theme.breakpoints.up('sm')]: {
      width: '43.2rem',
      alignItems: 'center',
      marginTop: '1rem',
      marginBottom: '15.5rem',
      // left: '-20%',
    },
  },
}));

const Container = styled.div`
  overflow: hidden;
`;

const StyledSlider = styled(Slider)`
  .slick-slide div {
    outline: none;
  }
`;

const ImageContainer = styled.div`
  margin: 0 0;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

const itemsL = [
  { id: 1, url: section6ImageLarge1 },
  { id: 2, url: section6ImageLarge2 },
  { id: 3, url: section6ImageLarge3 },
  { id: 4, url: section6ImageLarge4 },
];
const itemsS = [
  { id: 1, url: section6ImageSmall1 },
  { id: 2, url: section6ImageSmall2 },
  { id: 3, url: section6ImageSmall3 },
  { id: 4, url: section6ImageSmall4 },
];

export default function Branding(): JSX.Element {
  const history = useHistory();
  const landingText = useRecoilValue(landingTextState);
  const landingStyle = landingText?.buttonClassName1;
  const onCTAClick = () => {
    history.push(Routes.skinQuiz);
  };
  const classes = useStyles();
  const theme = useTheme();
  const smUP = useMediaQuery(theme.breakpoints.up('sm'));
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    lazyLoad: true,
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.section1}>
        <ScrollToTopOnMount />
        {smUP ? (
          <video
            autoPlay
            muted
            loop
            playsInline
            className={classes.section1Video}
          >
            <source src={section1Video}></source>
          </video>
        ) : (
          <Box className={classes.section1VideoBox}>
            <video
              autoPlay
              muted
              loop
              playsInline
              className={classes.section1Video}
            >
              <source src={section1Video}></source>
            </video>
          </Box>
        )}

        <Typography className={classes.section1Body}>
          <Fade bottom distance="25px">
            <Box className={classes.section1SubLogo} />
            <Typography className={classes.section1Sub1Text1}>
              디지털 스킨케어 브랜드
              <br />
              매니폴드
              <br />
            </Typography>
          </Fade>
          <Hidden xsDown>
            <br />
          </Hidden>
          <Hidden xsDown>
            <br />
          </Hidden>
          <Fade bottom distance="25px">
            {smUP ? (
              <Typography className={classes.section1Sub1Text2}>
                스마트폰 하나로 피부 분석부터 최적의 성분조합 매칭까지,
                <br />
                피부과학을 AI에 담아 맞춤형 스킨케어의 본질에 다가갑니다.
              </Typography>
            ) : (
              <Typography className={classes.section1Sub1Text2}>
                스마트폰 하나로 피부 분석부터
                <br />
                최적의 성분조합 매칭까지,
                <br />
                피부과학을 AI에 담아
                <br />
                맞춤형 스킨케어의 본질에 다가갑니다.
              </Typography>
            )}
          </Fade>
          <Hidden xsDown>
            <br />
          </Hidden>
          {smUP ? (
            <Typography className={classes.section1Sub1Text3}>
              매니폴드(manifold)는 데이터가 살고 있는 n차원의 부드러운
              위상공간을 말합니다.
              <br />
              우리는 당신의 피부데이터와 화장품성분데이터를 매니폴드 상의 좌표로
              학습, 주기적인 적용과 측정을 통해 피부를 위한 최적의 스킨케어를
              찾아드립니다.
            </Typography>
          ) : (
            <br />
          )}
        </Typography>
        {smUP ? (
          <div />
        ) : (
          <Box className={classes.section1Sub1Text3Box}>
            <Typography className={classes.section1Sub1Text3MTitle}>
              manifold
            </Typography>
            <Typography className={classes.section1Sub1Text3M}>
              매니폴드(manifold)는 데이터가 살고 있는
              <br />
              n차원의 부드러운 위상공간을 말합니다.
              <br />
              우리는 당신의 피부데이터와 화장품성분데이터를
              <br />
              매니폴드 상의 좌표로 학습, 주기적인 적용과 측정을 통해
              <br />
              피부를 위한 최적의 스킨케어를 찾아드립니다.
            </Typography>
          </Box>
        )}
      </Box>
      <Box className={classes.section2}>
        <img
          className={classes.section2Image}
          src={smUP ? section2ImageLarge : section2ImageSmall}
        />
        <Box className={classes.section2Text}>
          {smUP ? (
            <Typography className={classes.section2Title}>
              당신에겐 당신 피부에 꼭 맞는
              <br />
              스킨케어가 필요합니다.
            </Typography>
          ) : (
            <Typography className={classes.section2Title}>
              당신에겐 당신 피부에
              <br />꼭 맞는 스킨케어가 필요합니다.
            </Typography>
          )}
          {smUP ? (
            <Typography className={classes.section2Body}>
              모든 피부에 항상 좋은 성분이란 없습니다.
              <br />
              지금 당신 피부에 꼭 맞는 성분만이 있을 뿐입니다.
            </Typography>
          ) : (
            <Typography className={classes.section2Body}>
              모든 피부에 항상 좋은 성분이란 없습니다.
              <br />
              지금 당신 피부에 꼭 맞는 성분만이 있을 뿐입니다.
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.section3}>
        <img
          className={classes.section3Image}
          src={smUP ? section3ImageLarge : section3ImageSmall}
        />
        <Box className={classes.section3Text}>
          {smUP ? (
            <Typography className={classes.section3Title}>
              꼭 맞는 스킨케어의 기준
              <br />
              당신의 피부데이터입니다.
            </Typography>
          ) : (
            <Typography className={classes.section3Title}>
              꼭 맞는 스킨케어의 기준
              <br />
              당신의 피부데이터입니다.
            </Typography>
          )}
          {smUP ? (
            <Typography className={classes.section3Body}>
              피부를 보지 않고 피부 맞춤을 할 순 없습니다.
              <br />
              매니폴드는 데이터로 분석하고, 적용하고, 관찰합니다.
            </Typography>
          ) : (
            <Typography className={classes.section3Body}>
              피부를 보지 않고 피부 맞춤을 할 순 없습니다.
              <br />
              매니폴드는 데이터로 분석하고,
              <br />
              적용하고, 관찰합니다.
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.section4}>
        <img
          className={classes.section4Image}
          src={smUP ? section4ImageLarge : section4ImageSmall}
        />
        <Box className={classes.section4Text}>
          <Typography className={classes.section4Title}>
            지금 당신을 위한 최적의 스킨케어
            <br />
            미래 당신을 위한 최선의 피부변화
          </Typography>

          <Typography className={classes.section4Body}>
            매니폴드의 인공지능 기술이 당신의 피부를 케어합니다.
            <br />
            공학을 신뢰하는 당신의 디지털 스킨케어 브랜드.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.section6}>
        {smUP ? (
          <Container>
            <h5 style={{ color: 'white' }}>var </h5>
            <StyledSlider {...settings}>
              {itemsL.map((item) => {
                return (
                  <div key={item.id}>
                    <ImageContainer>
                      <Image src={item.url} />
                    </ImageContainer>
                  </div>
                );
              })}
            </StyledSlider>
            <Hidden xsDown>
              <br />
            </Hidden>
          </Container>
        ) : (
          <Container>
            <h5 style={{ color: 'white' }}>var </h5>
            <StyledSlider {...settings}>
              {itemsS.map((item) => {
                return (
                  <div key={item.id}>
                    <ImageContainer>
                      <Image src={item.url} />
                    </ImageContainer>
                  </div>
                );
              })}
            </StyledSlider>
            <Hidden xsDown>
              <br />
            </Hidden>
          </Container>
        )}
      </Box>
      <Box className={classes.section5}>
        <Box className={classes.section5Text}>
          <Fade bottom distance="25px">
            <Typography className={classes.section5Title}>
              피부 전문가와 인공지능 전문가
              <br />
              그리고 최고의 기업들이 함께합니다.
            </Typography>
          </Fade>
          {smUP ? (
            <Typography className={classes.section5Body}>
              피부과학을 AI에 담아 피부 맞춤 디자인 스킨케어를 실현합니다.
            </Typography>
          ) : (
            <Typography className={classes.section5Body}>
              피부과학을 AI에 담아
              <br />
              피부 맞춤 디자인 스킨케어를 실현합니다.
            </Typography>
          )}
        </Box>
      </Box>
      <Box className={classes.section7}>
        <img
          className={classes.section7Image}
          src={smUP ? section7ImageLarge : section7ImageSmall}
        />
      </Box>
      <Box className={classes.section8}>
        <img
          className={classes.section8Image}
          src={smUP ? section8ImageLarge : section8ImageSmall}
        />
      </Box>
      <Box className={classes.section8}>
        <Typography className={classes.section9Body}>
          Data matters, make your skin better
        </Typography>
      </Box>
      <Box className={classes.section9}>
        <Button
          className={`${classes.buttonSection4} ${classes.bottomButton} ${landingStyle}`}
          onClick={onCTAClick}
        >
          피부 테스트 시작
        </Button>
      </Box>
    </Box>
  );
}
