import Coupon from '../entities/Coupon';
import { Invoice } from '../entities/Invoice';
import { OrderItem } from '../entities/Order';
import { SkinQuizSolution } from '../entities/SkinQuizSolution';
import { Subscription } from '../entities/Subscription';
import { User, UserItem } from '../entities/User';
import { UserAddress } from '../entities/UserAddress';

export interface RegisterUserParams {
  userId: string;
  displayName: string;
  phoneNumber: string;
  birthdate: string;
  gender?: string;
}

export interface UpdateUserParams {
  userId: string;
  displayName?: string;
  useNotification?: boolean;
  hasReportFeedback?: boolean;
}

export interface RegisterUserResult {
  userId: string;
  isExists?: boolean;
  createdAt?: string;
}

export interface SatisfactionSurveyResult {
  userId: string;
  name: string;
  latestMembershipName?: string | undefined;
  firstAnswer?: number;
  secondAnswer?: string;
  thirdAnswer?: string;
  fourthAnswer?: string;
}

export abstract class UserRepository {
  public abstract getUser(userId: string): Promise<User>;
  public abstract getUserByEmail(email: string): Promise<UserItem>;
  public abstract getUserByPhoneNumber(phoneNumber: string): Promise<UserItem>;
  public abstract getUserSolution(quizId: string): Promise<SkinQuizSolution>;
  public abstract registerUser(
    params: RegisterUserParams,
  ): Promise<RegisterUserResult>;
  public abstract updateUser(params: UpdateUserParams): Promise<void>;
  public abstract updateUserQuiz(userId: string, quizId: string): Promise<void>;
  public abstract updateUserAddress(
    userId: string,
    address: UserAddress,
  ): Promise<void>;
  public abstract getSubscription(
    subscriptionId: string,
  ): Promise<Subscription>;
  public abstract createSubscription(
    membershipProductId: string,
    items: OrderItem[] | undefined,
    firstDeliveryDate: Date,
    coupon?: string,
  ): Promise<Subscription>;
  public abstract createSubscriptionBilling(
    subscriptionId: string,
    receiptId?: string,
  ): Promise<void>;
  public abstract getUpcomingInvoice(userId: string): Promise<Invoice>;
  public abstract updateSubscriptionPeriod(
    subscriptionId: string,
    interval: number,
  ): Promise<void>;
  public abstract checkCoupon(code: string, productId: string): Promise<Coupon>;
}
