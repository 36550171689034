import firebase from 'firebase/app';
import 'firebase/remote-config';

import { ConfigKey, ConfigService } from './ConfigService';

const remoteConfig = firebase.remoteConfig();

if (process.env.NODE_ENV === 'development') {
  remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
}

remoteConfig.defaultConfig = {
  landing_mode: 'image',
};

export class FirebaseConfig extends ConfigService {
  async fetch(): Promise<boolean> {
    try {
      return await remoteConfig.fetchAndActivate();
    } catch (err) {
      console.error(err);
    }
  }
  getString(key: ConfigKey): string {
    return remoteConfig.getString(key);
  }
  getNumber(key: ConfigKey): number {
    return remoteConfig.getNumber(key);
  }
  getBoolean(key: ConfigKey): boolean {
    return remoteConfig.getBoolean(key);
  }
}
