import React from 'react';
import {
  QuestionContainer,
  QuestionTitleBlock,
  TextArea,
} from '../StyledComponents';
import { QuestionProps } from './QuestionProps';

function TextQuestion({
  question,
  answerSheet,
  setAnswerSheet,
}: QuestionProps) {
  const questionId = `question${question.id}`;

  const onChange = ({ target }) => {
    setAnswerSheet({
      ...answerSheet,
      [questionId]: target.value,
    });
  };
  return (
    <QuestionContainer key={question.id}>
      <QuestionTitleBlock>{`${question.idSub}.${question.title}`}</QuestionTitleBlock>
      <TextArea
        id={String(question.id)}
        onChange={onChange}
        value={answerSheet[questionId]}
        placeholder={question.placeholder}
      />
    </QuestionContainer>
  );
}

export default TextQuestion;
