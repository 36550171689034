import React, { useEffect, useState } from 'react';
import Calendar, {
  CalendarTileProperties,
  ViewCallbackProperties,
} from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { makeStyles } from '@material-ui/core/styles';
import prevMonthIcon from '../../../assets/icon/icon_prev_month.png';
import nextMonthIcon from '../../../assets/icon/icon_next_month.png';
import { addBusinessDays, format, isSameDay, isSameMonth } from 'date-fns';
import { CalendarTile } from './CalendarTile';
import { Theme } from '@material-ui/core';
import {
  getNewMinSelectableDayByEventDate,
  isDisabledDate,
} from '../../utils/deliveryDate';

type Props = {
  selectedDate: Date;
  updateSelectedDate: (value: Date) => void;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'display': 'flex',
    'justifyContent': 'center',
    'alignItems': 'center',

    // basic react-calendar styling to apply our own design
    '& .react-calendar': {
      'border': 'none',
      'margin': '55px 5px 10px',
      'width': 'calc(100% - 10px)',
      'background': 'transparent',

      [theme.breakpoints.up('sm')]: {
        margin: '74px 0 20px',
        width: '100%',
      },

      '& :disabled': {
        background: 'transparent',
        color: '#C4C4C4',
      },

      '& :enabled': {
        background: 'transparent',
      },

      '& .react-calendar__navigation': {
        height: 'auto',
        marginBottom: '15px',

        [theme.breakpoints.up('sm')]: {
          marginBottom: '27px',
        },
      },

      '& .react-calendar__navigation button': {
        background: 'transparent',
        fontSize: '1.5rem',

        [theme.breakpoints.up('sm')]: {
          fontSize: '2.2rem',
        },
      },

      '& .react-calendar__tile--active': {
        color: 'black',
      },

      '& .react-calendar__navigation__label': {
        color: 'black',
      },

      '& .react-calendar__tile': {
        'padding': '0px',

        '& > abbr': {
          display: 'none',
        },
      },

      '& .react-calendar__month-view__days__day--weekend': {
        color: '#C4C4C4',
      },

      '& .react-calendar__month-view__weekdays__weekday': {
        'borderBottom': '2px #C4C4C4 solid',
        'padding': '0.7rem',

        [theme.breakpoints.up('sm')]: {
          padding: '1.4rem',
        },

        '& > abbr': {
          fontSize: '1rem',
          fontWeight: 'lighter',
          textDecoration: 'none',

          [theme.breakpoints.up('sm')]: {
            fontSize: '1.3rem',
          },
        },
      },

      '& .react-calendar__month-view__days': {
        position: 'relative',
        top: '-1px',
      },
    },
  },

  monthControlButton: {
    'width': '1.8rem',
    'height': '1.8rem',
    'backgroundColor': '#F6F6F6',
    'borderRadius': '50%',
    'display': 'grid',
    'justifyContent': 'center',
    'alignContent': 'center',

    '& > img': {
      width: '0.6rem',
      height: '0.6rem',
    },
  },
}));

const oneDay = 1000 * 60 * 60 * 24;

export const SelectFirstDeliveryDateCalendar = ({
  selectedDate,
  updateSelectedDate,
}: Props): JSX.Element => {
  const classes = useStyles();
  const today = new Date();
  const [currentViewMonth, setCurrentViewMonth] = useState(true);

  function createTile({ date }: CalendarTileProperties): JSX.Element {
    const isToday = isSameDay(date, today);
    const isSelectedDate = isSameDay(date, selectedDate);
    return (
      <CalendarTile
        date={date}
        isToday={isToday}
        isSelectedDate={isSelectedDate}
      />
    );
  }

  const currentViewMonthChange = ({
    activeStartDate,
  }: ViewCallbackProperties) => {
    const isThisMonth = isSameMonth(activeStartDate, today);
    setCurrentViewMonth(isThisMonth);
  };

  function formatMonthYear(locale: string, date: Date): string {
    return format(date, 'yyyy.MM');
  }

  function createPrevControlButton(): JSX.Element {
    return (
      <div className={classes.monthControlButton}>
        <img src={prevMonthIcon} alt={'이전 달'} />
      </div>
    );
  }

  function createNextControlButton(): JSX.Element {
    return (
      <div className={classes.monthControlButton}>
        <img src={nextMonthIcon} alt={'다음 달'} />
      </div>
    );
  }

  let minSelectableDay = new Date(
    today.getHours() < 10 ? today : addBusinessDays(today, 1),
  );
  const maxSelectableDay = new Date(minSelectableDay.getTime() + oneDay * 20); // approx. 21 days

  if (isDisabledDate(minSelectableDay)) {
    minSelectableDay = getNewMinSelectableDayByEventDate();
  }

  useEffect(() => {
    updateSelectedDate(minSelectableDay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className={classes.root}>
      <Calendar
        calendarType={'US'} // to make Sunday appear first
        locale={'en'}
        formatMonthYear={formatMonthYear}
        showNeighboringMonth={false}
        minDate={minSelectableDay}
        maxDate={maxSelectableDay}
        minDetail={'month'}
        maxDetail={'month'}
        next2Label={null}
        prev2Label={null}
        prevLabel={currentViewMonth ? <div></div> : createPrevControlButton()}
        nextLabel={createNextControlButton()}
        tileContent={createTile}
        onClickDay={updateSelectedDate}
        onActiveStartDateChange={currentViewMonthChange}
        tileDisabled={({ date }) => isDisabledDate(date)}
      />
    </section>
  );
};
