import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing:border-box;
  font-family: 'Noto Sans KR', sans-serif;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'Noto Sans KR', sans-serif;

  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none;  /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
}
button {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

#text{
  
}
input {
  padding: 0;
  outline:none;
  border:none;
  -webkit-border-radius: 0;
}
a:link {
    text-decoration: none ;
  }

  a:visited {
    text-decoration: none ;
  }

  a:hover {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }
  
h1 {
  font-size: 32px;
  line-height: 150%;
}

button {
  outline:none;
  /* font-family:'Noto Sans KR', sans-serif; */
}

`;
