import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useRecoilValue } from 'recoil';
import { authUserState, skinQuizSolutionState } from '../../states';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    'margin': '47px 16px 35px',
    'textAlign': 'center',
    [theme.breakpoints.up('sm')]: {
      margin: '68px 0px 34px',
    },

    '& > hr': {
      width: '100%',
      height: '1px',
      border: 'none',
      backgroundColor: '#F0F0F0',

      [theme.breakpoints.up('sm')]: {
        maxWidth: '328px',
      },
    },
  },
  itemContainer: {
    'display': 'flex',
    'alignItems': 'center',
    'backgroundColor': 'white',
    'border': '1px #F0F0F0 solid',
    'boxShadow': '0 5px 23px #0000000C',
    'marginBottom': '6px',
    'padding': '18px 5px 18px 13px',
    'cursor': 'pointer',

    [theme.breakpoints.up('sm')]: {
      padding: '29px 20px 29px 35px',
    },

    '& > img': {
      width: '8.1rem',
      height: '14.5rem',
      objectFit: 'contain',

      [theme.breakpoints.up('sm')]: {
        width: '12rem',
        height: '21.5rem',
      },
    },
  },

  title: {
    'fontSize': '2rem',
    'marginBottom': '10px',
    'textAlign': 'center',

    [theme.breakpoints.up('sm')]: {
      fontSize: '3rem',
      display: 'inline',
    },

    '& strong': {
      fontWeight: 'bold',
    },
  },
}));

export const HeaderSection = (): JSX.Element => {
  const classes = useStyles();
  const skinQuizSolution = useRecoilValue(skinQuizSolutionState);
  const authUser = useRecoilValue(authUserState);
  const userName = skinQuizSolution?.userName || authUser?.displayName;

  return (
    <section className={classes.root}>
      <p className={classes.title}>
        <strong>{userName}님</strong>을 위한&nbsp;
      </p>
      <p className={classes.title}>1:1 맞춤형 스킨케어</p>
    </section>
  );
};
