import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

type Props = {
  title: string;
  subtitle: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: '13px',
    textAlign: 'start',

    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
      marginLeft: 0,
    },
  },

  title: {
    fontSize: '1.3rem',
    fontWeight: 500,

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
    },
  },

  subtitle: {
    marginTop: '5px',
    fontSize: '1.3rem',
    fontWeight: 'lighter',

    [theme.breakpoints.up('sm')]: {
      fontSize: '2rem',
      marginTop: '10px',
    },
  },
}));

export const DeliveryDateInfoItem = (props: Props): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <p className={classes.title}>{props.title}</p>
      <p className={classes.subtitle}>{props.subtitle}</p>
    </div>
  );
};
