export class Consts {
  static readonly ERROR_SERVER_ERROR = 'SERVER_ERROR';
  static readonly ERROR_PHONE_NUMBER_ALREADY_EXISTS =
    'PHONE_NUMBER_ALREADY_EXISTS';
  static readonly ERROR_INVALID_CARD_INFO = 'INVALID_CARD_INFO';
  static readonly ERROR_SUBSCRIPTION_ALREADY_EXISTS =
    'SUBSCRIPTION_ALREADY_EXISTS';
  static readonly ERROR_INVALID_COUPON = 'INVALID_COUPON';
  static readonly ERROR_INVALID_ORDER = 'INVALID_ORDER';
  static readonly ERROR_INVALID_SUBSCRIPTION = 'INVALID_SUBSCRIPTION';

  static readonly ERROR_NOT_FOUND_ORDER = 'NOT_FOUND_ORDER';
  static readonly ERROR_NOT_FOUND_SUBSCRIPTION = 'NOT_FOUND_SUBSCRIPTION';
  static readonly ERROR_NOT_FOUND_COUPON = 'NOT_FOUND_COUPON';

  static readonly ERROR_PG_BILLING_KEY = 'PG_BILLING_KEY';
  static readonly ERROR_PG_BILLING = 'PG_BILLING';

  static readonly ERROR_COUPON_EXPIRED = 'COUPON_EXPIRED';
  static readonly ERROR_COUPON_EXCEED_LIMIT = 'COUPON_EXCEED_LIMIT';
  static readonly ERROR_COUPON_INVALID_PRODUCT = 'COUPON_INVALID_PRODUCT';
  static readonly ERROR_COUPON_INVALID_EMAIL = 'COUPON_INVALID_EMAIL';
  static readonly ERROR_INVALID_RECEIPT_ID = 'INVALID_RECEIPT_ID';
}
